import { SelectOption } from '../atoms/Select/Select';

export const AWS_REGIONS: SelectOption[] = [
  { label: 'US East (N. Virginia)', value: 'us-east-1' },
  { label: 'US East (Ohio)', value: 'us-east-2' },
  { label: 'US West (N. California)', value: 'us-west-1' },
  { label: 'Africa (Cape Town)', value: 'af-south-1' },
  { label: 'Asia Pacific (Hong Kong)', value: 'ap-east-1' },
  { label: 'Asia Pacific (Hyderabad)', value: 'ap-south-2' },
  { label: 'Asia Pacific (Jakarta)', value: 'ap-southeast-3' },
  { label: 'Asia Pacific (Melbourne)', value: 'ap-southeast-4' },
  { label: 'Asia Pacific (Mumbai)', value: 'ap-south-1' },
  { label: 'Asia Pacific (Osaka)', value: 'ap-northeast-3' },
  { label: 'Asia Pacific (Seoul)', value: 'ap-northeast-2' },
  { label: 'Asia Pacific (Singapore)', value: 'ap-southeast-1' },
  { label: 'Asia Pacific (Sydney)	', value: 'ap-southeast-2' },
  { label: 'Asia Pacific (Tokyo)', value: 'ap-northeast-1' },
  { label: 'Canada (Central)', value: 'ca-central-1	' },
  { label: 'Europe (Frankfurt)', value: 'eu-central-1' },
  { label: 'Europe (Ireland)', value: 'eu-west-1' },
  { label: 'Europe (London)	', value: 'eu-west-2' },
  { label: 'Europe (Milan)', value: 'eu-south-1' },
  { label: 'Europe (Paris)', value: 'eu-west-3' },
  { label: 'Europe (Spain)', value: 'eu-south-2' },
  { label: 'Europe (Stockholm)', value: 'eu-north-1' },
  { label: 'Europe (Zurich)', value: 'eu-central-2' },
  { label: 'Middle East (Bahrain)', value: 'me-south-1' },
  { label: 'Middle East (UAE)', value: 'me-central-1' },
  { label: 'South America (São Paulo)', value: 'sa-east-1' },
  { label: 'AWS GovCloud (US-East)', value: 'us-gov-east-1' },
  { label: 'AWS GovCloud (US-West)', value: 'us-gov-west-1' },
];

export const SCHEDULE_TYPES = [
  { label: 'Real time', value: 'realTime' },
  { label: 'One time', value: 'oneTime' },
  { label: 'Recurring', value: 'recurring' },
];

export const DATABASE_TYPES = [
  { label: 'Postgress', value: 'postgres' },
  { label: 'SqlServer', value: 'sqlserver' },
];

export const MARKETTING_TYPES = [
  { label: 'Finance', value: 'finance' },
  { label: 'Correspondence', value: 'correspondence' },
  { label: 'Social', value: 'social' },
  { label: 'Web', value: 'web' },
  { label: 'MobileApp', value: 'mobileapp' },
];

export enum ALIGN_BUTTONS {
  ALIGN_CENTRE = 'CENTER',
  ALIGN_LEFT = 'LEFT',
  ALIGN_RIGHT = 'RIGHT',
}

export enum CAMPAIGN_TYPE_ICONS {
  FORMS = 'https://cdn-icons-png.flaticon.com/512/11725/11725689.png',
  PDF = 'https://cdn-icons-png.flaticon.com/512/11180/11180336.png',
  SOCIAL = 'social-media-campaign-type.png',
}
