import { Link, useNavigate } from 'react-router-dom';
import './NewHeader.scss';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Button } from '../../atoms/Button/Button';

export const NewHeader: FC = () => {
  const navigate = useNavigate();
  // const isLoggedIn: boolean = localStorage.getItem('user.accessToken') !== null;
  const accessToken = useSelector(
    (state: RootState) => state.cmsAppSlice.accessToken,
  );
  const handleLogout = () => {
    localStorage.setItem('user.refreshToken', '');
    localStorage.setItem('user.accessToken', '');
    localStorage.setItem('user.email', '');

    localStorage.clear();
    navigate('/login');
  };
  return (
    <>
      <div className="nav">
        <input type="checkbox" id="nav-check" />
        <div className="nav-header">
          <div className="nav-title">
            <div className="logo">
              <Link to="/">
                <img
                  src="NCampaignSuite-white-logo.png"
                  className="white-logo"
                />
                <img
                  src="NCampaignSuite-logo-hD.png"
                  className="default-logo"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="nav-btn">
          <label>
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        <div className="nav-links">
          <ul>
            <li>
              <Link to="/">About</Link>
            </li>
            {/* <li>
              <Link to="/pdftemplates">PDF Templates</Link>
            </li> */}
            <li>
              <Link to="/mergepdf">Merge PDF</Link>
            </li>
            {accessToken && (
              <li>
                <Link to="/campaigns">Your campaigns</Link>
              </li>
            )}
            {!accessToken && (
              <li className="flex">
                <Link to="/login" className="header-contact-btn">
                  Login
                </Link>
                <Link to="/signup" className="header-contact-btn">
                  Sign Up
                </Link>
              </li>
            )}
            {accessToken && window.location.pathname !== '/login' && (
              <li className="flex">
                {/* <Link
                  to="/"
                  onClick={handleLogout}
                  className="header-contact-btn"
                >
                  Logout
                </Link> */}
                {<Button onClick={handleLogout} name={'Logout'} />}
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};
