import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { CampaignInfo } from '../../types/campaign';

export const createCampaign = async (
  createCampaignRequest: CampaignInfo,
): Promise<string> =>
  api
    .post(URLs.CREATE_CAMPAIGN, createCampaignRequest)
    .then((res: any) => res.data);

export const useCreateCampaign = (): UseMutationResult<
  string,
  Error,
  CampaignInfo
> => {
  return useMutation(
    [QueryKeys.CreateCampaign],
    (createCampaignRequest: CampaignInfo) =>
      createCampaign(createCampaignRequest),
  );
};
