import { FC } from 'react';
import './WebAudience.scss';
export const WebAudience: FC = () => {
  return (
    <div className="webAudience-container">
      <div className="url-text">https://www.abc.com/campaigns/</div>
      <div className="url-textbox">
        <input type="text" placeholder="Enter URL" />
      </div>
    </div>
  );
};
