import './CampaignList.scss';
import { FC, useEffect, useState } from 'react';
import { CampaignListTable } from './CampaignListTable';
import { Pagination } from '../../components/Pagination/Pagination';
import { Pagination as PaginationObj } from '../../types/campaign';
import { useGetCampaignList } from '../../hooks/useGetCampaignList/useGetCampaignList';

export const CampaignList: FC = () => {
  const { data: campaignList, mutate: loadCampaignList } = useGetCampaignList();

  const [pagination, setPagination] = useState<PaginationObj>({
    startIndex: 1,
    endIndex: 1,
    currentPage: 1,
    pageSize: 20,
  });

  useEffect(() => {
    loadCampaignList(pagination);
  }, []);

  return (
    <div className="brandList-container">
      <div className="flex-style align-item-end">
        {/* <div className="img"></div> */}

        <div className="text-container">
          <p>Your documents</p>
        </div>
      </div>

      <div className="headings">
        <div>Name</div>
        <div>Description</div>
        <div>Type</div>

        <div>Created Date</div>
        <div> Preview/Select</div>

        <div></div>
      </div>

      <div className="line"></div>

      <div className="lists">
        <CampaignListTable
          campaignList={campaignList?.records ? campaignList.records : []}
        />
      </div>
      <div
        style={{
          display: 'block',
          textAlign: 'end',
        }}
      >
        <Pagination
          updatePagination={setPagination}
          noOfPages={campaignList?.noOfPages ? campaignList.noOfPages : 1}
        />
      </div>
    </div>
  );
};
