import { FC } from 'react';
import './Pagination.scss';
import { Pagination as PaginationObj } from '../../types/campaign';

export interface PaginationProps {
  updatePagination: (pagination: PaginationObj) => void;
  noOfPages: number;
}

export const Pagination: FC<PaginationProps> = ({
  updatePagination,
  noOfPages,
}) => {
  return (
    <div className="cm-pagination">
      <li>&laquo;</li>
      {Array.from({ length: noOfPages }, (v, i) => `${i + 1}`).map(
        (page, index) => (
          <li
            key={page}
            onClick={() =>
              updatePagination({
                startIndex: 1,
                endIndex: 1,
                currentPage: index + 1,
                pageSize: 20,
              })
            }
          >
            {page}
          </li>
        ),
      )}

      <li>&raquo;</li>
    </div>
  );
};
