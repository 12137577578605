import { Title } from '../../atoms/Title/Title';
import './ManageUsers.scss';

const ManageUsers = () => {
  const employeesData = [
    {
      user: 'Oluwadamilare Moody',
      image: 'profileIcon.png',
      email: 'KaydynU@email.com',
      addedOn: 'May 28, 2023',
      status: 'Active',
    },
  ];

  return (
    <div className="ManageUser-container">
      <div className="flex-space-between">
        <Title text="Manage users" />

        <div className="add-user">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.55 10.45V13.3C8.55 13.5692 8.6412 13.7949 8.8236 13.9773C9.006 14.1597 9.23147 14.2506 9.5 14.25C9.76917 14.25 9.99495 14.1588 10.1773 13.9764C10.3597 13.794 10.4506 13.5685 10.45 13.3V10.45H13.3C13.5692 10.45 13.7949 10.3588 13.9773 10.1764C14.1597 9.994 14.2506 9.76853 14.25 9.5C14.25 9.23083 14.1588 9.00505 13.9764 8.82265C13.794 8.64025 13.5685 8.54937 13.3 8.55H10.45V5.7C10.45 5.43083 10.3588 5.20505 10.1764 5.02265C9.994 4.84025 9.76853 4.74937 9.5 4.75C9.23083 4.75 9.00505 4.8412 8.82265 5.0236C8.64025 5.206 8.54937 5.43147 8.55 5.7V8.55H5.7C5.43083 8.55 5.20505 8.6412 5.02265 8.8236C4.84025 9.006 4.74937 9.23147 4.75 9.5C4.75 9.76917 4.8412 9.99495 5.0236 10.1773C5.206 10.3597 5.43147 10.4506 5.7 10.45H8.55ZM9.5 19C8.18583 19 6.95083 18.7505 5.795 18.2514C4.63917 17.7523 3.63375 17.0756 2.77875 16.2212C1.92375 15.3663 1.24703 14.3608 0.7486 13.205C0.250167 12.0492 0.000633333 10.8142 0 9.5C0 8.18583 0.249533 6.95083 0.7486 5.795C1.24767 4.63917 1.92438 3.63375 2.77875 2.77875C3.63375 1.92375 4.63917 1.24703 5.795 0.7486C6.95083 0.250167 8.18583 0.000633333 9.5 0C10.8142 0 12.0492 0.249533 13.205 0.7486C14.3608 1.24767 15.3663 1.92438 16.2212 2.77875C17.0763 3.63375 17.7533 4.63917 18.2523 5.795C18.7514 6.95083 19.0006 8.18583 19 9.5C19 10.8142 18.7505 12.0492 18.2514 13.205C17.7523 14.3608 17.0756 15.3663 16.2212 16.2212C15.3663 17.0763 14.3608 17.7533 13.205 18.2523C12.0492 18.7514 10.8142 19.0006 9.5 19Z"
              fill="#00580A"
            />
          </svg>

          <div>Add a user</div>
        </div>
      </div>

      <div className="subHeading-text">
        Easily add, remove, and update user accounts to maintain an up-to-date
        user database.
      </div>

      <div className="filter-options">
        <div className="flex-display">
          <div className="filter">
            <span>
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 12C7.71667 12 7.479 11.904 7.287 11.712C7.095 11.52 6.99934 11.2827 7 11C7 10.7167 7.096 10.479 7.288 10.287C7.48 10.095 7.71734 9.99934 8 10H10C10.2833 10 10.521 10.096 10.713 10.288C10.905 10.48 11.0007 10.7173 11 11C11 11.2833 10.904 11.521 10.712 11.713C10.52 11.905 10.2827 12.0007 10 12H8ZM1 2C0.71667 2 0.479003 1.904 0.287003 1.712C0.0950034 1.52 -0.000663206 1.28267 3.46021e-06 1C3.46021e-06 0.71667 0.0960036 0.479004 0.288004 0.287004C0.480004 0.0950036 0.717337 -0.000663206 1 3.4602e-06H17C17.2833 3.4602e-06 17.521 0.0960036 17.713 0.288004C17.905 0.480004 18.0007 0.717337 18 1C18 1.28334 17.904 1.521 17.712 1.713C17.52 1.905 17.2827 2.00067 17 2H1ZM4 7C3.71667 7 3.479 6.904 3.287 6.712C3.095 6.52 2.99934 6.28267 3 6C3 5.71667 3.096 5.479 3.288 5.287C3.48 5.095 3.71734 4.99934 4 5H14C14.2833 5 14.521 5.096 14.713 5.288C14.905 5.48 15.0007 5.71734 15 6C15 6.28334 14.904 6.521 14.712 6.713C14.52 6.905 14.2827 7.00067 14 7H4Z"
                  fill="#222222"
                />
              </svg>
            </span>
            Filters
          </div>
          <div className="status">
            Status
            <span>
              <svg
                width="10"
                height="5"
                viewBox="0 0 10 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 5L0 0H10L5 5Z" fill="#00580A" />
              </svg>
            </span>
          </div>
        </div>
        <div className="search-box">
          <div className="position-helper">
            <input type={'text'} placeholder={'Search'} />
            <div className="search-icon">
              <svg
                width="14"
                height="14"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9 12.3L7.75 9.15C7.35 9.43333 6.91233 9.646 6.437 9.788C5.96167 9.93 5.48267 10.0007 5 10C3.61667 10 2.43733 9.51233 1.462 8.537C0.486668 7.56167 -0.000665984 6.38267 6.8306e-07 5C6.8306e-07 3.61667 0.487668 2.43733 1.463 1.462C2.43833 0.486667 3.61733 -0.000665984 5 6.8306e-07C6.38333 6.8306e-07 7.56267 0.487667 8.538 1.463C9.51333 2.43833 10.0007 3.61733 10 5C10 5.48333 9.929 5.96267 9.787 6.438C9.645 6.91333 9.43267 7.35067 9.15 7.75L12.3 10.9C12.4833 11.0833 12.575 11.3167 12.575 11.6C12.575 11.8833 12.4833 12.1167 12.3 12.3C12.1167 12.4833 11.8833 12.575 11.6 12.575C11.3167 12.575 11.0833 12.4833 10.9 12.3ZM5 8C5.83333 8 6.54167 7.70833 7.125 7.125C7.70833 6.54167 8 5.83333 8 5C8 4.16667 7.70833 3.45833 7.125 2.875C6.54167 2.29167 5.83333 2 5 2C4.16667 2 3.45833 2.29167 2.875 2.875C2.29167 3.45833 2 4.16667 2 5C2 5.83333 2.29167 6.54167 2.875 7.125C3.45833 7.70833 4.16667 8 5 8Z"
                  fill="#9DB39F"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div className="usersDetails">
        <div>No.</div>
        <div>User</div>
        <div>Email ID</div>
        <div>Added on</div>
        <div>Status</div>
        <div>Actions</div>
      </div>
      {employeesData.map((employee, index) => (
        <div className="details" key={index}>
          <div>{index + 1}</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{ marginRight: '10px' }}>
              <img src={employee.image} alt="employee image" />
            </span>
            {employee.user}
          </div>
          <div>{employee.email}</div>
          <div>{employee.addedOn}</div>
          <div>{employee.status}</div>
          <div className="flex-display">
            <div className="edit-button">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9428 0.895104C13.2145 0.614718 13.5392 0.391189 13.8982 0.237532C14.2571 0.0838748 14.643 0.00316129 15.0334 9.1018e-05C15.4238 -0.00297925 15.811 0.0716551 16.1723 0.219648C16.5336 0.367641 16.8618 0.586035 17.1379 0.862114C17.414 1.13819 17.6324 1.46644 17.7804 1.82774C17.9283 2.18903 18.003 2.57617 17.9999 2.96659C17.9968 3.35701 17.9161 3.74292 17.7625 4.10184C17.6088 4.46077 17.3853 4.78554 17.1049 5.05724L15.9521 6.21007L11.7899 2.04793L12.9428 0.895104ZM10.9964 2.84143L1.87341 11.9644C1.54404 12.2938 1.30449 12.7026 1.17873 13.1517L0.0206641 17.2876C-0.00609255 17.3835 -0.00687888 17.4848 0.0183856 17.5811C0.0436502 17.6773 0.0940571 17.7652 0.164442 17.8356C0.234826 17.9059 0.322658 17.9564 0.418938 17.9816C0.515217 18.0069 0.616482 18.0061 0.712358 17.9793L4.84755 16.8213C5.29665 16.6956 5.7058 16.4564 6.03555 16.1266L15.1593 7.00432L10.9972 2.84218L10.9964 2.84143Z"
                  fill="#00580A"
                />
              </svg>
            </div>

            <div className="delete-button">
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 18C2.45 18 1.979 17.804 1.587 17.412C1.195 17.02 0.999333 16.5493 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.804 17.021 14.412 17.413C14.02 17.805 13.5493 18.0007 13 18H3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                  fill="#00580A"
                />
              </svg>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ManageUsers;
