import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { Datasource } from '../../types/datasource';

export const getCampaignDatasource = async (
  campaignId: any,
): Promise<Datasource> => {
  return campaignId && campaignId != ''
    ? api
        .get(URLs.GET_CAMPAIGN_DATASOURCE_DETAILS(campaignId))
        .then((res: any) => res.data)
    : Promise.resolve({
        brandId: '',
        name: '',
        description: '',
        campaignType: '',
      });
};

export const useGetCampaignDatasource = (): UseMutationResult<
  Datasource,
  Error,
  string | null
> => {
  return useMutation(
    [QueryKeys.GetCampaignDatasource],
    (campaignId: string | null) => getCampaignDatasource(campaignId),
  );
};
