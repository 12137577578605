import { FC } from 'react';
import './PdfEmptyLine.scss';

export interface PdfEmptyLineProps {
  id: string;
  noOfRows: number;
  name: string;
  setSelectedElementId: (id: string) => void;
}

export const PdfEmptyLine: FC<PdfEmptyLineProps> = ({
  id,
  noOfRows = 1,
  setSelectedElementId,
}) => {
  return (
    <>
      {Array.from(
        {
          length: noOfRows,
        },
        (_, index) => (
          <div
            className="cm-br-empty-class"
            key={`${id}_br_${index}`}
            onClick={() => setSelectedElementId(id)}
          >
            <br />
          </div>
        ),
      )}
    </>
  );
};

PdfEmptyLine.displayName = 'PdfEmptyLine';
