import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { ForgetPassword } from '../../types/login';

export const postForgetPassword = async (
  forgetPassword: ForgetPassword,
): Promise<string> =>
  api
    .post(URLs.POST_FORGET_PASSWORD, forgetPassword)
    .then((res: any) => res.data);

export const useForgetPassword = (): UseMutationResult<
  string,
  Error,
  ForgetPassword
> => {
  return useMutation(
    [QueryKeys.ForgetPassword],
    (forgetPassword: ForgetPassword) => postForgetPassword(forgetPassword),
  );
};
