import './Select.scss';
import Select, { components } from 'react-select';
import ValidationMessage from '../ValidationMessage/ValidationMessage';

export interface SelectOption {
  value: number | string | boolean;
  label: string;
}

export interface SelectProps {
  name: string;
  id: string;
  label?: string;
  list: SelectOption[];
  value?: SelectOption;
  placeholder?: string;
  errorMessage?: string;
  onChange?: (e: any) => void;
  onBlur?: (e: React.ChangeEvent<any>) => void;
  isMulti?: boolean;
  width?: string;
  height?: string;
  className?: string;
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="10"
        height="5"
        viewBox="0 0 10 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M5 5L0 0H10L5 5Z" fill="#00580A" />
      </svg>
    </components.DropdownIndicator>
  );
};

const SelectBox = (props: SelectProps) => {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      background: '#FFFFFF',
      border: '1px solid #00580A',
      borderRadius: '8px',
      minWidth: `${props.width ? props.width : '254px'}`,
      // maxWidth:
      //   props.isMulti === true
      //     ? '300px'
      //     : `${props.width ? props.width : '254px'}`,
      minHeight: `${props.height ? props.height : '40px'}`,
      color: '#666666',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided: any) => ({
      ...provided,
      // Width: `${props.width ? props.width : '254px'}`,
      height: '30px',
      background: 'rgba(249, 250, 245, 0.5)',
      display: 'flex',
      alignItems: 'center',
      color: '#666666',
    }),
    menu: (provided: any) => ({
      ...provided,
      // maxWidth: `${props.width ? props.width : '254px'}`,
      background: 'rgba(249, 250, 245)',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      background: 'rgba(249, 250, 245, 0.5)',
      borderRadius: '4px',
      padding: '2px 6px',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      fontSize: '12px',
      color: '#666666',
    }),
  };
  return (
    <div className="Select-container">
      <div
        className="flex-display"
        // style={{
        //   width: `${props.width ? props.width : '254px'}`,
        // }}
      >
        <label htmlFor={props.id}>{props.label}</label>
        {props.errorMessage && <ValidationMessage label={props.errorMessage} />}
      </div>

      <Select
        styles={customStyles}
        components={{ DropdownIndicator }}
        name={props.name}
        id={props.id}
        placeholder={props.placeholder ? props.placeholder : 'Select...'}
        options={props.list}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        isMulti={props.isMulti}
        className={props.className ? props.className : ''}
      ></Select>
    </div>
  );
};

export default SelectBox;
