import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import './PdfPreview.scss';
import { useDownloadPreviewPdf } from '../../hooks/useDownloadPreviewPdf/useDownloadPreviewPdf';
import { CampaignCssAndPages } from '../../types/campaign';
import { SystemTemplatePdf } from '../../types/systemTemplate';
import { useSystemTemplateDownloadPdf } from '../../hooks/useSystemTemplateDownloadPdf/useSystemTemplateDownloadPdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface PdfPreviewProps {
  campaignCssAndPages?: CampaignCssAndPages;
  systemTemplateCssAndPages?: SystemTemplatePdf;
}

const PdfPreview: React.FC<PdfPreviewProps> = ({
  campaignCssAndPages,
  systemTemplateCssAndPages,
}) => {
  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);
  const [renderError, setRenderError] = useState<boolean>(false);
  const { mutate: downloadPdf } = useDownloadPreviewPdf();
  const { mutate: downloadTempaltePdf } = useSystemTemplateDownloadPdf();

  useEffect(() => {
    campaignCssAndPages
      ? downloadPdf(campaignCssAndPages, {
          onSuccess: data => {
            setPdfFile(
              new File([data], 'preview.pdf', {
                type: 'application/pdf',
              }),
            );
          },
          onError: () => {
            console.log('Error');
          },
        })
      : null;
    systemTemplateCssAndPages
      ? downloadTempaltePdf(systemTemplateCssAndPages, {
          onSuccess: data => {
            setPdfFile(
              new File([data], 'preview.pdf', {
                type: 'application/pdf',
              }),
            );
          },
        })
      : null;
  }, []);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handleRenderError = () => {
    setRenderError(true);
  };

  const handleLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <div className="pdfPreview-container">
      <div>
        <button onClick={handlePreviousPage} disabled={currentPage === 1}>
          &lt; Previous
        </button>
        <span style={{ marginLeft: '4px', marginRight: '4px' }}>
          Page {currentPage} of {numPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === numPages}>
          Next &gt;
        </button>
      </div>
      <div>
        {!renderError ? (
          <Document
            file={pdfFile}
            onLoadSuccess={handleLoadSuccess}
            onItemClick={() => null}
            onError={handleRenderError}
          >
            <Page
              pageNumber={currentPage}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </Document>
        ) : (
          <div>Failed to render PDF.</div>
        )}
      </div>
    </div>
  );
};

export default PdfPreview;
