import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import api from '../../api/apifilter';
import { ContactUsReq } from '../../types/common';

export const postContactUs = async (
  contactUsReq: ContactUsReq,
): Promise<void> =>
  api
    .post(
      'https://f6qjgkncn2.execute-api.eu-west-2.amazonaws.com/contactus',
      contactUsReq,
    )
    .then((res: any) => res.data);

export const usePostContactUs = (): UseMutationResult<
  void,
  Error,
  ContactUsReq
> => {
  return useMutation([QueryKeys.ContactUs], (contactUsReq: ContactUsReq) =>
    postContactUs(contactUsReq),
  );
};
