import { FC } from 'react';

export interface PdfPagination {
  noOfPages: number;
  activePage: number;
  setActivePage: (pageNo: number) => void;
}

export const PdfPagination: FC<PdfPagination> = ({
  noOfPages,
  activePage,
  setActivePage,
}) => {
  return (
    <div className="cm-pagination-wrapper">
      <div className="cm-pagination">
        <button className="btn-nav left-btn" type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="left-icon"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </button>
        <div className="page-numbers">
          {
            // uiPages &&
            noOfPages > 1 ? (
              Array.from({ length: noOfPages }, (v, i) => `${i + 1}`).map(
                (page, index) => (
                  <button
                    type="button"
                    className={
                      activePage === index
                        ? 'btn-page btn-selected'
                        : 'btn-page'
                    }
                    onClick={e => {
                      e.preventDefault();
                      setActivePage(index);
                      // e.preventDefault();
                    }}
                    key={`page_no_${index}`}
                  >
                    {page}
                  </button>
                ),
              )
            ) : (
              <button
                type="button"
                className="btn-page"
                onClick={e => {
                  e.preventDefault();
                  setActivePage(0);
                }}
              >
                1
              </button>
            )
          }
        </div>
        <button className="btn-nav right-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="right-icon"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
