import { useState, useEffect, FC } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import PreviousButton from '../../atoms/PreviousButton/PreviousButton';
import './FormDesign.scss';
import { FormikProvider, useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../app/store';
import { CampaignCssAndPages } from '../../types/campaign';
import { updateCampaignContent } from '../../slices/createCampaignSlice';
import { saveCampaignPagesFormValidation } from './validation';
// import NextButton from '../../atoms/NextButton/NextButton';
import { useGetCampaignContent } from '../../hooks/useGetCampaignContent/useGetCampaignContent';
import { useGetSystemTemplate } from '../../hooks/useGetSystemTemplate/useGetSystemTemplate';
import { FormPreview } from './FormPreview';
import { ElementsV2 } from '../../atoms/ElementsV2/ElementsV2';
import './FormPreview.scss';
import { PropertiesPanel } from '../../atoms/Elements/PropertiesPanel';
import { ElementJson } from '../../utils/elementsUtil';
import { LAYOUT_TYPE } from '../../types/layoutTypes';
import { Next } from '../../atoms/Next/Next';

interface UseFormikWithButtonsProps {
  initialValues: CampaignCssAndPages;
  onSubmit: (values: CampaignCssAndPages, activeButton: string) => void;
}

const useFormikWithButtons = ({
  initialValues,
  onSubmit,
}: UseFormikWithButtonsProps) => {
  const [activeButton, setActiveButton] = useState('');

  const formik = useFormik({
    initialValues,
    validationSchema: saveCampaignPagesFormValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: CampaignCssAndPages) => {
      onSubmit(values, activeButton);
    },
  });

  return { ...formik, activeButton, setActiveButton };
};

export const FormDesign: FC = () => {
  const [elementListMap, setElementListMap] = useState<
    Map<number, ElementJson[]>
  >(new Map());

  const [activePage, setActivePage] = useState<number>(0);
  const [pageLayoutMap, setPageLayoutMap] = useState<Map<number, LAYOUT_TYPE>>(
    new Map(),
  );
  const campaignTemplateIds = useSelector(
    (state: RootState) => state.createCampaignSlice.selectedSystemTemplateIds,
  );
  // const [elementNames, setElementNames] = useState<string[]>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [queryParams] = useSearchParams();
  const campaignId =
    queryParams.get('id') && queryParams.get('id') !== null
      ? queryParams.get('id')
      : '';
  const {
    mutate: getCampaignContent,
    isLoading: loadingContent,
    data: campaignContent,
  } = useGetCampaignContent();

  const {
    mutate: getSystemTemplate,
    data: systemTemplate,
    isLoading: loadingSystemTemplate,
  } = useGetSystemTemplate();

  useEffect(() => {
    if (!loadingSystemTemplate && systemTemplate) {
      formik.setFieldValue('templateId', systemTemplate.templateId);
      formik.setFieldValue('name', systemTemplate.name);
      formik.setFieldValue('description', systemTemplate.description);
      formik.setFieldValue('type', systemTemplate.type);
      formik.setFieldValue('marketingType', systemTemplate.marketingType);
      formik.setFieldValue('campaignType', systemTemplate.campaignType);
      formik.setFieldValue('cssContent', systemTemplate.cssContent);
      if (systemTemplate.pages && systemTemplate.pages.length > 0) {
        formik.setFieldValue('pages', systemTemplate.pages);
      } else {
        formik.setFieldValue('pages', [
          {
            name: '',
            content: '',
            cssContent: '',
            orientation: '',
            index: 0,
          },
        ]);
      }
    }
    if (
      campaignTemplateIds &&
      campaignTemplateIds.size > 0 &&
      campaignTemplateIds?.get(campaignId ? campaignId : '') &&
      campaignTemplateIds?.get(campaignId ? campaignId : '') !== undefined
    ) {
      const templateId = campaignTemplateIds?.get(campaignId ? campaignId : '');
      getSystemTemplate(templateId ? templateId : '');
    }
  }, [campaignTemplateIds]);

  useEffect(() => {
    if (
      !loadingSystemTemplate &&
      systemTemplate &&
      systemTemplate.pages &&
      systemTemplate.pages.length > 0
    ) {
      formik.setFieldValue('campaignPages', systemTemplate.pages);
    }
    if (!loadingSystemTemplate && systemTemplate) {
      formik.setFieldValue(
        'cssContent',
        systemTemplate.cssContent ? systemTemplate.cssContent : '',
      );
    }
  }, [loadingSystemTemplate, systemTemplate]);

  // const [pdfPreview, setPdfPreview] = useState<boolean>(false);

  const campaignCssAndPages = useSelector(
    (state: RootState) => state.createCampaignSlice.campaignCssAndPages,
  );

  const saveCampaignPagesInContext = (
    values: CampaignCssAndPages,
    activeButton: string,
  ) => {
    if (activeButton === 'save') {
      const newMap = new Map<string, CampaignCssAndPages>();
      campaignCssAndPages &&
        campaignCssAndPages.size > 0 &&
        campaignCssAndPages.forEach((value, key) => {
          newMap.set(key, value);
        });
      newMap.set(campaignId ? campaignId : '', values);
      dispatch(updateCampaignContent(newMap));
      navigate(`/reviewcontent?id=${campaignId}`);
    }
  };

  const formik = useFormikWithButtons({
    initialValues: {
      campaignId: campaignId ? campaignId : '',
      cssContent: '',
      campaignPages: [
        {
          name: 'Page: 1',
          content: '',
          cssContent: '',
          orientation: '',
          index: 0,
          active: true,
        },
      ],
    },
    onSubmit: async (values: CampaignCssAndPages, activeButton: string) => {
      saveCampaignPagesInContext(values, activeButton);
    },
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        if (event.key === 'z') {
          event.preventDefault();
          // handleUndo();
        } else if (event.key === 'y') {
          event.preventDefault();
          //handleRedo();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (campaignId && campaignId !== null) getCampaignContent(campaignId);
  }, []);

  useEffect(() => {
    if (!loadingContent && campaignContent && campaignContent.pages) {
      if (campaignContent.pages && campaignContent.cssContent !== null) {
        formik.setFieldValue('cssContent', campaignContent.cssContent);
      }
      if (campaignContent.caption) {
        formik.setFieldValue('caption', campaignContent.caption);
      }
      if (campaignContent.pages.length > 0) {
        formik.setFieldValue('campaignPages', campaignContent.pages);
      }
    }
  }, [loadingContent, campaignContent?.cssContent]);

  return (
    <div className="campaign-html-preview-container">
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className={'outer-grid-layout-2'}>
            <div className="html-code-section">
              <div className="grid-layout2">
                <div className="elements-section">
                  <ElementsV2
                    pageNumber={activePage}
                    setElementListMap={setElementListMap}
                    elementListMap={elementListMap}
                    setLayout={setPageLayoutMap}
                    pagesLayoutMap={pageLayoutMap}
                  />
                  {/* campaignId={campaignId ? campaignId : ''} /> */}
                </div>

                <div
                  className="html-preview-section"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {/* <button
                    className="pdf-button"
                    onClick={() => setPdfPreview(!pdfPreview)}
                  >
                    Pdf Preview
                  </button> */}
                  <FormPreview
                    elementListMap={elementListMap}
                    activePage={activePage}
                    setElementListMap={setElementListMap}
                    setActivePage={setActivePage}
                    // layout={pageLayoutMap.get(activePage)}
                    pageLayoutMap={pageLayoutMap}
                    // noOfPages={elementListMap.keys.length}
                    // campaignPages={formik.values.campaignPages}
                    // commonCss={formik.values.cssContent}
                  />
                </div>
              </div>
            </div>
            <div
            // style={{
            //   display: 'flex',
            //   justifyContent: 'end',
            //   marginRight: '10%',
            // }}
            >
              {/* <Sidebar></Sidebar> */}
              <PropertiesPanel
                changeAlignProperty={(id: string, newValue: string) => {
                  console.log('newValue');
                }}
                idList={[]}
                selectedElementId={''}
                setSelectedElementId={(id: string) => {
                  console.log('id');
                }}
              ></PropertiesPanel>
            </div>
          </div>

          {/* <div
            className="pdfPreview-pop-up"
            style={{
              position: 'absolute',
              top: '20px',
              left: '50%',
              transform: 'translate(-50%, 0px)',
            }}
          >
            {pdfPreview && (
              <div className="position-helper">
                <PdfPreview campaignCssAndPages={getCampaignCssAndPages()} />

                <div
                  className="closePfdPreview"
                  onClick={() => setPdfPreview(!pdfPreview)}
                >
                  X
                </div>
              </div>
            )}
          </div> */}

          <div className="navigationButtons">
            <Link to={`/selecttemplate?id=${campaignId}`}>
              <PreviousButton />
            </Link>

            <Next
              type={'button'}
              label={'Next'}
              onClick={() => formik.setActiveButton('save')}
            />
          </div>
        </form>
      </FormikProvider>
    </div>
  );
};
