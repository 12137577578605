import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { SystemTemplateResp } from '../../types/systemTemplate';
import { Pagination } from '../../types/campaign';

export const getSystemTemplateList = async (
  pagination: Pagination,
): Promise<SystemTemplateResp> =>
  api
    .post(URLs.GET_SYSTEM_TEMPLATE_LIST, pagination)
    .then((res: any) => res.data);

export const useGetSystemTemplateList = (): UseMutationResult<
  SystemTemplateResp,
  Error,
  Pagination
> => {
  return useMutation(
    [QueryKeys.GetSystemTemplateList],
    (pagination: Pagination) => getSystemTemplateList(pagination),
  );
};
