import './PdfTemplateList.scss';
import { LeftNavigation } from '../../components/LeftNavigation/LeftNavigation';
import { useGetPdfTemplateList } from '../../hooks/useGetPdfTemplateList/useGetPdfTemplateList';
import { useEffect, useState } from 'react';
import { Pagination } from '../../types/campaign';
import { Pagination as PaginationComp } from '../../components/Pagination/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'cm-react-components/dist/js';

export const PdfTemplateList = () => {
  const navigate = useNavigate();
  const isLoggedIn: boolean = localStorage.getItem('user.accessToken') !== null;
  const [pagination, setPagination] = useState<Pagination>({
    startIndex: 1,
    endIndex: 1,
    currentPage: 1,
    pageSize: 40,
  });
  const { data: pdfTemplateList, mutate: getPdfTemplates } =
    useGetPdfTemplateList();

  useEffect(() => {
    getPdfTemplates({ pagination: pagination, templateTypes: ['Finance'] });
  }, []);

  return (
    <>
      {/* <div className="breadcrum-title">
        <h1>PDF Templates</h1>
      </div> */}

      <section className="pdf-template-intro">
        <div className="container">
          <h1>Unique PDF Templates</h1>
          <p>
            Unique templates for just as many industries, NCampaignSuite has the
            tools to meet your needs.
          </p>
        </div>
        <div className="container">
          <div className="pdf-template-wrapper">
            <div className="sidebar-filter">
              <div className="primary">
                <div className="row">
                  <LeftNavigation
                    typeLabels={['Type1', 'Type2']}
                    industriesLabels={['Industry1', 'Industry2']}
                    // LanguagesLabels={['language1', 'language2']}
                  />

                  <div className="col-sm-9 col-xs-12">
                    <div className="templates-container">
                      {pdfTemplateList &&
                      pdfTemplateList.records &&
                      pdfTemplateList.records.length > 0 ? (
                        pdfTemplateList.records.map((record, index) => (
                          <div
                            className="template-box"
                            key={`template_${index}`}
                          >
                            <div className="template-preview-image">
                              <img src="images/pdf-templates/template03.png" />
                            </div>
                            <h2>{record.name}</h2>
                            <p>{record.description}</p>
                            <div className="use-template-cta-wrapper">
                              {/* <Link
                              to={`/createcampaign?templateid=${record.templateId}`}
                              onClick={()=>{}}
                            >
                              Use Template
                            </Link> */}
                              <Button
                                label="Use Template"
                                onClick={() => {
                                  isLoggedIn
                                    ? navigate(
                                        `/createcampaign?templateid=${record.templateId}`,
                                      )
                                    : navigate(
                                        `/login?templateid=${record.templateId}`,
                                      );
                                }}
                                name={'UseTemplate'}
                              />
                            </div>
                            <div className="use-template-cta-wrapper">
                              <Link
                                to={`/createpdftemplate?id=${record.templateId}`}
                              >
                                Edit Template
                              </Link>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="template-container">
              <div>
                <PaginationComp
                  updatePagination={setPagination}
                  noOfPages={
                    pdfTemplateList?.noOfPages ? pdfTemplateList.noOfPages : 1
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
