import { useQuery, UseQueryResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import { Dashboard } from '../../types/dashboard';
import api from '../../api/apifilter';

export const dashboard = async (): Promise<Dashboard[]> => {
  return api.get(URLs.DASHBOARD).then((res: any) => res.data);
};

export const useGetDashboard = (): UseQueryResult<Dashboard[], Error> => {
  return useQuery([QueryKeys.Dashboard], () => dashboard(), {
    refetchOnWindowFocus: false,
  });
};
