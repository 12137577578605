import { FC, useState } from 'react';
import './PdfLoopEnd.scss';
import { TextInput } from 'cm-react-components/dist/js';

export interface PdfLoopEndProps {
  id: string;
  name?: string;
  setSelectedElementId: (id: string) => void;
  value: string;
  onValueChange: (id: string, newValue: string) => void;
}

export const PdfLoopEndTag: FC<PdfLoopEndProps> = ({
  id,
  name,
  setSelectedElementId,
  value,
  onValueChange,
}) => {
  const [editing, setEditing] = useState<boolean>(false);

  return editing === true ? (
    <TextInput
      id={id}
      key={id}
      onBlur={e => {
        onValueChange(id, e.target.value);
        setEditing(false);
      }}
      onChange={e => {
        onValueChange(id, e.target.value);
      }}
      value={value}
      borderRadius="0px"
    ></TextInput>
  ) : (
    <div
      id={id}
      key={id}
      className="loop-end-tag"
      onClick={() => {
        setEditing(true);
        setSelectedElementId(id);
      }}
    >
      {`<loopend name="${value}">${value}</loopend>`}
    </div>
  );
};

PdfLoopEndTag.displayName = 'PdfLoopEndTag';
