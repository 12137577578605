import { FC } from 'react';
import './PdfCustomTagElementsPanel.scss';
import { PDF_ELEMENTS } from '../../types/pdfElements';

interface CustomTagElementProps {
  type: string;
  htmlCode: string;
}

export const CustomTagElement = ({ type, htmlCode }: CustomTagElementProps) => {
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData('text/plain', htmlCode);
  };

  return (
    // <div className="item" draggable onDragStart={handleDragStart}>
    //   <p>{type}</p>
    // </div>
    <div
      className="elements"
      onClick={() => {
        console.log('Element !!');
      }}
      onDragStart={handleDragStart}
      draggable
    >
      <img src="input-text.png" />
      <div className="element-name">{type}</div>
    </div>
  );
};

// interface LoopTagElementProps {
//   id: string;
//   name: string;
// }

// export const LoopTagElement = ({ id, name }: LoopTagElementProps) => {
//   const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
//     e.dataTransfer.setData('text/plain', htmlCode);
//   };

//   return (
//     // <div className="item" draggable onDragStart={handleDragStart}>
//     //   <p>{type}</p>
//     // </div>
//     <div
//       className="elements"
//       onClick={() => {
//         console.log('Element !!');
//       }}
//       onDragStart={handleDragStart}
//       draggable
//     >
//       <img src="input-text.png" />
//       <div className="element-name">{type}</div>
//     </div>
//   );
// };

export interface PdfCustomTagElementsPanelProps {
  hide?: boolean;
}

export const PdfCustomTagElementsPanel: FC<PdfCustomTagElementsPanelProps> = ({
  hide = false,
}) => {
  return hide === false ? (
    <div className="resize-container">
      <div className="cm-element-container">
        {/* <div className="title">
          <Title text="Design Campaign" />
        </div> */}

        <div className="row">
          <div className="cm-col">
            <div className="cm-tabs">
              <div className="social-cm-tab">
                <input type="checkbox" id="chck3" />
                <label className="tab-label" htmlFor="chck3">
                  PDF Elements
                </label>
                <div className="tab-content">
                  <div className="elements-wrapper">
                    {/* <div
                      className="elements"
                      onClick={() => {
                        console.log('Element !!');
                      }}
                    >
                      <img src="input-text.png" />
                      <div className="element-name">Label</div>
                    </div> */}
                    <CustomTagElement
                      type="Label"
                      htmlCode={PDF_ELEMENTS.Label}
                    ></CustomTagElement>
                    <CustomTagElement
                      type="Label Small Bold"
                      htmlCode={PDF_ELEMENTS.LabelBold}
                    ></CustomTagElement>
                    <CustomTagElement
                      type="Label Header Size"
                      htmlCode={PDF_ELEMENTS.LabelHeader}
                    ></CustomTagElement>
                    <CustomTagElement
                      type="FWG LOGO"
                      htmlCode={PDF_ELEMENTS.LOGO}
                    ></CustomTagElement>
                    <CustomTagElement
                      type="Money Wellness LOGO"
                      htmlCode={PDF_ELEMENTS.MW_LOGO}
                    ></CustomTagElement>
                    <CustomTagElement
                      type="Address"
                      htmlCode={PDF_ELEMENTS.Address}
                    ></CustomTagElement>
                    <CustomTagElement
                      type="List"
                      htmlCode={PDF_ELEMENTS.ULList}
                    ></CustomTagElement>
                    <CustomTagElement
                      type="Footer"
                      htmlCode={PDF_ELEMENTS.FOOTER}
                    ></CustomTagElement>
                    <CustomTagElement
                      type="Table"
                      htmlCode={PDF_ELEMENTS.TABLE}
                    ></CustomTagElement>
                    <CustomTagElement
                      type="Two Column Layout"
                      htmlCode={PDF_ELEMENTS.TWO_COLUMN_LAYOUT}
                    ></CustomTagElement>

                    <CustomTagElement
                      type="Empty Lines"
                      htmlCode={PDF_ELEMENTS.EMPTY_LINE}
                    ></CustomTagElement>
                    <CustomTagElement
                      type="Print Signature"
                      htmlCode={PDF_ELEMENTS.PRINT_SIGNATURE}
                    ></CustomTagElement>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div style={{ display: hide ? 'block' : 'none' }}></div>
  );
};
