import { FC, useEffect } from 'react';
import './TemplateForTrack.scss';
import { useGetCampaigntracking } from '../../hooks/useCampaignTracking/useCampaignTracking';
interface TemplateForTrackProps {
  brandId: string;
}
export const TemplateForTrack: FC<TemplateForTrackProps> = ({ brandId }) => {
  const { data, mutate: getTrackingData } = useGetCampaigntracking();

  useEffect(() => {
    getTrackingData({
      brandId: brandId,
      pagination: {
        startIndex: 0,
        endIndex: 10,
        currentPage: 1,
        pageSize: 10,
      },
    });
  }, []);
  return (
    <>
      {data &&
        data.map((item, index) => (
          <div className="progress-bar" key={`index${index}`}>
            <div className="name">{item.campaignName}</div>
            <div className="type">{item.campaignType}</div>
            <div className="scheduleType">{item.scheduleType}</div>
            <div className="lastExecutionStartTime">
              {item.lastExecutionStartTime}
            </div>
            <div className="lastExecutionEndTime">
              {item.lastExecutionEndTime}
            </div>
            <div className="failCount">{item.failCount}</div>
            <div className="successCount">{item.successCount}</div>
          </div>
        ))}
    </>
  );
};
