import './EditDeleteButton.scss';

export interface EditDeleteButtonProps {
  name: string;
}
const EditDeleteButton = (props: EditDeleteButtonProps) => {
  return (
    <div className="edit-delete-container">
      <button>{props.name}</button>
    </div>
  );
};

export default EditDeleteButton;
