import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import { LoginRequest, LoginResponse } from '../../types/login';
import api from '../../api/apifilter';

export const login = async (
  loginRequest: LoginRequest,
): Promise<LoginResponse> =>
  api.post(URLs.LOGIN, loginRequest).then((res: any) => res.data);

export const useLogin = (): UseMutationResult<
  LoginResponse,
  Error,
  LoginRequest
> => {
  return useMutation([QueryKeys.Login], (loginRequest: LoginRequest) =>
    login(loginRequest),
  );
};
