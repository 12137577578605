import { FC, useEffect, useState } from 'react';
import './CampaignListTable.css';
import { PreviewIframe } from '../../atoms/PreviewIframe/PreviewIframe';
import {
  // getCampaignContent,
  useGetCampaignContent,
} from '../../hooks/useGetCampaignContent/useGetCampaignContent';
import {
  updateBrandId,
  updateCampaignType,
  updateCreatedCampaignId,
} from '../../slices/createCampaignSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { CampaignDetails } from '../../types/campaign';
import { useExportCampaignPdf } from '../../hooks/useExportCampaignPdf/useExportCampaignPdf';
// import { URLs } from '../../api/URLs';

export interface CampaignListTableProps {
  campaignList: CampaignDetails[];
}

export const CampaignListTable: FC<CampaignListTableProps> = ({
  campaignList,
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  // const { data: campaignList, mutate: getCampaignList } = useGetCampaignList();

  // const [previewCampaignId, setPreviewCampaignId] = useState('');
  const openPreview = (campaignId: string) => {
    setPreviewOpen(true);
    // setPreviewCampaignId(campaignId);
    getCampaignContent(campaignId);
  };

  const [exportingCampaign, setExportingCampaign] = useState('');

  const {
    mutate: exportPdf,
    data: pdfBlob,
    isLoading: downloadingDoc,
  } = useExportCampaignPdf();

  const handlePdfExport = async (campaignId: string) => {
    try {
      setExportingCampaign(campaignId);

      await exportPdf(campaignId);
    } catch (error) {
      console.error('Error exporting PDF:', error);
      setExportingCampaign('');
    }
  };

  useEffect(() => {
    if (!downloadingDoc && pdfBlob !== null && pdfBlob !== undefined) {
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${exportingCampaign}.pdf`;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      setExportingCampaign('');
    }
  }, [pdfBlob, downloadingDoc]);

  const selectCampaign = (
    campaignId: string,
    brandId: string,
    campaignType: string,
  ) => {
    dispatch(updateCreatedCampaignId(campaignId));
    dispatch(updateBrandId(brandId));
    dispatch(updateCampaignType(campaignType));
    queryClient.invalidateQueries([QueryKeys.GetCampaignInfo]);
    navigate(`/createcampaign?id=${campaignId}`);
  };

  const closePreview = () => {
    setPreviewOpen(false);
  };

  const { mutate: getCampaignContent, data: campaignContent } =
    useGetCampaignContent();

  const [showMessage, setShowMessage] = useState(false);

  const [copiedRowIndex, setCopiedRowIndex] = useState<number | null>(null);

  const copyToClipboard = (id: string, rowIndex: number) => {
    navigator.clipboard.writeText(id).catch(error => {
      console.error('Error copying to clipboard:', error);
    });
    setCopiedRowIndex(rowIndex);
    setShowMessage(true);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showMessage) {
      timer = setTimeout(() => {
        setShowMessage(false);
      }, 500);
    }

    return () => clearTimeout(timer);
  }, [showMessage]);

  // useEffect(() => {
  //   getCampaignList({brandId: '' ,{
  //     startIndex: 1,
  //     endIndex: 1,
  //     currentPage: 1,
  //     pageSize: 10,
  //   }});
  // }, []);

  const renderPreviewOverlay = () => {
    return (
      <div className="preview-overlay">
        <div className="preview-content">
          {/* Add the PreviewIframe component here */}
          <div className="add-margin">
            <div className="closePreview">
              <div onClick={closePreview}>x</div>
            </div>
          </div>

          <PreviewIframe
            // htmlCode={
            //   campaignContent && campaignContent.pages
            //     ? campaignContent.pages.map(page => page.content)
            //     : []
            // }
            // cssCode={
            //   campaignContent && campaignContent.pages
            //     ? campaignContent.pages.map(page => page.cssContent)
            //     : []
            // }
            campaignPages={
              campaignContent && campaignContent.pages
                ? campaignContent.pages
                : []
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className="campaignlist_container">
      {campaignList &&
        campaignList.map((campaign, index) => (
          <div className="content" key={`campaing_${index}`}>
            <div className="flex-display">
              {/* <img className="logo" src="brand1.png" alt="Logo" /> */}
              <div className="left-text">{campaign.name}</div>
            </div>

            <div className="flex-display">{campaign.description}</div>
            <div>{campaign.campaignType}</div>

            {/* <div>{campaign.currentVersion}</div>
            <SelectBox
              name={'versionList'}
              id={'versionList'}
              list={campaign.versions.map(versionNumber => {
                return {
                  value: versionNumber,
                  label: versionNumber + '',
                };
              })}
              width={'95%'}
            /> */}
            <div>
              {campaign.createdAtStr ? `${campaign.createdAtStr}` : <></>}
            </div>

            {/* <div>{campaign.isDeleted === 1 ? 'Deleted' : 'Active'}</div> */}
            <div className="right-buttons">
              <div className="pdf-download">
                <svg
                  onClick={() => handlePdfExport(campaign.campaignId)}
                  // disabled={exportingCampaign === campaign.campaignId}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#00580a"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g id="Interface / Download">
                      <path
                        id="Vector"
                        d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12"
                        stroke="#00580a"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <button
                className="eye"
                onClick={() => openPreview(campaign.campaignId)}
              >
                👁️
              </button>

              <div className="edit-button">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() =>
                    selectCampaign(
                      campaign.campaignId,
                      campaign.brandId,
                      campaign.campaignType,
                    )
                  }
                >
                  <path
                    d="M12.9428 0.895104C13.2145 0.614718 13.5392 0.391189 13.8982 0.237532C14.2571 0.0838748 14.643 0.00316129 15.0334 9.1018e-05C15.4238 -0.00297925 15.811 0.0716551 16.1723 0.219648C16.5336 0.367641 16.8618 0.586035 17.1379 0.862114C17.414 1.13819 17.6324 1.46644 17.7804 1.82774C17.9283 2.18903 18.003 2.57617 17.9999 2.96659C17.9968 3.35701 17.9161 3.74292 17.7625 4.10184C17.6088 4.46077 17.3853 4.78554 17.1049 5.05724L15.9521 6.21007L11.7899 2.04793L12.9428 0.895104ZM10.9964 2.84143L1.87341 11.9644C1.54404 12.2938 1.30449 12.7026 1.17873 13.1517L0.0206641 17.2876C-0.00609255 17.3835 -0.00687888 17.4848 0.0183856 17.5811C0.0436502 17.6773 0.0940571 17.7652 0.164442 17.8356C0.234826 17.9059 0.322658 17.9564 0.418938 17.9816C0.515217 18.0069 0.616482 18.0061 0.712358 17.9793L4.84755 16.8213C5.29665 16.6956 5.7058 16.4564 6.03555 16.1266L15.1593 7.00432L10.9972 2.84218L10.9964 2.84143Z"
                    fill="#00580A"
                  />
                </svg>
              </div>

              {/* <div className="delete-button">
                <svg
                  onClick={() =>
                    deleteCampaign({ campaignId: campaign.campaignId })
                  }
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 18C2.45 18 1.979 17.804 1.587 17.412C1.195 17.02 0.999333 16.5493 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.804 17.021 14.412 17.413C14.02 17.805 13.5493 18.0007 13 18H3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                    fill="#00580A"
                  />
                </svg>
              </div> */}

              <div className="copy-button" key={index}>
                {showMessage === true && copiedRowIndex === index ? (
                  <span className="message">Copied!</span>
                ) : (
                  <svg
                    onClick={() => copyToClipboard(campaign.campaignId, index)}
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlSpace="preserve"
                    style={{
                      marginLeft: '15px',
                      position: 'absolute',
                      top: '-60px',
                      scale: '0.17',
                      left: '0px',
                    }}
                  >
                    <g style={{ fill: '#00580A' }}>
                      <path
                        className="st0"
                        d="M89.62,13.96v7.73h12.19h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02v0.02 v73.27v0.01h-0.02c-0.01,3.84-1.57,7.33-4.1,9.86c-2.51,2.5-5.98,4.06-9.82,4.07v0.02h-0.02h-61.7H40.1v-0.02 c-3.84-0.01-7.34-1.57-9.86-4.1c-2.5-2.51-4.06-5.98-4.07-9.82h-0.02v-0.02V92.51H13.96h-0.01v-0.02c-3.84-0.01-7.34-1.57-9.86-4.1 c-2.5-2.51-4.06-5.98-4.07-9.82H0v-0.02V13.96v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07V0h0.02h61.7 h0.01v0.02c3.85,0.01,7.34,1.57,9.86,4.1c2.5,2.51,4.06,5.98,4.07,9.82h0.02V13.96L89.62,13.96z M79.04,21.69v-7.73v-0.02h0.02 c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v64.59v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h12.19V35.65 v-0.01h0.02c0.01-3.85,1.58-7.34,4.1-9.86c2.51-2.5,5.98-4.06,9.82-4.07v-0.02h0.02H79.04L79.04,21.69z M105.18,108.92V35.65v-0.02 h0.02c0-0.91-0.39-1.75-1.01-2.37c-0.61-0.61-1.46-1-2.37-1v0.02h-0.01h-61.7h-0.02v-0.02c-0.91,0-1.75,0.39-2.37,1.01 c-0.61,0.61-1,1.46-1,2.37h0.02v0.01v73.27v0.02h-0.02c0,0.91,0.39,1.75,1.01,2.37c0.61,0.61,1.46,1,2.37,1v-0.02h0.01h61.7h0.02 v0.02c0.91,0,1.75-0.39,2.37-1.01c0.61-0.61,1-1.46,1-2.37h-0.02V108.92L105.18,108.92z"
                      />
                    </g>
                  </svg>
                )}
              </div>
            </div>
          </div>
        ))}
      {isPreviewOpen && renderPreviewOverlay()}
    </div>
  );
};
