import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../app/store';
import { CustomTagListResponse } from '../../types/customtag';
import { Pagination } from '../../types/campaign';

export const getCustomTagList = async (
  brandId: any,
  pagination: Pagination,
): Promise<CustomTagListResponse> =>
  api
    .post(URLs.GET_CUSTOMTAG_LIST(brandId), pagination)
    .then((res: any) => res.data);

export const useGetCustomTagList = (): UseMutationResult<
  CustomTagListResponse,
  Error,
  { brandId: string; pagination: Pagination }
> => {
  // const selectedBrandId = useSelector(
  //   (state: RootState) => state.cmsAppSlice.selectedBrandId,
  // );
  return useMutation(
    [QueryKeys.GetCustomTagList],
    (variables: { brandId: string; pagination: Pagination }) =>
      getCustomTagList(variables.brandId, variables.pagination),
  );
};
