import { useEffect, useState } from 'react';
import './Cookies.scss';

const Cookies = () => {
  const [cookieOverlayVisible, setCookieOverlayVisible] = useState(false);
  const [sidePanelVisible, setSidePanelVisible] = useState(false);
  const [isActiveClass, setIsActiveClass] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth > 768) {
        const header = document.querySelector('header');
        if (header) {
          if (window.scrollY > 30) {
            header.classList.add('active');
          } else {
            header.classList.remove('active');
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const saveCookies = () => {
    const cookieValue = 'nipuna-home';
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 7);
    const formattedExpiryDate = expiryDate.toUTCString();
    document.cookie = `nipunasolutions=${encodeURIComponent(
      cookieValue,
    )}; expires=${formattedExpiryDate}; path=/`;
  };

  const handleManageSettingClick = () => {
    setSidePanelVisible(true);
    setIsActiveClass(true);
    console.log('isActiveClass:', isActiveClass);
  };

  const handleAllCookieClick = () => {
    saveCookies();
    setCookieOverlayVisible(false);
  };

  const handleNecessaryCookieClick = () => {
    setCookieOverlayVisible(false);
  };

  const handleAllCookieSideClick = () => {
    saveCookies();
    setCookieOverlayVisible(false);
    setSidePanelVisible(false);
  };

  const handleRejectAllCookieClick = () => {
    setCookieOverlayVisible(false);
    setSidePanelVisible(false);
  };

  const handleMyChoiceCookieClick = () => {
    setCookieOverlayVisible(false);
    setSidePanelVisible(false);
  };

  useEffect(() => {
    if (!cookieExists('nipunasolutions')) {
      setCookieOverlayVisible(true);
    }
  }, []);

  const cookieExists = (name: string) => {
    return document.cookie.split(';').some(cookie => {
      return cookie.trim().startsWith(name + '=');
    });
  };

  return (
    <>
      {cookieOverlayVisible && (
        <div className="cookie-overlay" style={{ display: 'block' }}>
          <div className="cookie-box">
            <div className="cookie-flex">
              <div className="content">
                <h2>Cookies:</h2>
                <h2>The choice is yours</h2>
                <p>
                  We use cookies to make our site work well for you and so we
                  can continually improve it. The cookies that keep the site
                  functioning are always on. We use analytics and marketing
                  cookies to help us understand what content is of most interest
                  and to personalise your user experience.
                </p>
              </div>
              <div className="cta-wrapper">
                <div>
                  <a
                    // href="#"
                    // id="allCookie"
                    className="cookie-btn"
                    onClick={handleAllCookieClick}
                  >
                    Accept All Cookies
                  </a>
                </div>
                <div>
                  <a
                    // href="#"
                    // id="necessaryCookie"
                    className="cookie-btn"
                    onClick={handleNecessaryCookieClick}
                  >
                    Necessary Cookies Only
                  </a>
                </div>
                <div>
                  <a
                    // href="#"
                    id="manageSetting"
                    className="cookie-btn"
                    onClick={handleManageSettingClick}
                  >
                    Manage Cookie settings
                  </a>
                </div>
              </div>
            </div>
          </div>{' '}
        </div>
      )}

      {sidePanelVisible && (
        <div className="cookie-side-overlay open-side-cookie">
          <div className={`side-cookie-panel ${isActiveClass ? 'active' : ''}`}>
            {' '}
            <div className="cookie-logo">
              <img src="NipunaCampaignSuit-r-corner.png" />
            </div>
            <div className="cookie-side-panel-section-scroll">
              <div className="cookie-side-panel-section">
                <h2 className="cookie-h2">
                  Cookies:
                  <br />
                  The choice is yours
                </h2>
                <p className="cookie-p">
                  We use cookies to make our site work well for you and so we
                  can continually improve it. The cookies that are necessary to
                  keep the site functioning are always on. We use analytics and
                  marketing cookies to help us understand what content is of
                  most interest and to personalise your user experience.
                </p>
                <p className="cookie-p">
                  It’s your choice to accept these or not. You can either click
                  eslint-disable-next-line react/no-unescaped-entities the `I
                  accept all cookies` or `Reject all non-essential cookies`
                  button below or use the switches to choose and save your
                  choices.
                </p>
                <p className="cookie-p">
                  For detailed information on how we use cookies and other
                  tracking technologies, please visit our{' '}
                  <a
                    href="javascript:void(0);"
                    className="href-tag"
                    style={{ color: '#333' }}
                  >
                    cookies information page
                  </a>
                  .
                </p>
                <div>
                  <a className="cookie-btn" onClick={handleAllCookieSideClick}>
                    I accept all cookies
                  </a>
                </div>
              </div>
              <hr className="hr-tag" />
              <div className="cookie-side-panel-section">
                <h4 className="marg-t0">Necessary Cookies</h4>
                <p>
                  These cookies are necessary for the website to operate. Our
                  website cannot function without these cookies and they can
                  only be disabled by changing your browser preferences.
                </p>
                <div className="cookie-type">
                  <h4>Analytical/Performance Cookies</h4>
                  <div>
                    <label className="switchSmall">
                      <input type="checkbox" />
                      <small></small>
                    </label>
                  </div>
                </div>
                <p>
                  These cookies allow us to measure and report on website
                  activity by tracking page visits, visitor locations and how
                  visitors move around the site. The information collected does
                  not directly identify visitors. We drop these cookies to help
                  us analyse the data.
                </p>
                <div className="cookie-type">
                  <h4>Functional cookies</h4>
                  <div>
                    <label className="switchSmall">
                      <input type="checkbox" />
                      <small></small>
                    </label>
                  </div>
                </div>
                <p>
                  These cookies help us to understand how effective our
                  marketing campaigns are, and enhance your online experiences
                  with PwC with customisation.
                </p>
                <div className="cookie-type">
                  <h4>Marketing & Social Media Cookies</h4>
                  <div>
                    <label className="switchSmall">
                      <input type="checkbox" />
                      <small></small>
                    </label>
                  </div>
                </div>
                <p>
                  Marketing cookies help us provide you with personalised and
                  relevant services or advertising, and track the effectiveness
                  of our digital marketing activities. Social media cookies are
                  set by a range of social media services that we have added to
                  the site to enable you to share our content with your friends
                  and networks. They are capable of tracking your browser across
                  other sites and building up a profile of your interests. This
                  may impact the content and messages you see on other websites
                  you visit. If you do not allow these cookies you may not be
                  able to use or see these sharing tools.
                </p>
              </div>
            </div>
            <div className="cookie-side-panel-section">
              <div>
                <a className="cookie-btn" onClick={handleRejectAllCookieClick}>
                  Reject all non-essential cookies
                </a>
              </div>
              <div>
                <a className="cookie-btn" onClick={handleMyChoiceCookieClick}>
                  Save my cookie choices and close
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cookies;
