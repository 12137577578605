import * as yup from 'yup';

export const createCampaignFormValidation = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .min(8, 'Minimun 8 Characters')
    .max(150, 'Cannot exceed 150 characters')
    .required('Campaign is required '),
  description: yup.string().required(' Description is required'),
});
