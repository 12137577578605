import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrum } from '../../atoms/Breadcrum/Breadcrum';
import EditDeleteButton from '../../atoms/EditDeleteButton/EditDeleteButton';
import { Button } from '../../atoms/Button/Button';
import { Title } from '../../atoms/Title/Title';
import './CustomTagVersion.scss';

const CustomTagVersion = () => {
  const [currentVersion, setCurrentVersion] = useState<boolean>(true);
  const [versionHistory, setVersionHistory] = useState<boolean>(false);
  const versionData = [
    {
      versionNo: '5',
      tagName: 'dob',
      type: 'Global Tags',
      modifiedOn: '03:23 pm, May 31, 2023',
    },
  ];

  return (
    <div className="CustomTagVersion-container">
      <Breadcrum pageName={'DoB'} />

      <div className="flex-style">
        <div className="text-container">
          <Title text={'DoB'} />
        </div>

        <div className="flex-style">
          <Link to={'/create-custom-tag'}>
            <EditDeleteButton name="Edit" />
          </Link>

          <Button name="Copy" />

          <EditDeleteButton name="Delete" />
        </div>
      </div>

      <div className="versions-button">
        <button
          className={`${currentVersion === true ? 'active-button' : ''}`}
          onClick={() => {
            setCurrentVersion(!currentVersion);
            setVersionHistory(false);
          }}
        >
          Current Version
        </button>
        <button
          className={`${versionHistory === true ? 'active-button' : ''}`}
          onClick={() => {
            setVersionHistory(!versionHistory);
            setCurrentVersion(false);
          }}
        >
          Version History
        </button>
      </div>
      <div className="straight-line"></div>

      {currentVersion ? (
        <div className="current-version-content">
          <div>
            <div className="title">Type of Tag</div>
            <div className="content">Global Tags</div>
          </div>
          <div>
            <div className="title">Image</div>
            <div className="content">No image available</div>
          </div>
          <div>
            <div className="title">HTML Content</div>
            <code className="content">
              &lt;!DOCTYPE html&gt;
              <br />
              &lt;html&gt;
              <br />
              &lt;head&gt;
              <br />
              &lt;title&gt;Date of Birth&lt;/title&gt;
              <br />
              &lt;/head&gt;
              <br />
              &lt;body&gt;
              <br />
              <br />
              &lt;h1&gt;This is a Heading&lt;/h1&gt;
              <br />
              &lt;p&gt;This is a paragraph.&lt;/p&gt;
              <br />
              <br />
              &lt;/body&gt;
              <br />
              &lt;/html&gt;
            </code>
          </div>
        </div>
      ) : null}

      {versionHistory ? (
        <div>
          <div className="table-title">
            <div>Version No.</div>
            <div>Tag Name</div>
            <div>Types</div>
            <div>Modified On</div>
            <div> Actions</div>
          </div>

          {versionData.map((data, index) => (
            <div className="table-content" key={index}>
              <div style={{ textAlign: 'center' }}>{data.versionNo}</div>
              <div className="tag-name">{data.tagName}</div>
              <div>{data.type}</div>
              <div>{data.modifiedOn}</div>
              <div className="flex-display">
                <div className="view-button">
                  <svg
                    width="22"
                    height="15"
                    viewBox="0 0 22 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 4.5C10.2044 4.5 9.44129 4.81607 8.87868 5.37868C8.31607 5.94129 8 6.70435 8 7.5C8 8.29565 8.31607 9.05871 8.87868 9.62132C9.44129 10.1839 10.2044 10.5 11 10.5C11.7956 10.5 12.5587 10.1839 13.1213 9.62132C13.6839 9.05871 14 8.29565 14 7.5C14 6.70435 13.6839 5.94129 13.1213 5.37868C12.5587 4.81607 11.7956 4.5 11 4.5ZM11 12.5C9.67392 12.5 8.40215 11.9732 7.46447 11.0355C6.52678 10.0979 6 8.82608 6 7.5C6 6.17392 6.52678 4.90215 7.46447 3.96447C8.40215 3.02678 9.67392 2.5 11 2.5C12.3261 2.5 13.5979 3.02678 14.5355 3.96447C15.4732 4.90215 16 6.17392 16 7.5C16 8.82608 15.4732 10.0979 14.5355 11.0355C13.5979 11.9732 12.3261 12.5 11 12.5ZM11 0C6 0 1.73 3.11 0 7.5C1.73 11.89 6 15 11 15C16 15 20.27 11.89 22 7.5C20.27 3.11 16 0 11 0Z"
                      fill="#00580A"
                    />
                  </svg>
                </div>

                <div className="restore-button">
                  <button>Restore</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default CustomTagVersion;
