import React, { FC, ReactNode, useEffect } from 'react';

import { SOCIAL_LAYOUT_TYPE } from '../../types/layoutTypes';
import { DesignOne } from '../../socialDesigns/DesignOne/DesignOne';
import { DesignTwo } from '../../socialDesigns/DesignTwo/DesignTwo';
import { designOneProps, designTwoProps } from '../../types/social';
import './SocialContentPreview.scss';

export interface SocialContentPreviewProps {
  pagesPropsMap: Map<number, Map<string, string>>;
  setPagesPropsMap: (pagePropsMap: Map<number, Map<string, string>>) => void;
  children?: ReactNode;
  activePage: number;
  width?: number;
  setActivePage: (pageNumber: number) => void;
  pagesLayoutMap: Map<number, SOCIAL_LAYOUT_TYPE>;
  setPagesLayoutMap: (pagesLayoutMap: Map<number, SOCIAL_LAYOUT_TYPE>) => void;
  pagesDesignIdMap: Map<number, string>;
  setPagesDesignIdMap: (pagesDesignIdMap: Map<number, string>) => void;
  commonDesignId: string;
}

export const SocialContentPreview: FC<SocialContentPreviewProps> = ({
  pagesPropsMap,
  setPagesPropsMap,
  activePage,
  setActivePage,
  pagesLayoutMap,
  setPagesLayoutMap,
  pagesDesignIdMap,
  setPagesDesignIdMap,
  commonDesignId,
}) => {
  useEffect(() => {
    // Function to log the currently focused element
    const logFocusedElement = () => {
      // const focusedElement = document.activeElement;
      // console.log('Currently focused element:', focusedElement);
    };

    // Add an event listener to capture focus changes
    window.addEventListener('focus', logFocusedElement);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('focus', logFocusedElement);
    };
  }, []);

  const generateJson = (child: ReactNode): any => {
    if (React.isValidElement(child)) {
      // console.log(child.type);
      const childProps = child.props;
      const { children, ...props } = child.props;
      const childJson: any = {
        type:
          child.props && child.props['tagName']
            ? child.props['tagName']
            : child.key,
        props: props,
      };

      if (childProps.children) {
        childJson.children = React.Children.map(
          childProps.children,
          grandChild => generateJson(grandChild),
        );
      }

      return childJson;
    }
    return null;
  };

  const form = () => {
    const props = pagesPropsMap.get(activePage);
    return pagesLayoutMap.get(activePage) === SOCIAL_LAYOUT_TYPE.LAYOUT_ONE ? (
      <DesignOne
        values={props ? props : designOneProps}
        pagePropsMap={pagesPropsMap}
        setPagesPropsMap={setPagesPropsMap}
        pageId={activePage}
      ></DesignOne>
    ) : (
      <DesignTwo
        values={props ? props : designTwoProps}
        pagePropsMap={pagesPropsMap}
        setPagesPropsMap={setPagesPropsMap}
        pageId={activePage}
      ></DesignTwo>
    );
  };

  return (
    <div className="preview-resize-container">
      <div className="cm-preview-container">
        <div className="cm-preview-header">
          <div>Design</div>
          <div className="add-remove-buttons-container">
            <button
              type="button"
              id="add-page"
              className={'active'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                // e.preventDefault();
                const tempPagesPropsMap = new Map(pagesPropsMap);
                const tempPagesLayoutMap = new Map(pagesLayoutMap);
                const noOfPages = tempPagesPropsMap.size;
                if (noOfPages === 0) {
                  const elementList = tempPagesPropsMap.get(noOfPages);
                  if (!elementList || elementList.size === 0) {
                    tempPagesPropsMap.set(0, designOneProps);
                    tempPagesPropsMap.set(1, designOneProps);
                    tempPagesLayoutMap.set(0, SOCIAL_LAYOUT_TYPE.LAYOUT_ONE);
                    tempPagesLayoutMap.set(1, SOCIAL_LAYOUT_TYPE.LAYOUT_ONE);
                  }
                } else {
                  tempPagesPropsMap.set(noOfPages, designOneProps);
                  tempPagesLayoutMap.set(
                    noOfPages,
                    SOCIAL_LAYOUT_TYPE.LAYOUT_ONE,
                  );
                  const tempPagesDesignIdMap = new Map(pagesDesignIdMap);
                  tempPagesDesignIdMap.set(noOfPages, commonDesignId);
                  setPagesDesignIdMap(tempPagesDesignIdMap);
                }

                // console.log('adding new page :', noOfPages + 1);
                setPagesPropsMap(tempPagesPropsMap);
                setPagesLayoutMap(tempPagesLayoutMap);
              }}
            >
              <span>
                <svg
                  fill="#000000"
                  viewBox="-3.5 0 19 19"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cf-icon-svg"
                >
                  <path d="M11.16 16.153a.477.477 0 0 1-.476.475H1.316a.477.477 0 0 1-.475-.475V3.046a.477.477 0 0 1 .475-.475h6.95l2.893 2.893zm-1.11-9.924H8.059a.575.575 0 0 1-.574-.574V3.679H1.95v11.84h8.102zm-1.519 4.198a.475.475 0 0 1-.475.475H6.475v1.582a.475.475 0 1 1-.95 0v-1.582H3.944a.475.475 0 0 1 0-.95h1.581v-1.58a.475.475 0 0 1 .95 0v1.58h1.581a.475.475 0 0 1 .475.475z" />
                </svg>
              </span>
              Add Pages
            </button>
            {pagesLayoutMap.size > 1 && (
              <button
                type="button"
                id="delete-page"
                className={'active'}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  console.log('Delete page');
                }}
              >
                <span>
                  <svg
                    fill="#000000"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 100 100"
                    enableBackground="new 0 0 100 100"
                    xmlSpace="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M73.603,78.553h-6.49h0c-0.956,0-1.73,0.774-1.73,1.73h-0.007v3.01H24.735V36.16h17.723c0.956,0,1.73-0.774,1.73-1.73
			V16.707h21.188l0,20.627h0.008c0.003,0.954,0.776,1.726,1.73,1.726h6.49c0.954,0,1.727-0.772,1.729-1.726h0V37.33l0-0.001
			c0,0,0-0.001,0-0.001l0-24.828h-0.005V8.48c0-0.956-0.774-1.73-1.73-1.73h-2.45v0H42.457v0h-1.73L14.778,32.7v2.447v1.013v52.912
			v2.447c0,0.956,0.774,1.73,1.73,1.73h1.582h53.925h1.582c0.956,0,1.73-0.774,1.73-1.73v-2.448h0.005l0-8.789l0-0.001
			C75.333,79.328,74.559,78.553,73.603,78.553z"
                        />
                      </g>
                      <g>
                        <path
                          d="M79.21,58.751l5.505-5.505c0.676-0.676,0.676-1.771,0-2.447l-4.894-4.893c-0.676-0.676-1.771-0.676-2.446,0l-5.505,5.505
			l-5.505-5.505c-0.676-0.676-1.77-0.676-2.446,0L59.026,50.8c-0.676,0.676-0.676,1.771,0,2.447l5.505,5.505l-5.505,5.505
			c-0.676,0.676-0.676,1.771,0,2.446l4.894,4.893c0.676,0.676,1.77,0.676,2.446,0l5.505-5.505l5.505,5.505
			c0.676,0.676,1.77,0.676,2.446,0l4.894-4.893c0.676-0.676,0.676-1.771,0-2.446L79.21,58.751z"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                Delete Page
              </button>
            )}
          </div>
        </div>

        {form()}
        <div className="cm-pagination-wrapper">
          <div className="cm-pagination">
            <button className="btn-nav left-btn" type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="left-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </button>
            <div className="page-numbers">
              {pagesLayoutMap.size > 1 ? (
                Array.from(
                  { length: pagesLayoutMap.size },
                  (v, i) => `${i + 1}`,
                ).map((page, index) => (
                  <button
                    type="button"
                    className={
                      activePage === index
                        ? 'btn-page btn-selected'
                        : 'btn-page'
                    }
                    onClick={e => {
                      e.preventDefault();
                      setActivePage(index);
                      // e.preventDefault();
                    }}
                    key={`page_no_${index}`}
                  >
                    {page}
                  </button>
                ))
              ) : (
                <button
                  type="button"
                  className="btn-page"
                  onClick={e => {
                    e.preventDefault();
                    setActivePage(0);
                  }}
                >
                  1
                </button>
              )}
            </div>
            <button className="btn-nav right-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="right-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
