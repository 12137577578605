import { FC, useState } from 'react';
import './PdfCustomTag.scss';
import { copyContent } from '../../../utils/elementsUtil';

export interface PdfCustomTagProps {
  id: string;
  name?: string;
  setSelectedElementId: (id: string) => void;
  value: string;
  onValueChange: (id: string, newValue: string) => void;
}

export const PdfCustomTag: FC<PdfCustomTagProps> = ({
  id,
  name,
  setSelectedElementId,
  value,
  onValueChange,
}) => {
  const [isCopied, setCopied] = useState<boolean>(false);

  return (
    <div
      id={id}
      key={id}
      className="custom-tag"
      onClick={() => {
        // handleLabelClick();
        setSelectedElementId(id);
      }}
    >
      {value}
      <svg
        onClick={() =>
          copyContent(`<customtag id="${id}">${value}</customtag>`, setCopied)
        }
        className="copyicon_svg"
        width="15px"
        height="15px"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 15 15"
        xmlSpace="preserve"
      >
        <path
          d="M14,11.1V0.7L13.4,0H4.9L4.2,0.7v2.6H1.6L1,3.9v10.4L1.6,15h8.5l0.7-0.7v-2.6h2.6L14,11.1z M10.8,10.4V3.9
	l-0.7-0.7H5.5v-2h7.2v9.1H10.8z M2.3,4.6h7.2v9.1H2.3V4.6z"
        />
      </svg>
      {isCopied && <p>Copied!</p>}
    </div>
  );
};

PdfCustomTag.displayName = 'PdfCustomTag';
