import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { CampaignTrackingReq, CampaignTrackingRes } from '../../types/campaign';

export const getCampaignTracking = async (
  campaignTrackingReq: CampaignTrackingReq,
  brandId: any,
): Promise<CampaignTrackingRes[]> => {
  return api
    .post(URLs.GET_CAMPAIGN_TRACKING(brandId), campaignTrackingReq.pagination)
    .then((res: any) => res.data);
};

export const useGetCampaigntracking = (): UseMutationResult<
  CampaignTrackingRes[],
  Error,
  CampaignTrackingReq
> => {
  return useMutation(
    [QueryKeys.GetCampaignTracking],
    (campaignTrackingReq: CampaignTrackingReq) =>
      getCampaignTracking(campaignTrackingReq, campaignTrackingReq.brandId),
  );
};
