import { FC, useState } from 'react';
import classNames from 'classnames';
import './PdfAddress.scss';

export interface PdfAddressProps {
  id: string;
  name: string;
  alignment?: 'Left' | 'Right' | 'Center';
  className?: string;
  setSelectedElementId: (id: string) => void;
  value: string;
  onValueChange: (id: string, newValue: string) => void;
}

export const PdfAddress: FC<PdfAddressProps> = ({
  id,
  name,
  alignment = 'Left',
  className,
  setSelectedElementId,
  value,
  onValueChange,
}) => {
  // const pdfAddressRef = useRef<HTMLDivElement>(null);
  const [editing, setEditing] = useState<boolean>(false);
  // const handleChangeOnBlur = () => {
  //   // console.log('handleChangeOnBlur :: ', pdfLabelRef.current);
  //   if (pdfAddressRef.current) {
  //     // pdfLabelRef.current.removeAttribute('contentEditable');
  //     const newText = pdfAddressRef.current.innerHTML;
  //     onValueChange(id, newText ? newText : '');
  //   }
  // };
  // const handleKeyDown = (e: any) => {
  //   console.log(' Address Key Down');
  //   const selection = window.getSelection();
  //   if (e.key === 'Enter' && selection) {
  //     e.preventDefault();

  //     // Create a <br> element and insert it at the current caret position

  //     const range = selection.getRangeAt(0);
  //     const br = document.createElement('br');
  //     range.insertNode(br);
  //     range.setStartAfter(br);
  //     range.setEndAfter(br);
  //     selection.removeAllRanges();
  //     selection.addRange(range);
  //   }
  // };

  return editing ? (
    <div
      // ref={pdfAddressRef}
      id={id}
      key={id}
      className={classNames(
        'text-style-12 m-l-5px  f-w-400 t-a-start pdf-address',
        className,
        alignment === 'Right' ? 'pdf-text-right-align' : '',
      )}
    >
      <textarea
        rows={6}
        onBlur={e => {
          // setSelectedElementId(id);
          // value = e.target.value;
          // onValueChange(id, value);
          setEditing(false);
        }}
        onChange={e => {
          value = e.target.value;
          // console.log('value[0] :: ', value);
          onValueChange(id, value);
        }}
        value={value}
      ></textarea>
    </div>
  ) : (
    <div
      // ref={pdfAddressRef}
      id={id}
      key={id}
      className={classNames(
        'text-style-12 m-l-5px  f-w-400 t-a-start',
        className,
        alignment === 'Right' ? 'pdf-text-right-align' : '',
      )}
      // contentEditable
      // suppressContentEditableWarning
      onClick={() => {
        // handleChangeOnBlur();
        setSelectedElementId(id);
        setEditing(true);
      }}
      // onBlur={e => handleChangeOnBlur}
      // onInput={e => handleChangeOnBlur}
      // onKeyUp={e => {
      //   console.log('asdasd', e);
      // }}
      // onKeyDown={handleKeyDown}
      // onKeyDown={e => {
      //   console.log('asdasd', e);
      //   handleKeyDown(e);
      // }}
      dangerouslySetInnerHTML={{ __html: value }}
    ></div>
  );
};

PdfAddress.displayName = 'PdfAddress';
