import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import {
  SaveSocialCampaignContent,
  SocialCampaignContent,
} from '../../types/social';

export const saveSocialContent = async (
  socialCampaignContent: SocialCampaignContent,
  campaignId: string,
): Promise<void> => {
  const pages = socialCampaignContent.campaignPages.map((page, index) => {
    return {
      designId: page.designId,
      propsMap: page.propsMap
        ? JSON.parse(JSON.stringify(Object.fromEntries(page.propsMap)))
        : {},
    };
  });
  api
    .post(URLs.SAVE_SOCIAL_CONTENT(campaignId), { campaignPages: pages })
    .then((res: any) => res.data);
};

export const useSaveSocialContent = (): UseMutationResult<
  void,
  Error,
  SaveSocialCampaignContent
> => {
  return useMutation(
    [QueryKeys.SaveSocialContent],
    (saveSocialCampaignContent: SaveSocialCampaignContent) =>
      saveSocialContent(
        saveSocialCampaignContent.socialCampaignContent,
        saveSocialCampaignContent.campaignId,
      ),
  );
};
