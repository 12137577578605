import { FC } from 'react';
import InputBox from '../../atoms/InputBox/InputBox';
import { Title } from '../../atoms/Title/Title';
import './ChangePasswordPage.scss';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { ChangePassword } from '../../types/login';
import { useSearchParams } from 'react-router-dom';
import { useChangePassword } from '../../hooks/useChangePassword/useChangePassword';
import {
  updateAccessToken,
  updateRefreshToken,
} from '../../slices/cmsAppSlice';

export const ChangePasswordPage: FC<any> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { mutate } = useChangePassword();

  const email =
    queryParams.get('id') && queryParams.get('id') !== null
      ? queryParams.get('id')
      : '';

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      password: '',
      email: email,
    },
    onSubmit: (values: ChangePassword) => {
      mutate(values, {
        onSuccess: data => {
          if (data?.accessToken) {
            dispatch(updateAccessToken(data.accessToken));
            dispatch(updateRefreshToken(data.refreshToken));
            // addToast({
            //   label: `New Lead created: ${data.clientId}`,
            //   type: 'success',
            // });
            navigate('/');
          } else if (data?.forcePasswordChange) {
            navigate(`/home`);
          }
        },
        onError: () => {
          // addToast({
          //   label: `New Lead creation failed.`,
          //   type: 'error',
          // });
        },
      });
    },
  });

  return (
    <div className="reset-password-container">
      <form onSubmit={formik.handleSubmit}>
        <div className="reset-container">
          <div className="logo">
            <img src="NipunaCampaignSuit-r-corner.png" alt="logo" />
          </div>
          <Title text={'Current Password'} />
          <InputBox
            password={true}
            label={'Current Password'}
            id={'password'}
            name={'password'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={'Enter your current pasword'}
          />
          <InputBox
            password={true}
            label={'New Password'}
            id={'newPassword'}
            name={'newPassword'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={'Enter your new pasword'}
          />
          <InputBox
            password={true}
            label={'Confirm New Password'}
            id={'confirmNewPassword'}
            name={'confirmNewPassword'}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={'Confirm your new pasword'}
          />
          <button type="submit">Change Password</button>
        </div>
      </form>
    </div>
  );
};
