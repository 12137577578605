import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import './Elements.scss';
// import { useGetPersonalTags } from '../../hooks/useGetPersonalTags/useGetPersonalTags';
// import { useGetCampaignInfo } from '../../hooks/useGetCampaignInfo/useGetCampaignInfo';
// import { useGetCustomTagIds } from '../../hooks/useGetCustomTagIds/useGetCustomTagIds';
// import { useGetSystemTemplateList } from '../../hooks/useGetSystemTemplateList/useGetSystemTemplateList';
import { CustomTagElement } from '../../components/PdfCustomTagElementsPanel/PdfCustomTagElementsPanel';
import { PDF_ELEMENTS } from '../../types/pdfElements';

interface ElementProps {
  type: string;
  htmlCode: string;
  setSystemTemplateId?: (systemTemplateId: string) => void;
}

const Element = ({ type, htmlCode, setSystemTemplateId }: ElementProps) => {
  const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData('text/plain', htmlCode);
  };

  return setSystemTemplateId ? (
    <div className="item" onClick={() => setSystemTemplateId(htmlCode)}>
      <p>{type}</p>
    </div>
  ) : (
    <div className="item" draggable onDragStart={handleDragStart}>
      <p>{type}</p>
    </div>
  );
};

interface ElementsProps {
  campaignId: string;
  setSystemTemplateId?: (systemTemplateId: string) => void;
}

export const Elements: FC<ElementsProps> = () => {
  const [droppedHtmlCode, setDroppedHtmlCode] = React.useState<string>('');
  // const pagination = {
  //   startIndex: 1,
  //   endIndex: 1,
  //   currentPage: 1,
  //   pageSize: 30,
  // };
  // const { data: personalTags } = useGetPersonalTags();
  // const { data: customTagsIdNameList, mutate: loadCustomTags } =
  //   useGetCustomTagIds();

  // const { data: systemTemplateList, mutate: getSystemTemplates } =
  //   useGetSystemTemplateList();

  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(150);
  const startResizing = useCallback((mouseDownEvent: React.MouseEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (isResizing && sidebarRef.current) {
        const newWidth =
          mouseMoveEvent.clientX -
          sidebarRef.current.getBoundingClientRect().left;

        if (newWidth <= 300) {
          setSidebarWidth(newWidth);
        } else {
          setSidebarWidth(300);
        }
      }
    },
    [isResizing],
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  const [categories, setCategories] = useState([
    { title: 'Images', isClicked: false },
    // { title: 'CustomTags', isClicked: false },
    // { title: 'Demographics', isClicked: false },
    { title: 'PDF Elements', isClicked: false },
    // { title: 'Templates', isClicked: false },

    // Add more categories as needed
  ]);

  const toggleCategory = (index: number) => {
    setCategories(prevCategories =>
      prevCategories.map((category, i) =>
        i === index
          ? { ...category, isClicked: !category.isClicked }
          : category,
      ),
    );
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const data = e.dataTransfer.getData('text/plain');
    setDroppedHtmlCode(data);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  // const { data: campaignInfo, mutate: getCampaignInfo } = useGetCampaignInfo();
  // useEffect(() => {
  //   getCampaignInfo(campaignId);
  // }, [campaignId]);

  // useEffect(() => {
  //   if (campaignInfo && campaignInfo.brandId) {
  //     // loadCustomTags(campaignInfo.brandId);
  //   }
  // }, [campaignInfo]);

  // useEffect(() => {
  //   getSystemTemplates(pagination);
  // }, []);

  return (
    <div
      className="resize-container"
      ref={sidebarRef}
      style={{ minWidth: sidebarWidth }}
    >
      <div className="element-container">
        {/* <div className="heading">
          <p>Element</p>
        </div> */}

        <div className="element-items">
          {categories.map((category, index) => (
            <div className="category-section" key={index}>
              <div
                className={`category-title ${
                  category.isClicked ? 'active-font' : 'normal-font'
                }`}
                onClick={() => toggleCategory(index)}
              >
                {category.isClicked ? (
                  <div className="down-arrow">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5 5.5L0 0.5H10L5 5.5Z" fill="#222222" />
                    </svg>
                  </div>
                ) : (
                  <div className="right-arrow">
                    <svg
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.5 5L0.5 10L0.5 0L5.5 5Z" fill="#666666" />
                    </svg>
                  </div>
                )}
                <p>{category.title}</p>
              </div>
              {category.isClicked && category.title === 'Images' ? (
                <div className="elements-list">
                  <Element
                    type="Logo"
                    htmlCode="<img src='your-logo-url' alt='your-logo'>"
                  />
                  <Element
                    type="Custom Image"
                    htmlCode="<img src='your-image-url' alt='your-image'>"
                  />
                </div>
              ) : null}
              {/* {category.isClicked && category.title === 'Demographics' && (
                <div className="elements-list">
                  {personalTags?.map((item, index) => (
                    <Element
                      key={`pesonalTag_${index}`}
                      type={item.columnName}
                      htmlCode={`<ptag class="personal-tag">${item.columnName}</ptag>`}
                    />
                  ))}
                </div>
              )} */}
              {/* {category.isClicked && category.title === 'CustomTags' ? (
                <div className="elements-list">
                  {customTagsIdNameList?.map(
                    (item: { tagName: string; tagId: any }, index: any) => (
                      <Element
                        key={`pesonalTag_${index}`}
                        type={item.tagName}
                        htmlCode={`<customtag  class="custom-tag" id="${item.tagId}" name="${item.tagName}">${item.tagName}</customtag>`}
                      />
                    ),
                  )}
                </div>
              ) : null} */}
              {category.isClicked && category.title === 'PDF Elements' && (
                // <div className="social-cm-tab">
                //   <div className="tab-content">
                <div className="elements-wrapper">
                  <CustomTagElement
                    type="Label"
                    htmlCode={PDF_ELEMENTS.Label}
                  ></CustomTagElement>
                  <CustomTagElement
                    type="Label Small Bold"
                    htmlCode={PDF_ELEMENTS.LabelBold}
                  ></CustomTagElement>
                  <CustomTagElement
                    type="Label Header Size"
                    htmlCode={PDF_ELEMENTS.LabelHeader}
                  ></CustomTagElement>
                  {/* <CustomTagElement
                    type="FWG LOGO"
                    htmlCode={PDF_ELEMENTS.LOGO}
                  ></CustomTagElement>
                  <CustomTagElement
                    type="Money Wellness LOGO"
                    htmlCode={PDF_ELEMENTS.MW_LOGO}
                  ></CustomTagElement> */}
                  <CustomTagElement
                    type="Address"
                    htmlCode={PDF_ELEMENTS.Address}
                  ></CustomTagElement>
                  <CustomTagElement
                    type="List"
                    htmlCode={PDF_ELEMENTS.ULList}
                  ></CustomTagElement>
                  <CustomTagElement
                    type="Footer"
                    htmlCode={PDF_ELEMENTS.FOOTER}
                  ></CustomTagElement>
                  <CustomTagElement
                    type="Table"
                    htmlCode={PDF_ELEMENTS.TABLE}
                  ></CustomTagElement>
                  <CustomTagElement
                    type="Two Column Layout"
                    htmlCode={PDF_ELEMENTS.TWO_COLUMN_LAYOUT}
                  ></CustomTagElement>
                  {/* <CustomTagElement
                    type="Two Tabels"
                    htmlCode={PDF_ELEMENTS.TWO_COLUMN_TABLE}
                  ></CustomTagElement> */}
                  <CustomTagElement
                    type="Print Signature"
                    htmlCode={PDF_ELEMENTS.PRINT_SIGNATURE}
                  ></CustomTagElement>
                  <CustomTagElement
                    type="Empty Line"
                    htmlCode={PDF_ELEMENTS.EMPTY_LINE}
                  ></CustomTagElement>
                  <CustomTagElement
                    type="Loop Start"
                    htmlCode={PDF_ELEMENTS.LOOP_START}
                  ></CustomTagElement>
                  <CustomTagElement
                    type="Loop End"
                    htmlCode={PDF_ELEMENTS.LOOP_END}
                  ></CustomTagElement>
                  <CustomTagElement
                    type="Two tables"
                    htmlCode={PDF_ELEMENTS.TWO_TABLES}
                  ></CustomTagElement>
                  <CustomTagElement
                    type="Image"
                    htmlCode={PDF_ELEMENTS.PDF_IMAGE}
                  ></CustomTagElement>
                </div>

                //   </div>
                // </div>
              )}
              {/* {category.isClicked &&
                category.title === 'Templates' &&
                systemTemplateList && (
                  <div className="elements-list">
                    {systemTemplateList.records?.map((item, index) => (
                      <Element
                        key={`templateId_${item.templateId}`}
                        type={item.name}
                        htmlCode={item.templateId}
                        setSystemTemplateId={setSystemTemplateId}
                      />
                    ))}
                  </div>
                )} */}
            </div>
          ))}
        </div>

        <div
          className="droppable-container"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          dangerouslySetInnerHTML={{ __html: droppedHtmlCode }}
        />
      </div>
      <div className="sidebar-resizer" onMouseDown={startResizing} />
    </div>
  );
};
