import './CampaignTracking.scss';
import { FC, useState } from 'react';
import { Breadcrum } from '../../../atoms/Breadcrum/Breadcrum';
import { Title } from '../../../atoms/Title/Title';
import { TemplateForTrack } from '../../../atoms/ProgressTemplate/TemplateForTrack';
import SelectBox, { SelectOption } from '../../../atoms/Select/Select';
import { useGetDashboard } from '../../../hooks/useGetDashboard/useGetDashboard';
import { brandToSelectOption } from '../../../utils/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/store';

export const CampaignTracking: FC = () => {
  const selectedBrandId = useSelector(
    (state: RootState) => state.cmsAppSlice.selectedBrandId,
  );

  const [brandId, setBrandId] = useState<string>(
    selectedBrandId ? selectedBrandId : '',
  );

  const { data: brandsList } = useGetDashboard();

  const handleBrandSelectionChange = (option: SelectOption | any) => {
    setBrandId(option.value);
  };

  const brands: SelectOption[] = brandsList
    ? brandsList.map(brand => {
        return {
          label: brand.brandName,
          value: brand.brandId,
        };
      })
    : [];

  return (
    <div className="campaignprogress-container">
      <Breadcrum pageName="Campaign Tracking" />
      <div className="flex-style">
        <div className="text-container">
          <Title text={'Campaign Tracking'} />
          {/* <p> Desciption of your Campaign Tracking will appear here </p> */}
        </div>
      </div>
      {/* <div className="line"></div> */}
      <SelectBox
        id={'brandId'}
        name={'brandId'}
        label={'Brand'}
        list={brands}
        className="max-width254"
        // errorMessage={formik.errors.brandId}
        value={
          brandsList && brandsList.find(brand => brandId === brand.brandId)
            ? brandToSelectOption(
                brandsList.find(brand => brandId === brand.brandId),
              )
            : { label: 'Select brand', value: '' }
        }
        onChange={handleBrandSelectionChange}
      />

      <div className="headings">
        <div className="Name">Name</div>
        <div className="CampaignType">Type</div>
        <div className="scheduleType">Schedule Type</div>
        <div className="lastExecutionStartTime">Last Excution Time</div>
        <div className="lastExecutionEndTime">Last Excution Finish Time</div>
        <div className="failCount">Fail Count</div>
        <div className="successCount">Success Count</div>
      </div>
      <div className="list">
        <TemplateForTrack brandId={brandId} />
      </div>
    </div>
  );
};
