import { Link } from 'react-router-dom';
import './BrandsBox.scss';
import { useDispatch } from 'react-redux';
import {
  updateSelectedBrand,
  updateSelectedBrandId,
} from '../../slices/cmsAppSlice';

export interface BrandBoxProps {
  brandId: string;
  name: string;
  imgName?: string;
  noOfCampaigns: number;
}

const BrandsBox = (props: BrandBoxProps) => {
  const dispatch = useDispatch();

  return (
    <Link
      to="/campaign-list"
      onClick={() => {
        dispatch(updateSelectedBrand(props.name));
        dispatch(updateSelectedBrandId(props.brandId));
      }}
    >
      <div className="brandBox-container">
        <div className="menu">
          <svg
            width="12"
            height="3"
            viewBox="0 0 12 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.8244 2.40519C10.6643 2.40881 10.505 2.3804 10.356 2.32162C10.2069 2.26284 10.0711 2.17487 9.95655 2.06289C9.84198 1.9509 9.75093 1.81716 9.68876 1.6695C9.62659 1.52185 9.59454 1.36326 9.5945 1.20305C9.59446 1.04284 9.62643 0.884236 9.68853 0.73655C9.75063 0.588864 9.84161 0.455074 9.95612 0.343033C10.0706 0.230992 10.2064 0.142958 10.3554 0.0841018C10.5044 0.0252454 10.6637 -0.00324793 10.8238 0.000293987C11.1381 0.0072432 11.4371 0.136941 11.657 0.361623C11.8768 0.586306 11.9999 0.888117 12 1.20245C12.0001 1.51678 11.8771 1.81865 11.6574 2.04345C11.4377 2.26824 11.1387 2.39809 10.8244 2.40519ZM2.40519 1.20244C2.40881 1.36261 2.3804 1.52189 2.32162 1.67093C2.26284 1.81997 2.17487 1.95576 2.06289 2.07033C1.9509 2.1849 1.81716 2.27595 1.6695 2.33812C1.52185 2.4003 1.36326 2.43234 1.20305 2.43238C1.04284 2.43242 0.884236 2.40046 0.73655 2.33836C0.588865 2.27626 0.455074 2.18528 0.343033 2.07076C0.230992 1.95624 0.142959 1.8205 0.0841023 1.67149C0.0252458 1.52248 -0.00324793 1.36321 0.000293983 1.20304C0.0072432 0.888788 0.136941 0.589741 0.361623 0.369917C0.586306 0.150093 0.888117 0.0269625 1.20245 0.0268839C1.51678 0.0268053 1.81865 0.149785 2.04345 0.369496C2.26824 0.589207 2.39809 0.88819 2.40519 1.20244ZM7.21619 1.20244C7.21981 1.36261 7.1914 1.52189 7.13262 1.67093C7.07384 1.81997 6.98587 1.95576 6.87389 2.07033C6.7619 2.1849 6.62816 2.27595 6.4805 2.33812C6.33285 2.4003 6.17426 2.43234 6.01405 2.43238C5.85384 2.43242 5.69524 2.40046 5.54755 2.33836C5.39986 2.27626 5.26607 2.18528 5.15403 2.07076C5.04199 1.95624 4.95396 1.8205 4.8951 1.67149C4.83625 1.52248 4.80775 1.36321 4.81129 1.20304C4.81824 0.888788 4.94794 0.589741 5.17262 0.369917C5.39731 0.150093 5.69912 0.0269625 6.01345 0.0268839C6.32778 0.0268053 6.62965 0.149785 6.85445 0.369496C7.07924 0.589207 7.20909 0.88819 7.21619 1.20244Z"
              fill="#00580A"
            />
          </svg>
        </div>

        <div>
          {/* <img
            src={props.imgName}
            alt="brands logo"
            width={'117px'}
            height={'52px'}
          /> */}
        </div>

        <div className="brand-name">{props.name}</div>

        <div className="noOfCampaigns">{props.noOfCampaigns} Campaigns</div>
      </div>
    </Link>
  );
};

export default BrandsBox;
