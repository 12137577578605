import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { DataSourceDetails } from '../../types/datasource';
import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';

export const saveDataSource = async (
  saveDataSourceRequest: DataSourceDetails,
  campaignId: string,
): Promise<void> =>
  api
    .post(URLs.SAVE_DATASOURCE_TABLES(campaignId), saveDataSourceRequest)
    .then((res: any) => res.data);

export const useSaveDataSource = (): UseMutationResult<
  void,
  Error,
  DataSourceDetails
> => {
  const campaignId = useSelector(
    (state: RootState) => state.createCampaignSlice.createdCampaignId,
  );
  return useMutation(
    [QueryKeys.SaveDataSource],
    (saveDataSourceRequest: DataSourceDetails) =>
      saveDataSource(saveDataSourceRequest, campaignId ? campaignId : ''),
  );
};
