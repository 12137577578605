import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import './PdfLabel.scss';
import { TextInput } from 'cm-react-components/dist/js';

export interface PdfLabelProps {
  id: string;
  name: string;
  labelType?: 'Heading' | 'Bold' | 'Normal';
  alignment?: 'Left' | 'Right' | 'Center';
  className?: string;
  setSelectedElementId: (id: string) => void;
  value: string;
  onValueChange: (id: string, newValue: string) => void;
  color?: string;
  backgroundColor?: string;
  borderSize?: string;
  borderColor?: string;
  fontSize?: string;
  fontStyle?: string;
}

export const PdfLabel: FC<PdfLabelProps> = ({
  id,
  name,
  labelType = 'Normal',
  className,
  alignment,
  setSelectedElementId,
  value,
  onValueChange: onValueChange,
  color,
  backgroundColor,
  borderSize,
  borderColor,
  fontSize,
  fontStyle,
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [stateValue, setStateValue] = useState<string>(value);

  useEffect(() => {
    editing === false && onValueChange(id, stateValue);
  }, [editing]);

  switch (labelType) {
    case 'Bold': {
      return editing === true ? (
        <TextInput
          id={id}
          key={id}
          onBlur={e => {
            setStateValue(e.target.value);
            setEditing(false);
          }}
          onChange={e => {
            onValueChange(id, e.target.value);
          }}
          borderRadius="0px"
          value={value}
        ></TextInput>
      ) : (
        <div
          id={id}
          key={id}
          className={classNames(
            'text-style-12 f-w-600 caibri',
            className,
            alignment === 'Right'
              ? 'pdf-text-right-align'
              : alignment === 'Center'
              ? 'pdf-text-center-align'
              : '',
          )}
          style={{
            color: color,
            backgroundColor: backgroundColor,
            border: borderSize,
            borderColor: borderColor,
            fontSize: fontSize,
            textDecoration: fontStyle,
            fontWeight: fontStyle,
            fontStyle: fontStyle,
          }}
          onClick={() => {
            setSelectedElementId(id);
            setEditing(true);
          }}
        >
          {value}
        </div>
      );
      break;
    }
    case 'Heading': {
      return editing === true ? (
        <TextInput
          id={id}
          key={id}
          onBlur={e => {
            setStateValue(e.target.value);
            setEditing(false);
          }}
          onChange={e => {
            onValueChange(id, e.target.value);
          }}
          value={value}
          borderRadius={'0px'}
        ></TextInput>
      ) : (
        <div
          className={classNames(
            'cm-h2-font-size f-w-600',
            className,
            alignment === 'Right'
              ? 'pdf-text-right-align'
              : alignment === 'Center'
              ? 'pdf-text-center-align'
              : '',
          )}
          id={id}
          key={id}
          onClick={() => {
            // handleLabelClick();
            setSelectedElementId(id);
            setEditing(true);
          }}
          style={{
            color: color,
            backgroundColor: backgroundColor,
            border: borderSize,
            borderColor: borderColor,
            fontSize: fontSize,
            textDecoration: fontStyle,
            fontWeight: fontStyle,
            fontStyle: fontStyle,
          }}
        >
          {value}
        </div>
      );
      break;
    }
    case 'Normal': {
      return editing === true ? (
        <TextInput
          id={id}
          key={id}
          onBlur={e => {
            setStateValue(e.target.value);
            setEditing(false);
          }}
          onChange={e => {
            onValueChange(id, e.target.value);
          }}
          borderRadius={'0px'}
          value={value}
        ></TextInput>
      ) : (
        <div
          id={id}
          key={id}
          className={classNames(
            'text-style-12 f-w-400 caibri',
            className,
            alignment === 'Right'
              ? 'pdf-text-right-align'
              : alignment === 'Center'
              ? 'pdf-text-center-align'
              : '',
          )}
          onClick={() => {
            setSelectedElementId(id);
            setEditing(true);
          }}
          style={{
            color: color,
            backgroundColor: backgroundColor,
            border: borderSize,
            borderColor: borderColor,
            fontSize: fontSize,
            textDecoration: fontStyle,
            fontWeight: fontStyle,
            fontStyle: fontStyle,
          }}
        >
          {value}
        </div>
      );
      break;
    }
    default: {
      return editing === true ? (
        <TextInput
          id={id}
          key={id}
          onBlur={e => {
            setStateValue(e.target.value);
            setEditing(false);
          }}
          onChange={e => {
            onValueChange(id, e.target.value);
          }}
          value={value[0]}
        ></TextInput>
      ) : (
        <div
          id={id}
          key={id}
          className={classNames(
            'text-style-12 f-w-400 caibri',
            className,
            alignment === 'Right' ? 'pdf-text-right-align' : '',
          )}
          onClick={() => {
            // handleLabelClick();
            setSelectedElementId(id);
            setEditing(true);
          }}
          style={{
            color: color,
            backgroundColor: backgroundColor,
            border: borderSize,
            borderColor: borderColor,
            fontSize: fontSize,
            textDecoration: fontStyle,
            fontWeight: fontStyle,
            fontStyle: fontStyle,
          }}
        >
          Label
        </div>
      );
      break;
    }
  }
};

PdfLabel.displayName = 'PdfLabel';
