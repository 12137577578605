import { ChangeEvent, FC, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Breadcrum } from '../../atoms/Breadcrum/Breadcrum';
import { Button } from 'cm-react-components/dist/js';
import InputBox from '../../atoms/InputBox/InputBox';
import Textbox from '../../atoms/Textbox/Textbox';
import { Title } from '../../atoms/Title/Title';
import './CreatePdfTemplate.scss';
import SelectBox, { SelectOption } from '../../atoms/Select/Select';
import { useFormik } from 'formik';
import { createCampaignFormValidation } from './validation';
import { PdfTemplateInfo, templateTypes } from '../../types/campaign';
import { Next } from '../../atoms/Next/Next';
import { CAMPAIGN_TYPE_ICONS } from '../../types/constants';
import { useSavePdfTemplate } from '../../hooks/useSavePdfTemplate/useSavePdfTemplate';
import { useGetPdfTemplateInfo } from '../../hooks/useGetPdfTemplateInfo/useGetPdfTemplateInfo';
import { useIsPdfTemplateNameExists } from '../../hooks/useIsPdfTemplateNameExists/useIsPdfTemplateNameExists';

const CreatePdfTemplate: FC = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const templateId =
    queryParams.get('id') && queryParams.get('id') !== null
      ? queryParams.get('id')
      : '';
  const isEditing = templateId && templateId !== null;
  const {
    mutate: getPdfTemplateInfo,
    data: pdfTemplateInfo,
    isLoading: loading,
  } = useGetPdfTemplateInfo();
  const { mutate: isPdfTemplateNameExists } = useIsPdfTemplateNameExists();

  const { mutate } = useSavePdfTemplate();
  const handleReset = () => {
    window.location.reload();
  };

  const createPdfTemplate = (values: PdfTemplateInfo) => {
    formik.validateForm().then((result: any) => {
      if (result && Object.keys(result).length > 0) {
        // addToast({
        //   label: `Please pr  ovide mandatory information`,
        //   type: 'error',
        // });
      } else {
        mutate(templateId ? { ...values, templateId: templateId } : values, {
          onSuccess: data => {
            if (data) {
              navigate(`/pdftemplatedesign?id=${data}`);
            }
          },
          onError: () => {
            // addToast({
            //   label: `New Lead creation failed.`,
            //   type: 'error',
            // });
          },
        });
      }
    });
  };

  const formik = useFormik({
    initialValues:
      loading === false && pdfTemplateInfo
        ? pdfTemplateInfo
        : {
            //brandId: selectedBrandId ? selectedBrandId : 'cf v',
            name: '',
            description: '',
            templateType: '',
            charset: '',
            thumbnailUrl: '',
          },
    enableReinitialize: true,
    validationSchema: createCampaignFormValidation,
    onSubmit: async (values: PdfTemplateInfo) => {
      createPdfTemplate(values);
    },
  });

  const handleCampaignNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (pdfTemplateInfo && pdfTemplateInfo.name === value) {
      formik.handleChange(e);
      return;
    }
    isPdfTemplateNameExists(
      { name: value },
      {
        onSuccess: data => {
          if (data && data === true) {
            formik.setFieldError('name', 'Template Name already exists');
          }
        },
        // onError: () => {},
      },
    );
    formik.handleChange(e);
  };

  const handleCampaingTypeSelectionChange = (option: SelectOption | any) => {
    formik.setFieldValue('templateType', option.value);
  };

  useEffect(() => {
    if (queryParams.get('id')) {
      getPdfTemplateInfo(queryParams.get('id'));
      formik.setTouched({});
    }
  }, []);

  return (
    <div className="createCampaign-container">
      <form onSubmit={formik.handleSubmit} className="createCampaign-form">
        <Breadcrum pageName={'Create PDF Template'} />
        <Title text="Please provide template details" />
        <div></div>
        <div className="grid-layout">
          <div>
            <InputBox
              label="Template Name"
              id={'name'}
              name={'name'}
              onChange={handleCampaignNameChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              errorMessage={formik.errors.name}
            />

            <SelectBox
              id={'templateType'}
              name={'templateType'}
              label={'Template Type'}
              onChange={handleCampaingTypeSelectionChange}
              list={templateTypes}
              value={templateTypes.find(
                templateType =>
                  formik.values.templateType === templateType.value,
              )}
            />

            <Textbox
              label={'Description'}
              id={'description'}
              name={'description'}
              row={5}
              placeholder={'Enter the description here'}
              errorMessage={formik.errors.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
            />
            <InputBox
              label="Thumbnail URL"
              id={'thumbnailUrl'}
              name={'thumbnailUrl'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.thumbnailUrl}
              errorMessage={formik.errors.thumbnailUrl}
            />
          </div>
          <div className="right-image-panel">
            <img src={CAMPAIGN_TYPE_ICONS.PDF} />
          </div>
        </div>

        <div className="navigationButtons">
          <Button label="Reset" onClick={handleReset} size="medium" />

          <Link to="/">
            <Button label="Cancel" size={'medium'} />
          </Link>
          {isEditing && (
            <Link to={`/pdftemplatedesign?id=${templateId}`}>
              <Button label="Skip" size="medium" />
            </Link>
          )}
          <Next type={'submit'} label={isEditing ? 'Update' : 'Next'} />
        </div>
      </form>
    </div>
  );
};

export default CreatePdfTemplate;
