export const LOCAL_API_URL =
  'https://4ae0-2a02-c7c-f444-b300-5932-7774-40f0-1e8b.ngrok-free.app';
export const API_BASE =
  process.env.NODE_ENV === 'production' ? '' : LOCAL_API_URL;

export const URLs = {
  BRANDS: '/cms/content/brands/',
  REFRESH_TOKEN: '/ncs/user/refreshtoken',
  OIDC_LOGIN: '/ncs/user/oidctoken',
  LOGIN: '/ncs/user/login',
  CHANGE_PASSWORD: '/ncs/user/changepassword',
  CREATE_BRAND: '/cms/content/brand/save',
  DASHBOARD: '/cms/content/dashboard',
  CREATE_CAMPAIGN: '/ncs/content/campaign/save',
  CREATE_S3_CONNECTION: '/cms/content/connection/s3/save',
  GET_DATASOURCE_TABLES: '/cms/content/datasource/tables',
  GET_DATASOURCE_TABLES_BY_DS_ID: (datasourceId: string) =>
    `/cms/content/datasource/${datasourceId}/tables`,
  SAVE_DATASOURCE_TABLES: (campaignId: string) =>
    `/cms/content/campaign/${campaignId}/datasource/save`,
  GET_PERSONAL_TAGS: (campaignId: string) =>
    `/cms/content/campaign/${campaignId}/personaltags`,
  GET_CAMPAIGN_LIST: `/ncs/content/campaign/campaigns`,
  GET_CAMPAIGN_CONTENT: (campaignId: string) =>
    `/ncs/content/campaign/${campaignId}/content`,
  GET_PDF_TEMPLATE_CONTENT: (templateId: string) =>
    `/ncs/content/campaign/pdf/template/${templateId}/content`,
  GET_CAMPAIGN_INFO: (campaignId: string) =>
    `/ncs/content/campaign/${campaignId}/info`,
  GET_PDF_TEMPLATE_INFO: (templateId: string) =>
    `/ncs/content/campaign/pdf/template/${templateId}/info`,
  CHECK_CAMPAIGN_NAME_EXISTS: '/cms/content/campaign/exists',
  CHECK_PDF_TEMPLATE_NAME_EXISTS: '/ncs/content/campaign/pdf/template/exists',
  CHECK_SYSTEM_TEMPLATE_NAME_EXISTS: `/cms/content/systemtemplate/exists`,
  GET_CAMPAIGN_S3_DETAIL: (campaignId: string) =>
    `/cms/content/campaign/${campaignId}/s3details`,
  SAVE_CAMPAIGN_PAGES: (campaignId: string) =>
    `/ncs/content/campaign/${campaignId}/content/save`,
  CREATE_CUSTOMTAG: '/cms/content/customtag/content/save',
  CUSTOMTAG_NAME_EXISTS: '/cms/content/customtag/exists',
  GET_CAMPAIGN_PREVIEW: (campaignId: string) =>
    `/ncs/publish/campaign/${campaignId}/review`,
  GET_CAMPAIGN_PDF_PREVIEW: (campaignId: string) =>
    `/ncs/publish/campaign/${campaignId}/content/preview`,
  GET_CAMPAIGN_PDF_REVIEW: (campaignId: string) =>
    `/ncs/publish/campaign/${campaignId}/review/pdf`,
  DATASOURCES: (brandId: string) => `/cms/content/brand/${brandId}/datasources`,
  S3DETAILS: (brandId: string) => `/cms/content/brand/${brandId}/s3details`,
  CHECK_S3_NAME_EXISTS: '/cms/content/s3connection/exists',
  GET_CUSTOMTAG_LIST: (brandId: string) =>
    `/cms/content/brand/${brandId}/customtags`,
  GET_CUSTOMTAG_IDS: (brandId: string) =>
    `/cms/content/brand/${brandId}/customtagids`,
  GET_SYSTEM_TEMPLATE_LIST: `/cms/content/systemtemplates`,
  GET_PDF_TEMPLATE_LIST: `/ncs/content/campaign/pdf/template/filter`,
  GET_SYSTEM_TEMPLATE: (templateId: string) =>
    `/cms/content/systemtemplate/${templateId}`,
  SAVE_SYSTEM_TEMPLATE: `/cms/content/systemtemplate/save`,
  GET_CUSTOMTAG_CONTENT: (customTagId: string) =>
    `/cms/content/customtag/${customTagId}/version`,
  GET_CAMPAIGN_DATASOURCE_DETAILS: (campaignId: string) =>
    `/cms/content/campaign/${campaignId}/datasourcedetails`,
  CHECK_DATASOURCE_NAME_EXISTS: `/cms/content/datasource/isnameexists`,
  DELETE_CAMPAIGN: (campaignId: string) =>
    `/cms/content/campaign/delete/${campaignId}`,
  GET_SYSTEM_TEMPLATE_PDF_PREVIEW: `/ncs/publish/templates/content/preview`,
  GET_CAMPAIGN_TRACKING: (brandId: string) =>
    `/cms/reports/publish/report/${brandId}`,
  POST_FORGET_PASSWORD: `/cms/user/forgotpassword`,
  POST_RESET_PASSWORD: `/cms/user/resetpassword`,
  EXPORT_CAMPAIGN_PDF: (campaignId: string) =>
    `/cms/publish/campaign/${campaignId}/exportpdf`,
  GET_SOCIAL_IMAGE_PREVIEW: `/cms/social/design/preview`,
  SAVE_SOCIAL_CONTENT: (campaignId: string) =>
    `/cms/social/${campaignId}/content/save`,
  GET_SOCIAL_DESIGNS: `/cms/social/designs`,
  GET_SOCIAL_CAMPAIGN_PAGES: (campaignId: string) =>
    `/cms/social/${campaignId}/page/list`,
  PUBLISH_SOCIAL_CAMPAIGN: (campaignId: string) =>
    `/cms/social/${campaignId}/publish`,
  PUBLISH_PDF_CAMPAIGN: (campaignId: string) =>
    `/cms/publish/pdf/${campaignId}/publish`,
  MERGE_PDFS: `/ncs/publish/pdf/merge`,
  PDF_TEMPLATE_SAVE: `/ncs/content/campaign/pdf/template/save`,
  PDF_TEMPLATE_CONTENT_SAVE: (templateId: string) =>
    `/ncs/content/campaign/pdf/template/${templateId}/content/save`,
};
