import { useQuery, UseQueryResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { S3AndScheduleDetails } from '../../types/s3deatils';

export const getCampaignS3Details = async (
  campaignId: any,
): Promise<S3AndScheduleDetails> => {
  return api
    .get(URLs.GET_CAMPAIGN_S3_DETAIL(campaignId))
    .then((res: any) => res.data);
};

export const useGetCampaignS3Details = (
  campaignId: string | null,
): UseQueryResult<S3AndScheduleDetails, Error> => {
  return useQuery(
    [QueryKeys.GetCampaignS3Details],
    () => getCampaignS3Details(campaignId),
    {
      refetchOnWindowFocus: false,
    },
  );
};
