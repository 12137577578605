import axios from 'axios';
import { URLs, API_BASE } from './URLs';

const logout = () => {
  localStorage.setItem('user.refreshToken', '');
  localStorage.setItem('user.email', '');
  localStorage.setItem('user.isLoggedIn', '');
  localStorage.clear();

  //window.location.reload();
};

export function getSessionAccessToken() {
  const accessToken = localStorage.getItem('user.accessToken');
  return accessToken;
}

export function getSessionRefreshToken() {
  const refreshToken = localStorage.getItem('user.refreshToken');
  return refreshToken;
}

export function getUserEmail() {
  const refreshToken = localStorage.getItem('user.email');
  return refreshToken;
}

export function getSessionUser() {
  const user: any = localStorage.getItem('user.user')
    ? localStorage.getItem('user.user') === null
      ? {}
      : JSON.parse(String(localStorage.getItem('user.user')))
    : {};
  return user;
}

const instance = axios.create({
  baseURL: API_BASE,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config: any) => {
    // console.log('instance.interceptors.request.use->config.url', config.url);
    const token = getSessionAccessToken();
    if (
      token &&
      token !== 'undefined' &&
      token !== '' &&
      token !== undefined &&
      config.url !== '/ncs/user/refreshtoken' &&
      config.url !== '/ncs/user/login'
    ) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const originalConfig = err.config;
    originalConfig.failCount = originalConfig.failCount || 0;

    if (originalConfig.failCount >= 2) {
      logout();
      return Promise.reject(err);
    }

    if (err.response.status === 401) {
      if (
        originalConfig.url !== '/ncs/user/refreshtoken' &&
        getSessionAccessToken() !== undefined &&
        localStorage.getItem('user.isLoggedIn') === 'true'
      ) {
        try {
          // const rs = await getRefreshToken();
          // const { accessToken } = rs.data;
          // localStorage.setItem('user.accessToken', accessToken);

          originalConfig.failCount++;
          return instance(originalConfig);
        } catch (_error: any) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
        // } else if (originalConfig.url === CONSTANTS.MFA_SUBMIT) {
        //   return Promise.reject(err);
      } else if (originalConfig.url === '/cms/user/refreshtoken') {
        logout();
        return Promise.reject(err);
      } else {
        logout();
        return Promise.reject(err);
      }
    }
    if (err.response.status === 403 && err.response.data) {
      return Promise.reject(err.response.data);
    }
    if (
      err.response.status === 500 &&
      originalConfig.url === '/ncs/user/refreshtoken'
    ) {
      logout();
      return Promise.reject(err);
    }
    return Promise.reject(err);
  },
);

export function getRefreshToken() {
  if (!getSessionRefreshToken() || getSessionRefreshToken() === '') {
    return Promise.reject({ error: 'Empty refresh token' });
  }
  return instance.post(URLs.REFRESH_TOKEN, {
    refreshToken: getSessionRefreshToken(),
    email: getUserEmail(),
  });
}

export default instance;
