import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { API_BASE, URLs } from '../../api/URLs';
// import api from '../../api/apifilter';
import { PdfTemplateCssAndPages } from '../../types/campaign';
import axios, { AxiosRequestConfig } from 'axios';

const transformToFormData = (
  pdfTemplateCssAndPages: PdfTemplateCssAndPages,
): FormData => {
  const formData = new FormData();
  // formData.append('campaignId', obj.campaignId);
  const cssBlob = new Blob(
    [
      pdfTemplateCssAndPages.cssContent
        ? pdfTemplateCssAndPages.cssContent
        : '',
    ],
    {
      type: 'text/plain',
    },
  );
  // formData.append('cssContent', blob, 'cssfile.css');
  //formData.append('cssContent', obj.cssContent ? `"${obj.cssContent}"` : '');
  // formData.append('campaignPages[0].content', new Readable().push);
  formData.append('cssContent', cssBlob, 'cssfile.css');

  pdfTemplateCssAndPages.templatePages &&
    pdfTemplateCssAndPages.templatePages.map((templatePage, index) => {
      const contentBlob = new Blob([templatePage.content], {
        type: 'text/plain',
      });

      const jsonBlob = new Blob([templatePage.json ? templatePage.json : ''], {
        type: 'text/json',
      });

      const pageCssContentBlob = new Blob(
        [templatePage.cssContent ? templatePage.cssContent : ''],
        {
          type: 'text/plain',
        },
      );

      formData.append(
        `templatePages[${index}].json`,
        jsonBlob,
        `jsonfile_${index}.txt`,
      );

      formData.append(
        `templatePages[${index}].cssContent`,
        pageCssContentBlob,
        `cssfile_${index}.css`,
      );
      formData.append(
        `templatePages[${index}].content`,
        contentBlob,
        `content_${index}.html`,
      );

      formData.append(`templatePages[${index}].name`, templatePage.name);
      formData.append(
        `templatePages[${index}].orientation`,
        templatePage.orientation,
      );
      formData.append(`templatePages[${index}].index`, `${templatePage.index}`);
      templatePage.templateId &&
        formData.append(
          `templatePages[${index}].templateId`,
          templatePage.templateId,
        );
    });
  // templatePage.caption && formData.append('caption', templatePage.caption);
  return formData;
};

export const saveCampaignContent = async (
  pdfTemplateCssAndPages: PdfTemplateCssAndPages,
): Promise<string> => {
  const accessToken = localStorage.getItem('user.accessToken');
  const config: AxiosRequestConfig = {
    method: 'post',
    url:
      API_BASE +
      URLs.PDF_TEMPLATE_CONTENT_SAVE(pdfTemplateCssAndPages.templateId),
    headers: {
      'Content-Disposition': 'form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    data: transformToFormData(pdfTemplateCssAndPages),
  };

  return await axios(config).then((res: any) => res.data);
};

export const useSavePdfTemplateContent = (): UseMutationResult<
  string,
  Error,
  PdfTemplateCssAndPages
> => {
  return useMutation(
    [QueryKeys.SavePdfTemplateContent],
    (pdfTemplateCssAndPages: PdfTemplateCssAndPages) =>
      saveCampaignContent(pdfTemplateCssAndPages),
  );
};
