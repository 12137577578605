import { useState, useEffect, FC, useRef } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Breadcrum } from '../../atoms/Breadcrum/Breadcrum';
import { Elements } from '../../atoms/Elements/Elements';
import PreviousButton from '../../atoms/PreviousButton/PreviousButton';
import { Title } from '../../atoms/Title/Title';
import '../CampaignContent/CampaignContent.scss';
import './PdfContent.scss';
import '../../styles/_CommonStyles.scss';
// import { Button } from 'cm-react-components/dist/js';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import {
  CampaignCssAndPages,
  // CreateCampaignPage,
  UICreateCampaignPage,
} from '../../types/campaign';
// import { updateCampaignContent } from '../../slices/createCampaignSlice';
import { saveCampaignPagesFormValidation } from './validation';
import PdfPreview from '../../atoms/PdfPreview/PdfPreview';
import { Next } from '../../atoms/Next/Next';

import { PdfContentDesignPanel } from './PdfContentDesignPanel';
import { PropertiesPanel } from '../../atoms/Elements/PropertiesPanel';
import {
  changeAlignProperty,
  changeBulletTypeProperty,
  changeColorProperty,
  changeHeightProperty,
  changeNoOfRowsProperty,
  changeRecSizeProperty,
  changeWidthProperty,
  createComponent,
  jsonToJsxElementList,
  jsxElementsToJSONString,
  changeBackgroundColorProperty,
  changeHeaderFontColor,
  changeTableBorderSizeProperty,
  changeHeaderWeightProperty,
  changeTableFontColorProperty,
  changeHeaderFontProperty,
  changeHeaderColorProperty,
  changeBorderColorProperty,
  changeBorderSizeProperty,
  changeFontSizeProperty,
  changeDecorationProperty,
} from '../../utils/elementsUtil';
import { useSaveCampaignContent } from '../../hooks/useSaveCampaignContent/useSaveCampaignContent';
import { useGetCampaignContent } from '../../hooks/useGetCampaignContent/useGetCampaignContent';
import { COMMON_PDF_CSS } from '../PdfCustomTags/PdfCustomTagDesignPanel';
import { useGetPdfTemplateContent } from '../../hooks/useGetPdfTemplateContent/useGetPdfTemplateContent';

interface UseFormikWithButtonsProps {
  initialValues: CampaignCssAndPages;
  onSubmit: (values: CampaignCssAndPages, activeButton: string) => void;
}

const useFormikWithButtons = ({
  initialValues,
  onSubmit,
}: UseFormikWithButtonsProps) => {
  const [activeButton, setActiveButton] = useState('');

  const formik = useFormik({
    initialValues,
    validationSchema: saveCampaignPagesFormValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: CampaignCssAndPages) => {
      onSubmit(values, activeButton);
    },
  });

  return { ...formik, activeButton, setActiveButton };
};

export const PdfContent: FC = () => {
  const contentRef = useRef<any>(null);
  const navigate = useNavigate();
  const { mutate: saveCampaignContent } = useSaveCampaignContent();
  const [queryParams] = useSearchParams();
  const campaignId =
    queryParams.get('id') && queryParams.get('id') !== null
      ? queryParams.get('id')
      : '';

  const pdfTemplateId =
    queryParams.get('templateid') && queryParams.get('templateid') !== null
      ? queryParams.get('templateid')
      : '';

  const [activePage, setActivePage] = useState<number>(0);

  const [uiPages, setUIPages] = useState<Map<number, UICreateCampaignPage>>(
    () => {
      const intialUiPages = new Map<number, UICreateCampaignPage>();
      intialUiPages.set(0, {
        name: '',
        json: '',
        components: [],
        content: '',
        cssContent: '',
        orientation: '',
        index: 0,
        active: false,
        idList: [],
        selectedElementId: '',
      });
      return intialUiPages;
    },
  );

  const [selectedElementId, setSelectedElementId] = useState<string>('');
  const [pdfPreview, setPdfPreview] = useState<boolean>(false);

  const {
    mutate: getCampaignContent,
    isLoading: loadingContent,
    data: campaignContent,
  } = useGetCampaignContent();

  const {
    mutate: getPdfTemplateContent,
    isLoading: loadingPdfTemplateContent,
    data: pdfTemplateContent,
  } = useGetPdfTemplateContent();

  const campaignCssAndPages = useSelector(
    (state: RootState) => state.createCampaignSlice.campaignCssAndPages,
  );

  const saveCampaignPagesInContext = (
    values: CampaignCssAndPages,
    activeButton: string,
  ) => {
    if (activeButton === 'save') {
      const newMap = new Map<string, CampaignCssAndPages>();
      campaignCssAndPages &&
        campaignCssAndPages.size > 0 &&
        campaignCssAndPages.forEach((value, key) => {
          newMap.set(key, value);
        });
      newMap.set(campaignId ? campaignId : '', values);
      const transformedObjects = Array.from(uiPages).map(([key, page]) => {
        return {
          name: page.name,
          json: page.json,
          content: page.content,
          cssContent: page.cssContent,
          orientation: page.orientation,
          index: page.index,
          active: page.active,
          templateId: page.systemTemplateId,
        };
      });

      saveCampaignContent(
        {
          campaignId: campaignId ? campaignId : '',
          campaignPages: transformedObjects,
        },
        {
          onSuccess: data => {
            navigate(`/reviewcontent?id=${campaignId}`);
          },
          onError: () => {
            console.log('Save PDF content failed');
          },
        },
      );
      // dispatch(updateCampaignContent(newMap));
    }
  };

  const formik = useFormikWithButtons({
    initialValues: {
      campaignId: campaignId ? campaignId : '',
      cssContent: '',
      campaignPages: [
        {
          name: 'Page: 1',
          json: '',
          content: '',
          cssContent: '',
          orientation: '',
          index: 0,
          active: true,
        },
      ],
    },
    onSubmit: async (values: CampaignCssAndPages, activeButton: string) => {
      saveCampaignPagesInContext(values, activeButton);
    },
  });

  useEffect(() => {
    campaignId && campaignId !== null && getCampaignContent(campaignId);
    pdfTemplateId &&
      pdfTemplateId !== null &&
      getPdfTemplateContent(pdfTemplateId);
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey) {
        if (event.key === 'z') {
          event.preventDefault();
          // handleUndo();
        } else if (event.key === 'y') {
          event.preventDefault();
          //handleRedo();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const changeValue = (
    id: string,
    newValue: string | string[] | string[][],
  ) => {
    console.log(' :: ');
  };

  useEffect(() => {
    if (!loadingContent && campaignContent && campaignContent.pages) {
      const pages = new Map();
      campaignContent.pages.map((page, index) => {
        pages.set(page.index ? page.index : index, {
          name: page.name,
          json: page.json,
          components:
            page.json && page.json !== null
              ? jsonToJsxElementList(
                  page.json,
                  setSelectedElementId,
                  (newValue: string, id: string) => {
                    changeValue(id, newValue);
                  },
                )
              : [],
          content: page.content,
          cssContent: page.cssContent,
          orientation: page.orientation,
          index: page.index ? page.index : index,
          active: true,
          idList:
            page.json && page.json !== null
              ? jsonToJsxElementList(
                  page.json,
                  setSelectedElementId,
                  (newValue: string, id: string) => {
                    changeValue(id, newValue);
                  },
                ).map((element, index) => {
                  return element.key;
                })
              : [],
          systemTemplateId: page.templateId,
        });
      });
      pages.size === 0
        ? pages.set(0, {
            name: '',
            json: '',
            components: [],
            content: '',
            cssContent: '',
            orientation: '',
            index: 0,
            active: false,
            idList: [],

            selectedElementId: '',
          })
        : setUIPages(pages);
    }
  }, [loadingContent, campaignContent?.pages]);

  useEffect(() => {
    if (
      !loadingPdfTemplateContent &&
      pdfTemplateContent &&
      pdfTemplateContent.pages
    ) {
      const pages = new Map();
      pdfTemplateContent.pages.map((page, index) => {
        pages.set(page.index ? page.index : index, {
          name: page.name,
          json: page.json,
          components:
            page.json && page.json !== null
              ? jsonToJsxElementList(
                  page.json,
                  setSelectedElementId,
                  (newValue: string, id: string) => {
                    changeValue(id, newValue);
                  },
                )
              : [],
          content: page.content,
          cssContent: page.cssContent,
          orientation: page.orientation,
          index: page.index ? page.index : index,
          active: true,
          idList:
            page.json && page.json !== null
              ? jsonToJsxElementList(
                  page.json,
                  setSelectedElementId,
                  (newValue: string, id: string) => {
                    changeValue(id, newValue);
                  },
                ).map((element, index) => {
                  return element.key;
                })
              : [],
          systemTemplateId: page.templateId,
        });
      });
      pages.size === 0
        ? pages.set(0, {
            name: '',
            json: '',
            components: [],
            content: '',
            cssContent: '',
            orientation: '',
            index: 0,
            active: false,
            idList: [],

            selectedElementId: '',
          })
        : setUIPages(pages);
    }
  }, [loadingPdfTemplateContent, pdfTemplateContent?.pages]);

  const getCampaignCssAndPages = () => {
    const previewPages = Array.from(uiPages.values()).map((page, index) => {
      return {
        name: page.name,
        json: page.json,
        content: page.content,
        cssContent: page.cssContent,
        orientation: page.orientation,
        templateId: page.systemTemplateId,
        index: page.index,
        active: page.active,
      };
    });

    return {
      campaignId: campaignId ? campaignId : '',
      campaignPages: previewPages,

      commonCssContent: COMMON_PDF_CSS,
      caption: formik.values.caption,
    };
  };

  const updateIdList = (newIdList: string[]) => {
    const tempUiPages = new Map(uiPages);
    const tempActiveUIPage = tempUiPages.get(activePage);
    if (tempActiveUIPage) {
      tempActiveUIPage.idList = newIdList;
      tempUiPages.set(activePage, tempActiveUIPage);
    }

    setUIPages(tempUiPages);
  };

  const updateContent = (newContent: string) => {
    const tempUiPages = new Map(uiPages);
    const tempActiveUIPage = tempUiPages.get(activePage);
    if (tempActiveUIPage) {
      tempActiveUIPage.content = newContent;
      tempUiPages.set(activePage, tempActiveUIPage);
    }

    setUIPages(tempUiPages);
  };

  const updateComponents = (newComponents: JSX.Element[]) => {
    const tempUiPages = new Map(uiPages);
    const tempActiveUIPage = tempUiPages.get(activePage);

    if (tempActiveUIPage) {
      tempActiveUIPage.components = newComponents;
      tempActiveUIPage.json = jsxElementsToJSONString(newComponents);
      tempUiPages.set(activePage, tempActiveUIPage);
    }

    setUIPages(tempUiPages);
  };

  const deleteComponent = (elementId: string) => {
    const tempUiPages = new Map(uiPages);
    const tempActiveUIPage = tempUiPages.get(activePage);

    if (tempActiveUIPage && tempActiveUIPage.components) {
      const newComponents = tempActiveUIPage.components?.filter(
        item => item.key !== elementId,
      );
      tempActiveUIPage.json = jsxElementsToJSONString(newComponents);
      tempActiveUIPage.components = newComponents;
      tempUiPages.set(activePage, tempActiveUIPage);
    }
    setUIPages(tempUiPages);
  };

  const moveElementUp = (elementId: string) => {
    moveComponent(elementId, 'up');
  };

  const moveElementDown = (elementId: string) => {
    moveComponent(elementId, 'down');
  };

  const moveComponent = (elementId: string, direction: string) => {
    const tempUiPages = new Map(uiPages);
    const tempActiveUIPage = tempUiPages.get(activePage);

    if (tempActiveUIPage && tempActiveUIPage.components) {
      const index = tempActiveUIPage.components.findIndex(
        item => item.key === elementId,
      );
      const components = tempActiveUIPage.components;

      if (index !== -1) {
        if (direction === 'up' && index > 0) {
          const temp = components[index];
          components[index] = components[index - 1];
          components[index - 1] = temp;
        } else if (direction === 'down' && index < components.length - 1) {
          const temp = components[index];
          components[index] = components[index + 1];
          components[index + 1] = temp;
        }

        tempActiveUIPage.json = jsxElementsToJSONString(components);
        tempActiveUIPage.components = components;
        tempUiPages.set(activePage, tempActiveUIPage);
      }
      setUIPages(tempUiPages);
    }
  };

  const addComponent = (componentType: string) => {
    const tempUiPages = new Map(uiPages);
    const tempActiveUIPage = tempUiPages.get(activePage);

    if (tempActiveUIPage) {
      const updatedComponents = tempActiveUIPage.components
        ? [...tempActiveUIPage.components]
        : [];

      // if (updatedComponents.length === 0) {
      const component = createComponent(
        componentType,
        updateIdList,
        setSelectedElementId,
        changeValue,
        uiPages && uiPages.get(activePage) && uiPages.get(activePage)?.idList
          ? uiPages.get(activePage)?.idList
          : [],
      );
      updatedComponents.push(component);
      tempActiveUIPage.components = updatedComponents;
      tempActiveUIPage.content = contentRef.current.innerHTML;
      tempActiveUIPage.json = jsxElementsToJSONString(updatedComponents);
      tempUiPages.set(activePage, tempActiveUIPage);
      // updateContent(contentRef.current.innerHTML);
      // setComponentList(updatedComponents);
    }
    setUIPages(tempUiPages);
  };

  const updateSystemTemplateId = (systemTemplateId: string) => {
    const tempMap = new Map(uiPages);
    tempMap.set(uiPages.size, {
      name: '',
      json: '',
      content: '',
      cssContent: '',
      orientation: '',
      index: uiPages.size,
      active: false,
      idList: [],
      selectedElementId: '',
      systemTemplateId: systemTemplateId,
    });
    setUIPages(tempMap);

    setActivePage(uiPages.size);
    // setUpdatedSystemTemplateId(true);
  };

  return (
    <div className="campaign-html-preview-container">
      <Breadcrum pageName="Create a pdf document" />

      <div className="title">
        <Title text="Design your PDF document" />
      </div>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className={` ${'outer-grid-layout-2'}`}>
            <div className="html-code-section">
              <div className="grid-layout2 full-height-pdf-design">
                <div className="elements-section">
                  <Elements
                    campaignId={campaignId ? campaignId : ''}
                    setSystemTemplateId={updateSystemTemplateId}
                  />
                </div>

                <PdfContentDesignPanel
                  setPdfPreview={setPdfPreview}
                  showPreview={pdfPreview}
                  setUIPages={setUIPages}
                  // showTemplates={() => {
                  //   setShowTemplates(!showTemplates);
                  // }}
                  pages={uiPages}
                  addPage={() => {
                    const tempMap = new Map(uiPages);
                    tempMap.set(uiPages.size, {
                      name: '',
                      json: '',
                      components: [],
                      content: '',
                      cssContent: '',
                      orientation: '',
                      index: uiPages.size,
                      active: false,
                      idList: [],
                      selectedElementId: '',
                    });
                    setUIPages(tempMap);
                  }}
                  deletePage={() => {
                    console.log('Deleting page: ', activePage);
                    const tempMap = uiPages;
                    tempMap.delete(activePage);

                    setUIPages(tempMap);
                  }}
                  deleteComponent={deleteComponent}
                  moveComponentUp={moveElementUp}
                  moveComponentDown={moveElementDown}
                  contentRef={contentRef}
                  // setContent={(content: string) => {
                  //   const activeTempPage = uiPages.get(activePage);
                  //   if (activeTempPage) {
                  //     const tempPages = uiPages;
                  //     activeTempPage.content = content;
                  //     tempPages.set(activePage, activeTempPage);
                  //     console.log(
                  //       'contentRef.current.innerHtml in pdfcontent  :: ',
                  //       tempPages,
                  //     );
                  //     setUIPages(tempPages);
                  //   }
                  // }}
                  updateContent={updateContent}
                  cssContent={
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.cssContent
                      : ''
                  }
                  activePage={activePage}
                  setActivePage={setActivePage}
                  selectedElementId={selectedElementId}
                  idList={
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.idList
                      : []
                  }
                  setIdList={updateIdList}
                  components={
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : []
                  }
                  addComponent={addComponent}
                  setComponents={updateComponents}
                  htmlContent={
                    uiPages &&
                    uiPages.get(activePage) &&
                    uiPages.get(activePage)?.content
                  }
                  showHtmlEditor={false}
                ></PdfContentDesignPanel>
              </div>
            </div>

            <div
              className="html-preview-section"
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <PropertiesPanel
                changeAlignProperty={(id: string, newValue: string) => {
                  changeAlignProperty(
                    id,
                    newValue,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeColorProperty={(id: string, newValue: string) => {
                  changeColorProperty(
                    id,
                    newValue,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                idList={
                  (uiPages &&
                    uiPages.get(activePage) &&
                    uiPages.get(activePage)?.idList) ??
                  []
                }
                selectedElementId={
                  // (uiPages &&
                  //   uiPages.get(activePage) &&
                  //   uiPages.get(activePage)?.selectedElementId) ??
                  // ''
                  selectedElementId
                }
                setSelectedElementId={setSelectedElementId}
                changeNoOfRowsProperty={(id: string, newText: string) => {
                  changeNoOfRowsProperty(
                    id,
                    newText,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeLoopRecSize={(id: string, newText: string) => {
                  changeRecSizeProperty(
                    id,
                    newText,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeHeight={(id: string, newText: string) => {
                  changeHeightProperty(
                    id,
                    newText,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeWidth={(id: string, newText: string) => {
                  changeWidthProperty(
                    id,
                    newText,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeBulletTypeProperty={(id: string, newText: string) => {
                  changeBulletTypeProperty(
                    id,
                    newText,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeBackgroundColorProperty={(
                  id: string,
                  newValue: string,
                ) => {
                  changeBackgroundColorProperty(
                    id,
                    newValue,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeHeaderFontColor={(id: string, newValue: string) => {
                  changeHeaderFontColor(
                    id,
                    newValue,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeHeaderWeightProperty={(id: string, newValue: string) => {
                  changeHeaderWeightProperty(
                    id,
                    newValue,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeTableFontColorProperty={(
                  id: string,
                  newValue: string,
                ) => {
                  changeTableFontColorProperty(
                    id,
                    newValue,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeHeaderColorProperty={(id: string, newValue: string) => {
                  changeHeaderColorProperty(
                    id,
                    newValue,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeBorderSizeProperty={(id: string, newValue: string) => {
                  changeBorderSizeProperty(
                    id,
                    newValue,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeTableBorderSizeProperty={(
                  id: string,
                  newValue: string,
                ) => {
                  changeTableBorderSizeProperty(
                    id,
                    newValue,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeHeaderFontProperty={(id: string, newValue: string) => {
                  changeHeaderFontProperty(
                    id,
                    newValue,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeBorderColorProperty={(id: string, newValue: string) => {
                  changeBorderColorProperty(
                    id,
                    newValue,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeFontSizeProperty={(id: string, newValue: string) => {
                  changeFontSizeProperty(
                    id,
                    newValue,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
                changeDecorationProperty={(id: string, newValue: string) => {
                  changeDecorationProperty(
                    id,
                    newValue,
                    uiPages && uiPages.get(activePage)
                      ? uiPages.get(activePage)?.components
                      : [],
                    updateComponents,
                  );
                }}
              ></PropertiesPanel>
            </div>
          </div>

          <div
            className="pdfPreview-pop-up"
            style={{
              position: 'absolute',
              top: '20px',
              left: '50%',
              transform: 'translate(-50%, 0px)',
            }}
          >
            {pdfPreview && (
              <div className="position-helper">
                <PdfPreview campaignCssAndPages={getCampaignCssAndPages()} />

                <div
                  className="closePfdPreview"
                  onClick={() => setPdfPreview(!pdfPreview)}
                >
                  X
                </div>
              </div>
            )}

            {/* {showTemplates && (
              <div className="position-helper">
                <PdfTemplates
                  campaignCssAndPages={getCampaignCssAndPages()}
                  updateSystemTemplateId={updateSystemTemplateId}
                  addPage={() => {
                    const tempMap = new Map(uiPages);
                    tempMap.set(uiPages.size, {
                      name: '',
                      json: '',
                      components: [],
                      content: '',
                      cssContent: '',
                      orientation: '',
                      index: uiPages.size,
                      active: false,
                      idList: [],
                      selectedElementId: '',
                    });
                    setUIPages(tempMap);
                  }}
                  closeWindow={() => setShowTemplates(!showTemplates)}
                />

                <div
                  className="closePfdPreview"
                  onClick={() => setShowTemplates(!showTemplates)}
                >
                  X
                </div>
              </div>
            )} */}
          </div>

          <div className="navigationButtons">
            <Link to={`/selecttemplate?id=${campaignId}`}>
              <PreviousButton />
            </Link>

            {/* <NextButton
              type={'submit'}
              onClick={() => formik.setActiveButton('save')}
            /> */}
            <Next
              type={'submit'}
              label={'Next'}
              onClick={() => formik.setActiveButton('save')}
            />
          </div>
        </form>
      </FormikProvider>
    </div>
  );
};
