import { TextArea } from 'cm-react-components/dist/js';
import { FC, useEffect, useRef, useState } from 'react';

export interface PdfFooterProps {
  id: string;
  name?: string;
  setSelectedElementId: (id: string) => void;
  value: string;
  onValueChange: (id: string, newValue: string) => void;
}

export const PdfFooter: FC<PdfFooterProps> = ({
  id,
  name,
  setSelectedElementId,
  value,
  onValueChange,
}) => {
  const pdfFooterRef = useRef<HTMLDivElement>(null);

  const [editing, setEditing] = useState<boolean>(false);
  const [stateValue, setStateValue] = useState<string>(value);
  useEffect(() => {
    editing === false && onValueChange(id, stateValue);
  }, [editing]);

  return (
    <footer
      id={id}
      key={id}
      onClick={() => {
        setEditing(true);
        setSelectedElementId(id);
      }}
    >
      {editing === true ? (
        <TextArea
          rows={6}
          onBlur={e => {
            setStateValue(e.target.value);
            setEditing(false);
          }}
          onChange={e => {
            onValueChange(id, e.target.value);
          }}
          value={value}
        ></TextArea>
      ) : (
        <div className="pdf-footer" id={id} key={id}>
          <p
            ref={pdfFooterRef}
            className="pdf-footer-text text-style-12 f-w-400"
            onClick={() => {
              setEditing(true);
              setSelectedElementId(id);
            }}
          >
            {value}
          </p>
        </div>
      )}
    </footer>
  );
};

PdfFooter.displayName = 'PdfFooter';
