import './PreviousButton.scss';
const PreviousButton = () => {
  return (
    <div className="previousButton-container">
      <button>
        <span>
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.27529 0.275392C6.45863 0.458725 6.55029 0.692057 6.55029 0.97539C6.55029 1.25872 6.45863 1.49206 6.27529 1.67539L2.37529 5.57539L6.27529 9.47539C6.45863 9.65872 6.55029 9.89206 6.55029 10.1754C6.55029 10.4587 6.45863 10.6921 6.27529 10.8754C6.09196 11.0587 5.85863 11.1504 5.57529 11.1504C5.29196 11.1504 5.05863 11.0587 4.87529 10.8754L0.275293 6.27539C0.175293 6.17539 0.104293 6.06706 0.0622931 5.95039C0.0202932 5.83372 -0.00037384 5.70872 0.000292778 5.57539C0.000292778 5.44206 0.0212936 5.31706 0.0632935 5.20039C0.105293 5.08372 0.17596 4.97539 0.275293 4.87539L4.87529 0.275392C5.05863 0.0920582 5.29196 0.000390053 5.57529 0.000390053C5.85863 0.000390053 6.09196 0.0920582 6.27529 0.275392Z"
              fill="#00580A"
            />
          </svg>
        </span>
        Previous
      </button>
    </div>
  );
};

export default PreviousButton;
