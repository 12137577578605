import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { DataSourceTable } from '../../types/datasource';

export const getDataSourceTablesByDsId = async (
  datasourceId: string,
): Promise<DataSourceTable[]> =>
  api
    .get(URLs.GET_DATASOURCE_TABLES_BY_DS_ID(datasourceId))
    .then((res: any) => res.data);

export const useGetDataSourceTablesByDsaId = (): UseMutationResult<
  DataSourceTable[],
  Error,
  string
> => {
  return useMutation(
    [QueryKeys.GetDataSourceTablesByDsId],
    (datasourceId: string) => getDataSourceTablesByDsId(datasourceId),
  );
};
