export interface Name {
  name: string;
}

export const ORIENTATIONS = [
  {
    value: 'Potrait',
    label: 'Potrait',
  },
  {
    value: 'Landscape',
    label: 'Landscape',
  },
];

export interface ContactUsReq {
  name?: string;
  phoneNo?: string;
  email: string;
  message: string;
}
