import { FC } from 'react';
import './ForgotPassword.scss';
// import { Title } from '../../atoms/Title/Title';
import InputBox from '../../atoms/InputBox/InputBox';
import { useFormik } from 'formik';
import { ForgetPassword } from '../../types/login';
import { useForgetPassword } from '../../hooks/useForgetPassword/useForgetPassword';
import { Link } from 'react-router-dom';
export const ForgotPassword: FC = () => {
  const { mutate } = useForgetPassword();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values: ForgetPassword) => {
      mutate(values);
    },
  });

  return (
    <div className="forgot-password-page">
      <form onSubmit={formik.handleSubmit}>
        <div className="forgot-container">
          <div className="sign-logo">
            <img src="NipunaCampaignSuit-r-corner.png" alt="logo" />
          </div>
          <h1 className="recover-password-text">Recover Password</h1>
          <div className="input-forgotpassword-form">
            <InputBox
              label={''}
              id={'email'}
              name={'email'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={'Email Address'}
            />
            <a href="javascript:void(0);" className="login-button">
              Continue
            </a>
          </div>
          <div className="login-link">
            <Link to="/login" className="LogInButton2">
              Log In
            </Link>{' '}
            if you remember the password.
          </div>
          {/* <button className="cancel">cancel</button>
          <button className="reset" type="submit">
            Reset Password
          </button> */}
        </div>
      </form>
    </div>
  );
};
