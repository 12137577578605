import './PreviewBox.scss';
import ReactToPrint from 'react-to-print';
import { FC, useRef } from 'react';
export interface PreviewBoxProps {
  code: string;
}

export const PreviewBox: FC<PreviewBoxProps> = (props: PreviewBoxProps) => {
  const printRef = useRef<HTMLDivElement>(null);
  return (
    <div className="previewbox-container">
      <div className="titleSection">
        <p className="preview">Preview</p>
        <p className="fullscreen">Show full preview</p>
      </div>

      {props.code === '' ? (
        <div className="noPreview">
          <svg
            width="87"
            height="79"
            viewBox="0 0 87 79"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.1551 27.0995C6.64505 33.3245 2 39.5495 2 39.5495C2 39.5495 20.5802 64.4495 43.5 64.4495C46.3425 64.4495 49.1183 64.0665 51.8 63.3956M35.266 15.687C37.9274 15.0264 40.6809 14.6495 43.5 14.6495C66.4198 14.6495 85 39.5495 85 39.5495C85 39.5495 80.3549 45.7745 72.8449 51.9995"
              stroke="#C5EBC9"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M35.852 32.5385C34.1586 34.3848 33.125 36.8464 33.125 39.5493C33.125 45.2792 37.7701 49.9243 43.5 49.9243C46.3276 49.9243 48.8911 48.793 50.7625 46.9585"
              stroke="#C5EBC9"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M80.8498 76.8992L6.14984 2.19922"
              stroke="#C5EBC9"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <div className="noPreviewText">No preview available</div>

          <div className="NoPreviewInfo">
            Preview will be available once you enter the <br></br>
            code in the second step
          </div>
        </div>
      ) : (
        <div>
          <div className="preview-box">
            <div
              dangerouslySetInnerHTML={{ __html: props.code }}
              ref={printRef}
            ></div>
          </div>

          <ReactToPrint
            trigger={() => (
              <button className="print-button">Print Preview</button>
            )}
            content={() => printRef.current}
          />
        </div>
      )}
    </div>
  );
};
