import React, { useState } from 'react';
import './SignUpPage.scss';
import { Link } from 'react-router-dom';
import Cookies from '../Cookies/Cookies';
// import LogIn from '../LogIn/LogIn';

const SignUpPage: React.FC = () => {
  const [showSignUpForm, setShowSignUpForm] = useState(false);

  const handleSignUpClick = () => {
    setShowSignUpForm(true);
  };

  return (
    <>
      <div className="signup-page">
        <div className="signup-container">
          <div className="sign-logo">
            <img src="NipunaCampaignSuit-r-corner.png" alt="Logo" />
          </div>
          <h1 className="Sign-up-text">Sign Up</h1>
          <div className="third-party-options">
            <a href="javascript:void(0);" className="login-option-btn">
              <span className="button-text">
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="align"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M20.64 12.2c0-.63-.06-1.25-.16-1.84H12v3.49h4.84a4.14 4.14 0 0 1-1.8 2.71v2.26h2.92a8.78 8.78 0 0 0 2.68-6.62z"
                      fill="#4285F4"
                    ></path>{' '}
                    <path
                      d="M12 21a8.6 8.6 0 0 0 5.96-2.18l-2.91-2.26a5.4 5.4 0 0 1-8.09-2.85h-3v2.33A9 9 0 0 0 12 21z"
                      fill="#34A853"
                    ></path>{' '}
                    <path
                      d="M6.96 13.71a5.41 5.41 0 0 1 0-3.42V7.96h-3a9 9 0 0 0 0 8.08l3-2.33z"
                      fill="#FBBC05"
                    ></path>{' '}
                    <path
                      d="M12 6.58c1.32 0 2.5.45 3.44 1.35l2.58-2.59A9 9 0 0 0 3.96 7.95l3 2.34A5.36 5.36 0 0 1 12 6.58z"
                      fill="#EA4335"
                    ></path>
                  </g>
                </svg>
                <span>Log In with Google</span>
              </span>
            </a>
          </div>
          <div className="login-divider-wrapper">
            <span className="login-divider"></span>
            <span className="or">OR</span>
          </div>
          <div className={`input-${showSignUpForm ? 'signup' : 'login'}-form`}>
            <form>
              <div className="input-element">
                <input type="text" name="name" placeholder="Email Name" />
              </div>
              <div className="input-element">
                <input type="email" name="email" placeholder="Enter Email" />
              </div>
              <div className="input-element">
                <input
                  type="text"
                  name="organization"
                  placeholder="Enter Organization Name"
                />
              </div>
              <div className="input-element">
                <input
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                />
              </div>
              <div className="remember-check">
                <input type="checkbox" id="rememberme" />
                <label htmlFor="rememberme">
                  I agree to the Terms of Service & Privacy Policy
                </label>
              </div>
              {showSignUpForm ? (
                <a href="javascript:void(0);" className="signup-button">
                  Sign Up
                </a>
              ) : null}
            </form>
          </div>
          {!showSignUpForm ? (
            <a
              href="javascript:void(0);"
              className="signup-w-e-button"
              onClick={handleSignUpClick}
            >
              Sign Up with Email
            </a>
          ) : null}
          <div className="login-link">
            <Link className="log-in" to={'/login'}>
              Log In
            </Link>{' '}
            if you have an account.
          </div>
        </div>
      </div>
      <div>
        <Cookies />
      </div>
    </>
  );
};

export default SignUpPage;
