import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import './PdfULList.scss';
import { TextInput } from 'cm-react-components/dist/js';

export interface PdfULListProps {
  id: string;
  name: string;
  noOfRows: number;
  className?: string;
  setSelectedElementId: (id: string) => void;
  value: string[];
  onValueChange: (id: string, newValue: string[]) => void;
  bulletType?: string;
}

export const PdfULList: FC<PdfULListProps> = ({
  id,
  name,
  noOfRows,
  className,
  setSelectedElementId,
  value,
  onValueChange,
  bulletType,
}) => {
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [stateValue, setStateValue] = useState<string[]>(value);

  useEffect(() => {
    editingRow === null && onValueChange(id, stateValue);
  }, [editingRow]);

  return (
    <>
      <table
        id={id}
        key={id}
        className={classNames(
          'fwg-payment-detail text-style-12 f-w-400',
          className,
        )}
        // draggable
        onClick={() => {
          setSelectedElementId(id);
        }}
        // cellPadding={0}
        // cellSpacing={0}
        border={0}
        // contentEditable
        // suppressContentEditableWarning
      >
        <tbody>
          {Array.from(
            {
              length: noOfRows,
            },
            (_, index) => (
              <tr key={`${id}_row_${index}`}>
                <td>
                  {bulletType && bulletType === 'Dot' ? (
                    <>.</>
                  ) : bulletType && bulletType === 'Hypen' ? (
                    <>-</>
                  ) : (
                    <svg
                      width="15px"
                      viewBox="0 0 64 64"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      aria-hidden="true"
                      role="img"
                      className="iconify iconify--emojione"
                      preserveAspectRatio="xMidYMid meet"
                      fill="#50a61f"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        <path
                          fill="#50a61f"
                          d="M56 2L18.8 42.9L8 34.7H2L18.8 62L62 2z"
                        />
                      </g>
                    </svg>
                  )}
                </td>
                <td
                  // onBlur={e => {
                  //   value[index] = e.currentTarget.innerText;
                  //   onValueChange(id, value);
                  // }}
                  onClick={e => {
                    //value[index] = e.currentTarget.innerText;
                    //onValueChange(id, value);
                    setEditingRow(index);
                  }}
                >
                  {editingRow === index ? (
                    <TextInput
                      id={id}
                      key={id}
                      onBlur={e => {
                        value[index] = e.target.value;
                        setStateValue(value);
                        setEditingRow(null);
                      }}
                      onChange={e => {
                        value[index] = e.target.value;
                        onValueChange(id, value);
                      }}
                      borderRadius={'0px'}
                      value={value[index]}
                    ></TextInput>
                  ) : (
                    value[index]
                  )}
                </td>
                {/* <td></td> */}
              </tr>
            ),
          )}
        </tbody>
      </table>
    </>
  );
};

PdfULList.displayName = 'PdfULList';
