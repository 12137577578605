import { FC } from 'react';
import './Button.scss';

export interface ButtonProps {
  name: string;
  onClick?: (e: React.ChangeEvent<any>) => void;
  backgroundColor?: string;
}

export const Button: FC<ButtonProps> = (props: ButtonProps) => {
  return (
    <div>
      {props.name === 'Create' ||
      props.name === 'Publish' ||
      props.name === 'Create a Brand' ||
      props.name === 'Add Tag' ? (
        <div className="CreateButtonContainer">
          <button onClick={props.onClick}>{props.name}</button>
        </div>
      ) : (
        <div className="ButtonContainer">
          <button onClick={props.onClick}>{props.name}</button>
        </div>
      )}
    </div>
  );
};
