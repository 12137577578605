import { FC } from 'react';
import './TwoColumnLayout.scss';
import { ElementJson } from '../../utils/elementsUtil';
import { removeSpaces } from '../../utils/utils';
import {
  BUTTON_TAG,
  IMAGE_TAG,
  INPUT_FIELD_TAG,
  LABEL_TAG,
  MULTILINE_INPUT_TAG,
  PASSWORD_FIELD_TAG,
  SELECT_TAG,
} from '../../types/elementTypes';
import {
  // Button,
  Image,
  Label,
  Select,
  TextArea,
  TextInput,
} from 'cm-react-components/dist/js';
import { ButtonLayout } from '../../components/ButtonLayout/ButtonLayout';

export interface TwoColumnLayoutProps {
  elementList?: ElementJson[];
  className?: string;
}

export const TwoColumnLayout: FC<TwoColumnLayoutProps> = ({
  elementList,
  className,
}) => {
  return (
    <div className={className ? `cm-box ${className}` : `cm-box`}>
      <div className="box-row two-column height100">
        <div className="box-column gp-left-column">
          <div className="gp-overlay"></div>
          <div className="cm-intro-text">
            <h2>Looks like you`re new here!</h2>
            <p className="gp-bold-500">Join our group in few minutes!</p>
            <p className="gp-subtext">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry`s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book.
            </p>
          </div>
        </div>
        <div className="box-column">
          <div className="cm-preview-container">
            <form>
              <div className="gp-form-title">Sign up today</div>
              <div className="properties-wrapper">
                {elementList?.map((element, index) => {
                  switch (removeSpaces(element.type)) {
                    case removeSpaces(INPUT_FIELD_TAG):
                      return (
                        <TextInput
                          key={'textInput1'}
                          label="Input One"
                          borderRadius="14px"
                          size="xs"
                          onFocus={() => console.log('Text Got focus !!')}
                          onBlur={() => console.log('Text Lost focus !!')}
                          required={true}
                        />
                      );

                    case removeSpaces(SELECT_TAG):
                      return (
                        <Select
                          label="What did you"
                          options={['Option 1', 'Option 2', 'Option 3']}
                          key={'select1'}
                          borderRadius="14px"
                          onFocus={() => console.log('Select One Got focus !!')}
                          onBlur={() => console.log('Select Lost focus !!')}
                          tabIndex={3}
                        ></Select>
                      );
                    // case removeSpaces(INPUT_FIELD_TAG):
                    //   return <input key={index} type="email" placeholder="Enter email" />;
                    case removeSpaces(PASSWORD_FIELD_TAG):
                      return (
                        <input
                          key={index}
                          type="password"
                          placeholder="Enter password"
                        />
                      );
                    case removeSpaces(IMAGE_TAG):
                      return (
                        <Image
                          url="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiQmcqzN9KSMx-hxPJfiB3yt59uQhN9R4IqjisfUEitJv9lbQVN14QYLsUfmgiH-AoH2VgTFMdRBaTWa9XXpU9aMV1fveYnRgRsf4peaqt_rCR_qyQ483NgjHHdhfYpOr8axyGWhk3DHw5lAUQkXl6NGMugPS7k6Apw7CUjqRMgwAv01i2_AXyRumuBfw/s16000/blank-profile-picture-hd-images-photo.JPG"
                          height={40}
                          width={40}
                        />
                      );
                    case removeSpaces(MULTILINE_INPUT_TAG):
                      return (
                        <TextArea
                          key={'textarea'}
                          borderRadius="14px"
                          onFocus={() => console.log('TextArea Got focus !!')}
                          onBlur={() => console.log('TextArea Lost focus !!')}
                        ></TextArea>
                      );
                    case LABEL_TAG:
                      return <Label>Label component</Label>;
                    case BUTTON_TAG:
                      return <ButtonLayout numberOfButtons={2} />;
                    default:
                      return <></>;
                  }
                })}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
