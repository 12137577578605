import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import {
  DataSourceTablesRequest,
  DataSourceTable,
} from '../../types/datasource';

export const getDataSourceTables = async (
  datasourceDetails: DataSourceTablesRequest,
): Promise<DataSourceTable[]> =>
  api
    .post(URLs.GET_DATASOURCE_TABLES, datasourceDetails)
    .then((res: any) => res.data);

export const useGetDataSourceTables = (): UseMutationResult<
  DataSourceTable[],
  Error,
  DataSourceTablesRequest
> => {
  return useMutation(
    [QueryKeys.GetDataSourceTables],
    (datasourceDetails: DataSourceTablesRequest) =>
      getDataSourceTables(datasourceDetails),
  );
};
