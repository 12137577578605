import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { SystemTemplate } from '../../types/systemTemplate';

export const getSystemTemplate = async (
  templateId: string,
): Promise<SystemTemplate> =>
  api.get(URLs.GET_SYSTEM_TEMPLATE(templateId)).then((res: any) => res.data);

export const useGetSystemTemplate = (): UseMutationResult<
  SystemTemplate,
  Error,
  string
> => {
  return useMutation([QueryKeys.GetSystemTemplate], (templateId: string) =>
    getSystemTemplate(templateId),
  );
};
