import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { Name } from '../../types/common';

export const isS3NameExists = async (name: Name): Promise<boolean> => {
  return api.post(URLs.CHECK_S3_NAME_EXISTS, name).then((res: any) => res.data);
};

export const useIsS3NameExists = (): UseMutationResult<
  boolean,
  Error,
  Name
> => {
  return useMutation([QueryKeys.IsS3NameExists], (name: Name) =>
    isS3NameExists(name),
  );
};
