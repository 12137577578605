import React, { FC, ReactNode, useEffect, useRef } from 'react';

import './PdfCustomTagDesignPanel.scss';
import { ContentMoveElement } from '../../components/ContentMoveElement/ContentMoveElement';

export const COMMON_PDF_CSS = `@page{
  margin-top: 2cm;
  margin-left: 2cm;
  margin-right: 2cm;
  margin-bottom: 0px;
}


.text-style-12{

font-family: Arial, sans-serif;
line-height: 1.5;
font-size:12px;
color: #333333;
}

.ul-default-style li {
list-style: disc;
}

.ul-default-style {
padding-inline-start: 40px;
margin-block-start: 1em;
margin-block-end: 1em;
list-style: disc;
}

.arial{
  font-family: Arial, Helvetica, sans-serif;
}

.company-address{
  display: block;
  margin-left: auto;
  text-align: end;
}
.footer-text{
  margin-top: 100px;
  font-size: 10px;
  line-height:12px;
}

.m-l-5px{
margin-left: 5px;
}

.f-w-600{
  font-weight: 600;
}
.f-w-400{
  font-weight: 400;
}

.f-w-500{
  font-weight: 500;
}


li p{
margin: 0px;
}
.t-a-start{
text-align: start;
}

.customer-contact-details{
display: flex;
justify-content: space-between;
align-items: center;
text-align: end;
margin-bottom: 20px;
}

.f-w-700{
font-weight: 700;
}

.ref-date{
margin-left: auto;
text-align: end;
}

h2{
margin-block-start: 0em;
margin-block-end: 0em;
}

.debt-management p{
line-height: 14px;
} 

.pdf-text-right-align {
  text-align: right;
}
.pdf-text-center-align {
  text-align: center;
}
`;

export interface PdfCustomTagDesignPanelProps {
  content: string;
  setContent: (content: string) => void;
  cssContent: string;
  setJson: (json: string) => void;
  json: string;
  // setCssContent: string;
  children?: ReactNode;
  activePage: number;
  width?: number;
  setSelectedElementId: (id: string) => void;
  idList: string[];
  // setIdList: (idList: string[]) => void;
  components: JSX.Element[];
  setComponents: (components: JSX.Element[]) => void;
  selectedElementId: string;
  setShowPreview: (falg: boolean) => void;
  addComponent: (componentType: string) => void;
  deleteComponent: (selectedElementId: string) => void;
  moveComponentUp: (selectedElementId: string) => void;
  moveComponentDown: (selectedElementId: string) => void;
  // changeValueProperty: (
  //   id: string,
  //   newText: string,
  //   components?: JSX.Element[],
  //   setComponents?: (components: JSX.Element[]) => void,
  // ) => void;
}

export const PdfCustomTagDesignPanel: FC<PdfCustomTagDesignPanelProps> = ({
  // content,
  setContent,
  // cssContent,
  // json,
  selectedElementId,
  // setSelectedElementId,
  // idList,
  // setIdList,
  components,
  setComponents,
  setShowPreview,
  addComponent,
  deleteComponent,
  moveComponentDown,
  moveComponentUp,
}) => {
  const textRef = useRef<any>(null);

  // const [selectedElementId, setSelectedElementId] = useState<string>('');
  // const updateValue = (
  //   id: string,
  //   newValue: string,
  //   components: JSX.Element[],
  // ) => {
  //   console.log('PdfCustomTagDesignPanel nee values ::', id, newValue);
  //   console.log('setValue PdfCustomTagDesignPanel :: ', components);
  //   // changeValueProperty(id, newValue, components, setComponents);
  //   const jsonStrings = jsxElementsToJSONString(components);
  //   setJson(jsonStrings);
  //   console.log('Updated JSON :', jsonStrings);
  // };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const componentType = e.dataTransfer.getData('text/plain');

    addComponent(componentType);
    setContent(textRef.current.innerHTML);
    // const jsonStrings = jsxElementsToJSONString(updateComponentsWithSetValue);
    // setJson(jsonStrings);
    // console.log('convertToJson :: ', jsonStrings);
  };

  useEffect(() => {
    setContent(textRef.current.innerHTML);
  }, [components]);

  return (
    <div className="preview-resize-container">
      <div className="cm-tag-design-container">
        <div className="cm-preview-header">
          <div>Design</div>
          <div className="customtag-preview-button">
            <button
              id="preview"
              onClick={e => {
                e.preventDefault();
                setShowPreview(true);
              }}
              // onClick={() => {
              //   const tempMap = new Map(elementListMap);
              //   const noOfPages = tempMap.size;
              //   if (noOfPages === 0) {
              //     const elementList = tempMap.get(noOfPages);
              //     if (!elementList || elementList.length === 0) {
              //       tempMap.set(0, []);
              //       tempMap.set(1, []);
              //     }
              //   } else {
              //     tempMap.set(noOfPages + 1, []);
              //   }
              //   // console.log('adding new page :', noOfPages + 1);
              //   setElementListMap(tempMap);
              // }}
            >
              <span>
                <svg fill="#000000" viewBox="0 0 92 92" className="cf-icon-svg">
                  <path
                    d="M91.3,43.8C90.6,42.8,74.4,19,46,19C17.6,19,1.4,42.8,0.7,43.8c-0.9,1.3-0.9,3.1,0,4.5
	C1.4,49.2,17.6,73,46,73c28.4,0,44.6-23.8,45.3-24.8C92.2,46.9,92.2,45.1,91.3,43.8z M46,65C26.7,65,13.5,51.4,9,46
	c4.5-5.5,17.6-19,37-19c19.3,0,32.5,13.6,37,19C78.4,51.5,65.3,65,46,65z M48.3,29.6c-4.4-0.6-8.7,0.5-12.3,3.2c0,0,0,0,0,0
	c-7.3,5.5-8.8,15.9-3.3,23.2c2.7,3.6,6.5,5.8,10.9,6.5c0.8,0.1,1.6,0.2,2.3,0.2c3.6,0,7-1.2,9.9-3.3c7.3-5.5,8.8-15.9,3.3-23.2
	C56.6,32.5,52.7,30.2,48.3,29.6z M52.3,54.5c-2.2,1.7-5,2.4-7.8,2c-2.8-0.4-5.3-1.9-7-4.1C34.1,47.7,35,41,39.7,37.5
	c2.2-1.7,5-2.4,7.8-2c2.8,0.4,5.3,1.9,7,4.1C57.9,44.3,57,51,52.3,54.5z M51.9,40c0.8,0.7,1.2,1.8,1.2,2.8c0,1-0.4,2.1-1.2,2.8
	c-0.7,0.7-1.8,1.2-2.8,1.2c-1.1,0-2.1-0.4-2.8-1.2c-0.8-0.8-1.2-1.8-1.2-2.8c0-1.1,0.4-2.1,1.2-2.8c0.7-0.8,1.8-1.2,2.8-1.2
	C50.2,38.9,51.2,39.3,51.9,40z"
                  />
                </svg>
              </span>
              Preview
            </button>
          </div>
        </div>
        <div>
          <style>{COMMON_PDF_CSS}</style>
        </div>

        <div
          // contentEditable
          //suppressContentEditableWarning={true}
          onDrop={handleDrop}
          onDragOver={e => e.preventDefault()}
          className="cm-tag-design-section"
          ref={textRef}
          onClick={() => {
            setContent(textRef.current.innerHTML);
          }}
        >
          {/* {components.map((component, index) =>
            React.cloneElement(component, {
              setValue: (
                id: string,
                newValue: string,
                componentList: JSX.Element[],
              ) => {
                console.log('Second Overrided method !!', components.length);
                updateValue(id, newValue, components);
              },
            }),
          )} */}

          {/* {components} */}
          {components.map((component, index) => {
            return React.cloneElement(component, {
              onValueChange: (
                id: string,
                newValue: string | string[] | string[][],
              ) => {
                const tempFields = [...components];

                const updatedFields = tempFields.map(f =>
                  f.key === id
                    ? {
                        ...f,
                        props: {
                          ...f.props,
                          value:
                            typeof newValue === 'string'
                              ? newValue.replace('&lt;br&gt;', '<br>')
                              : Array.isArray(newValue)
                              ? newValue.map((val, index) => {
                                  return typeof val === 'string'
                                    ? val.replace('&lt;br&gt;', '<br>')
                                    : val;
                                })
                              : newValue,
                        },
                      }
                    : f,
                );
                // console.log('After updating', tempFields);
                setComponents(updatedFields);
              },
            });
          })}
        </div>
        <ContentMoveElement
          selectedElementId={selectedElementId}
          deleteComponent={deleteComponent}
          moveComponentUp={moveComponentUp}
          moveComponentDown={moveComponentDown}
        />
      </div>
    </div>
  );
};
