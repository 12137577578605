import { FC, useEffect, useState } from 'react';
import './SocialMediaDesign.scss';
import '../../styles/_CommonStyles.scss';
import { Breadcrum } from '../../atoms/Breadcrum/Breadcrum';
import { Title } from '../../atoms/Title/Title';
// import { FieldArray } from 'formik';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import PreviousButton from '../../atoms/PreviousButton/PreviousButton';
import { Next } from '../../atoms/Next/Next';
import { SocialElementsPanel } from '../../components/SocialElementsPanel/SocialElementsPanel';
import { SocialContentPreview } from './SocialContentPreview';
import { SOCIAL_LAYOUT_TYPE } from '../../types/layoutTypes';
import { PropertiesPanel } from '../../atoms/Elements/PropertiesPanel';
import { SocialMobilePreview } from './SocialMobilePreview';
import { designOneProps } from '../../types/social';
import { useSaveSocialContent } from '../../hooks/useSaveSocialContent/useSaveSocialContent';
import { useGetSocialDesignList } from '../../hooks/useGetSocialDesignList/useGetSocialDesignList';

export const SocialMediaDesign: FC = () => {
  const navigate = useNavigate();
  const { data: designList } = useGetSocialDesignList();
  const [queryParams] = useSearchParams();
  const [activePage, setActivePage] = useState<number>(0);
  const [pagesLayoutMap, setPagesLayoutMap] = useState<
    Map<number, SOCIAL_LAYOUT_TYPE>
  >(() => {
    const layoutMap = new Map<number, SOCIAL_LAYOUT_TYPE>();
    layoutMap.set(0, SOCIAL_LAYOUT_TYPE.LAYOUT_ONE);
    return layoutMap;
  });
  const [pagesDesignIdMap, setPagesDesignIdMap] = useState<Map<number, string>>(
    () => {
      const designId = designList ? designList[0].designId : '';
      const desigmMap = new Map();
      desigmMap.set(0, designId);
      return desigmMap;
    },
  );

  const [pagesPropsMap, setPagesPropsMap] = useState<
    Map<number, Map<string, string>>
  >(() => {
    const initialData = new Map();
    initialData.set(0, designOneProps);

    return initialData;
  });

  const campaignId =
    queryParams.get('id') && queryParams.get('id') !== null
      ? queryParams.get('id')
      : '';
  const { mutate: saveContent } = useSaveSocialContent();

  const saveCampaignContent = () => {
    const campaignPages: {
      designId: string;
      propsMap?: Map<string, string>;
    }[] = Array.from(pagesDesignIdMap).map(([key, value]) => {
      // const keyStr = key.toString();
      return { designId: value, propsMap: pagesPropsMap.get(key) };
    });
    if (campaignId) {
      saveContent(
        {
          campaignId: campaignId,
          socialCampaignContent: { campaignPages: campaignPages },
        },
        { onSuccess: data => navigate(`/reviewsocial?id=${campaignId}`) },
      );
    }

    //campaignId ? saveContent({campaignId: campaignId, campaignPages: {}})
  };
  useEffect(() => {
    const designId = designList ? designList[0].designId : '';

    const tempPagesDesignIdMap = new Map(pagesDesignIdMap);
    tempPagesDesignIdMap.set(0, designId);
    setPagesDesignIdMap(tempPagesDesignIdMap);
  }, [designList]);

  return (
    <div className="social-media-content-container">
      <Breadcrum pageName="Create a Social media design" />

      <div className="title">
        <Title text="Social media design" />
      </div>

      {/* <FormikProvider value={formik}> */}
      <form>
        <div className={`content rightPos`}>
          <div className="grid-layout-2">
            <SocialElementsPanel
              pageNumber={activePage}
              pagesLayoutMap={pagesLayoutMap}
              setLayout={setPagesLayoutMap}
              pagesDesignIdMap={pagesDesignIdMap}
              setPagesDesignIdMap={setPagesDesignIdMap}
              designList={designList ? designList : []}
            ></SocialElementsPanel>
            <div className="html-css">
              <>
                <SocialContentPreview
                  activePage={activePage}
                  pagesPropsMap={pagesPropsMap}
                  setPagesPropsMap={setPagesPropsMap}
                  setActivePage={setActivePage}
                  pagesLayoutMap={pagesLayoutMap}
                  setPagesLayoutMap={setPagesLayoutMap}
                  pagesDesignIdMap={pagesDesignIdMap}
                  setPagesDesignIdMap={setPagesDesignIdMap}
                  commonDesignId={designList ? designList[0].designId : ''}
                  key={`content_preview`}
                ></SocialContentPreview>
              </>

              <>
                <SocialMobilePreview
                  activePage={0}
                  pagesPropsMap={pagesPropsMap}
                  pagesDesignIdMap={pagesDesignIdMap}
                  key={`content_preview`}
                ></SocialMobilePreview>
              </>
            </div>
          </div>

          <PropertiesPanel
            changeAlignProperty={(id: string, newValue: string) => {
              console.log('newValue');
            }}
            idList={[]}
            selectedElementId={''}
            setSelectedElementId={(id: string) => {
              console.log('id:: ');
            }}
          ></PropertiesPanel>
        </div>
        <div className="navigationButtons">
          <Link to={`/audience?id=${campaignId}`}>
            <PreviousButton />
          </Link>
          {/* <Link to={`/reviewsocial?id=${campaignId}`}> */}
          <Next
            type={'submit'}
            label={'Next'}
            onClick={() => {
              saveCampaignContent();
            }} //formik.setActiveButton('save')}
          />
          {/* </Link> */}
        </div>
      </form>
      {/* </FormikProvider> */}
    </div>
  );
};
