import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import '../../styles/_CommonStyles.scss';
import { Breadcrum } from '../../atoms/Breadcrum/Breadcrum';
import { Title } from '../../atoms/Title/Title';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import PreviousButton from '../../atoms/PreviousButton/PreviousButton';
import { Next } from '../../atoms/Next/Next';
import './PdfCustomTagDesign.scss';
import { PropertiesPanel } from '../../atoms/Elements/PropertiesPanel';
import { PdfCustomTagElementsPanel } from '../../components/PdfCustomTagElementsPanel/PdfCustomTagElementsPanel';
import SelectBox, { SelectOption } from '../../atoms/Select/Select';
import { useGetDashboard } from '../../hooks/useGetDashboard/useGetDashboard';
import { FormikProvider, useFormik } from 'formik';
import { CustomTag } from '../../types/customtag';
import { useSaveCustomTag } from '../../hooks/useSaveCustomTag/useSaveCustomTag';
import { brandToSelectOption } from '../../utils/utils';
import { useIsCustomTagNameExists } from '../../hooks/useIsCustomTagNameExists/useIsCustomTagNameExists';
import { useGetCustomTagContent } from '../../hooks/useGetCustomTagContent/useGetCustomTagContent';
import { TextInput } from 'cm-react-components/dist/js';
import { PdfCustomTagDesignPanel } from './PdfCustomTagDesignPanel';
import {
  changeAlignProperty,
  changeColorProperty,
  changeNoOfRowsProperty,
  createComponent,
  getComponentIdsFromJson,
  jsonToJsxElementList,
  jsxElementsToJSONString,
} from '../../utils/elementsUtil';
import PdfPreview from '../../atoms/PdfPreview/PdfPreview';
// import { COMMON_PDF_CSS } from '../PdfContent/PdfContentDesignPanel';

export const PdfCustomTagDesign: FC = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { data: brandsList } = useGetDashboard();
  const { mutate } = useSaveCustomTag();
  const {
    mutate: getTagContent,
    data: tagContent,
    isLoading: loadingCustomTagData,
  } = useGetCustomTagContent();

  const createCustomTag = (values: any) => {
    formik.validateForm().then((result: any) => {
      if (!result || Object.keys(result).length == 0) {
        mutate(values, {
          onSuccess: () => {
            navigate('/customtags');
          },
          onError: () => {
            // addToast({
            //   label: `New Lead creation failed.`,
            //   type: 'error',
            // });
          },
        });
      }
    });
  };

  const [idList, setIdList] = useState<string[]>([]);
  const [selectedElementId, setSelectedElementId] = useState<string>('');
  const { mutate: isCustomTagNameExists } = useIsCustomTagNameExists();
  const formik = useFormik({
    initialValues: {
      tagName: '',
      description: '',
      brandId: '',
      json: '',
      htmlContent: '',
      cssContent: '',
    },
    // validationSchema: ,
    onSubmit: async (values: CustomTag) => {
      createCustomTag(values);
    },

    /* onSubmit: async () => {
      null;
    },*/
  });
  const brands: SelectOption[] = brandsList
    ? brandsList.map(brand => {
        return {
          label: brand.brandName,
          value: brand.brandId,
        };
      })
    : [];
  const handleBrandSelectionChange = (option: SelectOption | any) => {
    formik.setFieldValue('brandId', option.value);
  };
  const [pdfPreview, setPdfPreview] = useState<boolean>(false);

  const handleCustomTagNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (tagContent && tagContent.tagName === value) {
      formik.handleChange(e);
      return;
    }
    isCustomTagNameExists(
      { name: value },
      {
        onSuccess: data => {
          if (data && data === true) {
            formik.setFieldError('tagName', 'Tag Name already exists');
          }
        },
        // onError: () => {},
      },
    );
    formik.handleChange(e);
  };

  // const [components, setComponents] = useState<
  //   JSX.Element[] | React.FunctionComponentElement<any>[]
  // >([]);

  const [componentList, setComponentList] = useState<
    JSX.Element[] | React.FunctionComponentElement<any>[]
  >([]);

  const changeValue = (
    id: string,
    newValue: string | string[] | string[][],
  ) => {
    // if (changeValueProperty) {
    // }
    //changeValueProperty(id, newValue, componentList, setComponentList);
  };

  const addComponent = (componentType: string) => {
    const updatedComponents = [...componentList];

    // if (updatedComponents.length === 0) {
    const component = createComponent(
      componentType,
      setIdList,
      setSelectedElementId,
      changeValue,
      idList,
    );
    updatedComponents.push(component);
    // }

    setComponentList(updatedComponents);
  };

  const deleteComponent = (elementId: string) => {
    if (componentList) {
      const newComponents = componentList.filter(
        item => item.key !== elementId,
      );
      setComponentList(newComponents);
    }
  };

  const moveElementUp = (elementId: string) => {
    moveComponent(elementId, 'up');
  };

  const moveElementDown = (elementId: string) => {
    moveComponent(elementId, 'down');
  };

  const moveComponent = (elementId: string, direction: string) => {
    if (componentList) {
      const index = componentList.findIndex(item => item.key === elementId);
      const components = componentList;
      if (index !== -1) {
        if (direction === 'up' && index > 0) {
          const temp = components[index];
          components[index] = components[index - 1];
          components[index - 1] = temp;
        } else if (direction === 'down' && index < components.length - 1) {
          const temp = components[index];
          components[index] = components[index + 1];
          components[index + 1] = temp;
        }
        setComponentList(components);
      }
    }
  };

  useEffect(() => {
    if (jsxElementsToJSONString) {
      const jsonStrings = jsxElementsToJSONString(componentList);
      formik.setFieldValue('json', jsonStrings);
    }
  }, [componentList]);

  useEffect(() => {
    if (!loadingCustomTagData && tagContent) {
      formik.setFieldValue('tagId', tagContent.tagId);
      formik.setFieldValue('brandId', tagContent.brandId);
      formik.setFieldValue('tagName', tagContent.tagName);
      formik.setFieldValue('description', tagContent.description);
      formik.setFieldValue('cssContent', tagContent.cssContent);
      formik.setFieldValue('htmlContent', tagContent.htmlContent);
      formik.setFieldValue('json', tagContent.json);
      const loadeCmponents = jsonToJsxElementList(
        tagContent.json,
        setSelectedElementId,
        (newValue: string, id: string) => {
          changeValue(id, newValue);
        },
      );
      const ids = getComponentIdsFromJson(tagContent.json);
      setIdList(ids);
      const updateComponentsWithSetValue = loadeCmponents.map(
        (component, index) =>
          React.cloneElement(component, {
            setValue: (newValue: string, id: string) => {
              changeValue(id, newValue);
            },
          }),
      );

      updateComponentsWithSetValue &&
        updateComponentsWithSetValue.length > 0 &&
        setComponentList(updateComponentsWithSetValue);
      // setComponentList(loadeCmponents);
    }
  }, [loadingCustomTagData, tagContent]);

  useEffect(() => {
    if (queryParams.get('tag')) {
      const tagId = queryParams.get('tag');

      getTagContent({
        tagId: tagId ? tagId : '',
      });
    }
  }, []);

  const campaignId =
    queryParams.get('id') && queryParams.get('id') !== null
      ? queryParams.get('id')
      : '';

  return (
    <div className="social-media-content-container">
      <Breadcrum pageName="Custom tag design" />

      <div className="title">
        <Title text="Custom tag design" />
      </div>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="customtag-name-row navigationButtons">
            <SelectBox
              name={'brandId'}
              id={'brandId'}
              label={'Brand'}
              list={brands}
              placeholder={'Choose brand'}
              value={
                brandsList &&
                brandsList.find(
                  brand => formik.values.brandId === brand.brandId,
                )
                  ? brandToSelectOption(
                      brandsList.find(
                        brand => formik.values.brandId === brand.brandId,
                      ),
                    )
                  : { label: 'Select brand', value: '' }
              }
              onChange={handleBrandSelectionChange}
            />
            <TextInput
              id="tagName"
              name="tagName"
              label="Tag Name"
              onChange={handleCustomTagNameChange}
              onBlur={formik.handleBlur}
              value={formik.values.tagName}
              borderRadius="0px"
              // errorMessage={formik.errors.tagName}
            />
            <TextInput
              id="description"
              name="description"
              label="Description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              borderRadius="0px"
              value={formik.values.description}
              //errorMessage={formik.errors.description}
            />
          </div>
          <div className={`content rightPos`}>
            <div className="customtag-design-container grid-layout-2">
              <PdfCustomTagElementsPanel />

              <div className="html-css">
                <>
                  <PdfCustomTagDesignPanel
                    setShowPreview={setPdfPreview}
                    components={componentList}
                    setComponents={setComponentList}
                    setSelectedElementId={setSelectedElementId}
                    selectedElementId={selectedElementId}
                    idList={idList}
                    // setIdList={setIdList}
                    activePage={0}
                    content={formik.values.htmlContent}
                    setContent={(content: string) => {
                      formik.setFieldValue('htmlContent', content);
                    }}
                    json={formik.values.json}
                    setJson={(json: string) => {
                      // console.log('Updating JSON ', json);
                      formik.setFieldValue('json', json);
                    }}
                    cssContent={formik.values.cssContent}
                    key={`content_preview`}
                    addComponent={addComponent}
                    deleteComponent={deleteComponent}
                    moveComponentDown={moveElementDown}
                    moveComponentUp={moveElementUp}
                  />
                </>
                {/* <>
                  <PreviewIframe
                    showPagination={false}
                    campaignPages={[
                      {
                        content: formik.values.htmlContent,
                        name: '',
                        cssContent: formik.values.cssContent,
                        orientation: '',
                        index: 0,
                        active: true,
                      },
                    ]}
                    commonCss={''}
                  />
                </> */}
              </div>
            </div>

            <PropertiesPanel
              idList={idList}
              selectedElementId={selectedElementId}
              setSelectedElementId={setSelectedElementId}
              changeAlignProperty={(id: string, newText: string) => {
                changeAlignProperty(
                  id,
                  newText,
                  componentList,
                  setComponentList,
                );
              }}
              changeColorProperty={(id: string, newText: string) => {
                changeColorProperty(
                  id,
                  newText,
                  componentList,
                  setComponentList,
                );
              }}
              changeNoOfRowsProperty={(id: string, newText: string) => {
                changeNoOfRowsProperty(
                  id,
                  newText,
                  componentList,
                  setComponentList,
                );
              }}
            ></PropertiesPanel>
          </div>
          <div className="navigationButtons">
            <Link to={`/audience?id=${campaignId}`}>
              <PreviousButton />
            </Link>
            {/* <Link to={`/customtags`}> */}
            <Next
              type={'submit'}
              label={'Save'}
              onClick={e => {
                // e.preventDefault();
                //createCustomTag(formik.values);
                console.log('Save custom  tag content!!');
              }} //formik.setActiveButton('save')}
            />
            {/* </Link> */}
          </div>
        </form>
      </FormikProvider>
      <div
        className="pdfPreview-pop-up"
        style={{
          position: 'absolute',
          top: '20px',
          left: '50%',
          transform: 'translate(-50%, 0px)',
        }}
      >
        {pdfPreview && (
          <div className="position-helper">
            <PdfPreview
              systemTemplateCssAndPages={{
                cssContent: '',
                templateId: '',
                systemTemplatePages: [
                  {
                    // name: '',
                    content: formik.values.htmlContent,
                    cssContent: '',
                    orientation: 'Potrait',
                    // index: 1,
                    // active: true,
                  },
                ],
              }}
            />

            <div
              className="closePfdPreview"
              onClick={() => setPdfPreview(!pdfPreview)}
            >
              X
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
