export const INPUT_FIELD = 'Input field';
export const CHECK_BOX = 'Checkbox';
export const RANGE = 'Range';
export const PASSWORD_FIELD = 'Password field';
export const RADIO_BUTTONS = 'Radio buttons';
export const SEARCH_BAR = 'Search Bar';
export const SELECT = 'Select';
export const SWITCH_BUTTON = 'Switch button';
export const LABEL = 'Label';
export const EMAIL = 'Email';
export const IMAGE = 'Image';
export const MULTILINE_INPUT = 'Multiline Input field';
export const BUTTON = 'Button';
export const DIVIDER = 'Divider';

export const INPUT_FIELD_TAG = 'TextInput';
export const CHECK_BOX_TAG = 'CheckBox';
export const DIVIDER_TAG = 'Divider';
export const RANGE_TAG = 'Range';
export const PASSWORD_FIELD_TAG = 'Psssword';
export const RADIO_BUTTONS_TAG = 'Radio';
export const SEARCH_BAR_TAG = 'SearchBox';
export const SELECT_TAG = 'Select';
export const SWITCH_BUTTON_TAG = 'Switch';
export const LABEL_TAG = 'Label';
export const EMAIL_TAG = 'Email';
export const IMAGE_TAG = 'Image';
export const MULTILINE_INPUT_TAG = 'TextArea';
export const BUTTON_TAG = 'Button';

export const elementsLabelAndTagMap = new Map<string, string>();

elementsLabelAndTagMap.set(INPUT_FIELD_TAG, INPUT_FIELD);
elementsLabelAndTagMap.set(CHECK_BOX_TAG, CHECK_BOX);
elementsLabelAndTagMap.set(RANGE_TAG, RANGE);
elementsLabelAndTagMap.set(PASSWORD_FIELD_TAG, PASSWORD_FIELD);
elementsLabelAndTagMap.set(RADIO_BUTTONS_TAG, RADIO_BUTTONS);
elementsLabelAndTagMap.set(SEARCH_BAR_TAG, SEARCH_BAR);
elementsLabelAndTagMap.set(SELECT_TAG, SELECT);
elementsLabelAndTagMap.set(SWITCH_BUTTON_TAG, SWITCH_BUTTON);
elementsLabelAndTagMap.set(LABEL_TAG, LABEL);
// elementsLabelAndTagMap.set(INPUT_FIELD_TAG, EMAIL);
elementsLabelAndTagMap.set(EMAIL_TAG, EMAIL);
elementsLabelAndTagMap.set(IMAGE_TAG, IMAGE);
elementsLabelAndTagMap.set(MULTILINE_INPUT_TAG, MULTILINE_INPUT);
elementsLabelAndTagMap.set(BUTTON_TAG, BUTTON);
