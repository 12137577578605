import { createContext } from 'react';

export interface CmsApplication {
  name: string;
  accessToken: string;
  refreshToken: string;
  selectedBrand?: string;
  selectedBrandId?: string;
}

export const CmsApplicationContext = createContext<{
  cmsApplication: CmsApplication;
  setCmsApplication: (cmsApplication: CmsApplication) => void;
}>({
  cmsApplication: {
    name: '',
    accessToken: '',
    refreshToken: '',
    selectedBrand: '',
  },
  setCmsApplication: (cmsApplication: CmsApplication) => ({}),
});
