import { FC, useState } from 'react';
import { TextInput } from 'cm-react-components/dist/js';

export interface PdfImageProps {
  id: string;
  name?: string;
  setSelectedElementId: (id: string) => void;
  value: string;
  onValueChange: (id: string, newValue: string) => void;
  height?: number;
  width?: number;
  recIndex?: number;
  sourceImage: string;
}

export const PdfImage: FC<PdfImageProps> = ({
  id,
  name,
  setSelectedElementId,
  value,
  onValueChange,
  height,
  width,
  recIndex,
  sourceImage,
}) => {
  const [editing, setEditing] = useState<boolean>(false);

  return editing === true ? (
    <TextInput
      id={id}
      key={id}
      onBlur={e => {
        onValueChange(id, e.target.value);
        setEditing(false);
      }}
      onChange={e => {
        onValueChange(id, e.target.value);
      }}
      value={value}
      borderRadius="0px"
    ></TextInput>
  ) : (
    <div
      id={id}
      key={id}
      className="image-tag"
      onClick={() => {
        // handleLabelClick();
        setEditing(true);
        setSelectedElementId(id);
      }}
      data-name={value}
      style={{ height: `${height}px`, width: `${width}px` }}
    >
      {height && width ? (
        <>
          <div
            dangerouslySetInnerHTML={{ __html: sourceImage }}
            style={{
              height: `${height}px`,
              width: `${width}px`,
              overflow: 'hidden',
            }}
          />
          <img
            src={sourceImage}
            style={{
              height: `${height}px`,
              width: `${width}px`,
              display: 'none',
            }}
          />{' '}
        </>
      ) : (
        <>
          <div dangerouslySetInnerHTML={{ __html: sourceImage }} />
          <img src={sourceImage} alt="pdfimage" />{' '}
        </>
      )}
    </div>
  );
};

PdfImage.displayName = 'PdfImage';
