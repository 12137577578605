import { Link, useSearchParams } from 'react-router-dom';
import { Breadcrum } from '../../atoms/Breadcrum/Breadcrum';

import PreviousButton from '../../atoms/PreviousButton/PreviousButton';
import { Title } from '../../atoms/Title/Title';
import './PdfReviewContent.scss';
import { FC, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useDownloadReviewPdf } from '../../hooks/useDownloadReviewPdf/useDownloadReviewPdf';
import { Button } from 'cm-react-components/dist/js';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PdfReviewContent: FC = () => {
  const [queryParams] = useSearchParams();
  const campaignId =
    queryParams.get('id') && queryParams.get('id') !== null
      ? queryParams.get('id')
      : '';

  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);
  const [renderError, setRenderError] = useState<boolean>(false);
  const { mutate: downloadPdf } = useDownloadReviewPdf();

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < numPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handleRenderError = () => {
    setRenderError(true);
  };

  const handleLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if (campaignId) {
      downloadPdf(campaignId, {
        onSuccess: data => {
          setPdfFile(
            new File([data], 'preview.pdf', {
              type: 'application/pdf',
            }),
          );
        },
        onError: () => {
          console.log('Error');
        },
      });
    }
  }, [campaignId]);

  return (
    <div className="ReviewHtmlPreview-container">
      <Breadcrum pageName={'Create a Campaign'} />
      <Title text={'Review Campaign'} />

      <div className="pdfPreview-container pdf-rev-cont-width">
        <div>
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            &lt; Previous
          </button>
          <span style={{ marginLeft: '4px', marginRight: '4px' }}>
            Page {currentPage} of {numPages}
          </span>
          <button onClick={handleNextPage} disabled={currentPage === numPages}>
            Next &gt;
          </button>
        </div>
        <div>
          {!renderError ? (
            <Document
              file={pdfFile}
              onLoadSuccess={handleLoadSuccess}
              onItemClick={() => null}
              onError={handleRenderError}
            >
              <Page
                pageNumber={currentPage}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
          ) : (
            <div>Failed to render PDF.</div>
          )}
        </div>
      </div>
      <div className="navigationButtons">
        <Link to={`/contentdesign?id=${campaignId}`}>
          <PreviousButton />
        </Link>

        <Button label={'Download'} onClick={() => console.log('Download')} />
        {/* <Next
            type={'submit'}
            label={'Download'}
            onClick={event => campaignId && publishCampaign(campaignId)}
          /> */}
      </div>
    </div>
  );
};
