import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import InputBox from '../../atoms/InputBox/InputBox';
import { useLogin } from '../../hooks/useLogin/useLogin';
import { LoginRequest } from '../../types/login';
import { useFormik } from 'formik';
import './LoginPage.scss';
import { loginFormValidation } from './validation';
import {
  updateAccessToken,
  updateRefreshToken,
} from '../../slices/cmsAppSlice';
import { Link, useSearchParams } from 'react-router-dom';

const AUTH_PROVIDER = `https://ncampaignsuite.auth.eu-west-2.amazoncognito.com/oauth2/authorize`;

const REDIRECT_URI = `${window.location.origin}/signin-oidc`;
const RESPONSE_TYPE = 'code';
// const CLIENT_ID: string = `${process.env.REACT_APP_COGNITO_CLIENTID}`;

const CLIENT_ID = '7nteb6beismr74p7jsbstv6elh';
const SCOPE = 'email%20openid';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mutate } = useLogin();
  const [queryParams] = useSearchParams();
  const templateId =
    queryParams.get('templateid') && queryParams.get('templateid') !== null
      ? queryParams.get('templateid')
      : '';
  // const params = new URLSearchParams(window.location.search);

  const login = (values: any) => {
    formik.validateForm().then((result: any) => {
      if (result && Object.keys(result).length > 0) {
        // addToast({
        //   label: `Please provide mandatory information`,
        //   type: 'error',
        // });
      } else {
        mutate(values, {
          onSuccess: data => {
            if (data?.accessToken) {
              dispatch(updateAccessToken(data.accessToken));
              dispatch(updateRefreshToken(data.refreshToken));
              // addToast({
              //   label: `New Lead created: ${data.clientId}`,
              //   type: 'success',
              // });
              templateId
                ? navigate(`/createcampaign?templateid=${templateId}`)
                : navigate('/pdftemplates');
            } else if (data?.forcePasswordChange === true) {
              navigate(`/reset-password?id=${values.email}`);
            }
          },
          onError: () => {
            // addToast({
            //   label: `New Lead creation failed.`,
            //   type: 'error',
            // });
          },
        });
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginFormValidation,
    onSubmit: async (values: LoginRequest) => {
      login(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="log-in-page">
        <div className="login-container">
          <div className="sign-logo">
            <img src="NipunaCampaignSuit-r-corner.png" alt="logo" />
          </div>
          <div className="log-in-text">Log In</div>
          <div className="third-party-options">
            <a
              // href="javascript:void(0);"
              className="login-option-btn"
              href={`${AUTH_PROVIDER}?redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&client_id=${CLIENT_ID}&scope=${SCOPE}`}
            >
              <span className="button-text">
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  className="align"
                >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M20.64 12.2c0-.63-.06-1.25-.16-1.84H12v3.49h4.84a4.14 4.14 0 0 1-1.8 2.71v2.26h2.92a8.78 8.78 0 0 0 2.68-6.62z"
                      fill="#4285F4"
                    ></path>
                    <path
                      d="M12 21a8.6 8.6 0 0 0 5.96-2.18l-2.91-2.26a5.4 5.4 0 0 1-8.09-2.85h-3v2.33A9 9 0 0 0 12 21z"
                      fill="#34A853"
                    ></path>
                    <path
                      d="M6.96 13.71a5.41 5.41 0 0 1 0-3.42V7.96h-3a9 9 0 0 0 0 8.08l3-2.33z"
                      fill="#FBBC05"
                    ></path>
                    <path
                      d="M12 6.58c1.32 0 2.5.45 3.44 1.35l2.58-2.59A9 9 0 0 0 3.96 7.95l3 2.34A5.36 5.36 0 0 1 12 6.58z"
                      fill="#EA4335"
                    ></path>
                  </g>
                </svg>
                <span>Log In with Google</span>
              </span>
            </a>
          </div>
          <div className="login-divider-wrapper">
            <span className="login-divider"></span>
            <span className="or">OR</span>
          </div>
          <div className="loginPage__content__username">
            <InputBox
              id="email"
              name="email"
              label=""
              placeholder="Email Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
          </div>
          <div className="loginPage__content__password">
            <InputBox
              id="password"
              name="password"
              label=""
              password={true}
              placeholder="Enter password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              errorMessage={''}
            />
          </div>

          <div className="flex-bar">
            <div className="remember-check">
              <input type="checkbox" id="rememberme" />
              <label htmlFor="rememberme">Remember me</label>
            </div>
            <div
              className="forgot-password"
              onClick={() => navigate('/forgotpassword')}
            >
              Forgot Password
            </div>
          </div>

          <button className="login-button" onClick={() => login(formik.values)}>
            Log In
          </button>

          <div className="signup-link">
            <Link to="/signup" className="signUpButton">
              Sign up
            </Link>{' '}
            to create a new account.
          </div>
        </div>
        {/* <div className="loginPage__content__signup">
            Are you a new user? <a href=""> Sign Up Now</a>
          </div> */}
      </div>
    </form>
  );
};

export default LoginPage;
