import { Link } from 'react-router-dom';
import BrandsBox from '../../atoms/BrandsBox/BrandsBox';
import { useGetDashboard } from '../../hooks/useGetDashboard/useGetDashboard';
import './Brands.scss';
import { FC } from 'react';

const Brands: FC = () => {
  const { data: brandsData } = useGetDashboard();

  return (
    <div className="brands-container">
      <div className="title">
        <div className="heading">
          Brands
          {/* <div className="subheading">
            View and manage all your brands in one place.
          </div> */}
        </div>

        <Link to="/create-brand" className="create-brand">
          <div>Create a brand</div>
        </Link>
      </div>

      <div className="brand-list">
        {brandsData?.map((item, index) => (
          <BrandsBox
            key={`brand_${index}`}
            brandId={item.brandId}
            name={item.brandName}
            //imgName={item.}
            noOfCampaigns={item.noOfCampaigns}
          />
        ))}
      </div>
    </div>
  );
};

export default Brands;
