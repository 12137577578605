import './DatePicker.scss';
export interface DatePickerProps {
  id: string;
  name: string;
  value?: string;
  label: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.ChangeEvent<any>) => void;
}

const DatePicker = (props: DatePickerProps) => {
  return (
    <div className="DatePicker-container">
      <label htmlFor={props.id}>{props.label}</label>

      <input
        type="date"
        id={props.id}
        name={props.name}
        value={props.value}
        min={props.min}
        max={props.max}
        disabled={props.disabled}
        onChange={props.onChange}
        onBlur={props.onBlur}
      ></input>
    </div>
  );
};

export default DatePicker;
