import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { PdfTemplateInfo } from '../../types/campaign';

export const getPdfTemplateInfo = async (
  templateId: any,
): Promise<PdfTemplateInfo> => {
  return templateId && templateId != ''
    ? api
        .get(URLs.GET_PDF_TEMPLATE_INFO(templateId))
        .then((res: any) => res.data)
    : Promise.resolve({
        templateId: '',
        name: '',
        description: '',
        templateType: '',
        charset: '',
        thumbnailUrl: '',
      });
};

export const useGetPdfTemplateInfo = (): UseMutationResult<
  PdfTemplateInfo,
  Error,
  string | null
> => {
  return useMutation(
    [QueryKeys.GetPdfTemplateInfo],
    (templateId: string | null) => getPdfTemplateInfo(templateId),
  );
};
