import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { CampaignInfo } from '../../types/campaign';

export const getCampaignInfo = async (
  campaignId: any,
): Promise<CampaignInfo> => {
  return campaignId && campaignId != ''
    ? api.get(URLs.GET_CAMPAIGN_INFO(campaignId)).then((res: any) => res.data)
    : Promise.resolve({
        brandId: '',
        name: '',
        description: '',
        campaignType: '',
      });
};

export const useGetCampaignInfo = (): UseMutationResult<
  CampaignInfo,
  Error,
  string | null
> => {
  return useMutation([QueryKeys.GetCampaignInfo], (campaignId: string | null) =>
    getCampaignInfo(campaignId),
  );
};
