import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { API_BASE, URLs } from '../../api/URLs';
// import api from '../../api/apifilter';
import { CampaignCssAndPages } from '../../types/campaign';
import axios, { AxiosRequestConfig } from 'axios';

const transformToFormData = (obj: CampaignCssAndPages): FormData => {
  const formData = new FormData();
  // formData.append('campaignId', obj.campaignId);
  const cssBlob = new Blob([obj.cssContent ? obj.cssContent : ''], {
    type: 'text/plain',
  });
  // formData.append('cssContent', blob, 'cssfile.css');
  //formData.append('cssContent', obj.cssContent ? `"${obj.cssContent}"` : '');
  // formData.append('campaignPages[0].content', new Readable().push);
  formData.append('cssContent', cssBlob, 'cssfile.css');

  obj.campaignPages &&
    obj.campaignPages.map((campaignPage, index) => {
      const contentBlob = new Blob([campaignPage.content], {
        type: 'text/plain',
      });

      const jsonBlob = new Blob([campaignPage.json ? campaignPage.json : ''], {
        type: 'text/json',
      });

      const pageCssContentBlob = new Blob(
        [campaignPage.cssContent ? campaignPage.cssContent : ''],
        {
          type: 'text/plain',
        },
      );

      formData.append(
        `campaignPages[${index}].json`,
        jsonBlob,
        `jsonfile_${index}.txt`,
      );

      formData.append(
        `campaignPages[${index}].cssContent`,
        pageCssContentBlob,
        `cssfile_${index}.css`,
      );
      formData.append(
        `campaignPages[${index}].content`,
        contentBlob,
        `content_${index}.html`,
      );

      formData.append(`campaignPages[${index}].name`, campaignPage.name);
      formData.append(
        `campaignPages[${index}].orientation`,
        campaignPage.orientation,
      );
      formData.append(`campaignPages[${index}].index`, `${campaignPage.index}`);
      campaignPage.templateId &&
        formData.append(
          `campaignPages[${index}].templateId`,
          campaignPage.templateId,
        );
    });
  obj.caption && formData.append('caption', obj.caption);
  return formData;
};

export const saveCampaignContent = async (
  campaignCssAndPages: CampaignCssAndPages,
): Promise<string> => {
  const accessToken = localStorage.getItem('user.accessToken');
  const config: AxiosRequestConfig = {
    method: 'post',
    url: API_BASE + URLs.SAVE_CAMPAIGN_PAGES(campaignCssAndPages.campaignId),
    headers: {
      'Content-Disposition': 'form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    data: transformToFormData(campaignCssAndPages),
  };

  return await axios(config).then((res: any) => res.data);
};

export const useSaveCampaignContent = (): UseMutationResult<
  string,
  Error,
  CampaignCssAndPages
> => {
  return useMutation(
    [QueryKeys.SavePdfContent],
    (campaignCssAndPages: CampaignCssAndPages) =>
      saveCampaignContent(campaignCssAndPages),
  );
};
