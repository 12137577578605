import { FC, useEffect, useState } from 'react';
import './PdfTwoTables.scss';
import { TextInput } from 'cm-react-components/dist/js';

export interface PdfTwoTablesProps {
  id: string;
  name: string;
  alignment?: 'Left' | 'Right' | 'Center';
  className?: string;
  setSelectedElementId: (id: string) => void;
  value: string[][];
  onValueChange: (id: string, newValue: string[][]) => void;
  noOfRows: number;
  columns: number;
}

export const PdfTwoTables: FC<PdfTwoTablesProps> = ({
  id,
  name,
  alignment = 'Left',
  className,
  setSelectedElementId,
  value,
  onValueChange,
  noOfRows: rows,
  columns,
}) => {
  const [tableData, setTableData] = useState(value);
  const [editableCell, setEditableCell] = useState<{
    row: number;
    col: number;
  } | null>(null);

  const handleCellValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number,
    col: number,
  ) => {
    const newTableData = value.slice();
    newTableData[row][col] = event.target.value;
    setTableData(newTableData);
  };
  const handleCellClick = (row: number, col: number) => {
    setEditableCell({ row, col });
  };

  useEffect(() => {
    editableCell === null && onValueChange(id, tableData);
  }, [editableCell]);

  const createNewRows = (
    rowCount: number,
    columnCount: number,
    currentRows: number,
  ): string[][] => {
    const data: string[][] = [];

    for (let i = 0; i < rowCount; i++) {
      const row: string[] = [];
      for (let j = 0; j < columnCount + columnCount; j++) {
        row.push(`Row ${i + currentRows} - Col ${j + 1}`);
      }
      data.push(row);
    }

    return data;
  };

  const updateListToSize = (desiredSize: number) => {
    const currentSize = tableData.length;
    if (currentSize < desiredSize) {
      const tempTableData = tableData;
      for (const row of createNewRows(
        desiredSize - tableData.length,
        columns,
        tableData.length,
      )) {
        tempTableData.push([...row]);
      }
      setTableData(tempTableData);
    } else if (currentSize > desiredSize) {
      const updatedItems = tableData.slice(0, desiredSize);
      setTableData(updatedItems);
    }
  };

  useEffect(() => {
    rows && updateListToSize(rows);
  }, [rows]);

  return (
    <div
      // contentEditable
      // suppressContentEditableWarning
      id={id}
      key={id}
      onClick={() => setSelectedElementId(id)}
    >
      <table
        width="100%"
        cellSpacing="0"
        cellPadding="0"
        className="gp-pdf-preview-table"
      >
        <tr>
          <td>
            <table
              width="100%"
              cellSpacing="0"
              cellPadding="0"
              className="fwg-settlement-table"
            >
              <tbody>
                {tableData.slice(0, rows).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.slice(0, columns).map((cell, colIndex) => (
                      <td
                        className="fwg-s-value"
                        key={colIndex}
                        onClick={() => handleCellClick(rowIndex, colIndex)}
                      >
                        {editableCell &&
                        editableCell.row === rowIndex &&
                        editableCell.col === colIndex ? (
                          <TextInput
                            value={cell}
                            onBlur={e => {
                              setEditableCell(null);
                            }}
                            onChange={e => {
                              handleCellValueChange(e, rowIndex, colIndex);
                            }}
                            borderRadius="0px"
                          />
                        ) : (
                          cell
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
          <td>
            <table
              width="100%"
              cellSpacing="0"
              cellPadding="0"
              className="fwg-settlement-table"
            >
              <tbody>
                {tableData.slice(0, rows).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row
                      .slice(columns, columns + columns)
                      .map((cell, colIndex) => (
                        <td
                          className="fwg-s-value"
                          key={colIndex + columns}
                          onClick={() =>
                            handleCellClick(rowIndex, colIndex + columns)
                          }
                        >
                          {editableCell &&
                          editableCell.row === rowIndex &&
                          editableCell.col === colIndex + columns ? (
                            <TextInput
                              value={cell}
                              onBlur={e => {
                                setEditableCell(null);
                              }}
                              onChange={e => {
                                handleCellValueChange(
                                  e,
                                  rowIndex,
                                  colIndex + columns,
                                );
                              }}
                              borderRadius="0px"
                            />
                          ) : (
                            cell
                          )}
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
};

PdfTwoTables.displayName = 'PdfTwoTables';
