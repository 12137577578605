import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Breadcrum } from '../../atoms/Breadcrum/Breadcrum';
import './SelectSystemTemplateList.scss';
import { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../app/store';
import { useGetSystemTemplateList } from '../../hooks/useGetSystemTemplateList/useGetSystemTemplateList';
import { PreviewIframe } from '../../atoms/PreviewIframe/PreviewIframe';
import { updateCampaignTemplateIds } from '../../slices/createCampaignSlice';
import { useGetSystemTemplate } from '../../hooks/useGetSystemTemplate/useGetSystemTemplate';
import { Pagination } from '../../types/campaign';
import { Pagination as PaginationComp } from '../../components/Pagination/Pagination';
import PreviousButton from '../../atoms/PreviousButton/PreviousButton';
import { Next } from '../../atoms/Next/Next';
import { Button } from '../../atoms/Button/Button';

export interface SelectSystemTemplateListProps {
  hideNextButton?: boolean;
  updateSystemTemplateId?: (systemTemplateId: string) => void;
  closeWindow?: () => void;
  addPage?: () => void;
}

export const SelectSystemTemplateList: FC<SelectSystemTemplateListProps> = ({
  hideNextButton = false,
  updateSystemTemplateId,
  closeWindow,
  addPage,
}) => {
  const dispatch = useDispatch();
  const selectedBrand = useSelector(
    (state: RootState) => state.cmsAppSlice.selectedBrand,
  );
  const campaignTemplateIds = useSelector(
    (state: RootState) => state.createCampaignSlice.selectedSystemTemplateIds,
  );
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');
  const [queryParams] = useSearchParams();
  const campaignId =
    queryParams.get('id') && queryParams.get('id') !== null
      ? queryParams.get('id')
      : '';
  const navigate = useNavigate();
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [pagination, setPagination] = useState<Pagination>({
    startIndex: 1,
    endIndex: 1,
    currentPage: 1,
    pageSize: 40,
  });

  const { data: systemTemplateList, mutate: getSystemTemplates } =
    useGetSystemTemplateList();
  const {
    mutate: getSystemTemplate,
    data: systemTemplate,
    // isLoading: loadingSystemTemplate,
  } = useGetSystemTemplate();

  const openPreview = (templateId: string) => {
    getSystemTemplate(templateId);
    setPreviewOpen(true);
  };
  const closePreview = () => {
    setPreviewOpen(false);
  };

  useEffect(() => {
    getSystemTemplates(pagination);
  }, []);

  const renderPreviewOverlay = () => {
    return (
      <div className="preview-overlay">
        <div className="preview-content">
          <div className="add-margin">
            <div className="closePreview">
              <div onClick={closePreview}>x</div>
            </div>
          </div>

          <PreviewIframe
            campaignPages={systemTemplate?.pages ? systemTemplate.pages : []}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="selectSystemTemplateList-container">
      {hideNextButton === false && (
        <Breadcrum pageName={selectedBrand ? selectedBrand : ''} />
      )}
      <div className="flex-style">
        <div className="text-container">
          {/* <Title text={selectedBrand ? selectedBrand : ''} /> */}
          <h3>System Template List</h3>
        </div>
      </div>
      <div className="headings">
        <div>Select</div>
        <div>TagName</div>
        <div>Description</div>
        <div>Category</div>
        <div>Type</div>
        <div>CreationDate</div>
        <div>Preview</div>
      </div>

      <div className="line"></div>
      {systemTemplateList?.records &&
        systemTemplateList.records.map((systemTemplate, index) => (
          <div className="lists" key={`selectsystemtag_${index}`}>
            <input
              type="radio"
              name="selectedTemplateId"
              id="selectedTemplateId"
              value={systemTemplate.templateId}
              onChange={() => setSelectedTemplateId(systemTemplate.templateId)}
            />

            <div className="flex-display">
              {/* <img className="logo" src="brand1.png" alt="Logo" /> */}
              <div className="left-text">{systemTemplate.name}</div>
            </div>

            <div className="middle-text">{systemTemplate.description}</div>
            <div className="middle-text">{systemTemplate.marketingType}</div>
            <div className="middle-text">{systemTemplate.campaignType}</div>

            <div className="middle-text">{systemTemplate.createdAtStr}</div>

            <div className="flex-display">
              <button
                className="eye"
                onClick={() => openPreview(systemTemplate.templateId)}
              >
                👁️
              </button>
            </div>
          </div>
        ))}
      <div>
        <PaginationComp
          updatePagination={setPagination}
          noOfPages={
            systemTemplateList?.noOfPages ? systemTemplateList.noOfPages : 1
          }
        />
      </div>
      {isPreviewOpen && renderPreviewOverlay()}
      {hideNextButton === false && (
        <div className="navigationButtons">
          <Link to={`/datasource?id=${campaignId}`}>
            <PreviousButton />
          </Link>

          <Next
            type={'button'}
            label={'Next'}
            onClick={() => {
              //formik.setActiveButton('save')

              const newMap = new Map<string, string>();
              campaignTemplateIds &&
                campaignTemplateIds.size > 0 &&
                campaignTemplateIds.forEach((value, key) => {
                  newMap.set(key, value);
                });
              newMap.set(campaignId ? campaignId : '', selectedTemplateId);
              dispatch(updateCampaignTemplateIds(newMap));

              navigate(`/contentdesign?id=${campaignId}`);
            }}
          />
        </div>
      )}
      {hideNextButton === true && (
        <div className="navigationButtons">
          <Button
            // type={'button'}
            name={'Add Empty Page'}
            onClick={() => {
              //formik.setActiveButton('save')
              addPage && addPage();
              closeWindow && closeWindow();
            }}
          />
          <Next
            type={'button'}
            label={'Apply'}
            onClick={() => {
              //formik.setActiveButton('save')
              updateSystemTemplateId &&
                updateSystemTemplateId(selectedTemplateId);
              closeWindow && closeWindow();
            }}
          />
        </div>
      )}
    </div>
  );
};
