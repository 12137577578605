import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { API_BASE, URLs } from '../../api/URLs';
import axios, { AxiosRequestConfig } from 'axios';

export const getCampaignReviewPdf = async (
  campaignId: any,
): Promise<ArrayBuffer> => {
  const accessToken = localStorage.getItem('user.accessToken');
  const config: AxiosRequestConfig = {
    method: 'get',
    url: API_BASE + URLs.GET_CAMPAIGN_PDF_REVIEW(campaignId),
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return await axios(config).then((res: any) => res.data);
};

export const useDownloadReviewPdf = (): UseMutationResult<
  ArrayBuffer,
  Error,
  string
> => {
  return useMutation([QueryKeys.GetCampaignReviewPdf], (campaignId: string) =>
    getCampaignReviewPdf(campaignId),
  );
};
