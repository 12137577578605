import { CampaignCssAndPages } from './../../types/campaign';
import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { API_BASE, URLs } from '../../api/URLs';
import axios, { AxiosRequestConfig } from 'axios';

const transformToFormData = (
  obj: CampaignCssAndPages | undefined,
): FormData => {
  const formData = new FormData();
  if (!obj) {
    return formData;
  }

  obj.campaignPages.map((campaignPage, index) => {
    const contentBlob = new Blob([campaignPage.content], {
      type: 'text/plain',
    });

    if (campaignPage.cssContent) {
      const cssContentBlob = new Blob([campaignPage.cssContent], {
        type: 'text/plain',
      });
      formData.append(
        `pdfTemplatePages[${index}].cssContent`,
        cssContentBlob,
        `cssContent_${index}.css`,
      );
    }

    formData.append(
      `pdfTemplatePages[${index}].content`,
      contentBlob,
      `content_${index}.html`,
    );

    formData.append(`pdfTemplatePages[${index}].name`, `${campaignPage.name}`);
    formData.append(
      `pdfTemplatePages[${index}].orientation`,
      `${campaignPage.orientation}`,
    );
    campaignPage.templateId &&
      formData.append(
        `campaignPages[${index}].templateId`,
        `${campaignPage.templateId}`,
      );
  });

  return formData;
};

export const getCampaignPdfPreview = async (
  templateId: any,
  campaignCssAndPages: CampaignCssAndPages | undefined,
): Promise<ArrayBuffer> => {
  const accessToken = localStorage.getItem('user.accessToken');
  const config: AxiosRequestConfig = {
    method: 'post',
    url: API_BASE + URLs.GET_CAMPAIGN_PDF_PREVIEW(templateId),
    responseType: 'blob',
    headers: {
      'Content-Disposition': 'form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    data: transformToFormData(campaignCssAndPages),
  };

  return await axios(config).then((res: any) => res.data);
};

export const useDownloadPreviewPdf = (): UseMutationResult<
  ArrayBuffer,
  Error,
  CampaignCssAndPages
> => {
  return useMutation(
    [QueryKeys.GetCampaignPdfPreview],
    (campaignCssAndPages: CampaignCssAndPages) =>
      getCampaignPdfPreview(
        campaignCssAndPages.campaignId,
        campaignCssAndPages,
      ),
  );
};
