import { FC } from 'react';
import './SocialElementsPanel.scss';

import { SOCIAL_LAYOUT_TYPE } from '../../types/layoutTypes';
import { SocialDesignInfo } from '../../types/social';

export interface SocialElementsPanelProps {
  pageNumber: number;
  hide?: boolean;
  pagesLayoutMap: Map<number, SOCIAL_LAYOUT_TYPE>;
  setLayout: (pageLayouts: Map<number, SOCIAL_LAYOUT_TYPE>) => void;
  pagesDesignIdMap: Map<number, string>;
  setPagesDesignIdMap: (pagesDesignIdMap: Map<number, string>) => void;
  designList: SocialDesignInfo[];
}

export const SocialElementsPanel: FC<SocialElementsPanelProps> = ({
  pageNumber,
  hide = false,
  setLayout,
  pagesLayoutMap,
  pagesDesignIdMap,
  setPagesDesignIdMap,
  designList,
}) => {
  const addUpdatePageLayoutIntMap = (
    pageNumber: number,
    pageLayoutMap: Map<number, SOCIAL_LAYOUT_TYPE>,
    layout: SOCIAL_LAYOUT_TYPE,
  ) => {
    const tempMap = new Map(pageLayoutMap);
    tempMap.set(pageNumber, layout);

    return tempMap;
  };

  const updatePageDesignIdInMap = (
    pageNumber: number,
    designId: string,
    pagesDesignIdMap: Map<number, string>,
    setPagesDesignIdMap: (pagesDesignIdMap: Map<number, string>) => void,
  ) => {
    const tempPagesDesignIdMap = new Map(pagesDesignIdMap);
    tempPagesDesignIdMap.set(pageNumber, designId);

    setPagesDesignIdMap(tempPagesDesignIdMap);
  };

  return hide === false ? (
    <div className="resize-container">
      <div className="cm-element-container">
        {/* <div className="title">
          <Title text="Design Campaign" />
        </div> */}

        <div className="row">
          <div className="cm-col">
            <div className="cm-tabs">
              <div className="social-cm-tab">
                <input type="checkbox" id="chck3" />
                <label className="tab-label" htmlFor="chck3">
                  Templates
                </label>
                <div className="tab-content">
                  <div className="elements-wrapper">
                    {designList?.map((design, index) => (
                      <div
                        className="social_elements"
                        onClick={() => {
                          setLayout(
                            addUpdatePageLayoutIntMap(
                              pageNumber,
                              pagesLayoutMap,
                              design.designName === 'Design Two'
                                ? SOCIAL_LAYOUT_TYPE.LAYOUT_TWO
                                : SOCIAL_LAYOUT_TYPE.LAYOUT_ONE,
                            ),
                          );
                          updatePageDesignIdInMap(
                            pageNumber,
                            design.designId,
                            pagesDesignIdMap,
                            setPagesDesignIdMap,
                          );
                        }}
                        key={`design_${index}`}
                      >
                        <img src="input-text.png" />
                        <div className="element-name">{design.designName}</div>
                      </div>
                    ))}

                    {/* <div
                      className="social_elements"
                      onClick={() =>
                        setLayout(
                          addUpdatePageLayoutIntMap(
                            pageNumber,
                            pagesLayoutMap,
                            SOCIAL_LAYOUT_TYPE.LAYOUT_TWO,
                          ),
                        )
                      }
                    >
                      <img src="input-text.png" />
                      <div className="element-name">Design Two</div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div style={{ display: hide ? 'block' : 'none' }}></div>
  );
};
