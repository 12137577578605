import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CmsApplication } from '../context/cmsContext';

const initialState: CmsApplication = {
  accessToken: '',
  refreshToken: '',
  name: '',
  selectedBrand: '',
};

export const cmsAppSlice = createSlice({
  name: 'cmsApp',
  initialState,
  reducers: {
    updateAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      localStorage.setItem('user.accessToken', `${state.accessToken}`);
      localStorage.setItem('user.isLoggedIn', 'true');
    },
    updateRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    updateSelectedBrand: (state, action: PayloadAction<string>) => {
      state.selectedBrand = action.payload;
    },

    updateSelectedBrandId: (state, action: PayloadAction<string>) => {
      state.selectedBrandId = action.payload;
    },
  },
});

export const {
  updateAccessToken,
  updateRefreshToken,
  updateSelectedBrand,
  updateSelectedBrandId,
} = cmsAppSlice.actions;
