import { FC, useState } from 'react';
import './DesignTwo.scss';

export interface DesignTwoProps {
  values: Map<string, string>;
  setPagesPropsMap: (pagePropsMap: Map<number, Map<string, string>>) => void;
  pagePropsMap: Map<number, Map<string, string>>;
  pageId: number;
}

export const DesignTwo: FC<DesignTwoProps> = ({
  values,
  setPagesPropsMap,
  pagePropsMap,
  pageId,
}) => {
  const [content, setUpdatedValues] = useState<Map<string, string>>(values);
  return (
    <div className="social-component-container">
      <div className="social-template-content">
        <div>
          <svg
            height="50px"
            width="50px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
          >
            <path
              fill="#5286f9"
              d="M402.14,149.373l27.951-27.949c8.82-8.821,8.821-23.123,0-31.944c-8.82-8.821-23.123-8.82-31.944,0
                l-32.005,32.003c-26.008-16.002-55.733-26.514-87.553-29.919V45.176h67.765c12.475,0,22.588-10.114,22.588-22.588
                S358.828,0,346.353,0H256h-90.353c-12.475,0-22.588,10.114-22.588,22.588s10.114,22.588,22.588,22.588h67.765v46.387
                c-31.822,3.405-61.547,13.919-87.555,29.919L113.854,89.48c-8.818-8.821-23.121-8.821-31.944,0c-8.821,8.82-8.821,23.123,0,31.944
                l27.949,27.951c-39.849,38.376-64.682,92.24-64.682,151.802C45.176,417.425,139.752,512,256,512s210.824-94.575,210.824-210.824
                C466.824,241.614,441.99,187.75,402.14,149.373z"
            />
            <path
              fill="#bdfdff"
              d="M256,135.528c-91.338,0-165.647,74.309-165.647,165.649c0,91.338,74.309,165.647,165.647,165.647
                s165.647-74.309,165.647-165.647C421.647,209.837,347.338,135.528,256,135.528z"
            />
            <path
              fill="#2d50a7"
              d="M353.852,278.683h-75.264v-75.267c0-12.475-10.113-22.588-22.588-22.588
                c-12.475,0-22.588,10.114-22.588,22.588v97.855c0,12.475,10.114,22.588,22.588,22.588h97.852c12.475,0,22.588-10.114,22.588-22.588
                C376.44,288.797,366.328,278.683,353.852,278.683z"
            />
            <path
              fill="#81e5ee"
              d="M256,466.824V323.86c-12.475,0-22.588-10.114-22.588-22.588v-97.855
                c0-12.475,10.114-22.588,22.588-22.588v-45.3c-91.338,0-165.647,74.309-165.647,165.649C90.353,392.514,164.662,466.824,256,466.824
                z"
            />
            <path
              fill="#3d6deb"
              d="M90.353,301.176c0-91.339,74.309-165.649,165.647-165.649V0h-90.353
                c-12.475,0-22.588,10.114-22.588,22.588s10.114,22.588,22.588,22.588h67.765v46.387c-31.822,3.405-61.547,13.919-87.555,29.919
                L113.854,89.48c-8.818-8.821-23.121-8.821-31.944,0c-8.821,8.82-8.821,23.123,0,31.944l27.949,27.951
                c-39.849,38.376-64.682,92.24-64.682,151.802C45.176,417.425,139.752,512,256,512v-45.176
                C164.662,466.824,90.353,392.514,90.353,301.176z"
            />
          </svg>
        </div>
        <div>
          <input
            type="text"
            // value="GO HOME, SPORT."
            id={'prop1'}
            value={content.get('prop1')}
            onChange={obj => {
              const tempMap = new Map(content);
              tempMap.set('prop1', obj.target.value);
              setUpdatedValues(tempMap);

              const tempPagePropsMap = new Map(pagePropsMap);
              tempPagePropsMap.set(pageId, tempMap);
              setPagesPropsMap(tempPagePropsMap);
            }}
          />
        </div>
        <div className="social-template-title">
          <textarea
            rows={3}
            id={'prop2'}
            value={content.get('prop2')}
            onChange={obj => {
              const tempMap = new Map(content);
              tempMap.set('prop2', obj.target.value);
              setUpdatedValues(tempMap);

              const tempPagePropsMap = new Map(pagePropsMap);
              tempPagePropsMap.set(pageId, tempMap);
              setPagesPropsMap(tempPagePropsMap);
            }}
          />
          {/* We`ll Take It From Here
          </textarea> */}
        </div>

        <div>
          <div>
            <input
              type="text"
              // value="Pack up! Happy National Leave"
              id={'prop3'}
              value={content.get('prop3')}
              onChange={obj => {
                const tempMap = new Map(content);
                tempMap.set('prop3', obj.target.value);
                setUpdatedValues(tempMap);

                const tempPagePropsMap = new Map(pagePropsMap);
                tempPagePropsMap.set(pageId, tempMap);
                setPagesPropsMap(tempPagePropsMap);
              }}
            />
          </div>
          <div>
            <input
              type="text"
              // value="the Office Early Day!"
              id={'prop4'}
              value={content.get('prop4')}
              onChange={obj => {
                const tempMap = new Map(content);
                tempMap.set('prop4', obj.target.value);
                setUpdatedValues(tempMap);

                const tempPagePropsMap = new Map(pagePropsMap);
                tempPagePropsMap.set(pageId, tempMap);
                setPagesPropsMap(tempPagePropsMap);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
