import { FC } from 'react';

export interface ContentMoveElement {
  selectedElementId: string;
  deleteComponent: (elementId: string) => void;
  moveComponentUp: (elementId: string) => void;
  moveComponentDown: (elementId: string) => void;
}
export const ContentMoveElement: FC<ContentMoveElement> = ({
  selectedElementId,
  deleteComponent,
  moveComponentUp,
  moveComponentDown,
}) => {
  return (
    <div className="cm-component-action-box">
      <div className="cm-component-action">
        <div
          className="cm-actions"
          onClick={() => {
            moveComponentUp(selectedElementId);
          }}
        >
          <svg viewBox="0 0 24 24" fill="none">
            <path
              d="M12 4V20M12 4L8 8M12 4L16 8"
              stroke="#ffffff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Up</span>
        </div>
        <div
          className="cm-actions"
          onClick={() => {
            moveComponentDown(selectedElementId);
          }}
        >
          <svg viewBox="0 0 24 24" fill="none">
            <path
              d="M12 4V20M12 20L8 16M12 20L16 16"
              stroke="#ffffff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span>Down</span>
        </div>
        <div
          className="cm-actions"
          onClick={() => {
            deleteComponent(selectedElementId);
          }}
        >
          <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#ffffff"
              d="M352 192V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64H96a32 32 0 0 1 0-64h256zm64 0h192v-64H416v64zM192 960a32 32 0 0 1-32-32V256h704v672a32 32 0 0 1-32 32H192zm224-192a32 32 0 0 0 32-32V416a32 32 0 0 0-64 0v320a32 32 0 0 0 32 32zm192 0a32 32 0 0 0 32-32V416a32 32 0 0 0-64 0v320a32 32 0 0 0 32 32z"
            />
          </svg>
          <span>Delete</span>
        </div>
      </div>
    </div>
  );
};
