import { ChangeEvent, FC, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Breadcrum } from '../../atoms/Breadcrum/Breadcrum';
import { Button } from 'cm-react-components/dist/js';
import InputBox from '../../atoms/InputBox/InputBox';
// import Stepper from '../../atoms/Stepper/Stepper';
import Textbox from '../../atoms/Textbox/Textbox';
import { Title } from '../../atoms/Title/Title';
import './CreateCampaign.scss';
import { useFormik } from 'formik';
import { createCampaignFormValidation } from './validation';
import { CampaignInfo } from '../../types/campaign';
import { useCreateCampaign } from '../../hooks/useCreateCampaign/useCreateCampaign';
import { updateCreatedCampaignId } from '../../slices/createCampaignSlice';
import { useDispatch } from 'react-redux';
import { Next } from '../../atoms/Next/Next';
import { useGetCampaignInfo } from '../../hooks/useGetCampaignInfo/useGetCampaignInfo';
import { useIsCampaignNameExists } from '../../hooks/useIsCampaignNameExists/useIsCampaignNameExists';
import { CAMPAIGN_TYPE_ICONS } from '../../types/constants';

const CreateCampaign: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const campaignId =
    queryParams.get('id') && queryParams.get('id') !== null
      ? queryParams.get('id')
      : '';
  const templateId =
    queryParams.get('templateid') && queryParams.get('templateid') !== null
      ? queryParams.get('templateid')
      : '';
  const isEditing = campaignId && campaignId !== null;
  const {
    mutate: getCampaignInfo,
    data: campaignInfo,
    isLoading: loading,
  } = useGetCampaignInfo();
  const { mutate: isCampaignNameExists } = useIsCampaignNameExists();

  const { mutate } = useCreateCampaign();
  const handleReset = () => {
    window.location.reload();
  };

  const createCampaign = (values: CampaignInfo) => {
    formik.validateForm().then((result: any) => {
      if (result && Object.keys(result).length > 0) {
        // addToast({
        //   label: `Please pr  ovide mandatory information`,
        //   type: 'error',
        // });
      } else {
        mutate(campaignId ? { ...values, campaignId } : values, {
          onSuccess: data => {
            if (data) {
              dispatch(updateCreatedCampaignId(data));
              navigate(`/contentdesign?id=${data}&templateid=${templateId}`);
            }
          },
          onError: () => {
            // addToast({
            //   label: `New Lead creation failed.`,
            //   type: 'error',
            // });
          },
        });
      }
    });
  };

  const formik = useFormik({
    initialValues:
      loading === false && campaignInfo
        ? campaignInfo
        : {
            //brandId: selectedBrandId ? selectedBrandId : 'cf v',
            name: '',
            description: '',
            templateId: templateId || '',
          },
    enableReinitialize: true,
    validationSchema: createCampaignFormValidation,
    onSubmit: async (values: CampaignInfo) => {
      createCampaign(values);
    },
  });

  const handleCampaignNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (campaignInfo && campaignInfo.name === value) {
      formik.handleChange(e);
      return;
    }
    isCampaignNameExists(
      { name: value },
      {
        onSuccess: data => {
          if (data && data === true) {
            formik.setFieldError('name', 'Campaign Name already exists');
          }
        },
        // onError: () => {},
      },
    );
    formik.handleChange(e);
  };

  useEffect(() => {
    if (queryParams.get('id')) {
      getCampaignInfo(queryParams.get('id'));
      formik.setTouched({});
    }
  }, []);

  return (
    <div className="createCampaign-container">
      <form onSubmit={formik.handleSubmit} className="createCampaign-form">
        <Breadcrum pageName={'Create a Campaign'} />
        <Title text="Please provide campaign details" />
        <div>
          {/* <div className="subHeading">Details</div> */}

          {/* <p className="subHeading-texts">
            To get started, fill out the basic campaign details.
          </p> */}
        </div>
        <div className="grid-layout">
          <div>
            {/* <Stepper step={1} /> */}

            {/* <SelectBox
              id={'brandId'}
              name={'brandId'}
              label={'Brand'}
              list={brands}
              errorMessage={formik.errors.brandId}
              value={
                brandsList &&
                brandsList.find(
                  brand => formik.values.brandId === brand.brandId,
                )
                  ? brandToSelectOption(
                      brandsList.find(
                        brand => formik.values.brandId === brand.brandId,
                      ),
                    )
                  : { label: 'Select brand', value: '' }
              }
              onChange={handleBrandSelectionChange}
            /> */}

            <InputBox
              label="Campaign Name"
              id={'name'}
              name={'name'}
              onChange={handleCampaignNameChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              errorMessage={formik.errors.name}
            />

            {/* <SelectBox
              id={'campaignType'}
              name={'campaignType'}
              label={'Campaign Type'}
              onChange={handleCampaingTypeSelectionChange}
              list={campaignTypes}
              value={campaignTypes.find(
                campaignType =>
                  formik.values.campaignType === campaignType.value,
              )}
            /> */}

            <Textbox
              label={'Description'}
              id={'description'}
              name={'description'}
              row={5}
              placeholder={'Enter the description here'}
              errorMessage={formik.errors.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
            />
          </div>
          <div className="right-image-panel">
            <img src={CAMPAIGN_TYPE_ICONS.PDF} />
          </div>
        </div>

        <div className="navigationButtons">
          <Button label="Reset" onClick={handleReset} size="medium" />

          <Link to="/pdftemplates">
            <Button label="Cancel" size={'medium'} />
          </Link>
          {isEditing && (
            <Link to={`/contentdesign?id=${campaignId}`}>
              <Button label="Skip" size="medium" />
            </Link>
          )}
          <Next type={'submit'} label={isEditing ? 'Update' : 'Next'} />
        </div>
      </form>
    </div>
  );
};

export default CreateCampaign;
