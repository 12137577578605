import { useEffect } from 'react';
import './PrivacyPolicy.scss';
const PrivacyPolicy = () => {
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth > 768) {
        if (window.scrollY > 30) {
          document.querySelector('header')?.classList.add('active');
        } else {
          document.querySelector('header')?.classList.remove('active');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div>
      <header>
        <div className="container">
          <div className="nav-wrapper">
            <div className="logo">
              <a href="index.html">
                <img src="logo-white.png" className="white-logo" />
                <img src="logo.png" className="default-logo" />
              </a>
            </div>
            <nav role="navigation">
              <span className="ic menu" tabIndex={1}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </span>
              <span className="ic close"></span>
              <ul className="main-nav">
                <li className="top-level-link">
                  <a href="about.html">
                    <span>About</span>
                  </a>
                </li>
                <li className="top-level-link">
                  <a className="mega-menu">
                    <span>Services</span>
                  </a>
                  <div className="sub-menu-block">
                    <div className="flex-row">
                      <div className="menu-col">
                        <h2 className="sub-menu-head">Clothing</h2>
                        <ul className="sub-menu-lists">
                          <li>
                            <a href="services.html#cyber-security">
                              <div className="nav-icons">
                                <img src="./images/nav-icon/cybersecurity.png" />
                              </div>
                              <div>
                                <div className="navlist-title">
                                  Cybersecurity
                                </div>
                                <div className="navlist-subtitle">
                                  Detect digital threats early to minimise
                                  damage and fortify your infrastructure.
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="services.html#data-analytics">
                              <div className="nav-icons">
                                <img src="./images/nav-icon/data-analytics.png" />
                              </div>
                              <div>
                                <div className="navlist-title">
                                  Data Analytics
                                </div>
                                <div className="navlist-subtitle">
                                  Transform data into actionable insights for
                                  informed decision-making.
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="services.html#ai">
                              <div className="nav-icons">
                                <img src="./images/nav-icon/ai.png" />
                              </div>
                              <div>
                                <div className="navlist-title">AI</div>
                                <div className="navlist-subtitle">
                                  Transforming tasks into smart, efficient
                                  processes for a future-ready enterprise.
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="services.html#it-consulting">
                              <div className="nav-icons">
                                <img src="./images/nav-icon/it-consulting.png" />
                              </div>
                              <div>
                                <div className="navlist-title">
                                  IT Consulting
                                </div>
                                <div className="navlist-subtitle">
                                  To optimally achieve their business goals by
                                  utilisation of IT.
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="services.html#mobile-application">
                              <div className="nav-icons">
                                <img src="./images/nav-icon/mobile-app-dev.png" />
                              </div>
                              <div>
                                <div className="navlist-title">
                                  Mobile App Development
                                </div>
                                <div className="navlist-subtitle">
                                  A proven methodology for reliable and
                                  predictable Mobile App Development.
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="services.html#digital-transformation">
                              <div className="nav-icons">
                                <img src="./images/nav-icon/digital-transformation.png" />
                              </div>
                              <div>
                                <div className="navlist-title">
                                  Digital Transformation
                                </div>
                                <div className="navlist-subtitle">
                                  Guiding enterprises in their journey,
                                  leveraging the power of technology and
                                  innovation.
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="top-level-link">
                  <a href="success-stories.html" className="mega-menu">
                    <span>Success Stories</span>
                  </a>
                  <div className="sub-menu-block">
                    <div className="flex-row">
                      <div className="menu-col">
                        <h2 className="sub-menu-head">Clothing</h2>
                        <ul className="sub-menu-lists">
                          <li>
                            <a href="success-stories.html#credPortalStory">
                              <div className="nav-icons">
                                <img src="./images/nav-icon/cred-portal.png" />
                              </div>
                              <div>
                                <div className="navlist-title">
                                  Creditor Portal
                                </div>
                                <div className="navlist-subtitle">
                                  Revolutionizing Debt Resolution
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="success-stories.html#campManagerStory">
                              <div className="nav-icons">
                                <img src="./images/nav-icon/camp-manage.png" />
                              </div>
                              <div>
                                <div className="navlist-title">
                                  Campaign Manager
                                </div>
                                <div className="navlist-subtitle">
                                  Elevate Your Campaigns with the Ultimate
                                  Marketing Companion
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="success-stories.html#covidCalcStory">
                              <div className="nav-icons">
                                <img src="./images/nav-icon/covid-calc.png" />
                              </div>
                              <div>
                                <div className="navlist-title">
                                  Covid Calculator
                                </div>
                                <div className="navlist-subtitle">
                                  Transforming tasks into smart, efficient
                                  processes for a future-ready enterprise.
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="success-stories.html#crmStory">
                              <div className="nav-icons">
                                <img src="./images/nav-icon/crm.png" />
                              </div>
                              <div>
                                <div className="navlist-title">CRM</div>
                                <div className="navlist-subtitle">
                                  To optimally achieve their business goals by
                                  utilisation of IT.
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="top-level-link">
                  <a href="case-studies.html">
                    <span>Case Studies</span>
                  </a>
                </li>
                <li className="top-level-link">
                  <a className="mega-menu">
                    <span>Products</span>
                  </a>
                  <div className="sub-menu-block">
                    <div className="flex-row">
                      <div className="menu-col">
                        <h2 className="sub-menu-head">Clothing</h2>
                        <ul className="sub-menu-lists">
                          <li>
                            <a href="product_ncampaignsuite.html">
                              <div className="nav-icons">
                                <img src="./images/nav-icon/camp-manage.png" />
                              </div>
                              <div>
                                <div className="navlist-title">
                                  NCampaign Suite
                                </div>
                                <div className="navlist-subtitle">
                                  Manage your content on single platform.
                                </div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="top-level-link">
                  <a href="contact-us.html">
                    <span>Contact</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>

      <section className="contact banner">
        <div className="container">
          <div className="first-section">
            <h2>Privacy Policy</h2>
            <h1 className="h1-title-bluebg">
              Improving the employee experience &mdash; globally
            </h1>
            <p>
              Nipuna solutions are the partner of choice for most of the UKs
              leading enterprises, SMEs and technology challengers. As a team we
              are here to promote businesses & their value through custom
              software development, product design and consultancy services.
            </p>
          </div>
        </div>
      </section>

      <section className="privacy-sec pad-tb80">
        <div className="container">
          <div className="privacy-title">
            <h1>Introduction</h1>
            <p>
              <strong>Company Name</strong>: Nipuna Solutions
            </p>
            <p>
              <strong>Address</strong>: 20-22 ,Wenlock Road, London, N1 7GU,
              England
            </p>
            <p>
              <strong>Email</strong>: info@nipunasolutions.co.uk
            </p>
            <p>
              <strong>Contact Number</strong>: 0044-7577487858
            </p>
            <p>
              This Privacy Policy outlines how Nipuna Solutions (`we`, `us`, or
              `our`) protects and handles your personal data in accordance with
              the General Data Protection Regulation (GDPR). It explains how we
              collect, use, disclose, and store data, as well as how you can
              exercise your rights regarding your personal information. By using
              our website, services, or providing personal data to us, you agree
              to the terms outlined in this policy.
            </p>
            <p>
              In accordance with the General Data Protection Regulation (GDPR)
              of April 27, 2016, we dedicate ourselves to protecting the
              personal information of users.
            </p>
            <p>
              As part of our efforts to protect your information, we would like
              to inform you that the data you provide to us will be stored and
              processed in the EU-London zone.
            </p>
            <p>
              Depending on whether you are a publisher, advertiser, or other
              content provider with a contractual relation to Nipuna Solutions,
              we may utilize the information acquired through the Sites and
              Services in a wide range of ways.
            </p>
            <p>
              To protect your data, we follow a rigid policy, which is as
              follows:
            </p>
            <ul>
              <li>Your personal information is not sold to any parties.</li>
              <li>
                We constantly ensure that your personal information is secure.
              </li>
            </ul>
          </div>
          <h2>Data Protection</h2>
          <p>
            When we disclose your information to third parties as described
            above, we require them to use it only in accordance with this
            Privacy Policy, our contractual specifications, and for the purposes
            that we determine are appropriate in accordance with this Privacy
            Policy.
          </p>
          <p>
            We do not sell your personal data to third parties. However, we may
            share certain information with third parties for specific purposes
            outlined in this Privacy Policy.
          </p>
          <p>
            We may disclose your information under the following circumstances:
          </p>
          <ul>
            <li>
              <strong>Business transfers</strong>: In the event of a merger,
              acquisition, or sale of assets, we may transfer data to the
              acquiring entity.
            </li>
            <li>
              <strong>Legal compliance</strong>: We may disclose data to comply
              with applicable laws, regulations, or legal processes.
            </li>
            <li>
              <strong>Protecting rights and interests</strong>: When it seems
              necessary, data may be disclosed to investigate and prevent
              illegal activities, fraud, or potential threats to safety.
            </li>
            <li>
              <strong>Aggregate and de-identified information</strong>: We may
              share non-personal data for marketing, advertising, or statistical
              purposes.
            </li>
          </ul>

          <h2>Types of data collected</h2>
          <p>
            We collect various types of data from users, including but not
            limited to:
          </p>
          <ul>
            <li>
              Contact information (name, email address, mailing address, phone
              number).
            </li>
            <li>Connection data (IP address, browser data, cookies).</li>
            <li>Billing information (billing address, payment details).</li>
            <li>
              Gender and birth date (optional and for demographic analysis
              only).
            </li>
            <li>
              Database details, ftp, S3, and browser details (S3, Ftp, and
              database details are password protected).
            </li>
          </ul>

          <h2>Methods of data collection</h2>
          <p>We collect personal data through the following means</p>
          <ul>
            <li>
              User-submitted information: When you create an account or use our
              services, you may provide us with personal data.
            </li>
            <li>
              Cookies and tracking technologies: We may use cookies and similar
              technologies to collect information about your interactions with
              our website.
            </li>
          </ul>

          <p>We collect this data for the following purposes:</p>
          <ul>
            <li>
              User registration (creation of a user account) and authentication.
            </li>
            <li>
              Providing our services and fulfilling contractual obligations.
            </li>
            <li>
              Tailoring content and user experiences to improve our services.
            </li>
            <li>
              Conducting surveys and evaluations to enhance user satisfaction.
            </li>
            <li>Improving the quality and functionality of our platform.</li>
            <li>Managing user accounts, rights requests, and disputes.</li>
            <li>Complying with legal and regulatory obligations.</li>
            <li>
              Creating trade statistics and setting up marketing operations.
            </li>
            <li>Social network interaction.</li>
            <li>
              Broadcasting of information and newsletters with a commercial
              focus.
            </li>
          </ul>

          <h2>Legal Basis for Processing:</h2>
          <p>
            The processing of your personal data is based on one or more of the
            following legal grounds:
          </p>
          <ul>
            <li>Your consent, when you voluntarily provide personal data.</li>
            <li>The necessity to perform a contract with you.</li>
            <li>
              Our legitimate interests, such as improving our services and
              marketing activities.
            </li>
          </ul>

          <h2>Data Utilization for Social Media Campaigns</h2>
          <p>
            we use the collected data for social media campaigning purposes in
            the following ways:
          </p>
          <ul>
            <li>
              Targeted Advertising: We use the collected data to deliver
              personalized advertisements to users on social media platforms.
            </li>
            <li>
              Audience Analysis: The collected data helps us understand our
              audience better.
            </li>
            <li>
              Customer Engagement: We utilize the collected data to engage with
              our customers and social media followers. This includes responding
              to inquiries, comments, and feedback, as well as providing
              personalized recommendations or offers based on their interests
              and past interactions.
            </li>
            <li>
              Social Media Campaign Optimization: The collected data helps us
              evaluate the performance of our social media campaigns.
            </li>
          </ul>
          <p>
            It`s important to note that we handle the collected data
            responsibly, ensuring compliance with applicable data protection
            laws and regulations. We prioritize the privacy and security of our
            users, and we do not sell or share personal data with third parties
            for their own direct marketing purposes without your explicit
            consent.
          </p>

          <h2>Disclosing Your Information</h2>
          <p>
            When we disclose your information to third parties as described
            above, we required them to use it only in accordance with this
            Privacy Policy, our contractual specifications, and for the purposes
            that we determine are appropriate in accordance with this Privacy
            Policy.
          </p>
          <p>
            We do not sell your personal data to third parties. However, we may
            share certain information with third parties for specific purposes
            outlined in this Privacy Policy.
          </p>
          <p>
            We may disclose your information under the following circumstances:
          </p>
          <ul>
            <li>
              <strong>Business transfers</strong> In the event of a merger,
              acquisition, or sale of assets, we may transfer data to the
              acquiring entity.
            </li>
            <li>
              <strong>Legal compliance</strong> We may disclose data to comply
              with applicable laws, regulations, or legal processes.
            </li>
            <li>
              <strong>Protecting rights and interests</strong> When it seems
              necessary, data may be disclosed to investigate and prevent
              illegal activities, fraud, or potential threats to safety.
            </li>
            <li>
              <strong>Aggregate and de-identified information</strong> We may
              share non-personal data for marketing, advertising, or statistical
              purposes.
            </li>
          </ul>

          <h2>Data security</h2>
          <p>
            We implement security measures to protect your data from
            unauthorized access, disclosure, alteration, or destruction.
          </p>
          <p>
            Your data is stored on secure Amazon Web Services servers, ensuring
            a high level of security.
          </p>
          <p>
            Your Data is securely transmitted over the Internet via an HTTPS
            connection secured by an SSL certificate (Base-64 Encryption). Your
            username and password, which must be strong and not shared,
            safeguard access to your Campaign Suite account.
          </p>

          <h3>Third-Party Access</h3>
          <p>
            We may share user information with trusted third parties, including
            service providers and partners who assist us in delivering our
            services.
          </p>
          <p>
            These parties will only use the information in accordance with our
            instructions and applicable laws.
          </p>
          <p>
            We may collaborate with various third parties to support our social
            media campaigns and advertising efforts. Below are the types of
            third parties we may engage with and how they contribute to our
            activities:
          </p>
          <ul>
            <li>
              <strong>Social Media Platforms:</strong> We interact with popular
              social media platforms, such as Facebook, Instagram, and others.
            </li>
            <li>
              <strong>Content Creation and Management Services:</strong> In some
              cases, we may partner with content creation and management
              services to develop engaging social media content. These services
              may include graphic designers, copywriters, and social media
              management tools.
            </li>
            <li>
              <strong>Compliance and Legal Entities:</strong> In certain
              situations, we may need to engage compliance and legal entities to
              ensure our social media campaigns adhere to relevant laws and
              regulations.
            </li>
            <li>
              <strong>Customer Support Platforms:</strong> To ensure timely and
              effective customer support on social media, we may integrate with
              customer support platforms. These platforms help us respond to
              inquiries, comments, and direct messages promptly.
            </li>
            <li>
              <strong>Research and Survey Partners:</strong> We may collaborate
              with research and survey partners to conduct market research or
              gather feedback from our social media audience. This data helps us
              understand customer preferences and improve our offerings.
            </li>
            <li>
              <strong>Public Relations (PR) Agencies:</strong> PR agencies might
              be involved in managing our social media presence, engaging with
              influencers, and handling public relations efforts related to our
              social media campaigns.
            </li>
          </ul>
          <p>
            Please note that while we may share data with these third parties
            for specific purposes, we ensure that they handle your information
            responsibly and in accordance with our privacy policy. We do not
            sell, rent, or trade your personal data with third parties for their
            marketing purposes. Your privacy and trust are essential to us, and
            we take measures to protect your data throughout these partnerships.
            If you have any concerns or questions regarding our engagement with
            third parties, please feel free to contact us at{' '}
            <a href="mailto:info@nipunasolutions.co.uk">
              info@nipunasolutions.co.uk
            </a>
          </p>

          <h2>Data Retention</h2>
          <p>
            We retain user data for a period of 180 days from the date of
            service access discontinuation, unless otherwise required for legal,
            administrative, or evidentiary purposes.
          </p>
          <p>
            Users are notified that following are the exceptions to the above
            sentence:
          </p>
          <ul>
            <li>
              For a duration of five (5) years, collected billing
              information—including user account information on the Nipuna
              Solutions billing system—is maintained.
            </li>
            <li>
              For ten (10) years, invoices related to user accounts are
              maintained.
            </li>
            <li>
              Cookies are maintained for a maximum of thirteen (13) months
              following their first deposit in the user`s terminal equipment.
            </li>
          </ul>
          <h3>Data Retention Criteria</h3>
          <p>
            We believe in maintaining transparency and ensuring that your
            personal information is retained only for as long as necessary to
            fulfill the purposes for which it was collected. Our data retention
            period is determined by several criteria that help us strike a
            balance between providing excellent service to you and safeguarding
            your privacy:
          </p>
          <ol>
            <li>
              <strong>Purpose of Data Collection:</strong> We assess the primary
              purpose for which the data was collected. Once that purpose is
              fulfilled, and we no longer need the information to serve its
              original intent, we will promptly dispose of or anonymize the
              data.
            </li>
            <li>
              <strong>Legal Requirements:</strong> We adhere to applicable laws
              and regulations governing data retention. If there are specific
              legal obligations requiring us to retain certain data for a
              designated period, we will comply with such requirements.
            </li>
            <li>
              <strong>Business Needs:</strong> We evaluate our business
              requirements and may retain certain data for the purpose of
              financial record-keeping, audit trails, or other legitimate
              business purposes.
            </li>
            <li>
              <strong>Customer Relationship:</strong> If you are a registered
              user or customer, we may retain your data for as long as you
              maintain an active account or continue to engage with our
              services.
            </li>
            <li>
              <strong>Consent and Marketing Preferences:</strong> If you have
              provided explicit consent for specific data processing activities
              or marketing communications, we will retain your data as long as
              your consent is valid. You have the right to withdraw your consent
              at any time, and upon doing so, we will erase or anonymize the
              relevant data.
            </li>
            <li>
              <strong>Data Security and Integrity:</strong> We take data
              security seriously. Some data may be retained in backup systems or
              archives to ensure data integrity, prevent accidental loss, or
              protect against security breaches.
            </li>
            <li>
              <strong>Statistical Analysis and Research:</strong> Aggregated and
              anonymized data may be retained for statistical analysis, market
              research, or business planning purposes. This data will not
              contain personally identifiable information.
            </li>
            <li>
              <strong>User Rights and Requests:</strong> If you exercise your
              rights to access, correct, or delete your personal data, we will
              fulfill your request promptly, which may involve deleting relevant
              data unless retention is required for legal or legitimate business
              purposes.
            </li>
            <li>
              <strong>Data Minimization Principle:</strong> We follow the
              principle of data minimization, meaning we only collect and retain
              data that is relevant and necessary for the specified purposes.
            </li>
          </ol>
          <p>
            Overall, our data retention period is regularly reviewed and updated
            to align with our data management policies, regulatory requirements,
            and best practices in data privacy. We are committed to ensuring
            that your personal information is retained responsibly and used in
            ways that respect your privacy preferences. If you have any
            questions or concerns regarding our data retention practices, please
            reach out to us at info@nipunasolutions.co.uk.
          </p>

          <h2>Facebook privacy guidelines</h2>

          <ol>
            <li>
              <strong>Data Collection and Usage:</strong> We follow Facebook
              guidelines while collecting data from users which can include
              personal information, location data, activity data, and more. We
              use the information we collect to create and manage user account,
              personalize user’s experience on our app by suggesting relevant
              content, and enhance our services based on your usage patterns.
            </li>
            <li>
              <strong>Legal Requirements:</strong> We adhere to applicable laws
              and regulations governing data retention. If there are specific
              legal obligations requiring us to retain certain data for a
              designated period, we will comply with such requirements.
            </li>
            <li>
              <strong>Privacy Settings and Controls:</strong> As per Facebook
              guidelines, they offer a range of privacy settings and controls
              that allow users to customize who can see their posts, who can
              send friend requests, and also explains how users can manage their
              visibility and protect their personal information.
            </li>
            <li>
              <strong>Data Sharing with Third Parties:</strong> We value privacy
              and transparency. In line with Facebook`s guidelines, we want to
              inform about how we share data with third parties. We may share
              certain user data with trusted partners, including advertisers and
              developers, to enhance your experience and improve our services.
              Rest assured, your personal information remains secure, and we
              ensure that these partners adhere to strict privacy standards.
              Users have the ability to manage your app permissions and control
              the extent to which your data is shared
            </li>
            <li>
              <strong>Security Measures:</strong> Safeguarding your data is our
              utmost priority. We take comprehensive security measures to ensure
              the integrity, confidentiality, and availability of the
              information you entrust to us. Our commitment to data security
              aligns with the standards set by Facebook, ensuring that your
              privacy is always respected.
            </li>
            <li>
              <strong>Cookies and Tracking Technologies:</strong> Facebook uses
              cookies and tracking technologies for analytics to improve user
              experiences and measure ad effectiveness.
            </li>
            <li>
              <strong>Review or Deletion of Data:</strong> We understand the
              importance of giving our users control over their data. If a user
              decides to discontinue using our services or delete any of his
              data, we want to make the process of data deletion as
              straightforward as possible.
            </li>
            <li>
              <strong>Legal Requirements and Data Requests:</strong> Facebook
              may discuss their obligations to respond to legal requests for
              user data from law enforcement or government authorities. They
              might explain how they review and respond to such requests while
              protecting user privacy. Our aim is to balance our
              responsibilities to both users and the law, ensuring that data
              disclosures are transparent, limited, and respectful of user
              rights. By adhering to these principles, we strive to maintain the
              trust of our users while fulfilling our legal obligations.
            </li>
            <li>
              <strong>Notification of Privacy Changes:</strong> Facebook
              typically informs users about any changes to their privacy
              practices. This includes updating users about policy changes and
              providing options if users disagree with the changes.
            </li>
            <li>
              <strong>Contact Information:</strong> Facebook provides ways for
              users to contact them with privacy-related questions or concerns.
              This could include a dedicated privacy contact email or support
              channels.
            </li>
            <li>
              <strong>Effective Date:</strong> Facebook`s privacy policy usually
              includes the effective date of the policy, helping users
              understand when the policy was last updated.
            </li>
          </ol>

          <p>
            The specifics of these guidelines might change over time, so it`s
            always a good practice to refer directly to Facebook`s official
            privacy policy for the most accurate and current information.
          </p>

          <h2>Consent and Users rights</h2>

          <p>
            We automatically collect certain user information when you interact
            with our Services.
          </p>
          <p>
            Users are notified of the Privacy Policy before agreeing to a
            contract with Nipuna Solutions.
          </p>
          <p>
            We use your information to deliver and enhance the functionality of
            our Services and personalize your experience.
          </p>
          <p>
            We may use your information to communicate with you, respond to your
            inquiries, and provide important updates and information.
            <br />
            With your express consent, we may send you promotional materials and
            newsletters related to our Services.
          </p>
          <p>
            Users who share the personal information of a third party must
            confirm that they have obtained the necessary consent from the third
            party to allow us to use, publish, and/or distribute the information
            in accordance with this Privacy Policy.
          </p>
          <p>
            In accordance with Act no. 78-17 of 6 January 1978 on data
            processing, files and freedoms modified by the law 2004-801 of 6
            August 2004, and the Regulation (EU) 2016/679 on the protection of
            natural persons with regard to the processing of personal data and
            on the free movement of such data, users have the following rights
            concerning their personal data:
          </p>
          <ul>
            <li>Right to access and obtain a copy of their data.</li>
            <li>Right to rectify any inaccurate or incomplete information.</li>
            <li>Right to request the deletion of their data.</li>
            <li>
              Right to object to the use of their data for specific purposes.
            </li>
            <li>Right to limit the processing of their data.</li>
            <li>Right to data portability.</li>
          </ul>

          <h3>User Rights Regarding Personal Data</h3>
          <p>
            We are committed to respecting your rights and ensuring that you
            have control over your personal data. We acknowledge and uphold the
            following user rights regarding their personal data:
          </p>

          <ul>
            <li>
              <strong>Right to Access and Obtain a Copy of Data:</strong>
            </li>
          </ul>
          <p>
            You have the right to request access to the personal data we hold
            about you. Upon receiving such a request, we will provide you with a
            copy of the information we have within a reasonable timeframe. This
            enables you to review and verify the accuracy and lawfulness of the
            data processing.
          </p>

          <ul>
            <li>
              <strong>
                Right to Rectify Inaccurate or Incomplete Information:
              </strong>
            </li>
          </ul>
          <p>
            If you believe that the personal data we hold about you is
            inaccurate, outdated, or incomplete, you have the right to request
            corrections or updates. We will promptly make the necessary
            amendments to ensure the information is accurate and up-to-date.
          </p>

          <ul>
            <li>
              <strong>Right to Request the Deletion of Data:</strong>
            </li>
          </ul>
          <p>
            You have the right to request the erasure of your personal data
            under certain circumstances, such as when the data is no longer
            necessary for the purposes for which it was collected, or when you
            withdraw your consent. We will assess the validity of your request
            and take appropriate action in accordance with applicable laws and
            regulations.
          </p>

          <ul>
            <li>
              <strong>Right to Object to Specific Data Processing:</strong>
            </li>
          </ul>
          <p>
            You have the right to object to the processing of your personal data
            based on our legitimate interests or for direct marketing purposes.
            We will review your objection and cease the processing unless we can
            demonstrate compelling legitimate grounds that override your
            interests, rights, and freedoms.
          </p>

          <ul>
            <li>
              <strong>Right to Limit the Processing of Data:</strong>
            </li>
          </ul>
          <p>
            You may have the right to request a limitation on the processing of
            your personal data in certain situations. This right applies, for
            example, when you contest the accuracy of the data, the processing
            is unlawful, or we no longer need the data for the original purpose
            but you require it for legal claims.
          </p>

          <ul>
            <li>
              <strong>Right to Data Portability:</strong>
            </li>
          </ul>
          <p>
            You have the right to receive your personal data in a structured,
            commonly used, and machine-readable format. If technically feasible,
            we can transmit this data to another data controller at your
            request.
          </p>
          <p>
            Users wishing to assert any of their rights or delete any of their
            information or opt out of marketing communications, may send their
            request by email providing necessary information of their
            identification: name, email at the following address:
            info@nipunasolutions.co.uk
          </p>

          <h2>Cookies</h2>
          <p>
            We use cookies to enhance your browsing experience on our platform.
            These cookies track your device`s navigation and may store
            information such as language preferences, display settings, form
            data, and authentication details.
          </p>
          <p>
            You have the option to manage and modify your cookie preferences
            through your web browser settings. You can choose to accept or
            reject cookies or be prompted for consent before cookies are stored
            on your device.
          </p>
          <p>
            we use cookies and similar tracking technologies to enhance your
            browsing experience and improve our services. These technologies
            help us understand how you interact with our website and enable us
            to provide personalized content and advertisements.
          </p>

          <h3>Purpose of Using Cookies:</h3>
          <ol>
            <li>
              <strong>Analytics:</strong> We use cookies to gather data about
              website traffic and user behavior. This information allows us to
              analyze website performance, identify areas for improvement, and
              optimize user experience.
            </li>
            <li>
              <strong>Targeted Advertising:</strong> Cookies help us deliver
              targeted advertisements based on your interests and browsing
              habits. This ensures that you receive relevant content and
              promotions.
            </li>
          </ol>

          <p>Cookies allow us to:</p>
          <ul>
            <li>
              Generate statistics and analyze traffic to enhance service
              quality.
            </li>
            <li>
              Customize the platform`s presentation based on your preferences.
            </li>
            <li>
              Save information related to forms you filled or products you
              selected.
            </li>
            <li>
              Facilitate access to personal spaces with login credentials.
            </li>
          </ul>

          <h3>How Users Can Manage Their Cookie Preferences:</h3>
          <p>
            You have the option to manage your cookie preferences. Most web
            browsers allow you to control cookie settings through the browser
            settings. You can choose to accept or reject cookies or receive
            notifications when cookies are being used. Please note that blocking
            certain types of cookies may impact your experience on our website.
          </p>

          <h3>Security Measures:</h3>
          <p>
            We prioritize the security and confidentiality of your personal
            data. We have implemented various security measures to protect your
            information from unauthorized access, alteration, disclosure, or
            destruction. These measures include:
          </p>
          <ol>
            <li>
              <strong>Encryption:</strong> We use industry-standard encryption
              protocols to secure data during transmission.
            </li>
            <li>
              <strong>Access Controls:</strong> Access to personal data is
              restricted to authorized personnel only and based on the principle
              of least privilege.
            </li>
            <li>
              <strong>Firewalls and Intrusion Detection Systems:</strong> Our
              network infrastructure is equipped with firewalls and intrusion
              detection systems to safeguard against external threats.
            </li>
            <li>
              <strong>Regular Audits and Assessments:</strong> We conduct
              regular security audits and assessments to identify and address
              potential vulnerabilities.
            </li>
          </ol>

          <h3>Data Breach Handling:</h3>
          <p>
            In the unfortunate event of a data breach, we have a comprehensive
            incident response plan in place. If a breach occurs, we will
            promptly investigate, take necessary remedial actions, and notify
            affected individuals and authorities as required by applicable laws
            and regulations.
          </p>

          <h3>Children`s Privacy:</h3>
          <p>
            We are committed to protecting the privacy of children. Our services
            are not directed to children under the age of 13, and we do not
            knowingly collect personal data from individuals under this age. If
            we become aware that a child under 13 has provided us with personal
            data, we will take immediate steps to delete the information from
            our systems.
          </p>

          <h3>Age Restrictions:</h3>
          <p>
            To use our services, you must be at least 18 years old or the age of
            majority in your jurisdiction. If you are under the required age,
            you may only use our services with the consent of a parent or
            guardian.
          </p>

          <h2>Policy Updates</h2>
          <p>
            We reserve the right to update or modify this Privacy Policy as
            necessary to reflect changes in our website, services, or legal
            obligations. The `Last Updated`` date at the top of the policy
            indicates the latest revision. We encourage you to review the policy
            periodically for any updates.
          </p>

          <h2>Contacting Us</h2>
          <p>
            If you have any questions, concerns, or requests related to this
            Privacy Policy or your personal data, please contact us at
            info@nipunasolutions.co.uk. We will make reasonable efforts to
            address your inquiries promptly.
          </p>
          <p>
            Thank you for reading our Privacy Policy. We prioritize the
            protection of your personal data and strive to ensure your privacy
            rights are respected.
          </p>

          <hr />
          <h6 style={{ textAlign: 'right' }}>Modified on January 12, 2024</h6>
        </div>
      </section>

      <footer>
        <div className="container">
          <div className="footer-wrapper">
            <div className="footer-intro-column">
              <div className="footer-logo">
                <img src="logo-white.png" />
              </div>
              <p>Crafting IT Excellence for your Business.</p>
            </div>
            <div className="footer-column">
              <h2 className="footer-title">Links</h2>
              <ul className="footer-links">
                <li>
                  <a href="index.html">Home</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Nipuna Insights</a>
                </li>
                <li>
                  <a href="javascript:void(0)">Press Release</a>
                </li>
                <li>
                  <a href="contact-us.html">Contact Us</a>
                </li>
                <li className="marg-b0">
                  <a href="privacy-policy.html">Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div className="footer-column">
              <h2 className="footer-title">Services</h2>
              <ul className="footer-links">
                <li>
                  <a href="services.html#cyber-security">Cybersecurity</a>
                </li>
                <li>
                  <a href="services.html#data-analytics">Data Analytics</a>
                </li>
                <li>
                  <a href="services.html#ai">AI</a>
                </li>
                <li>
                  <a href="services.html#it-consulting">IT Consulting</a>
                </li>
                <li>
                  <a href="services.html#mobile-application">
                    Mobile App Development
                  </a>
                </li>
                <li className="marg-b0">
                  <a href="services.html#digital-transformation">
                    Digital Transformation
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-column">
              <h2 className="footer-title">Social Links</h2>
              <ul className="footer-social-links">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100073958389457/"
                    target="_blank"
                    className="facebook"
                    rel="noreferrer"
                  >
                    <svg
                      fill="#000000"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="-143 145 512 512"
                      xmlSpace="preserve"
                    >
                      {' '}
                      <g>
                        {' '}
                        <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M272.8,560.7 c-20.8,20.8-44.9,37.1-71.8,48.4c-27.8,11.8-57.4,17.7-88,17.7c-30.5,0-60.1-6-88-17.7c-26.9-11.4-51.1-27.7-71.8-48.4 c-20.8-20.8-37.1-44.9-48.4-71.8C-107,461.1-113,431.5-113,401s6-60.1,17.7-88c11.4-26.9,27.7-51.1,48.4-71.8 c20.9-20.8,45-37.1,71.9-48.5C52.9,181,82.5,175,113,175s60.1,6,88,17.7c26.9,11.4,51.1,27.7,71.8,48.4 c20.8,20.8,37.1,44.9,48.4,71.8c11.8,27.8,17.7,57.4,17.7,88c0,30.5-6,60.1-17.7,88C309.8,515.8,293.5,540,272.8,560.7z" />{' '}
                        <path d="M146.8,313.7c10.3,0,21.3,3.2,21.3,3.2l6.6-39.2c0,0-14-4.8-47.4-4.8c-20.5,0-32.4,7.8-41.1,19.3 c-8.2,10.9-8.5,28.4-8.5,39.7v25.7H51.2v38.3h26.5v133h49.6v-133h39.3l2.9-38.3h-42.2v-29.9C127.3,317.4,136.5,313.7,146.8,313.7z" />
                      </g>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/nipunasolutions/"
                    target="_blank"
                    className="linkedin"
                    rel="noreferrer"
                  >
                    <svg
                      fill="#000000"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="-143 145 512 512"
                      xmlSpace="preserve"
                    >
                      {' '}
                      <g>
                        {' '}
                        <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M272.8,560.7 c-20.8,20.8-44.9,37.1-71.8,48.4c-27.8,11.8-57.4,17.7-88,17.7c-30.5,0-60.1-6-88-17.7c-26.9-11.4-51.1-27.7-71.8-48.4 c-20.8-20.8-37.1-44.9-48.4-71.8C-107,461.1-113,431.5-113,401s6-60.1,17.7-88c11.4-26.9,27.7-51.1,48.4-71.8 c20.9-20.8,45-37.1,71.9-48.5C52.9,181,82.5,175,113,175s60.1,6,88,17.7c26.9,11.4,51.1,27.7,71.8,48.4 c20.8,20.8,37.1,44.9,48.4,71.8c11.8,27.8,17.7,57.4,17.7,88c0,30.5-6,60.1-17.7,88C309.8,515.8,293.5,540,272.8,560.7z" />{' '}
                        <rect x="-8.5" y="348.4" width="49.9" height="159.7" />{' '}
                        <path d="M15.4,273c-18.4,0-30.5,11.9-30.5,27.7c0,15.5,11.7,27.7,29.8,27.7h0.4c18.8,0,30.5-12.3,30.4-27.7 C45.1,284.9,33.8,273,15.4,273z" />{' '}
                        <path d="M177.7,346.9c-28.6,0-46.5,15.6-49.8,26.6v-25.1H71.8c0.7,13.3,0,159.7,0,159.7h56.1v-86.3c0-4.9-0.2-9.7,1.2-13.1 c3.8-9.6,12.1-19.6,27-19.6c19.5,0,28.3,14.8,28.3,36.4v82.6H241v-88.8C241,369.9,213.2,346.9,177.7,346.9z" />{' '}
                      </g>{' '}
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/nipunasolutions/"
                    target="_blank"
                    className="instagram"
                    rel="noreferrer"
                  >
                    <svg
                      fill="#000000"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="-143 145 512 512"
                      xmlSpace="preserve"
                    >
                      {' '}
                      <g>
                        {' '}
                        <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M272.8,560.7 c-20.8,20.8-44.9,37.1-71.8,48.4c-27.8,11.8-57.4,17.7-88,17.7c-30.5,0-60.1-6-88-17.7c-26.9-11.4-51.1-27.7-71.8-48.4 c-20.8-20.8-37.1-44.9-48.4-71.8C-107,461.1-113,431.5-113,401s6-60.1,17.7-88c11.4-26.9,27.7-51.1,48.4-71.8 c20.9-20.8,45-37.1,71.9-48.5C52.9,181,82.5,175,113,175s60.1,6,88,17.7c26.9,11.4,51.1,27.7,71.8,48.4 c20.8,20.8,37.1,44.9,48.4,71.8c11.8,27.8,17.7,57.4,17.7,88c0,30.5-6,60.1-17.7,88C309.8,515.8,293.5,540,272.8,560.7z" />{' '}
                        <path d="M191.6,273h-157c-27.3,0-49.5,22.2-49.5,49.5v52.3v104.8c0,27.3,22.2,49.5,49.5,49.5h157c27.3,0,49.5-22.2,49.5-49.5V374.7 v-52.3C241,295.2,218.8,273,191.6,273z M205.8,302.5h5.7v5.6v37.8l-43.3,0.1l-0.1-43.4L205.8,302.5z M76.5,374.7 c8.2-11.3,21.5-18.8,36.5-18.8s28.3,7.4,36.5,18.8c5.4,7.4,8.5,16.5,8.5,26.3c0,24.8-20.2,45.1-45.1,45.1C88,446.1,68,425.8,68,401 C68,391.2,71.2,382.1,76.5,374.7z M216.1,479.5c0,13.5-11,24.5-24.5,24.5h-157c-13.5,0-24.5-11-24.5-24.5V374.7h38.2 c-3.3,8.1-5.2,17-5.2,26.3c0,38.6,31.4,70,70,70c38.6,0,70-31.4,70-70c0-9.3-1.9-18.2-5.2-26.3h38.2V479.5z" />{' '}
                      </g>{' '}
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/nipunaukltd/"
                    target="_blank"
                    className="twitter"
                    rel="noreferrer"
                  >
                    <svg
                      fill="#000000"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      // eslint-disable-next-line react/no-unknown-property
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      //   style="enable-background:new 0 0 512 512;"
                      xmlSpace="preserve"
                    >
                      {' '}
                      <g>
                        {' '}
                        <path d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M415.8,415.7 c-20.8,20.8-44.9,37.1-71.8,48.4c-27.8,11.8-57.4,17.7-88,17.7c-30.5,0-60.1-6-88-17.7c-26.9-11.4-51.1-27.7-71.8-48.4 c-20.8-20.8-37.1-44.9-48.4-71.8C36,316.1,30,286.5,30,256s6-60.1,17.7-88c11.4-26.9,27.7-51.1,48.4-71.8 c20.9-20.8,45-37.1,71.9-48.5C195.9,36,225.5,30,256,30s60.1,6,88,17.7c26.9,11.4,51.1,27.7,71.8,48.4 c20.8,20.8,37.1,44.9,48.4,71.8c11.8,27.8,17.7,57.4,17.7,88c0,30.5-6,60.1-17.7,88C452.8,370.8,436.5,395,415.8,415.7z" />{' '}
                      </g>{' '}
                      <g>
                        {' '}
                        <path d="M331.8,128h39.7l-86.7,99.1l102,134.8h-79.8l-62.5-81.8l-71.6,81.8h-39.7l92.7-106L128,128h81.9l56.5,74.7L331.8,128z M317.9,338.1h22L197.9,150.5h-23.6L317.9,338.1z" />{' '}
                      </g>{' '}
                    </svg>
                  </a>
                </li>
              </ul>
              <h2 className="footer-title">Get in touch</h2>
              <div className="footer-contact-option">
                <svg
                  width="15px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {' '}
                  <g strokeWidth="0" />{' '}
                  <g strokeLinecap="round" strokeLinejoin="round" />{' '}
                  <g>
                    {' '}
                    <path
                      d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z"
                      fill="#fff"
                    />{' '}
                  </g>{' '}
                </svg>
                <a href="tel:+0044 7399652379">0044 7399652379</a>
              </div>
              <div className="footer-contact-option">
                <svg
                  fill="#FFFFFF"
                  width="15px"
                  viewBox="0 -2.5 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  {' '}
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    {' '}
                    <g
                      transform="translate(-340.000000, -922.000000)"
                      fill="#FFFFFF"
                    >
                      {' '}
                      <g transform="translate(56.000000, 160.000000)">
                        {' '}
                        <path d="M294,774.474 L284,765.649 L284,777 L304,777 L304,765.649 L294,774.474 Z M294.001,771.812 L284,762.981 L284,762 L304,762 L304,762.981 L294.001,771.812 Z">
                          {' '}
                        </path>{' '}
                      </g>{' '}
                    </g>{' '}
                  </g>{' '}
                </svg>
                <a href="mailto:info@nipunasolutions.co.uk">
                  info@nipunasolutions.co.uk
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright">
        &copy; 2024 Nipuna Solutions. All Rights Reserved.
      </div>
    </div>
  );
};
export default PrivacyPolicy;
