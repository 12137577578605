import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { API_BASE } from '../../api/URLs';
import { FormData } from '../../screens/MergePdf/MergePdf';
import axios from 'axios';

const instance = axios.create({
  baseURL: API_BASE,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
  responseType: 'blob',
});

export const mergePdfs = async (formData: FormData): Promise<ArrayBuffer> =>
  instance
    .post(
      'https://api.ncampaignsuite.com/v1/ncs-publish-api/pdf/merge',
      formData,
    )
    .then((res: any) => res.data);

export const useMergePdfs = (): UseMutationResult<
  ArrayBuffer,
  Error,
  FormData
> => {
  return useMutation([QueryKeys.MergePdf], (formData: FormData) =>
    mergePdfs(formData),
  );
};
