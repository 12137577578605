import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './PreviewIframe.scss';
import { CreateCampaignPage } from '../../types/campaign';
import { SystemTemplatePage } from '../../types/systemTemplate';
import { getPropValue } from '../../utils/utils';

interface PreviewIframeProps {
  // htmlCode: string[];
  // cssCode: string[];
  // orientation?: string[];
  campaignPages: CreateCampaignPage[] | SystemTemplatePage[];
  commonCss?: string;
  showPagination?: boolean;
}

export const PreviewIframe: React.FC<PreviewIframeProps> = ({
  campaignPages,
  commonCss,
  showPagination = true,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeHeight, setIframeHeight] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const location = useLocation();

  useEffect(() => {
    const resizeIframe = () => {
      const iframe = iframeRef.current;
      if (iframe) {
        iframe.style.height = `${iframe.contentWindow?.document.body.scrollHeight}px`;
        setIframeHeight(iframe.contentWindow?.document.body.scrollHeight || 0);
      }
    };
    const iframeDocument = iframeRef.current?.contentDocument;
    if (iframeDocument && campaignPages && campaignPages[pageNumber]) {
      // Clear existing styles
      const existingStyleTag = iframeDocument.querySelector('style');
      if (existingStyleTag) {
        existingStyleTag.remove();
      }

      const styleTag = iframeDocument.createElement('style');
      styleTag.appendChild(
        iframeDocument.createTextNode(commonCss ? commonCss : ''),
      );

      styleTag.appendChild(
        iframeDocument.createTextNode(
          campaignPages[pageNumber].cssContent
            ? campaignPages[pageNumber].cssContent
            : '',
        ),
      );

      iframeDocument.head.appendChild(styleTag);
      iframeDocument.body.innerHTML = campaignPages[pageNumber].content
        ? campaignPages[pageNumber].content
        : '';
      resizeIframe();
    }
  }, [campaignPages, pageNumber]);

  const paginationLinks = Array.from(
    { length: campaignPages.length > 5 ? 5 : campaignPages.length },
    (_, index) => (
      <div
        className={`pagenumber ${pageNumber === index ? 'active' : ''}`}
        key={index}
        onClick={() => {
          setPageNumber(index);
        }}
      >
        {index + 1}
      </div>
    ),
  );

  const sidebarRef = useRef<HTMLDivElement>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(595);

  const startResizing = useCallback((mouseDownEvent: React.MouseEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (isResizing && sidebarRef.current) {
        const newWidth =
          sidebarRef.current.getBoundingClientRect().right -
          mouseMoveEvent.clientX;

        if (newWidth <= 450) {
          setSidebarWidth(newWidth);
        }
      }
    },
    [isResizing],
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <div
      className="preview-resize-container"
      ref={sidebarRef}
      style={{
        // position: 'sticky',
        // top: '0px',
        minWidth: sidebarWidth + 'px',
      }}
    >
      <div className="sidebar-resizer" onMouseDown={startResizing} />
      <div
        className="previewIframe-container"
        style={{
          height: `${location.pathname === '/reviewcontent' ? 'auto' : '100%'}`,
        }}
      >
        <div className="titleSection">
          <p className="preview">Preview</p>
          {showPagination === true && (
            <div className="pagination-section">
              <div
                onClick={() => {
                  if (pageNumber >= 1) {
                    setPageNumber(pageNumber - 1);
                  }
                }}
              >
                &lt;
              </div>
              {paginationLinks}

              <div
                onClick={() => {
                  if (pageNumber <= campaignPages.length) {
                    setPageNumber(pageNumber + 1);
                  }
                }}
              >
                &gt;
              </div>
            </div>
          )}
        </div>

        {campaignPages &&
        campaignPages.length > 0 &&
        campaignPages[0] &&
        campaignPages[0].content === '' ? (
          <div className="noPreview">
            <svg
              width="87"
              height="79"
              viewBox="0 0 87 79"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.1551 27.0995C6.64505 33.3245 2 39.5495 2 39.5495C2 39.5495 20.5802 64.4495 43.5 64.4495C46.3425 64.4495 49.1183 64.0665 51.8 63.3956M35.266 15.687C37.9274 15.0264 40.6809 14.6495 43.5 14.6495C66.4198 14.6495 85 39.5495 85 39.5495C85 39.5495 80.3549 45.7745 72.8449 51.9995"
                stroke="#C5EBC9"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M35.852 32.5385C34.1586 34.3848 33.125 36.8464 33.125 39.5493C33.125 45.2792 37.7701 49.9243 43.5 49.9243C46.3276 49.9243 48.8911 48.793 50.7625 46.9585"
                stroke="#C5EBC9"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M80.8498 76.8992L6.14984 2.19922"
                stroke="#C5EBC9"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <div className="noPreviewText">No preview available</div>

            <div className="NoPreviewInfo">
              Preview will be available once you enter the <br></br>
              code in the second step
            </div>
          </div>
        ) : (
          <div>
            <div
              className="preview-box"
              style={{
                height: `${
                  location.pathname === '/reviewcontent'
                    ? iframeHeight + 'px'
                    : '595px'
                }`,
                overflow: 'auto',
                position: 'relative',
              }}
            >
              <iframe
                ref={iframeRef}
                height={
                  location.pathname === '/reviewcontent'
                    ? iframeHeight
                    : '595px'
                }
                width={'100%'}
                style={{
                  height: '',
                  rotate: `${
                    campaignPages[pageNumber] &&
                    getPropValue(campaignPages[pageNumber], 'orientation') ===
                      'Landscape'
                      ? '90deg'
                      : ''
                  }`,
                  position: 'absolute',
                  top: '0',
                  left: '0',
                }}
                title="Preview iframe"
              />
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          position: 'absolute',
          top: '0',
          bottom: '0',
          zIndex: '10',
          left: '0',
        }}
      ></div>
    </div>
  );
};
