import * as yup from 'yup';

export const audienceS3FormValidation = yup.object().shape({
  s3Connection: yup.object().shape({
    name: yup.string().trim().required('Name is required'),
    accessKey: yup
      .string()
      .trim()
      .required('Access Key ID is required')
      .min(16, 'Access Key ID must be at least 16 characters')
      .max(128, 'Access Key ID cannot exceed 128 characters')
      .matches(
        /^[\w]+$/,
        'Access Key ID must contain only alphanumeric characters',
      ),
    secreteAccessKey: yup
      .string()
      .trim()
      .required('secretAccess Key ID is required')
      .min(16, 'secretAccess Key ID must be at least 16 characters')
      .max(128, 'secretAccess Key ID cannot exceed 128 characters'),
    // .matches(
    //   /^[\w]+$/,
    //   'secretAccess Key ID must contain only alphanumeric characters',
    // ),
    bucketName: yup.string().required('Bucket Name is required'),
    region: yup.string().required('Region is required'),
  }),
  campaignSchedule: yup.object().shape({}),
});
