import ValidationMessage from '../ValidationMessage/ValidationMessage';
import './Textbox.scss';

export interface TextboxProps {
  label?: string;
  id: string;
  name: string;
  row?: number;
  placeholder: string;
  errorMessage?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.ChangeEvent<any>) => void;
}

const Textbox = (props: TextboxProps) => {
  return (
    <div className="textbox-container">
      <div className="flex-display">
        <label htmlFor={props.id}>{props.label}</label>

        {props.errorMessage && <ValidationMessage label={props.errorMessage} />}
      </div>
      <textarea
        name={props.name}
        id={props.id}
        rows={props.row}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
      ></textarea>
    </div>
  );
};

export default Textbox;
