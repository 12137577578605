import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { API_BASE, URLs } from '../../api/URLs';
// import api from '../../api/apifilter';
import { CreateSystemTemplateReq } from '../../types/systemTemplate';
import axios, { AxiosRequestConfig } from 'axios';

const transformToFormData = (obj: CreateSystemTemplateReq): FormData => {
  const formData = new FormData();
  const cssBlob = new Blob([obj.cssContent ? obj.cssContent : ''], {
    type: 'text/plain',
  });

  formData.append('cssContent', cssBlob, 'cssfile.css');
  obj.pages &&
    obj.pages.map((templatePage, index) => {
      const contentBlob = new Blob([templatePage.content], {
        type: 'text/plain',
      });

      const pageCssContentBlob = new Blob(
        [templatePage.cssContent ? templatePage.cssContent : ''],
        {
          type: 'text/plain',
        },
      );

      formData.append(
        `pages[${index}].cssContent`,
        pageCssContentBlob,
        `cssfile_${index}.css`,
      );
      formData.append(
        `pages[${index}].content`,
        contentBlob,
        `content_${index}.html`,
      );

      formData.append(`pages[${index}].name`, templatePage.name);
      formData.append(`pages[${index}].orientation`, templatePage.orientation);
    });
  formData.append('marketingType', obj.marketingType);
  formData.append('description', obj.description);
  formData.append('name', obj.name);
  formData.append('campaignType', obj.campaignType);
  obj.pdfFile && formData.append('pdfFile', obj.pdfFile);
  if (obj.templateId) {
    formData.append('templateId', obj.templateId);
  }
  return formData;
};

export const saveSystemTemplate = async (
  campaignCssAndPages: CreateSystemTemplateReq,
): Promise<string> => {
  const accessToken = localStorage.getItem('user.accessToken');
  const config: AxiosRequestConfig = {
    method: 'post',
    url: API_BASE + URLs.SAVE_SYSTEM_TEMPLATE,
    headers: {
      'Content-Disposition': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    data: transformToFormData(campaignCssAndPages),
  };

  return await axios(config).then((res: any) => res.data);
};

export const useSaveSystemTemplate = (): UseMutationResult<
  string,
  Error,
  CreateSystemTemplateReq
> => {
  return useMutation(
    [QueryKeys.CreateSystemTemplate],
    (createSystemTemplateReq: CreateSystemTemplateReq) =>
      saveSystemTemplate(createSystemTemplateReq),
  );
};
