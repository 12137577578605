import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { DataSourceIdName } from '../../types/datasource';

export const getDataSourceNameExists = async (
  dataSourceIdName: DataSourceIdName,
): Promise<boolean> => {
  return api
    .post(URLs.CHECK_DATASOURCE_NAME_EXISTS, dataSourceIdName)
    .then((res: any) => res.data);
};

export const useGetDataSourceNameExists = (): UseMutationResult<
  boolean,
  Error,
  DataSourceIdName
> => {
  return useMutation(
    [QueryKeys.DataSourceNameExists],
    (dataSourceIdName: DataSourceIdName) =>
      getDataSourceNameExists(dataSourceIdName),
  );
};
