import './UploadButton.scss';

interface UploadButtonProps {
  onClick?: (e: React.ChangeEvent<any>) => void;
}

export const UploadButton = (props: UploadButtonProps) => {
  return (
    <div className="upload-container">
      <button onClick={props.onClick}>
        <span>
          <svg
            width="22"
            height="16"
            viewBox="0 0 22 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 16H5.5C3.98333 16 2.68733 15.475 1.612 14.425C0.536667 13.375 -0.000666047 12.0917 6.19579e-07 10.575C6.19579e-07 9.275 0.391667 8.11667 1.175 7.1C1.95833 6.08333 2.98333 5.43333 4.25 5.15C4.66667 3.61667 5.5 2.375 6.75 1.425C8 0.475 9.41667 0 11 0C12.95 0 14.6043 0.679333 15.963 2.038C17.3217 3.39667 18.0007 5.05067 18 7C19.15 7.13333 20.1043 7.62933 20.863 8.488C21.6217 9.34667 22.0007 10.3507 22 11.5C22 12.75 21.5623 13.8127 20.687 14.688C19.8117 15.5633 18.7493 16.0007 17.5 16H12V8.85L13.6 10.4L15 9L11 5L7 9L8.4 10.4L10 8.85V16Z"
              fill="#00580A"
            />
          </svg>
        </span>
        Upload
      </button>
    </div>
  );
};
