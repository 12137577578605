import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { PdfTemplateInfo } from '../../types/campaign';

export const savePdfTemplate = async (
  pdfTemplateInfo: PdfTemplateInfo,
): Promise<string> =>
  api
    .post(URLs.PDF_TEMPLATE_SAVE, pdfTemplateInfo)
    .then((res: any) => res.data);

export const useSavePdfTemplate = (): UseMutationResult<
  string,
  Error,
  PdfTemplateInfo
> => {
  return useMutation(
    [QueryKeys.CreatePdfTemplate],
    (pdfTemplateInfo: PdfTemplateInfo) => savePdfTemplate(pdfTemplateInfo),
  );
};
