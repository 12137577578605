import { FC, useState } from 'react';
import './Pagination.scss';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const Pagination: FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const [visiblePages, setVisiblePages] = useState([1, 2, 3, 4, 5]);

  const handlePageChange = (page: number) => {
    if (page === currentPage) return; // Do nothing if the current page is selected

    onPageChange(page);

    // Update the visible pages based on the selected page
    if (page > currentPage && page !== totalPages) {
      setVisiblePages(visiblePages.map(num => num + 1));
    } else if (page < currentPage && page !== 1) {
      setVisiblePages(visiblePages.map(num => num - 1));
    }
  };

  const handleLeftIndicatorClick = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleRightIndicatorClick = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  return (
    <div className="paginations">
      {currentPage > 0 && (
        <div
          className="pagination-indicator"
          onClick={handleLeftIndicatorClick}
        >
          &lt;
        </div>
      )}

      {visiblePages.map(pageNumber => (
        <div
          key={pageNumber}
          className={`pagination-item ${
            pageNumber === currentPage ? 'active' : ''
          }`}
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </div>
      ))}

      {currentPage < totalPages && (
        <div
          className="pagination-indicator"
          onClick={handleRightIndicatorClick}
        >
          &gt;
        </div>
      )}
    </div>
  );
};
