import { useEffect } from 'react';

const Page = () => {
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      // Cancel the event to prevent the browser from closing without confirmation
      e.preventDefault();
      // Chrome requires the returnValue property to be set
      e.returnValue = '';

      // Show confirmation message
      const confirmationMessage = 'Are you sure you want to leave this page?';
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return <div>{/* Your component content */}</div>;
};

export default Page;
