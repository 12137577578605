import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { Name } from '../../types/common';

export const isPdfTemplateNameExists = async (name: Name): Promise<boolean> => {
  return api
    .post(URLs.CHECK_PDF_TEMPLATE_NAME_EXISTS, name)
    .then((res: any) => res.data);
};

export const useIsPdfTemplateNameExists = (): UseMutationResult<
  boolean,
  Error,
  Name
> => {
  return useMutation([QueryKeys.IsPdfTemplateNameExists], (name: Name) =>
    isPdfTemplateNameExists(name),
  );
};
