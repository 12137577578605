import React from 'react';
import { App } from './App';
import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from 'react-query';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { persistor, store } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import './fonts/Aaargh.ttf';
import './fonts/Aver Bold.ttf';
import './fonts/abel-regular.ttf';
import './fonts/AvenirLTStd-Black.otf';

const queryClient = new QueryClient();

const rootElement = document.getElementById('root');

// if (rootElement) {

if (!rootElement) throw new Error('Application loading failed');

const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          {/* <ToastContextProvider> */}
          <App />
          {/* </ToastContextProvider> */}
        </QueryClientProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>,

  // document.getElementById('root'),
);
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
