import React from 'react';
import { PdfAddress } from '../components/PdfComponents/PdfAddress/PdfAddress';
import { PdfFWGLogo } from '../components/PdfComponents/PdfFWGLogo/PdfFWGLogo';
import { PdfFooter } from '../components/PdfComponents/PdfFooter/PdfFooter';
import { PdfLabel } from '../components/PdfComponents/PdfLabel/PdfLabel';
import { PdfMWLogo } from '../components/PdfComponents/PdfMWLogo/PdfMWLogo';
import { PdfTable } from '../components/PdfComponents/PdfTable/PdfTable';
import { PdfULList } from '../components/PdfComponents/PdfULList/PdfULList';
import {
  BUTTON_TAG,
  IMAGE_TAG,
  INPUT_FIELD_TAG,
  MULTILINE_INPUT_TAG,
  SELECT_TAG,
} from '../types/elementTypes';
import { PDF_ELEMENTS } from '../types/pdfElements';
import { PdfTwoColumnLayout } from '../components/PdfComponents/PdfTwoColumnLayout/PdfTwoColumnLayout';
import { PdfEmptyLine } from '../components/PdfComponents/PdfEmptyLine/PdfEmptyLine';
import { PdfPrintSignature } from '../components/PdfComponents/PdfPrintSignature/PdfPrintSignature';
import { CustomTagElement } from '../components/PdfCustomTagElementsPanel/PdfCustomTagElementsPanel';
import { PdfLoopStartTag } from '../components/PdfComponents/PdfLoopStart/PdfLoopStart';
import { PdfLoopEndTag } from '../components/PdfComponents/PdfLoopEnd/PdfLoopEnd';
import { PdfCustomTag } from '../components/PdfComponents/PdfCustomTag/PdfCustomTag';
import { PdfPersonalTag } from '../components/PdfComponents/PdfPersonalTag/PdfPersonalTag';
import { PdfTwoTables } from '../components/PdfComponents/PdfTwoTables/PdfTwoTables';
import { PdfImage } from '../components/PdfComponents/PdfImage/PdfImage';

export interface ElementJson {
  type: string;
  props?: {
    id?: string;
    name?: string;
    label?: string;
    options?: string[];
    borderRadius?: string;
    tabIndex?: number;
    tagName?: string;
    width?: number;
    height?: number;
    url?: string;
    size?: string;
  };
}

export const getElementJson = (element: string): ElementJson => {
  switch (element) {
    case IMAGE_TAG:
      return {
        type: 'Image',
        props: {
          url: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiQmcqzN9KSMx-hxPJfiB3yt59uQhN9R4IqjisfUEitJv9lbQVN14QYLsUfmgiH-AoH2VgTFMdRBaTWa9XXpU9aMV1fveYnRgRsf4peaqt_rCR_qyQ483NgjHHdhfYpOr8axyGWhk3DHw5lAUQkXl6NGMugPS7k6Apw7CUjqRMgwAv01i2_AXyRumuBfw/s16000/blank-profile-picture-hd-images-photo.JPG',
          height: 40,
          width: 40,
          tagName: 'Image',
        },
      };
    case INPUT_FIELD_TAG:
      return {
        type: 'TextInput',
        props: {
          name: 'textinput',
          borderRadius: '14px',
          size: 'xs',
          tagName: 'TextInput',
        },
      };
    case SELECT_TAG:
      return {
        type: 'Select',
        props: {
          label: 'select',
          options: ['Option 1', 'Option 2', 'Option 3'],
          borderRadius: '14px',
          tabIndex: 3,
          tagName: 'Select',
        },
      };
    case MULTILINE_INPUT_TAG:
      return {
        type: 'TextArea',
        props: {
          name: 'TextArea',
          id: 'textArea',
          borderRadius: '14px',
          tagName: 'TextArea',
        },
      };
    case BUTTON_TAG:
      return {
        type: 'Button',
        props: {
          name: 'Submit',
          id: 'submit',
        },
      };
    default:
      return { type: '' };
  }
};

export const noOfIdExists = (list: string[], idPrefix: string): number => {
  const filteredList = list.filter(str => str.startsWith(idPrefix));

  return filteredList.length;
};

export const createComponent = (
  componentType: string,
  setIdList: (idList: string[]) => void,
  setSelectedElementId: (id: string) => void,
  setValue: (id: string, newValue: string | string[] | string[][]) => void,
  idList?: string[],

  //setValue: (value: string, id: string) => void,
  // changeValueProperty: (
  //   id: string,
  //   newText: string,
  //   components?: JSX.Element[],
  //   setComponents?: (components: JSX.Element[]) => void,
  // ) => void,
) => {
  switch (componentType) {
    case PDF_ELEMENTS.Label: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `Label_${noOfIdExists(tempIdList, 'Label_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfLabel
          key={ID}
          id={ID}
          name={ID}
          setSelectedElementId={setSelectedElementId}
          value={'Label'}
          onValueChange={(id: string, newValue: string) => {
            setValue(id, newValue); // components, setComponents)
          }}
        />
      );
    }
    case PDF_ELEMENTS.LabelBold: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `LabelBold_${noOfIdExists(tempIdList, 'LabelBold_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfLabel
          key={ID}
          id={ID}
          name={ID}
          setSelectedElementId={setSelectedElementId}
          labelType="Bold"
          value={'Label Bold'}
          onValueChange={(id: string, newValue: string) => {
            // changeValueProperty(id, newValue, components, setComponents)
            setValue(id, newValue);
          }}
        />
      );
    }
    case PDF_ELEMENTS.LabelHeader: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `LabelHeader_${noOfIdExists(tempIdList, 'LabelHeader_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfLabel
          key={ID}
          id={ID}
          name={ID}
          setSelectedElementId={setSelectedElementId}
          labelType="Heading"
          value={'Label Heading'}
          onValueChange={(id: string, newValue: string) => {
            // changeValueProperty(id, newValue, components, setComponents)
            setValue(id, newValue);
          }}
        />
      );
    }
    case PDF_ELEMENTS.ULList: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `List_${noOfIdExists(tempIdList, 'List_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfULList
          noOfRows={3}
          key={ID}
          id={ID}
          name={ID}
          setSelectedElementId={setSelectedElementId}
          value={[
            'Method of payment: Direct Debt',
            'Method of payment: Direct Debt',
            'Method of payment: Direct Debt',
          ]}
          onValueChange={(id: string, newValue: string[]) => {
            // changeValueProperty(id, newValue, components, setComponents)
            setValue(id, newValue);
          }}
        />
      );
    }
    case PDF_ELEMENTS.Address: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `Address_${noOfIdExists(tempIdList, 'Address_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfAddress
          key={ID}
          id={ID}
          name={ID}
          setSelectedElementId={setSelectedElementId}
          value={`Ms Shan Khan <br />
          1 Fisher Lane <br />
          Leeds <br />
          M11 7FQ <br />`}
          onValueChange={(id: string, newValue: string) => {
            // changeValueProperty(id, newValue, components, setComponents)
            setValue(id, newValue);
          }}
        />
      );
    }

    case PDF_ELEMENTS.LOGO: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `FWG_Logo_${noOfIdExists(tempIdList, 'FWG_Logo_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfFWGLogo
          key={ID}
          id={ID}
          name={ID}
          setSelectedElementId={setSelectedElementId}
        />
      );
    }
    case PDF_ELEMENTS.MW_LOGO: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `MW_Logo_${noOfIdExists(tempIdList, 'MW_Logo_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfMWLogo
          key={ID}
          id={ID}
          name={ID}
          setSelectedElementId={setSelectedElementId}
        />
      );
    }
    case PDF_ELEMENTS.PRINT_SIGNATURE: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `Print_Signature_${noOfIdExists(
        tempIdList,
        'Print_Signature_',
      )}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfPrintSignature
          key={ID}
          id={ID}
          name={ID}
          setSelectedElementId={setSelectedElementId}
        />
      );
    }
    case PDF_ELEMENTS.TWO_COLUMN_LAYOUT: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `Two_Column_Layout_${noOfIdExists(
        tempIdList,
        'Two_Column_Layout_',
      )}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfTwoColumnLayout
          key={ID}
          id={ID}
          name={ID}
          setSelectedElementId={setSelectedElementId}
          value={[
            `Left Column Content Line One <br> Left Column Content Line Two <br> `,
            `Right Column Content Line One <br> Right Column Content Line Two <br>`,
          ]}
          onValueChange={(id: string, newValue: string[]) => {
            setValue(id, newValue); // components, setComponents)
          }}
        />
      );
    }

    case PDF_ELEMENTS.FOOTER: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `Footer_${noOfIdExists(tempIdList, 'Footer_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfFooter
          key={ID}
          id={ID}
          name={ID}
          setSelectedElementId={setSelectedElementId}
          value={`Money Wellness is a trading style of Gregory Pennington Limited ©
          2023. Registered office: Think Park, Mosley Road, Trafford Park,
          Manchester, M17 1FQ. Registered in England and Wales. Co Reg No.
          02855061. Authorised and regulated by the Financial Conduct Authority
          for its consumer credit and insurance and mortgage mediation
          activities`}
          onValueChange={(id: string, newValue: string) => {
            setValue(id, newValue); // components, setComponents)
          }}
        />
      );
    }
    case PDF_ELEMENTS.TABLE: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `Table_${noOfIdExists(tempIdList, 'Table_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfTable
          key={ID}
          id={ID}
          name={ID}
          noOfRows={2}
          columns={2}
          setSelectedElementId={setSelectedElementId}
          value={[
            ['ACCOUNT NO', '1334896'],
            ['OUTSTANDING BALANCE', '2,725.70'],
          ]}
          onValueChange={(id: string, newValue: string[][]) => {
            console.log('--');
          }}
        />
      );
    }
    case PDF_ELEMENTS.EMPTY_LINE: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `EmptyLine_${noOfIdExists(tempIdList, 'EmptyLine_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfEmptyLine
          key={ID}
          id={ID}
          name={ID}
          noOfRows={1}
          setSelectedElementId={setSelectedElementId}
        />
      );
    }
    case PDF_ELEMENTS.LOOP_START: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `LoopStart_${noOfIdExists(tempIdList, 'LoopStart_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfLoopStartTag
          key={ID}
          id={ID}
          name={ID}
          value={`Debts.Debt`}
          setSelectedElementId={setSelectedElementId}
          onValueChange={(id: string, newValue: string) => {
            setValue(id, newValue);
          }}
        />
      );
    }
    case PDF_ELEMENTS.LOOP_END: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `LoopEnd_${noOfIdExists(tempIdList, 'LoopEnd_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfLoopEndTag
          key={ID}
          id={ID}
          name={ID}
          value={`Debts.Debt`}
          setSelectedElementId={setSelectedElementId}
          onValueChange={(id: string, newValue: string) => {
            setValue(id, newValue);
          }}
        />
      );
    }
    case PDF_ELEMENTS.PDF_IMAGE: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `Image_${noOfIdExists(tempIdList, 'Image_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfImage
          key={ID}
          id={ID}
          name={ID}
          value={`Image`}
          height={10}
          width={10}
          setSelectedElementId={setSelectedElementId}
          onValueChange={(id: string, newValue: string) => {
            setValue(id, newValue);
          }}
          sourceImage={''}
        />
      );
    }
    case PDF_ELEMENTS.CUSTOM_TAG: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `LoopEnd_${noOfIdExists(tempIdList, 'LoopEnd_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfCustomTag
          key={ID}
          id={ID}
          name={ID}
          value={`<label>Loop End</label></loop>`}
          setSelectedElementId={setSelectedElementId}
          onValueChange={(id: string, newValue: string) => {
            setValue(id, newValue); // components, setComponents)
          }}
        />
      );
    }
    case PDF_ELEMENTS.TWO_TABLES: {
      const tempIdList = idList ? [...idList] : [];
      const ID = `TwoTables_${noOfIdExists(tempIdList, 'TwoTables_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        <PdfTwoTables
          key={ID}
          id={ID}
          name={ID}
          columns={2}
          noOfRows={3}
          value={[
            ['Creditor Name', 'Barclays', 'Creditor Name', 'Barclays'],
            ['Account No', '6733322333', 'Account No', '6733322333'],
            ['Amount', '£2345.09', 'Amount', '£1230.00'],
          ]}
          setSelectedElementId={setSelectedElementId}
          onValueChange={(id: string, newValue: string[][]) => {
            setValue(id, newValue); // components, setComponents)
          }}
        />
      );
    }
    default: {
      const customTagId = getAttributeValue(componentType, 'custom-tag', 'id');
      if (customTagId && customTagId !== '') {
        const tempIdList = idList ? [...idList] : [];
        // const ID = `${noOfIdExists(tempIdList, customTagId)}`;
        tempIdList.push(customTagId);
        setIdList(tempIdList);
        const value = getAttributeValue(componentType, 'custom-tag', 'value');
        return (
          <PdfCustomTag
            key={customTagId}
            id={customTagId}
            setSelectedElementId={setSelectedElementId}
            value={value ? value : ''}
            onValueChange={() => {
              console.log('');
            }}
          />
        );
      }

      const personalTagName = getAttributeValue(
        componentType,
        'personal-tag',
        'value',
      );
      if (personalTagName && personalTagName !== '') {
        const tempIdList = idList ? [...idList] : [];
        // const ID = `${personalTagName}${noOfIdExists(
        //   tempIdList,
        //   personalTagName,
        // )}`;
        tempIdList.push(personalTagName);
        setIdList(tempIdList);

        return (
          <PdfPersonalTag
            key={personalTagName}
            id={personalTagName}
            setSelectedElementId={setSelectedElementId}
            value={personalTagName}
            onValueChange={() => {
              console.log('');
            }}
          />
        );
      }

      const tempIdList = idList ? [...idList] : [];
      const ID = `Custom_${noOfIdExists(tempIdList, 'Custom_')}`;
      tempIdList.push(ID);
      setIdList(tempIdList);
      return (
        // <PdfLabel
        //   key={ID}
        //   id={ID}
        //   name={ID}
        //   setSelectedElementId={setSelectedElementId}
        // />
        <div
          id={ID}
          key={ID}
          onClick={() => {
            // handleLabelClick();
            setSelectedElementId(ID);
          }}
          className="custom-tag-img"
        >
          {componentType}
        </div>
      );
    }
  }
};

export const getComponentIdsFromJson = (jsonStr: string) => {
  try {
    const data = JSON.parse(jsonStr);

    return data.map((item: { type: any; key: any; props: any }) => {
      return item.props.id;
    });
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return [];
  }
};

export const jsonToJsxElementList = (
  jsonStr: string,
  setSelectedElementId: (id: string) => void,
  onLabelChange: (nextValue: string, id: string) => void,
): JSX.Element[] => {
  try {
    const data = JSON.parse(jsonStr);

    return data.map((item: { type: any; key: any; props: any }) => {
      const { type, key, props } = item;
      switch (type) {
        case 'PdfTable':
          return React.createElement(PdfTable, {
            key,
            id: props.id,
            // name: props.name;
            alignment: props.alignment,
            className: props.className,
            setSelectedElementId: setSelectedElementId,
            ...props,
          });
        case 'PdfLabel':
          return React.createElement(PdfLabel, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            onValueChange: onLabelChange,
            // name: props.name;
            labelType: props.labelType,
            alignment: props.alignment,
            className: props.className,
            value: props.value,
            ...props,
          });
        case 'PdfPrintSignature':
          return React.createElement(PdfPrintSignature, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            // name: props.name;
            alignment: props.alignment,
            className: props.className,
            ...props,
          });
        case 'PdfAddress':
          return React.createElement(PdfAddress, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            // name: props.name;
            alignment: props.alignment,
            className: props.className,
            ...props,
          });
        case 'PdfTwoColumnLayout':
          return React.createElement(PdfTwoColumnLayout, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            // name: props.name;
            alignment: props.alignment,
            className: props.className,
            ...props,
          });
        case 'PdfFooter':
          return React.createElement(PdfFooter, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            // name: props.name;
            ...props,
          });
        case 'PdfFWGLogo':
          return React.createElement(PdfFWGLogo, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            // name: props.name;
            ...props,
          });
        case 'PdfMWLogo':
          return React.createElement(PdfMWLogo, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            // name: props.name;
            ...props,
          });
        case 'PdfULList':
          return React.createElement(PdfULList, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            // name: props.name;
            className: props.className,
            bulletType: props.bulletType,
            ...props,
          });
        case 'PdfEmptyLine':
          return React.createElement(PdfEmptyLine, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            // name: props.name;
            noOfRows: props.noOfRows,
            ...props,
          });
        case 'CustomTagElement':
          return React.createElement(CustomTagElement, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            type: props.type,
            htmlCode: props.htmlCode,
            ...props,
          });
        case 'PdfLoopStartTag':
          return React.createElement(PdfLoopStartTag, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            type: props.type,
            htmlCode: props.htmlCode,
            ...props,
          });
        case 'PdfLoopEndTag':
          return React.createElement(PdfLoopEndTag, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            type: props.type,
            htmlCode: props.htmlCode,
            ...props,
          });
        case 'PdfCustomTag':
          return React.createElement(PdfCustomTag, {
            key: key ? key : props.id,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            type: props.type,
            htmlCode: props.htmlCode,
            ...props,
          });
        case 'PdfPersonalTag':
          return React.createElement(PdfPersonalTag, {
            key: key ? key : props.value,

            setSelectedElementId: setSelectedElementId,
            type: props.type,
            htmlCode: props.htmlCode,
            ...props,
            id: props.value,
          });
        case 'PdfTwoTables':
          return React.createElement(PdfTwoTables, {
            key: key ? key : props.value,

            setSelectedElementId: setSelectedElementId,
            type: props.type,
            htmlCode: props.htmlCode,
            ...props,
          });
        case 'PdfImage':
          return React.createElement(PdfImage, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            // name: props.name;
            height: props.height,
            width: props.width,
            ...props,
          });

        default: {
          return React.createElement(PdfEmptyLine, {
            key,
            id: props.id,
            setSelectedElementId: setSelectedElementId,
            // name: props.name;
            noOfRows: props.noOfRows,
            ...props,
          });
        }
      }
    });
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return [];
  }
};

export const jsxElementsToJSONString = (jsxElements: JSX.Element[]): string => {
  const jsonArray = jsxElements.map((element, index) => {
    return {
      type: element.type.displayName,
      key: element.key,
      props: { ...element.props },
    };
  });

  // Convert the array of JSON-compatible objects to a JSON string
  const jsonString = JSON.stringify(jsonArray, null, 2);

  return jsonString;
};

export const generateJsonString = (jsonStrings: any[]): string => {
  return JSON.stringify(jsonStrings);
};

export const changeAlignProperty = (
  id: string,
  newText: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }

  const index = components.findIndex(component => {
    const componentId = component.key; // Assuming the key is the id
    return componentId === id;
  });

  if (index !== -1) {
    // Create a copy of the components array
    const updatedComponents = [...components];

    // Update the property of the found component
    updatedComponents[index] = React.cloneElement(components[index], {
      alignment: newText,
    });

    // Update the state with the new array of components
    setComponents(updatedComponents);
  }
};

export const changeColorProperty = (
  id: string,
  newText: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }
  const index = components.findIndex(component => {
    const componentId = component.key; // Assuming the key is the id
    return componentId === id;
  });

  if (index !== -1) {
    // Create a copy of the components array
    const updatedComponents = [...components];

    // Update the property of the found component
    updatedComponents[index] = React.cloneElement(components[index], {
      // className: newText === 'Blue' ? 'pdf-blue-color' : '',
      color: newText,
    });
    // Update the state with the new array of components
    setComponents(updatedComponents);
  }
};

export const changeBackgroundColorProperty = (
  id: string,
  newText: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }
  const index = components.findIndex(component => {
    const componentId = component.key; // Assuming the key is the id
    return componentId === id;
  });
  if (index !== -1) {
    // Create a copy of the components array
    const updatedComponents = [...components];

    updatedComponents[index] = React.cloneElement(components[index], {
      backgroundColor: newText,
    });
    // Update the state with the new array of components
    setComponents(updatedComponents);
  }
};

// // Assume you have a state or variable to store the border sizes
// const borderSizeMap: { [key: string]: number } = {};
export const changeBorderSizeProperty = (
  id: string,
  newSize: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }
  // // Parse the newSize to a number
  // const newSizeNumber = parseInt(newSize, 10);
  // // Update the border size in the map
  // borderSizeMap[id] = isNaN(newSizeNumber) ? 0 : newSizeNumber;
  const index = components.findIndex(component => {
    const componentId = component.key; // Assuming the key is the id
    return componentId === id;
  });
  if (index !== -1) {
    const updatedComponents = [...components];
    updatedComponents[index] = React.cloneElement(components[index], {
      borderSize: `${newSize}px solid`,
      // style: {
      //   ...components[index].props.style,
      //   borderWidth: `${borderSizeMap[id]}px`, // Set the border size
      //   borderColor: 'black', // Default color is black
      //   borderStyle: 'solid', // Use 'solid' as default border style
      // },
    });
    // Update the state with the new array of components
    setComponents(updatedComponents);
  }
};
export const changeBorderColorProperty = (
  id: string,
  newText: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }
  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });
  if (index !== -1) {
    const updatedComponents = [...components];
    updatedComponents[index] = React.cloneElement(components[index], {
      borderColor: newText,
    });
    setComponents(updatedComponents);
  }
};
export const changeHeaderColorProperty = (
  id: string,
  newText: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }
  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });
  if (index !== -1) {
    const updatedComponents = [...components];
    updatedComponents[index] = React.cloneElement(components[index], {
      headerColor: newText,
    });
    setComponents(updatedComponents);
  }
};
export const changeTableFontColorProperty = (
  id: string,
  newText: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }
  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });
  if (index !== -1) {
    const updatedComponents = [...components];
    updatedComponents[index] = React.cloneElement(components[index], {
      tableFontColor: newText,
    });
    setComponents(updatedComponents);
  }
};
export const changeHeaderFontProperty = (
  id: string,
  newSize: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }
  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });
  if (index !== -1) {
    const updatedComponents = [...components];
    updatedComponents[index] = React.cloneElement(components[index], {
      headingFontSize: `${newSize}px`,
    });
    setComponents(updatedComponents);
  }
};
export const changeFontSizeProperty = (
  id: string,
  newSize: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }
  const index = components.findIndex(component => {
    const componentId = component.key; // Assuming the key is the id
    return componentId === id;
  });
  if (index !== -1) {
    const updatedComponents = [...components];
    updatedComponents[index] = React.cloneElement(components[index], {
      fontSize: `${newSize}px`,
    });
    // Update the state with the new array of components
    setComponents(updatedComponents);
  }
};

export const changeDecorationProperty = (
  id: string,
  newText: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }
  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });
  if (index !== -1) {
    const updatedComponents = [...components];
    const style: React.CSSProperties = { ...components[index].props.style };
    if (newText === 'Underline') {
      style.textDecoration = 'underline';
      style.fontWeight = 'normal';
      style.fontStyle = 'normal';
    } else if (newText === 'Bold') {
      style.textDecoration = 'none';
      style.fontWeight = 'bold';
      style.fontStyle = 'normal';
    } else if (newText === 'Italic') {
      style.textDecoration = 'none';
      style.fontWeight = 'normal';
      style.fontStyle = 'italic';
    } else {
      // For "None" option
      style.textDecoration = 'none';
      style.fontWeight = 'normal';
      style.fontStyle = 'normal';
    }
    updatedComponents[index] = React.cloneElement(components[index], {
      fontStyle: newText,
    });
    setComponents(updatedComponents);
  }
};

export const changeHeaderWeightProperty = (
  id: string,
  newText: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }
  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });
  if (index !== -1) {
    const updatedComponents = [...components];
    const style: React.CSSProperties = { ...components[index].props.style };
    if (newText === 'Bold') {
      style.fontWeight = 'bold';
    } else {
      style.fontWeight = 'normal';
    }
    updatedComponents[index] = React.cloneElement(components[index], {
      headerWeight: newText,
    });
    setComponents(updatedComponents);
  }
};
export const changeTableBorderSizeProperty = (
  id: string,
  newSize: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }
  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });
  if (index !== -1) {
    const updatedComponents = [...components];
    updatedComponents[index] = React.cloneElement(components[index], {
      tableBorder: `${newSize}px solid`,
    });
    setComponents(updatedComponents);
  }
};
export const changeHeaderFontColor = (
  id: string,
  newText: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }
  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });
  if (index !== -1) {
    const updatedComponents = [...components];
    updatedComponents[index] = React.cloneElement(components[index], {
      headerFontColor: newText,
    });
    setComponents(updatedComponents);
  }
};
export const changeNoOfRowsProperty = (
  id: string,
  newText: string,
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }

  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });

  if (index !== -1) {
    const updatedComponents = [...components];

    updatedComponents[index] = React.cloneElement(components[index], {
      noOfRows: newText,
    });

    setComponents(updatedComponents);
  }
};

export const changeValueProperty = (
  id: string,
  newText: string | string[] | string[][],
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }

  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });

  if (index !== -1) {
    const updatedComponents = [...components];

    updatedComponents[index] = React.cloneElement(components[index], {
      value: newText,
    });
    setComponents(updatedComponents);
  }
};

export const changeRecSizeProperty = (
  id: string,
  newText: string | string[] | string[][],
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }

  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });

  if (index !== -1) {
    const updatedComponents = [...components];

    updatedComponents[index] = React.cloneElement(components[index], {
      recSize: newText,
    });
    setComponents(updatedComponents);
  }
};

export const changeHeightProperty = (
  id: string,
  newText: string | string[] | string[][],
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }

  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });

  if (index !== -1) {
    const updatedComponents = [...components];

    updatedComponents[index] = React.cloneElement(components[index], {
      height: newText,
    });
    setComponents(updatedComponents);
  }
};

export const changeWidthProperty = (
  id: string,
  newText: string | string[] | string[][],
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }

  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });

  if (index !== -1) {
    const updatedComponents = [...components];

    updatedComponents[index] = React.cloneElement(components[index], {
      width: newText,
    });
    setComponents(updatedComponents);
  }
};

export const replacePdfFwgSvgLogo = (
  content: string,
  oldTag: string,
): string => {
  const regex = new RegExp(`<${oldTag}[^>]*>.*?</${oldTag}>`, 'g');
  const replacedString = content.replace(
    regex,
    `<<svg width="80px" height="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 80 30.3" style="enable-background:new 0 0 80 30.3;" xml:space="preserve"><style type="text/css">
    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
    .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#1CB580;}
    .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#2AB24A;}
    .st3{fill-rule:evenodd;clip-rule:evenodd;fill:#1A57A7;}
    .st4{fill-rule:evenodd;clip-rule:evenodd;fill:#2CABE1;}
    </style>
    <rect class="st0" width="80" height="30.3"/>
    <g>
    <path class="st1" d="M65.7,2.7h-5c-0.6,0-1.2,0.5-1.2,1.2v7.4c0,0,0,2.6-1.2,5.1c-1.3,2.8-3.5,4.1-6.7,4.1h0
     c-3.4,0-5.5-1.4-6.9-4.3c-1.1-2.4-1.1-4.8-1.1-4.9V3.8c0-0.7-0.5-1.2-1.2-1.2h-5c-0.6,0-1.2,0.5-1.2,1.2v7.4c0,0.4,0.1,4.1,1.8,7.9
     c2.5,5.5,7.3,8.5,13.5,8.5h0.1c4.2,0,7.7-1.4,10.4-4c4.7-4.7,4.9-11.7,4.9-12.4V3.8C66.9,3.2,66.4,2.7,65.7,2.7"/>
    <path class="st2" d="M73,15.2c0-0.5-0.3-1-0.8-1.1c-1.9-0.6-3.3-1.8-4.2-3.9c-1.1-2.4-1.1-4.9-1.1-4.9V3.8c0-0.6-0.5-1.2-1.2-1.2
     h-5c-0.6,0-1.2,0.5-1.2,1.2v1.5c0,0.4,0,3.9,1.7,7.6c2,4.6,5.7,7.6,10.4,8.5c0.6,0.1,1.3-0.3,1.4-0.9c0-0.1,0-0.1,0-0.2
     C73,20.3,73,15.2,73,15.2z"/>
    <path class="st3" d="M7,15.2c0-0.5,0.3-1,0.8-1.1c1.9-0.6,3.3-1.8,4.2-3.9c1.1-2.4,1.1-4.9,1.1-4.9V3.8c0-0.6,0.5-1.2,1.2-1.2h5
     c0.6,0,1.2,0.5,1.2,1.2v1.5c0,0.4,0,3.9-1.7,7.6c-2,4.6-5.7,7.6-10.4,8.5c-0.6,0.1-1.3-0.3-1.4-0.9c0-0.1,0-0.1,0-0.2V15.2L7,15.2z
     "/>
    <path class="st4" d="M42.5,2.7h-5c-0.6,0-1.2,0.5-1.2,1.2v7.4c0,0,0,2.6-1.2,5.1c-1.3,2.8-3.5,4.1-6.7,4.1h0
     c-3.4,0-5.5-1.4-6.9-4.3c-1.1-2.4-1.1-4.8-1.1-4.9V3.8c0-0.7-0.5-1.2-1.2-1.2h-5c-0.6,0-1.2,0.5-1.2,1.2v7.4c0,0.4,0.1,4.1,1.8,7.9
     c2.5,5.5,7.3,8.5,13.5,8.5h0.1c4.2,0,7.7-1.4,10.4-4c4.7-4.7,4.9-11.7,4.9-12.4V3.8C43.7,3.2,43.2,2.7,42.5,2.7"/>
    <path class="st3" d="M7,15.2c0-0.5,0.3-1,0.8-1.1c1.9-0.6,3.3-1.8,4.2-3.9c1.1-2.4,1.1-4.9,1.1-4.9V3.8c0-0.6,0.5-1.2,1.2-1.2h5
     c0.6,0,1.2,0.5,1.2,1.2v1.5c0,0.4,0,3.9-1.7,7.6c-2,4.6-5.7,7.6-10.4,8.5c-0.6,0.1-1.3-0.3-1.4-0.9c0-0.1,0-0.1,0-0.2V15.2L7,15.2z
     "/>
    <path class="st2" d="M73,15.2c0-0.5-0.3-1-0.8-1.1c-1.9-0.6-3.3-1.8-4.2-3.9c-1.1-2.4-1.1-4.9-1.1-4.9V3.8c0-0.6-0.5-1.2-1.2-1.2
     h-5c-0.6,0-1.2,0.5-1.2,1.2v1.5c0,0.4,0,3.9,1.7,7.6c2,4.6,5.7,7.6,10.4,8.5c0.6,0.1,1.3-0.3,1.4-0.9c0-0.1,0-0.1,0-0.2
     C73,20.3,73,15.2,73,15.2z"/>
    <path class="st1" d="M65.7,2.7h-5c-0.6,0-1.2,0.5-1.2,1.2v7.4c0,0,0,2.6-1.2,5.1c-1.3,2.8-3.5,4.1-6.7,4.1h0
     c-3.4,0-5.5-1.4-6.9-4.3c-1.1-2.4-1.1-4.8-1.1-4.9V3.8c0-0.7-0.5-1.2-1.2-1.2h-5c-0.6,0-1.2,0.5-1.2,1.2v7.4c0,0.4,0.1,4.1,1.8,7.9
     c2.5,5.5,7.3,8.5,13.5,8.5h0.1c4.2,0,7.7-1.4,10.4-4c4.7-4.7,4.9-11.7,4.9-12.4V3.8C66.9,3.2,66.4,2.7,65.7,2.7"/>
    </g></svg>`,
  );
  return replacedString;
};

export const changeBulletTypeProperty = (
  id: string,
  newText: string | string[] | string[][],
  components?: JSX.Element[],
  setComponents?: (components: JSX.Element[]) => void,
) => {
  if (!components || !setComponents) {
    return;
  }

  const index = components.findIndex(component => {
    const componentId = component.key;
    return componentId === id;
  });

  if (index !== -1) {
    const updatedComponents = [...components];

    updatedComponents[index] = React.cloneElement(components[index], {
      bulletType: newText,
    });
    setComponents(updatedComponents);
  }
};

export const handleKeyDown = (e: any) => {
  const selection = window.getSelection();
  // console.log(e, ' ::: ', selection);
  if ((e.key === 'Enter' || e.code === 'Enter') && selection) {
    // console.log('selection :: ', selection);
    e.preventDefault();
    document.execCommand('insertHTML', false, '<br>');
  }
};

export const getAttributeValue = (
  customHTML: string,
  className: string,
  attributeName: string,
) => {
  try {
    // const customHTML =
    //   '<my-custom-element data-id="123" data-name="Custom Element Example"></my-custom-element>';

    // Create a DOMParser instance and parse the HTML string
    const parser = new DOMParser();
    const doc = parser.parseFromString(customHTML, 'text/html');

    // Find the custom element in the parsed document
    const customElements = doc.getElementsByClassName(className);

    if (customElements && customElements[0]) {
      // Access custom attributes
      return attributeName === 'id'
        ? customElements[0].getAttribute('id')
        : customElements[0].innerHTML;
    }
    return '';
  } catch (error) {
    return '';
  }
};

export const copyContent = (
  content: string,
  setCopied: (copiedFlag: boolean) => void,
) => {
  const input = document.createElement('input');
  input.value = content;
  document.body.appendChild(input);
  input.select();
  document.execCommand('copy');
  document.body.removeChild(input);

  setCopied(true);
  setTimeout(() => setCopied(false), 1000);
};
