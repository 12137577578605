// import { useDispatch } from 'react-redux';
// import { useNavigate } from 'react-router';
import { useOidcLogin } from '../../hooks/useOdicLogin/useOdicLogin';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

// const AUTH_PROVIDER: string =
//   'https://ncampaign.auth.eu-west-2.amazoncognito.com/oauth2/authorize';
// const REDIRECT_URI: string =
//  'https://creditor-portal.development.financialwellnessgroup.co.uk/signin-oidc';
const REDIRECT_URI = `${window.location.origin}/signin-oidc`;
const RESPONSE_TYPE = 'code';
// const CLIENT_ID: string = `${process.env.REACT_APP_COGNITO_CLIENTID}`;
// const SCOPE: string = 'email%20openid';

export const OidcPage = () => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { mutate: fetchToken, data: tokenResponse } = useOidcLogin();

  // const params = new URLSearchParams(window.location.search);
  const [queryParams] = useSearchParams();
  const code =
    queryParams.get(RESPONSE_TYPE) && queryParams.get(RESPONSE_TYPE) !== null
      ? queryParams.get(RESPONSE_TYPE)
      : '';

  useEffect(() => {
    if (code && code !== '') {
      fetchToken({ code: code, redirectUri: REDIRECT_URI });
    }
  }, [code]);

  return (
    // <form onSubmit={formik.handleSubmit}>
    //   <div className="loginPage">
    //     <div className="loginPage__content">
    //       <div className="loginPage__content__logo">
    //         <img src="NipunaCampaignSuit-r-corner.png" alt="logo" />
    //       </div>
    //       <div className="loginPage__content__title">
    //         Welcome to NCampaign Suite!
    //       </div>
    //       <div className="loginPage__content__username">
    //         <InputBox
    //           id="email"
    //           name="email"
    //           label="User ID"
    //           placeholder="Enter your user id"
    //           onChange={formik.handleChange}
    //           onBlur={formik.handleBlur}
    //           value={formik.values.email}
    //         />
    //       </div>
    //       <div className="loginPage__content__password">
    //         <InputBox
    //           id="password"
    //           name="password"
    //           label="Password"
    //           password={true}
    //           placeholder="Enter your password"
    //           onChange={formik.handleChange}
    //           onBlur={formik.handleBlur}
    //           value={formik.values.password}
    //           errorMessage={''}
    //         />
    //       </div>
    //       <button
    //         className="loginPage__content__button"
    //         onClick={() => login(formik.values)}
    //       >
    //         Login
    //       </button>

    //       <div
    //         className="loginPage__content__forgot-password"
    //         onClick={() =>
    //           navigate(`/reset-password?id=${formik.values.email}`)
    //         }
    //       >
    //         Forgotten your password?
    //       </div>
    //     </div>
    //     {/* <div className="loginPage__content__signup">
    //         Are you a new user? <a href=""> Sign Up Now</a>
    //       </div> */}
    //   </div>
    // </form>
    <div>
      {tokenResponse ? tokenResponse.accessToken : 'response not loaded!!'}
    </div>
  );
};
