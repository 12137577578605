import { FC } from 'react';
import { ALIGN_BUTTONS } from '../../types/constants';
import { Button } from 'cm-react-components/dist/js';
import './ButtonLayout.scss';

export interface ButtonLayoutProps {
  alignButtons?: ALIGN_BUTTONS;
  numberOfButtons: number;
}
export const ButtonLayout: FC<ButtonLayoutProps> = ({
  alignButtons,
  numberOfButtons,
}) => {
  return (
    <div
      className={
        alignButtons && alignButtons === ALIGN_BUTTONS.ALIGN_LEFT
          ? 'buttons_container justify_left'
          : 'buttons_container justify_center'
      }
    >
      {numberOfButtons === 1 ? (
        <Button
          label={'SBUMIT'}
          key={'button'}
          onFocus={() => console.log('Button Got focus !!')}
          onBlur={() => console.log('Button Lost focus !!')}
        ></Button>
      ) : numberOfButtons === 2 ? (
        <>
          <Button
            label="RESET"
            key={'button'}
            onFocus={() => console.log('Button Got focus !!')}
            onBlur={() => console.log('Button Lost focus !!')}
          ></Button>
          <Button
            label="SUBMIT"
            key={'button'}
            onFocus={() => console.log('Button Got focus !!')}
            onBlur={() => console.log('Button Lost focus !!')}
          ></Button>
        </>
      ) : (
        <>
          <Button
            label="RESET"
            key={'button'}
            onFocus={() => console.log('Button Got focus !!')}
            onBlur={() => console.log('Button Lost focus !!')}
          ></Button>
          <Button
            label="CANCEL"
            key={'button'}
            onFocus={() => console.log('Button Got focus !!')}
            onBlur={() => console.log('Button Lost focus !!')}
          ></Button>
          <Button
            label="SUBMIT"
            key={'button'}
            onFocus={() => console.log('Button Got focus !!')}
            onBlur={() => console.log('Button Lost focus !!')}
          ></Button>
        </>
      )}
    </div>
  );
};
