import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Breadcrum } from '../../atoms/Breadcrum/Breadcrum';
// import { Button } from '../../atoms/GenerallButton/Button';
import { Button } from 'cm-react-components/dist/js';
import InputBox from '../../atoms/InputBox/InputBox';
import './PdfGenerator.scss';
import '../../styles/_CommonStyles.scss';
import { PreviewIframe } from '../../atoms/PreviewIframe/PreviewIframe';
import SelectBox, { SelectOption } from '../../atoms/Select/Select';
import Textbox from '../../atoms/Textbox/Textbox';
import { Title } from '../../atoms/Title/Title';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { MARKETTING_TYPES } from '../../types/constants';
import { createSystemTemplateFormValidation } from './validation';
import { CreateSystemTemplateReq } from '../../types/systemTemplate';
import { useSaveSystemTemplate } from '../../hooks/useSaveSystemTemplate/useSaveSystemTemplate';
import { useGetSystemTemplate } from '../../hooks/useGetSystemTemplate/useGetSystemTemplate';
import { campaignTypes } from '../../types/campaign';
import PdfPreview from '../../atoms/PdfPreview/PdfPreview';
import { useIsSystemTemplateNameExists } from '../../hooks/useIsSystemTemplateNameExists/useIsSystemTemplateNameExists';
import { ORIENTATIONS } from '../../types/common';
// import prettier from 'prettier/standalone';
// import htmlParser from 'prettier/parser-html';
import { Next } from '../../atoms/Next/Next';

interface UseFormikWithButtonsProps {
  initialValues: CreateSystemTemplateReq;
  onSubmit: (values: CreateSystemTemplateReq, activeButton: string) => void;
}

const useFormikWithButtons = ({
  initialValues,
  onSubmit,
}: UseFormikWithButtonsProps) => {
  const [activeButton, setActiveButton] = useState('');

  const formik = useFormik({
    initialValues,
    validationSchema: createSystemTemplateFormValidation,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values: CreateSystemTemplateReq) => {
      onSubmit(values, activeButton);
    },
  });

  return { ...formik, activeButton, setActiveButton };
};

export const PdfGenerator: FC = () => {
  const [queryParams] = useSearchParams();
  const [templateId, setTemplateId] = useState<string | null>('');
  const [pageHtmlAccordions, setPageHtmlAccordions] = useState<boolean[]>([
    false,
  ]);
  const [pageCssAccordions, setPageCssAccordions] = useState<boolean[]>([
    false,
  ]);
  const [pdfPreview, setPdfPreview] = useState<boolean>(false);

  const [commonCssAccordions, setCommonAccordions] = useState<boolean>(false);
  const markettingTypes: SelectOption[] = MARKETTING_TYPES.map(marketType => {
    return {
      label: marketType.label,
      value: marketType.value,
    };
  });
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  // const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const navigate = useNavigate();
  const { mutate: saveSystemTemplate } = useSaveSystemTemplate();

  const formik = useFormikWithButtons({
    initialValues: {
      name: '',
      description: '',
      marketingType: '',
      campaignType: '',
      //img: '',
      pages: [
        {
          name: '',
          content: '',
          cssContent: '',
          orientation: '',
          index: 0,
        },
      ],
      cssContent: '',
      pdfFile: undefined,
    },
    onSubmit: async (values: CreateSystemTemplateReq, activeButton: string) => {
      activeButton === 'save' &&
        saveSystemTemplate(
          values
            ? values
            : {
                name: '',
                description: '',
                marketingType: '',
                campaignType: '',
                //img: '',
                pages: [
                  {
                    name: '',
                    content: '',
                    cssContent: '',
                    orientation: '',
                    index: 0,
                  },
                ],
                cssContent: '',
                pdfFile: undefined,
              },
          {
            onSuccess: () => {
              navigate('/systemtemplates');
            },
            onError: () => {
              console.log('Error saving system templates');
            },
          },
        );
    },
  });

  const handleMarketingTypeSelectionChange = (option: SelectOption | any) => {
    formik.setFieldValue('marketingType', option.value);
  };
  const handleCampaingTypeSelectionChange = (option: SelectOption | any) => {
    formik.setFieldValue('campaignType', option.value);
  };

  const updatePageCssAccordion = (index: number) => {
    const updatedItems = [...pageCssAccordions];
    updatedItems[index] = !pageCssAccordions[index];
    setPageCssAccordions(updatedItems);
  };

  const updatePageHtmlAccordion = (index: number) => {
    const updatedItems = [...pageHtmlAccordions];
    updatedItems[index] = !pageHtmlAccordions[index];
    setPageHtmlAccordions(updatedItems);
  };

  const toggleErrorOverlay = () => {
    setIsErrorOpen(!isErrorOpen);
  };

  const getsystemTemplateCssAndPages = () => {
    return {
      templateId: templateId ? templateId : '',
      systemTemplatePages: formik.values.pages,
      cssContent: formik.values.cssContent,
    };
  };

  const {
    mutate: getSystemTemplate,
    data: systemTemplate,
    isLoading: loadingSystemTemplate,
  } = useGetSystemTemplate();

  const { mutate: isSystemTemplateName } = useIsSystemTemplateNameExists();

  useEffect(() => {
    if (!loadingSystemTemplate && systemTemplate) {
      formik.setFieldValue('templateId', systemTemplate.templateId);
      formik.setFieldValue('name', systemTemplate.name);
      formik.setFieldValue('description', systemTemplate.description);
      formik.setFieldValue('type', systemTemplate.type);
      formik.setFieldValue('marketingType', systemTemplate.marketingType);
      formik.setFieldValue('campaignType', systemTemplate.campaignType);
      formik.setFieldValue('cssContent', systemTemplate.cssContent);

      if (systemTemplate.pages && systemTemplate.pages.length > 0) {
        formik.setFieldValue('pages', systemTemplate.pages);
      } else {
        formik.setFieldValue('pages', [
          {
            name: '',
            content: '',
            cssContent: '',
            orientation: '',
            index: 0,
          },
        ]);
      }
    }
  }, [loadingSystemTemplate, systemTemplate]);

  const handleOrientationSelectionChange = (
    option: SelectOption[] | any,
    index: number,
  ) => {
    formik.setFieldValue(`pages[${index}].orientation`, option.value);
  };

  const handleSystemTemplateNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (systemTemplate && systemTemplate.name === value) {
      formik.handleChange(e);
      return;
    }
    isSystemTemplateName(
      { name: value },
      {
        onSuccess: data => {
          data && data === true
            ? formik.setFieldError(
                'name',
                'System Template Name already exists',
              )
            : formik.setFieldError('name', '');
        },
      },
    );
    formik.handleChange(e);
  };

  // const formatCode = (index: number) => {
  //   try {
  //     const formattedCode = prettier.format(
  //       formik.values.pages[index].content,
  //       {
  //         parser: 'html',
  //         plugins: [htmlParser],
  //       },
  //     );
  //     formik.setFieldValue(`pages[${index}].content`, formattedCode);
  //     setErrorMessages([]); // Clear any previous errors
  //   } catch (error) {
  //     const errorMessage = (error as Error).message;
  //     console.error('Error formatting code:', errorMessage);
  //     setErrorMessages([errorMessage]);
  //     setIsErrorOpen(true);
  //   }
  // };

  useEffect(() => {
    if (queryParams.get('template')) {
      const templateId = queryParams.get('template');
      setTemplateId(templateId);

      getSystemTemplate(templateId ? templateId : '');
    }
  }, []);

  return (
    <div className="createSystemTemplate-container">
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Breadcrum pageName={'Create a System Template'} />
          <Title text="System templates" />
          <div className="grid-layout">
            <div>
              <SelectBox
                name={'marketingType'}
                id={'marketingType'}
                label={'Category'}
                list={markettingTypes}
                placeholder={'Choose Category Type'}
                value={
                  MARKETTING_TYPES.find(
                    marketType =>
                      formik.values.marketingType === marketType.value,
                  )
                    ? MARKETTING_TYPES.find(
                        marketType =>
                          formik.values.marketingType === marketType.value,
                      )
                    : { label: 'Select Category type', value: '' }
                }
                onChange={handleMarketingTypeSelectionChange}
              />
              <SelectBox
                name={'campaignType'}
                id={'campaignType'}
                label={'Type'}
                list={campaignTypes}
                placeholder={'Choose Type'}
                value={
                  campaignTypes.find(
                    campaignType =>
                      formik.values.campaignType === campaignType.value,
                  )
                    ? campaignTypes.find(
                        campaignType =>
                          formik.values.campaignType === campaignType.value,
                      )
                    : { label: 'Select  type', value: '' }
                }
                onChange={handleCampaingTypeSelectionChange}
              />
              <InputBox
                id="name"
                name="name"
                label="Template Name"
                onChange={handleSystemTemplateNameChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                errorMessage={formik.errors.name}
              />
              <InputBox
                id="description"
                name="description"
                label="Description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                errorMessage={formik.errors.description}
              />
              <div className="common-css-content">
                <div
                  className="heading"
                  // onClick={() => setCommonAccordions(!commonCssAccordions)}
                >
                  <div> Upload Pdf</div>
                  <div className="flex-display">
                    <div className="commonCss-upload-button">
                      <label htmlFor={'pdfFile'} className="upload">
                        Upload a file
                      </label>
                      <input
                        key={'pdfFile'}
                        id={'pdfFile'}
                        type="file"
                        accept=".pdf"
                        // style={{ display: 'none' }}
                        // ref={cssRef}
                        // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        //   const file = e.target.files?.[0];

                        //   if (file) {
                        //     const reader = new FileReader();
                        //     reader.onload = e => {
                        //       const fileContent = e.target?.result as string;
                        //       formik.setFieldValue(`pdfFile`, fileContent);
                        //     };
                        //     reader.readAsText(file);
                        //   }
                        // }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          formik.setFieldValue(
                            'pdfFile',
                            event.currentTarget.files?.[0],
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="common-css-content">
                <div
                  className="heading"
                  onClick={() => setCommonAccordions(!commonCssAccordions)}
                >
                  <div> Common Css</div>
                  <div className="flex-display">
                    <div className="commonCss-upload-button">
                      <label htmlFor={'commonCssFile'} className="upload">
                        Upload a file
                      </label>
                      <input
                        key={'commonCssFile'}
                        id={'commonCssFile'}
                        type="file"
                        accept=".css"
                        style={{ display: 'none' }}
                        // ref={cssRef}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const file = e.target.files?.[0];

                          if (file) {
                            const reader = new FileReader();
                            reader.onload = e => {
                              const fileContent = e.target?.result as string;
                              formik.setFieldValue(`cssContent`, fileContent);
                            };
                            reader.readAsText(file);
                          }
                        }}
                      />
                    </div>

                    <div>
                      {commonCssAccordions ? (
                        <span>&#9660;</span>
                      ) : (
                        <span>&#x25B2;</span>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="textarea"
                  style={{
                    display: commonCssAccordions ? 'none' : 'block',
                  }}
                >
                  <Textbox
                    key={'cssContent'}
                    // label={'Css Content'}
                    id={'cssContent'}
                    name={'cssContent'}
                    // row={15}
                    placeholder={'Enter the Css content here'}
                    value={formik.values.cssContent}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <FieldArray
                name="pages"
                validateOnChange={true}
                render={arrayHelpers =>
                  formik.values.pages.map((page, index) => (
                    <div key={`systemTemplate${index}`}>
                      <div className="css-content">
                        <div
                          className="heading"
                          onClick={() => updatePageCssAccordion(index)}
                        >
                          <div> CSS - page: {index + 1}</div>
                          <div className="flex-display">
                            <div
                              className="css-upload-button"
                              key={`systemTemplate-upload-${index}`}
                            >
                              <label
                                htmlFor={`cssFile-${index}`}
                                className="upload"
                              >
                                Upload a file
                              </label>
                              <input
                                key={`cssFile-${index}`}
                                id={`cssFile-${index}`}
                                type="file"
                                accept=".css"
                                style={{ display: 'none' }}
                                // ref={cssRef}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  const file = e.target.files?.[0];

                                  if (file) {
                                    const reader = new FileReader();
                                    reader.onload = e => {
                                      const fileContent = e.target
                                        ?.result as string;
                                      formik.setFieldValue(
                                        `pages[${index}].cssContent`,
                                        fileContent,
                                      );
                                    };
                                    reader.readAsText(file);
                                  }
                                }}
                              />
                            </div>

                            <div>
                              {pageCssAccordions[index] ? (
                                <span>&#9660;</span>
                              ) : (
                                <span>&#x25B2;</span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div
                          className="textarea"
                          style={{
                            display: pageCssAccordions[index]
                              ? 'none'
                              : 'block',
                          }}
                        >
                          <Textbox
                            key={`pages[${index}].cssContent`}
                            // label={'Css Content'}
                            id={`pages[${index}].cssContent`}
                            name={`pages[${index}].cssContent`}
                            // row={15}
                            placeholder={'Enter the Css content here'}
                            value={formik.values.pages[index].cssContent}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="html-content">
                        <div className="heading">
                          <div> HTML - page: {index + 1}</div>
                          <div className="flex-display">
                            <div
                              style={{
                                display: pageHtmlAccordions[index]
                                  ? 'none'
                                  : 'block',
                              }}
                            >
                              <SelectBox
                                key={`pages[${index}].orientation`}
                                id={`pages[${index}].orientation`}
                                name={`pages[${index}].orientation`}
                                label={'Orientation'}
                                width="150px"
                                height="30px"
                                list={ORIENTATIONS}
                                placeholder={'Choose...'}
                                onChange={e =>
                                  handleOrientationSelectionChange(e, index)
                                }
                                value={
                                  ORIENTATIONS.find(
                                    orientation =>
                                      formik.values.pages[index].orientation ===
                                      orientation.value,
                                  )
                                    ? ORIENTATIONS.find(
                                        orientation =>
                                          formik.values.pages[index]
                                            .orientation === orientation.value,
                                      )
                                    : {
                                        label: 'Select Category type',
                                        value: '',
                                      }
                                }
                              />
                            </div>

                            <div
                              className="html-upload-button"
                              key={`systemTemplate-upload-${index}`}
                            >
                              <label
                                htmlFor={`htmlFile-${index}`}
                                className="upload"
                              >
                                Upload a file
                              </label>
                              <input
                                key={`htmlFile-${index}`}
                                id={`htmlFile-${index}`}
                                type="file"
                                accept=".html"
                                style={{ display: 'none' }}
                                // ref={cssRef}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  const file = e.target.files?.[0];

                                  if (file) {
                                    const reader = new FileReader();
                                    reader.onload = e => {
                                      const fileContent = e.target
                                        ?.result as string;
                                      formik.setFieldValue(
                                        `pages[${index}].content`,
                                        fileContent,
                                      );
                                    };
                                    reader.readAsText(file);
                                  }
                                }}
                              />
                            </div>
                            <div
                              className="arrow"
                              onClick={() => {
                                updatePageHtmlAccordion(index);
                              }}
                            >
                              {pageHtmlAccordions[index] ? (
                                <span>&#9660;</span>
                              ) : (
                                <span>&#x25B2;</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="textarea"
                          style={{
                            display: pageHtmlAccordions[index]
                              ? 'none'
                              : 'block',
                          }}
                        >
                          <Textbox
                            // label={'Html Content'}
                            key={`pages[${index}].content`}
                            id={`pages[${index}].content`}
                            name={`pages[${index}].content`}
                            // row={15}
                            placeholder={'Enter the HTML content here'}
                            value={
                              formik.values.pages[index]
                                ? formik.values.pages[index].content
                                : ''
                            }
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      {index === formik.values.pages.length - 1 && (
                        <div className="add-more-button">
                          {/* <div className="format">
                            <Button
                              name="formatcode "
                              onClick={() => formatCode(index)}
                            ></Button
                            >
                          </div> */}
                          {/* <div className="preview-button">
                            <Button
                              label={'formatcode'}
                              onClick={() => formatCode(index)}
                            />
                          </div> */}
                          {/* <Button
                            name="Add page"
                            onClick={() => {
                              console.log('Adding New Page ');
                              arrayHelpers.push({
                                name: `Page: ${index + 2}`,
                                cssContent: '',
                                orientation: '',
                                index: index + 1,
                                content: '',
                              });
                              console.log('Added New Page ');
                            }}
                          ></Button> */}
                          <div className="preview-button">
                            <Button
                              label={'Add page'}
                              onClick={() => {
                                arrayHelpers.push({
                                  name: `Page: ${index + 2}`,
                                  cssContent: '',
                                  orientation: '',
                                  index: index + 1,
                                  content: '',
                                });
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                }
              />

              <div className="navigationButtons">
                <Link to="/systemtemplates">
                  {/* <Button name={'Cancel'} /> */}
                  <Button label="Cancel" size={'medium'} />
                </Link>
                {/* <NextButton
                  type={'submit'}
                  onClick={() => formik.setActiveButton('save')}
                /> */}
                <Next
                  type={'submit'}
                  label={'Next'}
                  onClick={() => formik.setActiveButton('save')}
                />
              </div>
            </div>
            <div>
              {/* <button
                className="pdf-button"
                onClick={() => setPdfPreview(!pdfPreview)}
              >
                {pdfPreview ? 'Hide Pdf Preview' : 'Pdf Preview'}
              </button> */}

              <div className="preview-button">
                <Button
                  label={'Pdf Preview'}
                  onClick={() => setPdfPreview(!pdfPreview)}
                >
                  {pdfPreview ? 'Hide Pdf Preview' : 'Pdf Preview'}
                </Button>
              </div>
              <PreviewIframe
                // htmlCode={
                //   formik.values.pages &&
                //   formik.values.pages.map((page, index) => {
                //     return page.content;
                //   })
                // }
                // cssCode={
                //   formik.values.pages &&
                //   formik.values.pages.map((page, index) => {
                //     return page.cssContent;
                //   })
                // }
                commonCss={formik.values.cssContent}
                // orientation={formik.values.pages.map((page, index) => {
                //   return page.orientation;
                // })}
                campaignPages={formik.values.pages}
              />
            </div>
          </div>
          <div
            style={{
              position: 'absolute',
              top: '20px',
              left: '50%',
              transform: 'translate(-50%, 0px)',
            }}
          >
            {pdfPreview && (
              <PdfPreview
                systemTemplateCssAndPages={getsystemTemplateCssAndPages()}
              />
            )}
          </div>

          {isErrorOpen && (
            <div className="error-overlay">
              <div className="error-popup">
                <h3>Error(s) Found:</h3>
                <ul>
                  {/* {errorMessages.map((message, i) => (
                    <li key={i}>{message}</li>
                  ))} */}
                </ul>
                <button onClick={toggleErrorOverlay}>x</button>
              </div>
            </div>
          )}
        </form>
      </FormikProvider>
    </div>
  );
};
