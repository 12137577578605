import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { cmsAppSlice } from '../slices/cmsAppSlice';
import { createCampaignSlice } from '../slices/createCampaignSlice';
import storage from 'redux-persist/lib/storage';
import persistStore from 'redux-persist/es/persistStore';

const rootReducer = combineReducers({
  cmsAppSlice: cmsAppSlice.reducer,
  createCampaignSlice: createCampaignSlice.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//     reducer: {
//      // all reducer should be listed here
//      crmAppSlice: crmAppSlice.reducer
//     },
//   })

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [...getDefaultMiddleware()],
});

export const persistor = persistStore(store);

//export type RootState = ReturnType<typeof store.getState>
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
