import { FC } from 'react';
import './Breadcrum.scss';

export interface BreadcrumProps {
  pageName: string;
}

export const Breadcrum: FC<BreadcrumProps> = (props: BreadcrumProps) => {
  return (
    <div className="breadcrum-container">
      <div className="previousPath">
        Brands
        <span className="greaterthan">&gt;</span>
      </div>

      <div className="currentpath">{props.pageName}</div>
    </div>
  );
};
