import './App.scss';
import 'cm-react-components/dist/css/cm.css';
// import Header from './atoms/Header/Header';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import LeftMenu from './atoms/LeftMenu/LeftMenu';
import Page from './atoms/Elements/Page';
import { useState } from 'react';
import { CmsApplicationContext, CmsApplication } from './context/cmsContext';
import { Audience } from './screens/Audience/Audience';
import Brands from './screens/Brands/Brands';
// import { CampaignContent } from './screens/CampaignContent/CampaignContent';
import { CampaignList } from './screens/CampaignList/CampaignList';
import CreateCampaign from './screens/CreateCampaign/CreateCampaign';
// import { CreateCustomTags } from './screens/CreateCustomTags/CreateCustomTags';
import ViewHtmlPreview from './screens/ViewHtmlPreview/ViewHtmlPreview';
import { PdfReviewContent } from './screens/PdfReviewContent/PdfReviewContent';
import LoginPage from './screens/LoginPage/LoginPage';
import ManageUsers from './screens/ManageUsers/ManageUsers';
import CustomTagVersion from './screens/CustomTagVersions/CustomTagVersion';
import DataSource from './screens/DataSource/DataSource';
import { CustomTagList } from './screens/CustomTagList/CustomTagList';
import SocialMediaEdit from './screens/SocialMediaEdit/SocialMediaEdit';
import { PrivateRoute } from './routes/PrivateRoute';
import { CampaignTracking } from './screens/Brands/CampaignTracking/CampaignTracking';
import { SystemTemplateList } from './screens/SystemTemplateList/SystemTemplateList';
import { SocialMediaDesign } from './screens/SocialMediaContent/SocialMediaDesign';
// import MenuBar from './atoms/MenuBar/MenuBar';
import { PdfGenerator } from './screens/PdfGenerator/PdfGenerator';
import { SelectSystemTemplateList } from './screens/SelectSystemTemplateList/SelectSystemTemplateList';
import { useSelector } from 'react-redux';
import { RootState } from './app/store';
import { FormDesign } from './screens/FormDesign/FormDesign';
import { MenuBarV2 } from './atoms/MenuBarV2/MenuBarV2';
import { PdfCustomTagDesign } from './screens/PdfCustomTags/PdfCustomTagDesign';
import { OidcPage } from './screens/OidcPage/OidcPage';
import { NewFooter } from './screens/NewFooter/NewFooter';
import { NewHeader } from './screens/NewHeader/NewHeader';
import SignUpPage from './screens/SignUpPage/SignUpPage';
import { ForgotPassword } from './screens/ForgotPassword/ForgotPassword';
import PrivacyPolicy from './screens/PrivacyPolicy/PrivacyPolicy';
import { ChangePasswordPage } from './screens/ChangePasswordPage/ChangePasswordPage';
import { HomePage } from './screens/HomePage/HomePage';
import { PdfTemplateList } from './screens/PdfTemplateList/PdfTemplateList';
import CreatePdfTemplate from './screens/CreatePdfTemplate/CreatePdfTemplate';
import { PdfTemplateContent } from './screens/PdfTemplateContent/PdfTemplateContent';
import { PdfContent } from './screens/PdfContent/PdfContent';
import MergePdf from './screens/MergePdf/MergePdf';
import { ContactUs } from './screens/ContactUs/ContactUs';

const initappContext = {
  name: '',
  refreshToken: '',
  accessToken: '',
  htmlCode: '',
  cssCode: '',
};
export const App = () => {
  const isLoggedIn: boolean = localStorage.getItem('user.accessToken') !== null;

  const [cmsApplication, setCmsApplication] =
    useState<CmsApplication>(initappContext);
  // const accessToken = localStorage.getItem('user.accessToken');

  const backgroundDesign = useSelector(
    (state: RootState) => state.createCampaignSlice.backgroundDesign,
  );

  // useEffect(() => {
  //   // Add an event listener to the beforeunload event
  //   const handleBeforeUnload = () => {
  //     console.log('Clearing storage :: ::');
  //     // Clear the localStorage
  //     localStorage.clear();
  //   };

  //   // Attach the event listener when the component mounts
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  return (
    <CmsApplicationContext.Provider
      value={{ cmsApplication, setCmsApplication }}
    >
      <div className="App" style={{ backgroundColor: backgroundDesign }}>
        <Page />
        <BrowserRouter>
          {/* <MenuBarV2 isLoggedIn={isLoggedIn} /> */}
          <NewHeader />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/pdftemplates" element={<PdfTemplateList />} />
            <Route path="/signin-oidc" element={<OidcPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/mergepdf" element={<MergePdf />} />
            <Route
              path="/social-media-edit"
              element={
                <PrivateRoute>
                  <MenuBarV2 isLoggedIn={isLoggedIn} />
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <SocialMediaEdit />
                  </div>
                </PrivateRoute>
              }
            />
            <Route path="/signup" element={<SignUpPage />} />

            <Route
              path="/forgotpassword"
              element={
                <PrivateRoute>
                  <ForgotPassword />
                </PrivateRoute>
              }
            />
            <Route
              path="/privacypolicy"
              element={
                <PrivateRoute>
                  <PrivacyPolicy />
                </PrivateRoute>
              }
            />
            <Route
              path="/manage-users"
              element={
                <PrivateRoute>
                  <MenuBarV2 isLoggedIn={isLoggedIn} />
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <ManageUsers />
                  </div>
                </PrivateRoute>
              }
            />
            <Route path="/campaigns" element={<CampaignList />} />
            <Route
              path="/customtags"
              element={
                <PrivateRoute>
                  <MenuBarV2 isLoggedIn={isLoggedIn} />

                  <CustomTagList />
                </PrivateRoute>
              }
            />
            <Route
              path="/audience"
              element={
                <PrivateRoute>
                  <MenuBarV2 isLoggedIn={isLoggedIn} />
                  <Audience />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/datasource"
              element={
                <PrivateRoute>
                  <MenuBarV2 isLoggedIn={isLoggedIn} />

                  <DataSource />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/reviewcontent"
              element={
                <PrivateRoute>
                  <PdfReviewContent />
                </PrivateRoute>
              }
            />
            <Route path="/contactus" element={<ContactUs />} />

            <Route path="/reset-password" element={<ChangePasswordPage />} />
            <Route
              path="/view-html-preview"
              element={
                <PrivateRoute>
                  <ViewHtmlPreview />
                </PrivateRoute>
              }
            ></Route>
            <Route path="/brands" element={<Brands />} />
            <Route path="/createcampaign" element={<CreateCampaign />} />
            <Route path="/createpdftemplate" element={<CreatePdfTemplate />} />
            <Route
              path="/customtag"
              element={
                <PrivateRoute>
                  <MenuBarV2 isLoggedIn={isLoggedIn} />

                  <PdfCustomTagDesign />
                </PrivateRoute>
              }
            />
            <Route
              path="/custom-tag-version"
              element={
                <PrivateRoute>
                  <MenuBarV2 isLoggedIn={isLoggedIn} />

                  <CustomTagVersion />
                </PrivateRoute>
              }
            />
            <Route
              path="/systemtemplate"
              element={
                <div>
                  {/* <MenuBarV2 isLoggedIn={isLoggedIn} /> */}
                  <PdfGenerator />
                </div>
              }
            />
            <Route path="/pdftemplatedesign" element={<PdfTemplateContent />} />
            <Route path="/contentdesign" element={<PdfContent />} />
            <Route
              path="/track-campaign"
              element={
                <PrivateRoute>
                  <MenuBarV2 isLoggedIn={isLoggedIn} />

                  <CampaignTracking />
                </PrivateRoute>
              }
            />
            <Route
              path="/systemtemplates"
              element={
                // <PrivateRoute>
                //   <MenuBarV2 isLoggedIn={isLoggedIn} />

                <SystemTemplateList />
                // </PrivateRoute>
              }
            />
            <Route
              path="/socialcontent"
              element={
                <PrivateRoute>
                  <MenuBarV2 isLoggedIn={isLoggedIn} />

                  <SocialMediaDesign />
                </PrivateRoute>
              }
            />

            <Route
              path="/selecttemplate"
              element={
                <div>
                  <MenuBarV2 isLoggedIn={isLoggedIn} />
                  <SelectSystemTemplateList />
                </div>
              }
            />
            <Route path="/formdesign" element={<FormDesign />} />
          </Routes>
        </BrowserRouter>
        <NewFooter />
      </div>
    </CmsApplicationContext.Provider>
  );
};

export default App;
