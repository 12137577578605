import { useEffect, useState } from 'react';
import { useGetSystemTemplateList } from '../../hooks/useGetSystemTemplateList/useGetSystemTemplateList';
import './SystemTemplateTable.scss';
import { useNavigate } from 'react-router-dom';
import { PreviewIframe } from '../../atoms/PreviewIframe/PreviewIframe';
import { useGetSystemTemplate } from '../../hooks/useGetSystemTemplate/useGetSystemTemplate';
import { Pagination as PaginationComp } from '../../components/Pagination/Pagination';
import { Pagination } from '../../types/campaign';

export const SystemTemplateTable = () => {
  const navigate = useNavigate();
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [pagination, setPagination] = useState<Pagination>({
    startIndex: 1,
    endIndex: 1,
    currentPage: 1,
    pageSize: 20,
  });

  const { data: systemTemplateList, mutate: getSystemTemplates } =
    useGetSystemTemplateList();
  const {
    mutate: getSystemTemplate,
    data: systemTemplate,
    // isLoading: loadingSystemTemplate,
  } = useGetSystemTemplate();

  const openPreview = (templateId: string) => {
    getSystemTemplate(templateId);
    setPreviewOpen(true);
  };
  const closePreview = () => {
    setPreviewOpen(false);
  };

  useEffect(() => {
    getSystemTemplates(pagination);
  }, []);

  useEffect(() => {
    getSystemTemplates(pagination);
  }, [pagination]);

  const renderPreviewOverlay = () => {
    return (
      <div className="preview-overlay">
        <div className="preview-content">
          <div className="add-margin">
            <div className="closePreview">
              <div onClick={closePreview}>x</div>
            </div>
          </div>

          <PreviewIframe
            campaignPages={
              systemTemplate && systemTemplate.pages ? systemTemplate.pages : []
            }
            commonCss={
              systemTemplate && systemTemplate.cssContent
                ? systemTemplate.cssContent
                : ''
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className="SystemTemplateInfo-container">
      {systemTemplateList &&
        systemTemplateList.records &&
        systemTemplateList.records.length > 0 &&
        systemTemplateList.records.map((systemTemplate, index) => (
          <div className="content" key={`systemtag_${index}`}>
            <div className="flex-display">
              {/* <img className="logo" src="brand1.png" alt="Logo" /> */}
              <div className="left-text">{systemTemplate.name}</div>
            </div>

            <div className="middle-text">{systemTemplate.description}</div>
            <div className="middle-text">{systemTemplate.marketingType}</div>
            <div className="middle-text">{systemTemplate.campaignType}</div>

            <div className="middle-text">{systemTemplate.createdAtStr}</div>

            <div className="right-buttons">
              <button
                className="eye"
                onClick={() => openPreview(systemTemplate.templateId)}
              >
                👁️
              </button>

              <div className="flex-display">
                <div className="edit-button">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      navigate(
                        `/systemtemplate?template=${systemTemplate.templateId}`,
                      );
                    }}
                  >
                    <path
                      d="M12.9428 0.895104C13.2145 0.614718 13.5392 0.391189 13.8982 0.237532C14.2571 0.0838748 14.643 0.00316129 15.0334 9.1018e-05C15.4238 -0.00297925 15.811 0.0716551 16.1723 0.219648C16.5336 0.367641 16.8618 0.586035 17.1379 0.862114C17.414 1.13819 17.6324 1.46644 17.7804 1.82774C17.9283 2.18903 18.003 2.57617 17.9999 2.96659C17.9968 3.35701 17.9161 3.74292 17.7625 4.10184C17.6088 4.46077 17.3853 4.78554 17.1049 5.05724L15.9521 6.21007L11.7899 2.04793L12.9428 0.895104ZM10.9964 2.84143L1.87341 11.9644C1.54404 12.2938 1.30449 12.7026 1.17873 13.1517L0.0206641 17.2876C-0.00609255 17.3835 -0.00687888 17.4848 0.0183856 17.5811C0.0436502 17.6773 0.0940571 17.7652 0.164442 17.8356C0.234826 17.9059 0.322658 17.9564 0.418938 17.9816C0.515217 18.0069 0.616482 18.0061 0.712358 17.9793L4.84755 16.8213C5.29665 16.6956 5.7058 16.4564 6.03555 16.1266L15.1593 7.00432L10.9972 2.84218L10.9964 2.84143Z"
                      fill="#00580A"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        ))}
      {isPreviewOpen && renderPreviewOverlay()}
      <div>
        <PaginationComp
          updatePagination={setPagination}
          noOfPages={
            systemTemplateList?.noOfPages ? systemTemplateList.noOfPages : 1
          }
        />
      </div>
    </div>
  );
};
