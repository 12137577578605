import React, { useEffect, useState } from 'react';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useMergePdfs } from '../../hooks/useMergePdfs/useMergePdfs';
import { Button } from 'cm-react-components/dist/js';
import InputBox from '../../atoms/InputBox/InputBox';

export interface FormData {
  files: File[];
  email: string;
}

const validationSchema = Yup.object({
  files: Yup.array().min(1, 'At least one file is required'),
});

const MergePdf: React.FC = () => {
  const {
    data: pdfBlob,
    isLoading: downloadingDoc,
    mutate: mergePdfs,
  } = useMergePdfs();

  //   const formik = useFormik({
  //     initialValues,
  //     validationSchema: validationSchema,
  //     onSubmit: (values: FormData) => {
  //       // Handle file upload logic here
  //       console.log('Submitting values:', values);
  //       mergePdfs(values);
  //       console.log('Submitted values:', values);
  //     },
  //   });

  const downloadPdf = (values: FormData, activeButton: string) => {
    if (activeButton === 'download') {
      mergePdfs(values);
    }
  };

  interface UseFormikWithButtonsProps {
    initialValues: FormData;
    onSubmit: (values: FormData, activeButton: string) => void;
  }

  const useFormikWithButtons = ({
    initialValues,
    onSubmit,
  }: UseFormikWithButtonsProps) => {
    const [activeButton, setActiveButton] = useState('');

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit: (values: FormData) => {
        onSubmit(values, activeButton);
      },
    });

    return { ...formik, activeButton, setActiveButton };
  };
  const createEmptyFile = (fileName: string): File => {
    const emptyContent = new Blob([''], { type: 'application/octet-stream' });
    return new File([emptyContent], fileName, {
      type: 'application/octet-stream',
    });
  };
  const formik = useFormikWithButtons({
    initialValues: {
      email: '',
      files: [createEmptyFile('emptyfile.pdf')],
    },
    onSubmit: async (values: FormData, activeButton: string) => {
      downloadPdf(values, activeButton);
    },
  });

  useEffect(() => {
    if (!downloadingDoc && pdfBlob !== null && pdfBlob !== undefined) {
      const blob = new Blob([pdfBlob], { type: 'application/pdf' });

      // Create a temporary anchor element to trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'merged-doc.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [pdfBlob]);

  return (
    <div className="CreateBrand-container">
      {/* <Breadcrum pageName={'File merge'} /> */}
      <div className="title-container" style={{ margin: '25px' }}>
        <div className="title">PDF File Merge</div>
      </div>

      <div className="grid-layout">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {/* <p className="subHeading-texts">
            Please upload your pdf files, we don`t save and read your files,
            fully protected.
          </p> */}
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              {/* <div className="button outlined medium">
          <label htmlFor="files">Select File:</label>
        </div> */}
              <table id="fileTable">
                <thead>
                  <tr>
                    {/* <th>File Name</th> */}
                    <th> </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="button outlined medium">
                      <input
                        key={`files[0]`}
                        id={`files[0]`}
                        type="file"
                        accept=".pdf"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          console.log(
                            'e.target.files :: ',
                            e.target.files?.item(0),
                          );
                          formik.setFieldValue(
                            `files[${0}]`,
                            e.target.files?.item(0),
                          );
                        }}
                      />
                    </td>
                    <td></td>
                  </tr>
                  <FieldArray
                    name="files"
                    validateOnChange={true}
                    render={arrayHelpers => (
                      <>
                        {formik.values.files.slice(1).map((file, index) => (
                          <tr key={`file-list-${index}`}>
                            <td>
                              <div className="button outlined medium">
                                <input
                                  key={`files[${index + 1}]`}
                                  id={`files[${index + 1}]`}
                                  type="file"
                                  accept=".pdf"
                                  // style={{ display: 'none' }}
                                  //   ref={cssRef}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    const file = e.target.files?.[0];

                                    if (file) {
                                      formik.setFieldValue(
                                        `files[${index + 1}]`,
                                        file,
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </td>
                            <td>
                              <div
                                // className="preview-button"
                                key={`file-div-${index}`}
                              >
                                <Button
                                  type="button"
                                  label={'Remove'}
                                  onClick={() => {
                                    arrayHelpers.remove(index + 1);
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td>
                            <div className="preview-button">
                              <Button
                                type="button"
                                label={'Add more files'}
                                onClick={() => {
                                  arrayHelpers.push(
                                    createEmptyFile('emptyfile.pdf'),
                                  );
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                  />
                </tbody>
              </table>
              <div>
                <InputBox
                  label="Email"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.errors.files && formik.touched.files && (
                  <div>{'File error'}</div>
                )}
              </div>
              <div className="preview-button">
                <Button
                  label="Merge"
                  type="submit"
                  onClick={() => formik.setActiveButton('download')}
                />
              </div>
            </form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
};

export default MergePdf;
