// import { ALIGN } from 'cm-react-components/dist/js/components/molecules/Grid/Grid';
import {
  FC,
  ReactNode,
  // useCallback,
  useEffect,
  useState,
  // useRef,
  // useState,
} from 'react';

import { Image } from 'cm-react-components/dist/js';
import './SocialMobilePreview.scss';
import { useGetSocialImage } from '../../hooks/useGetSocialImage/useGetSocialImage';

export interface SocialMobilePreviewProps {
  pagesPropsMap: Map<number, Map<string, string>>;
  children?: ReactNode;
  activePage: number;
  width?: number;
  pagesDesignIdMap: Map<number, string>;
}

export const SocialMobilePreview: FC<SocialMobilePreviewProps> = ({
  pagesPropsMap,
  activePage,
  pagesDesignIdMap,
}) => {
  const [previewActivePage, setPreviewActivePage] = useState(activePage);
  const { data: imageBlob, mutate: loadImagePreview } = useGetSocialImage();
  useEffect(() => {
    const props = pagesPropsMap.get(previewActivePage);
    const pageDesingId = pagesDesignIdMap.get(previewActivePage);

    if (props) {
      loadImagePreview({
        designId: pageDesingId ? pageDesingId : '',
        propsMap: props,
      });
    }
  }, [pagesPropsMap, previewActivePage]);

  return (
    <div className="preview-resize-container-mobile">
      <div className="cm-preview-container-mobile">
        <div className="cm-preview-header">
          <div>Mobile Preview</div>
          <div className=" refresh-button-container">
            <button
              id="add-page"
              className={'active'}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();

                const props = pagesPropsMap.get(previewActivePage);
                const pageDesingId = pagesDesignIdMap.get(previewActivePage);

                loadImagePreview({
                  designId: pageDesingId ? pageDesingId : '',
                  propsMap: props,
                });
              }}
            >
              <span>
                <svg
                  version="1.1"
                  id="_x32_"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      d="M403.925,108.102c-27.595-27.595-62.899-47.558-102.459-56.29L304.182,0L201.946,53.867l-27.306,14.454
		l-5.066,2.654l8.076,4.331l38.16,20.542l81.029,43.602l2.277-42.859c28.265,7.546,53.438,22.53,73.623,42.638
		c29.94,29.939,48.358,71.119,48.358,116.776c0,23.407-4.843,45.58-13.575,65.687l40.37,17.532
		c11.076-25.463,17.242-53.637,17.242-83.219C465.212,198.306,441.727,145.904,403.925,108.102z"
                      fill="000000"
                    />
                    <path
                      d="M296.256,416.151l-81.101-43.612l-2.272,42.869c-28.26-7.555-53.51-22.53-73.618-42.636
		c-29.945-29.95-48.364-71.12-48.364-116.767c0-23.427,4.844-45.522,13.576-65.697l-40.37-17.531
		c-11.076,25.53-17.242,53.723-17.242,83.228c0,57.679,23.407,110.157,61.21,147.893c27.595,27.594,62.899,47.548,102.453,56.202
		l-2.716,51.9l102.169-53.878l27.455-14.454l4.988-2.643l-7.999-4.332L296.256,416.151z"
                      fill="000000"
                    />
                  </g>
                </svg>
              </span>
              Reload Image
            </button>
          </div>
        </div>
        <div className="cm-preview-image ">
          {imageBlob ? (
            <img src={URL.createObjectURL(imageBlob)} />
          ) : (
            <Image url="https://cdn-icons-png.flaticon.com/512/11725/11725689.png" />
          )}
        </div>
        <div className="cm-pagination-wrapper">
          <div className="cm-pagination">
            <button className="btn-nav left-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="left-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </button>
            <div className="page-numbers">
              {pagesPropsMap.size > 1 ? (
                Array.from(
                  { length: pagesPropsMap.size },
                  (v, i) => `${i + 1}`,
                ).map((page, index) => (
                  <button
                    type="button"
                    className={
                      previewActivePage === index
                        ? 'btn-page btn-selected'
                        : 'btn-page'
                    }
                    onClick={e => {
                      setPreviewActivePage(index);
                      e.preventDefault();
                    }}
                    key={`page_no_${index}`}
                  >
                    {page}
                  </button>
                ))
              ) : (
                <button
                  type="button"
                  className="btn-page"
                  onClick={e => {
                    setPreviewActivePage(0);
                    e.preventDefault();
                  }}
                >
                  1
                </button>
              )}
            </div>
            <button className="btn-nav right-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="right-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
