import { Link } from 'react-router-dom';
import { Breadcrum } from '../../atoms/Breadcrum/Breadcrum';
//import { Button } from '../../atoms/GenerallButton/Button';
import { Button } from 'cm-react-components/dist/js';
import './CustomTagList.scss';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { CustomTagListTable } from '../CustomTagListTable/CustomTagListTable';
import SelectBox, { SelectOption } from '../../atoms/Select/Select';
import { useGetDashboard } from '../../hooks/useGetDashboard/useGetDashboard';
import { useGetCustomTagList } from '../../hooks/useGetCustomTagList/useGetCustomTagList';
import { Pagination } from '../../components/Pagination/Pagination';
import { Pagination as PaginationObj } from '../../types/campaign';

export const CustomTagList: FC = () => {
  const selectedBrand = useSelector(
    (state: RootState) => state.cmsAppSlice.selectedBrand,
  );
  const [pagination, setPagination] = useState<PaginationObj>({
    startIndex: 1,
    endIndex: 1,
    currentPage: 1,
    pageSize: 20,
  });
  const [brandId, setBrandId] = useState<string>('');
  const [brandLabel, setBrandLabel] = useState<string>('');
  const { data: customTagList, mutate: loadCustomTags } = useGetCustomTagList();
  const { data: brandsList } = useGetDashboard();
  const brands: SelectOption[] = brandsList
    ? brandsList.map(brand => {
        return {
          label: brand.brandName,
          value: brand.brandId,
        };
      })
    : [];

  const handleBrandSelectionChange = (option: SelectOption | any) => {
    setBrandId(option.value);
    setBrandLabel(option.label);
    setPagination({
      startIndex: 1,
      endIndex: 1,
      currentPage: 1,
      pageSize: 20,
    });
    //loadCustomTags({ brandId: option.value, pagination: pagination });
  };

  useEffect(() => {
    brandId && loadCustomTags({ brandId: brandId, pagination: pagination });
  }, [pagination]);

  return (
    <div className="CustomTag-container">
      <Breadcrum
        pageName={brandLabel ? brandLabel : selectedBrand ? selectedBrand : ''}
      />
      <SelectBox
        name={'brandId'}
        id={'brandId'}
        label={'Brand'}
        list={brands}
        placeholder={'Choose brand'}
        onChange={handleBrandSelectionChange}
        className="max-width254"
      />
      <div className="flex-style">
        {/* <div className="img">
         xs
        </div> */}
        <div className="text-container">
          {/* <Title text={brandLabel ? brandLabel : ''} /> */}
          {/* <p>Stay organised and in control of your Custom Tag List</p> */}
        </div>
        <div className="flex-style">
          <Link to="/customtag">
            {/* <Button name="Create" /> */}
            <Button className="button-orange" label="Create" size="small" />
          </Link>
        </div>
      </div>
      <div className="headings">
        <div>TagName</div>
        <div>TagId</div>
        <div>Description</div>
        <div>CreationDate</div>
        <div>Current Version</div>
        <div>Versions</div>
        <div>Preview/Select</div>
      </div>
      <div className="line"></div>
      <div className="lists">
        <CustomTagListTable
          customTagList={customTagList?.records}
          setPagination={setPagination}
        />
      </div>
      <div
        style={{
          display: 'block',
          textAlign: 'end',
        }}
      >
        <Pagination
          updatePagination={setPagination}
          noOfPages={customTagList?.noOfPages ? customTagList.noOfPages : 1}
        />
      </div>
    </div>
  );
};
