import { Breadcrum } from '../../atoms/Breadcrum/Breadcrum';
import { Button } from '../../atoms/Button/Button';
import { Pagination } from '../../atoms/Pagination/Pagination';
import { PreviewBox } from '../../atoms/PreviewBox/PreviewBox';
import { Title } from '../../atoms/Title/Title';
import './ViewHtmlPreview.scss';

const ViewHtmlPreview = () => {
  return (
    <div className="ViewHtmlPreview-container">
      <Breadcrum pageName={'Campaign2name'} />

      <div className="flex-layout">
        <div>
          <Title text={'Campaign 2 name'} />
          <div className="subHeading-text">
            Description of your campaign 2 name will come here.
          </div>
        </div>

        <div className="buttons">
          <Button name="Review & Publish" />
          <Button name="Edit" />
          <Button name="Copy" />
          <Button name="Delete" />
        </div>
      </div>

      <div className="subHeading">Design</div>

      <div className="pagination-section">
        <Pagination currentPage={4} totalPages={9} onPageChange={() => null} />
      </div>
      <PreviewBox
        code={
          '<p> hi </p><p> hi </p><p> hi </p><p> hi </p><p> hi </p><p> hi </p><p> hi </p><p> hi </p><p> hi </p><p> hi </p><p> hi </p><p> hi </p>  <p> hi </p><p> hi </p> <p> hi </p><p> hi </p><p> hi </p><p> hi </p><p> hi </p><p> hi </p><p> hi </p><p> hi </p>'
        }
      />

      <div className="pagination-section">
        {' '}
        <Pagination currentPage={4} totalPages={9} onPageChange={() => null} />
      </div>
    </div>
  );
};

export default ViewHtmlPreview;
