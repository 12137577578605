export interface SocialCampaignPage {
  designId: string;
  propsMap?: Map<string, string>;
}

export interface SocialCampaignContent {
  campaignPages: SocialCampaignPage[];
}

export interface SaveSocialCampaignContent {
  campaignId: string;
  socialCampaignContent: SocialCampaignContent;
}

export interface SocialDesignInfo {
  designId: string;
  designName: string;
}

export const designOneProps = new Map([
  ['prop1', 'NCampaignSuite!'],
  ['prop2', 'Empower Your Campaigns!'],
  ['prop3', 'For more details contact'],
  ['prop4', 'sales@nipunasolutions.co.uk'],
]);
export const designTwoProps = new Map([
  ['prop1', 'NCampaignSuite!'],
  ['prop2', 'Empower Your Campaigns!'],
  ['prop3', 'For more details contact'],
  ['prop4', 'sales@nipunasolutions.co.uk'],
]);
