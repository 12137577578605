import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { Name } from '../../types/common';

export const isCustomTagNameExists = async (name: Name): Promise<boolean> => {
  return api
    .post(URLs.CUSTOMTAG_NAME_EXISTS, name)
    .then((res: any) => res.data);
};

export const useIsCustomTagNameExists = (): UseMutationResult<
  boolean,
  Error,
  Name
> => {
  return useMutation([QueryKeys.IsCustomTagNameExists], (name: Name) =>
    isCustomTagNameExists(name),
  );
};
