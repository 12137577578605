import { FC } from 'react';
import './Title.scss';

export interface TitleProps {
  text: string;
}

export const Title: FC<TitleProps> = (props: TitleProps) => {
  return (
    <div className="title-container">
      <div className="title">{props.text}</div>
    </div>
  );
};
