import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { CustomTag } from '../../types/customtag';

export const saveCustomTag = async (
  createCustomTagRequest: CustomTag,
): Promise<string> => {
  return api
    .post(URLs.CREATE_CUSTOMTAG, createCustomTagRequest)
    .then((res: any) => res.data);
};

export const useSaveCustomTag = (): UseMutationResult<
  string,
  Error,
  CustomTag
> => {
  return useMutation(
    [QueryKeys.CreateCustomTag],
    (createCustomTagRequest: CustomTag) =>
      saveCustomTag(createCustomTagRequest),
  );
};
