import './Next.scss';
export interface NextProps {
  onClick?: (e: React.ChangeEvent<any>) => void;
  type?: 'button' | 'reset' | 'submit' | undefined;
  label?: string;
}
export const Next = (props: NextProps) => {
  return (
    <div className="nextContainer">
      <button onClick={props.onClick} type={props.type ? props.type : 'button'}>
        {props.label ? props.label : 'Save'}
        <span>
          <svg
            width="7"
            height="12"
            viewBox="0 0 7 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.275 10.875C0.0916663 10.6917 0 10.4583 0 10.175C0 9.89167 0.0916663 9.65833 0.275 9.475L4.175 5.575L0.275 1.675C0.0916663 1.49167 0 1.25833 0 0.975C0 0.691667 0.0916663 0.458333 0.275 0.275C0.458333 0.0916663 0.691666 0 0.974999 0C1.25833 0 1.49167 0.0916663 1.675 0.275L6.275 4.875C6.375 4.975 6.446 5.08333 6.488 5.2C6.53 5.31667 6.55067 5.44167 6.55 5.575C6.55 5.70833 6.529 5.83333 6.487 5.95C6.445 6.06667 6.37433 6.175 6.275 6.275L1.675 10.875C1.49167 11.0583 1.25833 11.15 0.974999 11.15C0.691666 11.15 0.458333 11.0583 0.275 10.875Z"
              fill="white"
            />
          </svg>
        </span>
      </button>
    </div>
  );
};
