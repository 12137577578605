import Cookies from '../Cookies/Cookies';
import './HomePage.scss';

export const HomePage = () => {
  return (
    <>
      <section className="banner">
        <div className="banner-content">
          <div className="banner-text">
            <h1>Design and Build PDF Documents</h1>
            <p>
              Create your PDF documents easy and free. <br />
              For automation and personalised PDF documents, please
              contact&nbsp;
              <a href="mail to: info@nipunasolutions.co.uk">
                info@nipunasolutions.co.uk
              </a>
            </p>
            <br />
            <p>Forms and workflows coming soon..</p>
          </div>
          <div className="banner-hero">
            <img src="hero.png" />
          </div>
        </div>
      </section>
      <Cookies />
    </>
  );
};
