import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { S3ConnectionAndSchedule } from '../../types/audience';

export const saveS3Connection = async (
  s3AndSschedule: S3ConnectionAndSchedule,
): Promise<string> => {
  // const s3AndSschedule = {
  //   campaignId: s3ConnectionDetails.campaignId,
  //   s3Connection: s3ConnectionDetails,
  // };

  return api
    .post(URLs.CREATE_S3_CONNECTION, s3AndSschedule)
    .then((res: any) => res.data);
};
export const useSaveS3Connection = (): UseMutationResult<
  string,
  Error,
  S3ConnectionAndSchedule
> => {
  return useMutation(
    [QueryKeys.SaveS3Details],
    (createAudienceRequest: S3ConnectionAndSchedule) =>
      saveS3Connection(createAudienceRequest),
  );
};
