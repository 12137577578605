import { FC, useState } from 'react';

import './DesignOne.scss';

export interface LayoutOneProps {
  values: Map<string, string>;
  setPagesPropsMap: (pagePropsMap: Map<number, Map<string, string>>) => void;
  pagePropsMap: Map<number, Map<string, string>>;
  pageId: number;
}
export const DesignOne: FC<LayoutOneProps> = ({
  values,
  setPagesPropsMap,
  pagePropsMap,
  pageId,
}) => {
  const [content, setUpdatedValues] = useState<Map<string, string>>(values);

  return (
    <div className="social-component-container">
      <div className="social-template-content">
        <div>
          <input
            type="text"
            id={'prop1'}
            value={content.get('prop1')}
            onChange={obj => {
              const tempMap = new Map(content);
              tempMap.set('prop1', obj.target.value);
              setUpdatedValues(tempMap);

              const tempPagePropsMap = new Map(pagePropsMap);
              tempPagePropsMap.set(pageId, tempMap);
              setPagesPropsMap(tempPagePropsMap);
            }}
          ></input>
        </div>
        <div className="social-template-title">
          <textarea
            rows={4}
            id={'prop2'}
            value={content.get('prop2')}
            onChange={obj => {
              const tempMap = new Map(content);
              tempMap.set('prop2', obj.target.value);
              setUpdatedValues(tempMap);

              const tempPagePropsMap = new Map(pagePropsMap);
              tempPagePropsMap.set(pageId, tempMap);
              setPagesPropsMap(tempPagePropsMap);
            }}
            // onBlur={obj => {
            //   updatedValues.set('prop2', obj.target.value);
            //   setUpdatedValues(updatedValues);
            // }}
          />
        </div>

        <div className="social-template-footer">
          <div>
            <input
              type="text"
              id={'prop3'}
              value={content.get('prop3')}
              onChange={obj => {
                const tempMap = new Map(content);
                tempMap.set('prop3', obj.target.value);
                setUpdatedValues(tempMap);

                const tempPagePropsMap = new Map(pagePropsMap);
                tempPagePropsMap.set(pageId, tempMap);
                setPagesPropsMap(tempPagePropsMap);
              }}
            />
          </div>
          <div>
            <input
              id={'prop4'}
              type="text"
              value={content.get('prop4')}
              onChange={obj => {
                const tempMap = new Map(content);
                tempMap.set('prop4', obj.target.value);
                setUpdatedValues(tempMap);

                const tempPagePropsMap = new Map(pagePropsMap);
                tempPagePropsMap.set(pageId, tempMap);
                setPagesPropsMap(tempPagePropsMap);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
