import { FC } from 'react';
import './PdfPrintSignature.scss';

export interface PdfPrintSignatureProps {
  id: string;
  name: string;
  alignment?: 'Left' | 'Right' | 'Center';
  className?: string;
  setSelectedElementId: (id: string) => void;
}

export const PdfPrintSignature: FC<PdfPrintSignatureProps> = ({
  id,
  name,
  alignment = 'Left',
  className,
  setSelectedElementId,
}) => {
  return (
    <div
      id={id}
      className="fwg-settlement-flex fwg-marg-t40"
      onClick={() => setSelectedElementId(id)}
    >
      <table
        cellSpacing="0"
        cellPadding="0"
        className="fwg-settlement-table fwg-font13"
        style={{ border: '0' }}
      >
        <tr>
          <td className="fwg-s-title">PRINT NAME:</td>
          <td className="fwg-s-value"></td>
        </tr>
        <tr>
          <td className="fwg-s-title">SIGN</td>
          <td className="fwg-s-value"></td>
        </tr>
        <tr>
          <td className="fwg-s-title">DATE:</td>

          <td
            className="fwg-s-value"
            style={{ textAlign: 'right', paddingRight: '30px' }}
          ></td>
        </tr>
      </table>

      <table
        cellSpacing="0"
        cellPadding="0"
        className="fwg-settlement-table fwg-font13"
        style={{ border: '0' }}
      >
        <tr>
          <td className="fwg-s-title">PRINT NAME:</td>
          <td className="fwg-s-value"></td>
        </tr>
        <tr>
          <td className="fwg-s-title">SIGN</td>
          <td className="fwg-s-value"></td>
        </tr>
        <tr>
          <td className="fwg-s-title">DATE:</td>

          <td
            className="fwg-s-value"
            style={{ textAlign: 'right', paddingRight: '30px' }}
          ></td>
        </tr>
      </table>
    </div>
  );
};

PdfPrintSignature.displayName = 'PdfPrintSignature';
