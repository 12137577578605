import { FC, useState } from 'react';
import './SideBar.scss';
import './Elements.scss';

export interface PropertiesPanelProps {
  selectedElementId?: string;
  setSelectedElementId: (id: string) => void;
  idList?: string[];
  changeAlignProperty: (id: string, newText: string) => void;
  changeColorProperty?: (id: string, newText: string) => void;
  changeNoOfRowsProperty?: (id: string, newText: string) => void;
  changeNoOfColumnsProperty?: (id: string, newText: string) => void;
  changeLoopRecSize?: (id: string, newText: string) => void;
  changeHeight?: (id: string, newText: string) => void;
  changeWidth?: (id: string, newText: string) => void;
  changeBulletTypeProperty?: (id: string, newText: string) => void;
  changeBackgroundColorProperty?: (id: string, newText: string) => void;
  changeTableFontColorProperty?: (id: string, newText: string) => void;
  changeBorderSizeProperty?: (id: string, newText: string) => void;
  changeBorderColorProperty?: (id: string, newText: string) => void;
  changeFontSizeProperty?: (id: string, newText: string) => void;
  changeDecorationProperty?: (id: string, newText: string) => void;
  changeBulletColorProperty?: (id: string, newText: string) => void;
  changeBulletSizeProperty?: (id: string, newText: string) => void;
  changeImageProperty?: (id: string, newText: string) => void;
  changeHeaderColorProperty?: (id: string, newText: string) => void;
  changeHeaderFontProperty?: (id: string, newText: string) => void;
  changeHeaderWeightProperty?: (id: string, newText: string) => void;
  changeTableBorderSizeProperty?: (id: string, newText: string) => void;
  changeHeaderFontColor?: (id: string, newText: string) => void;
}

export const PropertiesPanel: FC<PropertiesPanelProps> = ({
  selectedElementId,
  setSelectedElementId,
  idList,
  changeAlignProperty,
  changeColorProperty,
  changeNoOfRowsProperty,
  changeNoOfColumnsProperty,
  changeLoopRecSize,
  changeHeight,
  changeWidth,
  changeBulletTypeProperty,
  changeHeaderColorProperty,
  changeHeaderFontProperty,
  changeBackgroundColorProperty,
  changeTableBorderSizeProperty,
  changeTableFontColorProperty,
  changeHeaderWeightProperty,
  changeHeaderFontColor,
  changeBorderSizeProperty,
  changeBorderColorProperty,
  changeFontSizeProperty,
  changeDecorationProperty,
  changeBulletColorProperty,
  changeBulletSizeProperty,
}) => {
  const [categories, setCategories] = useState([
    { title: 'Table', isClicked: false },
  ]);
  const toggleCategory = (index: number) => {
    setCategories(prevCategories =>
      prevCategories.map((category, i) =>
        i === index
          ? { ...category, isClicked: !category.isClicked }
          : category,
      ),
    );
  };

  return (
    <div className="custom-select">
      {/* <form> */}
      <div className="properties-wrapper">
        <div className="cm-form-elements">
          <label>Name*</label>
          <div className="cm-input-elements">
            <select
              value={selectedElementId}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                const newValue = event.target.value;
                setSelectedElementId(newValue);
              }}
            >
              {idList &&
                idList.map((id, index) => {
                  return <option key={id}>{id}</option>;
                })}
            </select>
          </div>
        </div>
        <div className="cm-form-elements">
          <label>Align</label>
          <div className="cm-input-elements">
            <select
              id="align"
              name="align"
              onChange={event => {
                selectedElementId &&
                  changeAlignProperty(selectedElementId, event.target.value);
              }}
            >
              <option>Left</option>
              <option>Center</option>
              <option>Right</option>
            </select>
          </div>
        </div>
        <div className="cm-form-elements">
          <label>Text Decoration</label>
          <div className="cm-input-elements">
            <select
              id="Decoration"
              name="Decoration"
              onChange={event => {
                changeDecorationProperty &&
                  selectedElementId &&
                  changeDecorationProperty(
                    selectedElementId,
                    event.target.value,
                  );
              }}
            >
              <option>None</option>
              <option>Underline</option>
              <option>Italic</option>
              <option>Bold</option>
            </select>
          </div>
        </div>
        <div className="cm-form-elements">
          <label>Color</label>
          <div className="cm-input-elements">
            <select
              id="color"
              name="color"
              onChange={event => {
                changeColorProperty &&
                  selectedElementId &&
                  changeColorProperty(selectedElementId, event.target.value);
              }}
            >
              <option>Black</option>
              <option>Blue</option>
            </select>
          </div>
        </div>
        <div className="cm-form-elements">
          <label>BackgroundColor</label>
          <div className="cm-input-elements">
            <input
              type="color"
              id="color"
              name="color"
              onChange={event => {
                changeBackgroundColorProperty &&
                  selectedElementId &&
                  changeBackgroundColorProperty(
                    selectedElementId,
                    event.target.value,
                  );
              }}
            />
          </div>
        </div>
        <div className="cm-form-elements">
          <label>Bullet Color</label>
          <div className="cm-input-elements">
            <input
              type="color"
              id="bulletColor"
              name="bulletColor"
              onChange={event => {
                changeBulletColorProperty &&
                  selectedElementId &&
                  changeBulletColorProperty(
                    selectedElementId,
                    event.target.value,
                  );
              }}
            />
          </div>
        </div>
        <div className="cm-form-elements">
          <label>Bullet Size</label>
          <div className="cm-input-elements">
            <input
              type="number"
              id="bulletSize"
              name="bulletSize"
              min="1"
              max="50"
              onChange={event => {
                changeBulletSizeProperty &&
                  selectedElementId &&
                  changeBulletSizeProperty(
                    selectedElementId,
                    event.target.value,
                  );
              }}
            />
          </div>
        </div>
        <div className="cm-form-elements">
          <label>No Of Rows</label>
          <div className="cm-input-elements">
            <input
              type="text"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = event.target.value;
                selectedElementId &&
                  changeNoOfRowsProperty &&
                  changeNoOfRowsProperty(selectedElementId, newValue);
              }}
            />
          </div>
        </div>
        <div className="cm-form-elements">
          <label>Label</label>
          <div className="cm-input-elements">
            <input type="text" />
          </div>
        </div>
        <div className="cm-form-elements">
          <label>Border radius</label>
          <div className="cm-input-elements">
            <input type="text" placeholder="Border radius" />
          </div>
        </div>
        <div className="cm-form-elements">
          <label>Place holder</label>
          <div className="cm-input-elements">
            <input type="text" placeholder="Placeholder text" />
          </div>
        </div>
        <div className="cm-form-elements">
          <label>Image</label>
          <div className="cm-input-elements">
            <input type="text" placeholder="Image URL" />
          </div>
        </div>
        <div className="cm-form-elements">
          <label>Name</label>
          <div className="cm-input-elements">
            <input type="text" placeholder="" />
          </div>
        </div>
        <div className="cm-form-elements">
          <label>RecSize</label>
          <div className="cm-input-elements">
            <input
              type="text"
              placeholder="1"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = event.target.value;
                selectedElementId &&
                  changeLoopRecSize &&
                  changeLoopRecSize(selectedElementId, newValue);
              }}
            />
          </div>
        </div>

        <div className="cm-form-elements">
          <label>Heigth</label>
          <div className="cm-input-elements">
            <input
              name="height"
              type="text"
              placeholder="height"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = event.target.value;
                selectedElementId &&
                  changeHeight &&
                  changeHeight(selectedElementId, newValue);
              }}
            />
          </div>
        </div>
        <div className="cm-form-elements">
          <label>Width</label>
          <div className="cm-input-elements">
            <input
              name="width"
              type="text"
              placeholder="width"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = event.target.value;
                selectedElementId &&
                  changeWidth &&
                  changeWidth(selectedElementId, newValue);
              }}
            />
          </div>
        </div>
        <div className="cm-form-elements">
          <label>Bullet Type</label>
          <div className="cm-input-elements">
            <select
              id="bulletType"
              name="bulletType"
              onChange={event => {
                changeBulletTypeProperty &&
                  selectedElementId &&
                  changeBulletTypeProperty(
                    selectedElementId,
                    event.target.value,
                  );
              }}
            >
              <option>Green Tick</option>
              <option>Dot</option>
              <option>Hypen</option>
            </select>
          </div>
        </div>
        <div className="cm-form-elements">
          <label>Font Size</label>
          <div className="cm-input-elements">
            <input
              type="number"
              id="fontSize"
              name="fontSize"
              min="0"
              onChange={event => {
                changeFontSizeProperty &&
                  selectedElementId &&
                  changeFontSizeProperty(selectedElementId, event.target.value);
              }}
            />
          </div>
        </div>
        <div className="element-items">
          {categories.map((category, index) => (
            <div className="category-section" key={index}>
              <div
                className={`category-title ${
                  category.isClicked ? 'active-font' : 'normal-font'
                }`}
                onClick={() => toggleCategory(index)}
              >
                {category.isClicked ? (
                  <div className="down-arrow">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5 5.5L0 0.5H10L5 5.5Z" fill="#222222" />
                    </svg>
                  </div>
                ) : (
                  <div className="right-arrow">
                    <svg
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M5.5 5L0.5 10L0.5 0L5.5 5Z" fill="#666666" />
                    </svg>
                  </div>
                )}
                <p>{category.title}</p>
              </div>
              {category.isClicked && category.title === 'Images' ? (
                <div className="elements-list">Images</div>
              ) : null}
              {category.isClicked && category.title === 'Table' && (
                <div className="elements-list">
                  <div className="cm-form-elements">
                    <label>No Of Columns</label>
                    <div className="cm-input-elements">
                      <input
                        type="text"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          const newValue = event.target.value;
                          selectedElementId &&
                            changeNoOfColumnsProperty &&
                            changeNoOfColumnsProperty(
                              selectedElementId,
                              newValue,
                            );
                        }}
                      />
                    </div>
                  </div>
                  <div className="cm-form-elements">
                    <label>Table Font Color</label>
                    <div className="cm-input-elements">
                      <input
                        type="color"
                        id="tablefontcolor"
                        name="tablefontcolor"
                        onChange={event => {
                          changeTableFontColorProperty &&
                            selectedElementId &&
                            changeTableFontColorProperty(
                              selectedElementId,
                              event.target.value,
                            );
                        }}
                      />
                    </div>
                  </div>
                  <div className="cm-form-elements">
                    <label>Table Border Color</label>
                    <div className="cm-input-elements">
                      <input
                        type="color"
                        id="color"
                        name="color"
                        onChange={event => {
                          changeBorderColorProperty &&
                            selectedElementId &&
                            changeBorderColorProperty(
                              selectedElementId,
                              event.target.value,
                            );
                        }}
                      />
                    </div>
                  </div>
                  <div className="cm-form-elements">
                    <label>Table Border Size</label>
                    <div className="cm-input-elements">
                      <input
                        type="number"
                        id="borderSize"
                        name="borderSize"
                        min="0"
                        onChange={event => {
                          changeBorderSizeProperty &&
                            selectedElementId &&
                            changeBorderSizeProperty(
                              selectedElementId,
                              event.target.value,
                            );
                        }}
                      />
                    </div>
                  </div>
                  <div className="cm-form-elements">
                    <label>Table Header Font Color</label>
                    <div className="cm-input-elements">
                      <input
                        type="color"
                        id="color"
                        name="color"
                        onChange={event => {
                          changeHeaderFontColor &&
                            selectedElementId &&
                            changeHeaderFontColor(
                              selectedElementId,
                              event.target.value,
                            );
                        }}
                      />
                    </div>
                  </div>
                  <div className="cm-form-elements">
                    <label>Table Header Size</label>
                    <div className="cm-input-elements">
                      <input
                        type="number"
                        id="headersize"
                        name="headersize"
                        onChange={event => {
                          changeHeaderFontProperty &&
                            selectedElementId &&
                            changeHeaderFontProperty(
                              selectedElementId,
                              event.target.value,
                            );
                        }}
                      />
                    </div>
                  </div>
                  <div className="cm-form-elements">
                    <label>Table Header Background Color</label>
                    <div className="cm-input-elements">
                      <input
                        type="color"
                        id="color"
                        name="color"
                        onChange={event => {
                          changeHeaderColorProperty &&
                            selectedElementId &&
                            changeHeaderColorProperty(
                              selectedElementId,
                              event.target.value,
                            );
                        }}
                      />
                    </div>
                  </div>
                  <div className="cm-form-elements">
                    <label>Table Header Decoration</label>
                    <div className="cm-input-elements">
                      <select
                        id="headerDecoration"
                        name="headerDecoration"
                        onChange={event => {
                          changeHeaderWeightProperty &&
                            selectedElementId &&
                            changeHeaderWeightProperty(
                              selectedElementId,
                              event.target.value,
                            );
                        }}
                      >
                        <option>None</option>
                        <option>Bold</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {category.isClicked && category.title === 'CustomTags' ? (
                <div className="elements-list">
                  <div>CustomTags</div>
                  <div>CustomTagsTwo</div>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
      {/* </form> */}
    </div>
  );
};
