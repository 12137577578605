import { useState } from 'react';
import InputBox from '../../atoms/InputBox/InputBox';
import './SocialMediaEdit.scss';
// import logoImage from './logo-white.png';
interface styles {
  fontSize: number;
  fontFamily: string;
}
const SocialMediaEdit = () => {
  const [text1, setText1] = useState<string>('MOTORSPORTS AT');
  const [text2, setText2] = useState<string>('YAS MARINA CURCUIT');
  const [text3, setText3] = useState<string>(
    ' Perfectly located, connected and serviced, hire our exhilaratingtrack for motorsports events or manufacturer test days.',
  );
  const [buttonName, setButtonName] = useState<string>(
    'Call to action text here! ',
  );
  const [brandName, setBrandName] = useState<string>('YAS MARINA CURCUIT');

  //   const [brandMottoStyle, setBrandMottoStyle] = useState<styles>({
  //     fontSize: 12,
  //     fontFamily: '',
  //   });
  const [text1Style, setText1style] = useState<styles>({
    fontSize: 12,
    fontFamily: '',
  });
  const [text2Style, setText2style] = useState<styles>({
    fontSize: 12,
    fontFamily: '',
  });
  const [text3style, setText3style] = useState<styles>({
    fontSize: 12,
    fontFamily: '',
  });

  const [buttonTextStyle, setButtonTextStyle] = useState<styles>({
    fontSize: 12,
    fontFamily: '',
  });

  const [brandTextStyle, setBrandTextStyle] = useState<styles>({
    fontSize: 12,
    fontFamily: '',
  });

  //   console.log('brandMotto -- ', brandMotto);
  //   console.log('brandMottoStyle-fontSize', brandMottoStyle.fontSize);
  //   console.log('brandMottoStyle-fontfamily', brandMottoStyle.fontFamily);
  return (
    <div className="SocialMediaDesign-container">
      <div>
        <InputBox
          label={'Heading 1'}
          id={'text1'}
          name={'text1'}
          onChange={e => {
            setText1(e.target.value);
          }}
        />
        <div
          style={{
            width: '400px',
            display: 'flex',
            columnGap: '20px',
          }}
        >
          <InputBox
            label={'Font Size'}
            id={'text1FontSize'}
            name={'text1FontSize'}
            onChange={e => {
              const newSize = parseInt(e.target.value, 10);
              setText1style(prevStyle => ({
                ...prevStyle,
                fontSize: newSize,
              }));
            }}
          />
          <InputBox
            label={'Font Family'}
            id={'text1FontFamily'}
            name={'text1FontFamily'}
            onChange={e => {
              setText1style(prevStyle => ({
                ...prevStyle,
                fontFamily: e.target.value,
              }));
            }}
          />
        </div>
        <InputBox
          label={'Heading 2'}
          id={'text2'}
          name={'text2'}
          onChange={e => {
            setText2(e.target.value);
          }}
        />
        <div
          style={{
            width: '400px',
            display: 'flex',
            columnGap: '20px',
          }}
        >
          <InputBox
            label={'Font Size'}
            id={'text2Fontsize'}
            name={'text2Fontsize'}
            onChange={e => {
              const newSize = parseInt(e.target.value, 10);
              setText2style(prevStyle => ({
                ...prevStyle,
                fontSize: newSize,
              }));
            }}
          />
          <InputBox
            label={'Font Family'}
            id={'text2Family'}
            name={'text2Family'}
            onChange={e => {
              setText2style(prevStyle => ({
                ...prevStyle,
                fontFamily: e.target.value,
              }));
            }}
          />
        </div>
        <InputBox
          label={'Description'}
          id={'text3'}
          name={'text3'}
          onChange={e => {
            setText3(e.target.value);
          }}
        />
        <div
          style={{
            width: '400px',
            display: 'flex',
            columnGap: '20px',
          }}
        >
          <InputBox
            label={'Font Size'}
            id={'text3FontSize'}
            name={'text3FontSize'}
            onChange={e => {
              const newSize = parseInt(e.target.value, 10);
              setText3style(prevStyle => ({
                ...prevStyle,
                fontSize: newSize,
              }));
            }}
          />
          <InputBox
            label={'Font Family'}
            id={'text3FontFamily'}
            name={'text3FontFamily'}
            onChange={e => {
              setText3style(prevStyle => ({
                ...prevStyle,
                fontFamily: e.target.value,
              }));
            }}
          />
        </div>
        <InputBox
          label={'Button Text'}
          id={'button'}
          name={'button'}
          onChange={e => {
            setButtonName(e.target.value);
          }}
        />
        <div
          style={{
            width: '400px',
            display: 'flex',
            columnGap: '20px',
          }}
        >
          <InputBox
            label={'Font Size'}
            id={'buttonTextFontSize'}
            name={'buttonTextFontSize'}
            onChange={e => {
              const newSize = parseInt(e.target.value, 10);
              setButtonTextStyle(prevStyle => ({
                ...prevStyle,
                fontSize: newSize,
              }));
            }}
          />
          <InputBox
            label={'Font Family'}
            id={'buttonTextFontFamily'}
            name={'buttonTextFontFamily'}
            onChange={e => {
              setButtonTextStyle(prevStyle => ({
                ...prevStyle,
                fontFamily: e.target.value,
              }));
            }}
          />
        </div>

        <InputBox
          label={'Brand Logo'}
          id={'brandLogo'}
          name={'brandLogo'}
          onChange={e => {
            setBrandName(e.target.value);
          }}
        />
        <div
          style={{
            width: '400px',
            display: 'flex',
            columnGap: '20px',
          }}
        >
          <InputBox
            label={'Font Size'}
            id={'brandTextFontSize'}
            name={'brandTextFontSize'}
            onChange={e => {
              const newSize = parseInt(e.target.value, 10);
              setBrandTextStyle(prevStyle => ({
                ...prevStyle,
                fontSize: newSize,
              }));
            }}
          />
          <InputBox
            label={'Font Family'}
            id={'brandTextFontFamily'}
            name={'brandTextFontFamily'}
            onChange={e => {
              setBrandTextStyle(prevStyle => ({
                ...prevStyle,
                fontFamily: e.target.value,
              }));
            }}
          />
        </div>

        <div className="uploadButtons">
          <label htmlFor="image1" className="upload">
            Upload background image 1
          </label>
          <input
            id="image1"
            type="file"
            accept=".jpg, .png"
            style={{ display: 'none' }}
          ></input>

          <label htmlFor="image2" className="upload">
            Upload background image 2
          </label>
          <input
            id="image2"
            type="file"
            accept=".jpg, .png"
            style={{ display: 'none' }}
          ></input>
        </div>
      </div>
      <div className="design2-container">
        <img
          src="https://static.myconnect.ae/-/media/yasconnect/project/ymc/experiences-landing/carousel-with-preview-new/home-page-experiences-pagecarousellarge-826-490px-tour-1-web.jpg
        "
          alt="background image"
        />
        <img
          src="https://static.myconnect.ae/-/media/yasconnect/project/ymc/yaselectrified/yas-electrified-article2-660x337.jpg"
          alt="background image"
        />
        <div className="content">
          <div
            className="punchline"
            style={{
              fontSize: `${text1Style.fontSize}px`,
              fontFamily: `${text1Style.fontFamily}`,
            }}
          >
            {text1}
          </div>
          <div
            className="punchline"
            style={{
              fontSize: `${text2Style.fontSize}px`,
              fontFamily: `${text2Style.fontFamily}`,
            }}
          >
            {text2}
          </div>
          <div
            className="text"
            style={{
              fontSize: `${text3style.fontSize}px`,
              fontFamily: `${text3style.fontFamily}`,
            }}
          >
            {text3}
          </div>
          <button
            style={{
              fontSize: `${buttonTextStyle.fontSize}px`,
              fontFamily: `${buttonTextStyle.fontFamily}`,
            }}
          >
            {buttonName} <span> &gt; </span>
          </button>
        </div>
        <div
          className="brand-logo"
          style={{
            fontSize: `${brandTextStyle.fontSize}px`,
            fontFamily: `${brandTextStyle.fontFamily}`,
          }}
        >
          {brandName}
        </div>
      </div>
    </div>
  );
};
export default SocialMediaEdit;
