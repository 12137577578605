import { useQuery, UseQueryResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { SocialDesignInfo } from '../../types/social';

export const getSocialDesignList = async (): Promise<SocialDesignInfo[]> => {
  return api.get(URLs.GET_SOCIAL_DESIGNS).then((res: any) => res.data);
};

export const useGetSocialDesignList = (): UseQueryResult<
  SocialDesignInfo[],
  Error
> => {
  return useQuery(
    [QueryKeys.GetSocialDesignList],
    () => getSocialDesignList(),
    {
      refetchOnWindowFocus: false,
    },
  );
};
