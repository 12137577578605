export enum PDF_ELEMENTS {
  Label = 'LABEL',
  LabelBold = 'LABEL_BOLD',
  LabelHeader = 'LABEL_HEADER',
  Address = 'ADDRESS',
  ULList = 'UL_LIST',
  LOGO = 'LOGO',
  MW_LOGO = 'MW_LOGO',
  FOOTER = 'FOOTER',
  TABLE = 'TABLE',
  TWO_COLUMN_LAYOUT = 'Two Column Layout',
  // TWO_COLUMN_TABLE = 'Two Column Table',
  EMPTY_LINE = 'Empty Line',
  PRINT_SIGNATURE = 'Print Signature',
  LOOP_START = 'Start Loop',
  LOOP_END = 'End Loop',
  CUSTOM_TAG = 'Custom Tag',
  TWO_TABLES = 'Two Tables',
  PDF_IMAGE = 'Pdf Image',
}
