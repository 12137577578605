import { TextInput } from 'cm-react-components/dist/js';
import { FC, useEffect, useState } from 'react';
import './PdfTable.scss';

export interface PdfTableProps {
  id: string;
  name: string;
  alignment?: 'Left' | 'Right' | 'Center';
  className?: string;
  setSelectedElementId: (id: string) => void;
  noOfRows: number;
  columns: number;
  value: string[][];
  headerColor?: string;
  headerFontColor?: string;
  headingFontSize?: string;
  tableFontColor?: string;
  headerWeight?: string;
  tableBorder?: string;
  borderColor?: string;
  onValueChange: (id: string, newValue: string[][]) => void;
}

export const PdfTable: FC<PdfTableProps> = ({
  id,
  name,
  alignment = 'Left',
  className,
  noOfRows: rows,
  columns,
  setSelectedElementId,
  value,
  headerColor,
  headingFontSize,
  tableFontColor,
  headerWeight,
  tableBorder,
  headerFontColor,
  borderColor = '#cae5f6',
  onValueChange,
}) => {
  const [tableData, setTableData] = useState(value);
  const [editableCell, setEditableCell] = useState<{
    row: number | null;
    col: number | null;
  } | null>(null);

  // const [tableHeading, setTableHeading] = useState<string[]>(() =>
  //   Array.from({ length: columns }, (_, colIndex) => `Heading ${colIndex + 1}`),
  // );
  useEffect(() => {
    setTableData(value);
  }, [value]);

  const handleCellValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: number,
    col: number,
  ) => {
    const newTableData = tableData.map(r => [...r]);
    newTableData[row][col] = event.target.value;
    setTableData(newTableData);
    // }
  };
  const handleCellClick = (row: number | null, col: number) => {
    setEditableCell({ row, col });
  };

  useEffect(() => {
    editableCell === null && onValueChange(id, tableData);
  }, [editableCell]);

  const createNewRows = (
    rowCount: number,
    columnCount: number,
    currentRows: number,
  ): string[][] => {
    const data: string[][] = [];

    for (let i = 0; i < rowCount; i++) {
      const row: string[] = [];
      for (let j = 0; j < columnCount; j++) {
        row.push(`Row ${i + currentRows} - Col ${j + 1}`);
      }
      data.push(row);
    }

    return data;
  };

  const updateListToSize = (desiredSize: number) => {
    const currentSize = tableData.length;
    if (currentSize < desiredSize) {
      const tempTableData = [...tableData];
      for (const row of createNewRows(
        desiredSize - tableData.length,
        columns,
        tableData.length,
      )) {
        tempTableData.push([...row]);
      }
      setTableData(tempTableData);
    } else if (currentSize > desiredSize) {
      const updatedItems = tableData.slice(0, desiredSize);
      setTableData(updatedItems);
    }
  };

  useEffect(() => {
    rows && updateListToSize(rows);
  }, [rows]);

  return (
    <div id={id} key={id} onClick={() => setSelectedElementId(id)}>
      <table
        width="100%"
        cellSpacing="0"
        cellPadding="0"
        // className="ncs-table"
        style={{ width: '100%', border: tableBorder, borderColor: borderColor }}
      >
        <tbody>
          <tr>
            <td>
              <table
                width="100%"
                cellSpacing="0"
                cellPadding="0"
                className="ncs-table"
                style={{
                  color: tableFontColor,
                  border: tableBorder,
                  borderColor: borderColor,

                  width: '100%',
                }}
              >
                <tbody>
                  {/* <tr> */}
                  {/* {tableData.slice(0, rows).map((heading, colIndex) => ( */}
                  {tableData.slice(0, 1).map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.slice(0, columns).map((heading, colIndex) => (
                        <td
                          key={colIndex}
                          className="fwg-s-value"
                          onClick={() => handleCellClick(0, colIndex)}
                          style={{
                            backgroundColor: headerColor,
                            fontSize: headingFontSize,
                            fontWeight: headerWeight,
                            border: tableBorder,
                            color: headerFontColor,
                            borderColor: borderColor,
                            // borderLeft: borderColor,
                            borderCollapse: 'collapse',
                          }}
                        >
                          {editableCell &&
                          editableCell.row === 0 &&
                          editableCell.col === colIndex ? (
                            <TextInput
                              value={heading}
                              onBlur={() =>
                                setEditableCell({ row: null, col: null })
                              }
                              onChange={e =>
                                handleCellValueChange(e, 0, colIndex)
                              }
                              borderRadius="0px"
                            />
                          ) : (
                            heading
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                  {tableData.slice(1, rows).map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.slice(0, columns).map((cell, colIndex) => (
                        <td
                          className="fwg-s-value"
                          key={colIndex}
                          onClick={() =>
                            handleCellClick(rowIndex + 1, colIndex)
                          }
                          style={{
                            border: tableBorder,
                            color: tableFontColor,
                            borderColor: borderColor,
                            // borderLeft: borderColor,
                            borderCollapse: 'collapse',
                          }}
                        >
                          {editableCell &&
                          editableCell.row === rowIndex + 1 &&
                          editableCell.col === colIndex ? (
                            <TextInput
                              value={cell}
                              onBlur={e => {
                                setEditableCell(null);
                              }}
                              onChange={e => {
                                handleCellValueChange(
                                  e,
                                  rowIndex + 1,
                                  colIndex,
                                );
                              }}
                              borderRadius="0px"
                            />
                          ) : (
                            cell
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

PdfTable.displayName = 'PdfTable';
