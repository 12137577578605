import { useQuery, UseQueryResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { DataSourcesRequest } from '../../types/datasourcelist';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';

export const getDataSources = async (
  brandId: any,
): Promise<DataSourcesRequest[]> => {
  return api.get(URLs.DATASOURCES(brandId)).then((res: any) => res.data);
};

export const useGetDataSources = (): UseQueryResult<
  DataSourcesRequest[],
  Error
> => {
  const selectedBrandId = useSelector(
    (state: RootState) => state.cmsAppSlice.selectedBrandId,
  );
  return useQuery(
    [QueryKeys.DataSources],
    () => getDataSources(selectedBrandId),
    {
      refetchOnWindowFocus: false,
    },
  );
};
