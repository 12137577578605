import { Link } from 'react-router-dom';
import { Button } from 'cm-react-components/dist/js';
// import { Button } from '../../atoms/GenerallButton/Button';
import './SystemTemplateList.scss';
import { FC } from 'react';
import { SystemTemplateTable } from './SystemTemplateTable';

export const SystemTemplateList: FC = () => {
  return (
    <div className="SystemTemplateList-container">
      <div className="flex-style">
        <div className="text-container">
          <p>System Template List</p>
        </div>
        <div className="flex-style">
          <Link to="/systemtemplate">
            {/* <Button name="Create" /> */}
            <Button className="button-orange" label="Create" size="small" />
          </Link>
        </div>
      </div>
      <div className="headings">
        <div>TagName</div>
        <div>Description</div>
        <div>Category</div>
        <div>Type</div>
        <div>CreationDate</div>
        <div>Preview/Edit</div>
      </div>
      <div className="line"></div>
      <div className="lists">
        <SystemTemplateTable />
      </div>
    </div>
  );
};
