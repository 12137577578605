export enum QueryKeys {
  Login = 'LOGIN',
  OidcLogin = 'OIDC_LOGIN',
  ChangePassword = 'CHANGE_PASSWORD',
  ContactUs = 'CONTACT_US',
  Dashboard = 'DASHBOARD',
  CreateBrand = 'CREATE_BRAND',
  CreateCampaign = 'CREATE_CAMPAIGN',
  CreatePdfTemplate = 'CREATE_PDF_TEMPLATE',
  Audience = 'AUDIENCE',
  GetDataSourceTables = 'GET_DATASOURCE_TABLES',
  GetDataSourceTablesByDsId = 'GET_DATASOURCE_TABLES_BY_DS_ID',
  SaveDataSource = 'SAVE_DATASOURCE',
  IsCampaignNameExists = 'IS_CAMPAIGN_NAME_EXISTS',
  IsPdfTemplateNameExists = 'IS_PDF_TEMPLATE_NAME_EXISTS',
  IsSystemTemplateNameExists = 'IS_SYSTEM_TEMPLATE_NAME_EXIST',
  GetCampaignList = 'GET_CAMPAIGN_LIST',
  GetCampaignContent = 'GET_CAMPAIGN_CONTENT',
  GetPdfTemplateContent = 'GET_PDF_TEMPLATE_CONTENT',
  GetCampaignInfo = 'GET_CAMPAIGN_INFO',
  GetPdfTemplateInfo = 'GET_PDF_TEMPLATE_INFO',
  GetCampaignDatasource = 'GET_CAMPAIGN_DATASOURCE',
  SaveCampaignContent = 'SAVE_CAMPAIGN_CONTENT',
  SavePdfContent = 'SAVE_PDF_CONTENT',
  SavePdfTemplateContent = 'SAVE_PDF_TEMPLATE_CONTENT',
  CreateCustomTag = 'CREATE_CUSTOMTAG',
  CreateSystemTemplate = 'CREATE_SYSTEM_TEMPLATE',
  IsCustomTagNameExists = 'IS_CUSTOMTAG_NAME_EXISTS',
  GetCampaignPreview = 'GET_CAMPAIGN_PREVIEW',
  GetSystemTemplatePreview = 'GET_STYSTEM_TEMPLATE_PREVIEW',
  GetCampaignPdfPreview = 'GET_CAMPAIGN_PDF_PREVIEW',
  GetCampaignReviewPdf = 'GET_CAMPAIGN_REVIEW_PDF',
  DataSources = 'DATASOURCES',
  DataSourceNameExists = 'DATASOURCE_NAME_EXISTS',
  MergePdf = 'MERGE_PDF',
  GetCampaignS3Details = 'GET_CAMPAIGN_S3_DETAILS',
  GetCustomTagList = 'GET_CUSTOMATAG_LIST',
  GetSystemTemplateList = `GET_SYSTEM_TEMPLATE_LIST`,
  GetPdfTemplateList = `GET_PDF_TEMPLATE_LIST`,
  GetSystemTemplate = `GET_SYSTEM_TEMPLATE`,
  GetCustomTagContent = 'GET_CUSTOMTAG_CONTENT',
  DeleteCampaign = `DELETE_CAMPAIGN`,
  SaveS3Details = 'SAVE_S3_DETAILS',
  IsS3NameExists = 'IS_S3_NAME_EXISTS',
  GetCampaignTracking = 'GET_CAMPAIGN_TRACKING',
  ForgetPassword = 'FORGET_PASSWORD',
  ResetPassword = 'RESET_PASSWORD',
  ExportCampaignPdf = `EXPORT_CAMPAIGN_PDF`,
  GetSocialImageBlob = 'GET_SOCIAL_IMAGE_BLOB',
  SaveSocialContent = 'SAVE_SOCIAL_CONTENT',
  GetSocialDesignList = 'GET_SOCIAL_DESIGN_LIST',
  GetSocialCampaignPages = 'GET_SOCIAL_CAMPAIGN_PAGES',
  SocialCampaignPublish = 'SOCIAL_CAMPAIGN_PUBLISH',
  PdfCampaignPublish = 'PDF_CAMPAIGN_PUBLISH',
}
