import { useEffect, useState, useRef } from 'react';
import './ValidationMessage.scss';

export interface LabelProps {
  label: string;
}

const ValidationMessage = (props: LabelProps) => {
  const [labelText, setLabelText] = useState('');
  const intervalIdRef = useRef<NodeJS.Timer | undefined>(undefined);

  function simulateTyping(label: string) {
    // This function simulates typing by adding one character at a time to the label text
    let index = 0;
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    intervalIdRef.current = setInterval(() => {
      //if (index == 0) setLabelText(prevText => prevText + label.charAt(index));
      if (index === label.length) {
        clearInterval(intervalIdRef.current);
        return;
      }
      setLabelText(prevText => prevText + label.charAt(index));
      ++index;
    }, 0); // adjust the interval time as needed
  }

  useEffect(() => {
    simulateTyping(props.label);
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [props.label]);

  return (
    <div className="ValidationMessage-container">
      <div className="tooltip warningMessage">{labelText}</div>
    </div>
  );
};

export default ValidationMessage;
