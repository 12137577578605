import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import {
  PdfTemplatesResp,
  TemplateTypesRequest,
} from '../../types/pdfCampaigns';

export const getPdfTemplateList = async (
  templateTypesRequest: TemplateTypesRequest,
): Promise<PdfTemplatesResp> =>
  api
    .post(URLs.GET_PDF_TEMPLATE_LIST, templateTypesRequest)
    .then((res: any) => res.data);

export const useGetPdfTemplateList = (): UseMutationResult<
  PdfTemplatesResp,
  Error,
  TemplateTypesRequest
> => {
  return useMutation(
    [QueryKeys.GetPdfTemplateList],
    (templateTypesRequest: TemplateTypesRequest) =>
      getPdfTemplateList(templateTypesRequest),
  );
};
