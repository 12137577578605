import React, { FC, ReactNode, useEffect, useRef } from 'react';

import './PdfTemplateContentDesignPanel.scss';
import '../../styles/_CommonStyles.scss';
// import { changeValueProperty, createComponent } from '../../utils/elementsUtil';
import { UICreateCampaignPage } from '../../types/campaign';
import { ContentMoveElement } from '../../components/ContentMoveElement/ContentMoveElement';
import { PdfPagination } from './PdfPagination';

export interface PdfTemplateContentDesignPanelProps {
  updateContent: (content: string) => void;
  contentRef: React.RefObject<HTMLDivElement>;
  cssContent?: string;
  htmlContent?: string;
  // setContent?: (content: string) => void;
  children?: ReactNode;
  activePage: number;
  // setActivePage: (activePage: number) => void;
  width?: number;
  // setSelectedElementId: (id: string) => void;
  selectedElementId: string;
  idList?: string[];
  setIdList: (idList: string[]) => void;
  components?: JSX.Element[];
  setComponents: (components: JSX.Element[]) => void;
  addComponent: (componentType: string) => void;
  pages: Map<number, UICreateCampaignPage>;
  // setUIPages: (pages: Map<number, UICreateCampaignPage>) => void;
  addPage: () => void;
  setPdfPreview: (showPreview: boolean) => void;
  showPreview: boolean;
  deleteComponent: (elementId: string) => void;
  moveComponentUp: (elementId: string) => void;
  moveComponentDown: (elementId: string) => void;
  setActivePage: (pageNo: number) => void;
  deletePage: () => void;
  // showTemplates: () => void;
  showHtmlEditor?: boolean;
}

export const PdfTemplateContentDesignPanel: FC<
  PdfTemplateContentDesignPanelProps
> = ({
  activePage,
  // setContent,
  updateContent,
  contentRef,
  cssContent,
  htmlContent,
  selectedElementId,
  idList,
  setIdList,
  components,
  setComponents,
  addComponent,
  addPage,
  deletePage,
  pages,
  showPreview,
  setPdfPreview,
  // showTemplates,
  deleteComponent,
  moveComponentUp,
  moveComponentDown,
  setActivePage,
  showHtmlEditor = false,
}) => {
  // const [selectedElementId, setSelectedElementId] = useState<string>('');
  const cursorPositionRef = useRef<number | null>(null);

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const componentId = e.dataTransfer.getData('text/plain');

    addComponent(componentId);

    // setContent(contentRef.current.innerHTML);
  };

  const handleContentChange = (event: React.ChangeEvent<HTMLDivElement>) => {
    const selection = window.getSelection();
    if (selection) {
      cursorPositionRef.current = selection.getRangeAt(0).startOffset;
    }
    updateContent(event.currentTarget.innerHTML);
  };

  useEffect(() => {
    // Restore the cursor position after rendering
    if (contentRef.current && cursorPositionRef.current !== null) {
      const range = document.createRange();
      const selection = window.getSelection();

      if (selection) {
        range.setStart(
          contentRef.current.childNodes[0],
          cursorPositionRef.current,
        );
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }, []);

  return (
    <div className="preview-resize-container">
      <div className="cm-tag-design-container">
        <div className="cm-preview-header">
          <div className="pdfdesign-add-del-container">
            <div className="pdfdesign-add-button">
              <button
                type="button"
                id="addpage"
                onClick={() => {
                  // }
                  // console.log('adding new page :', noOfPages + 1);
                  // setUIPages(tempMap);
                  addPage();
                  // showTemplates();
                }}
              >
                <span>
                  <svg
                    fill="#000000"
                    viewBox="-3.5 0 19 19"
                    xmlns="http://www.w3.org/2000/svg"
                    className="cf-icon-svg"
                  >
                    <path d="M11.16 16.153a.477.477 0 0 1-.476.475H1.316a.477.477 0 0 1-.475-.475V3.046a.477.477 0 0 1 .475-.475h6.95l2.893 2.893zm-1.11-9.924H8.059a.575.575 0 0 1-.574-.574V3.679H1.95v11.84h8.102zm-1.519 4.198a.475.475 0 0 1-.475.475H6.475v1.582a.475.475 0 1 1-.95 0v-1.582H3.944a.475.475 0 0 1 0-.95h1.581v-1.58a.475.475 0 0 1 .95 0v1.58h1.581a.475.475 0 0 1 .475.475z" />
                  </svg>
                </span>
                Add Page
              </button>
            </div>
            {pages.size > 1 && (
              <div className="pdfdesign-delete-button">
                <button
                  type="button"
                  id="deletepage"
                  onClick={() => {
                    deletePage();
                  }}
                >
                  <span>
                    <svg
                      fill="#000000"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 100 100"
                      enableBackground="new 0 0 100 100"
                      xmlSpace="preserve"
                    >
                      <g>
                        <g>
                          <path
                            d="M73.603,78.553h-6.49h0c-0.956,0-1.73,0.774-1.73,1.73h-0.007v3.01H24.735V36.16h17.723c0.956,0,1.73-0.774,1.73-1.73
			V16.707h21.188l0,20.627h0.008c0.003,0.954,0.776,1.726,1.73,1.726h6.49c0.954,0,1.727-0.772,1.729-1.726h0V37.33l0-0.001
			c0,0,0-0.001,0-0.001l0-24.828h-0.005V8.48c0-0.956-0.774-1.73-1.73-1.73h-2.45v0H42.457v0h-1.73L14.778,32.7v2.447v1.013v52.912
			v2.447c0,0.956,0.774,1.73,1.73,1.73h1.582h53.925h1.582c0.956,0,1.73-0.774,1.73-1.73v-2.448h0.005l0-8.789l0-0.001
			C75.333,79.328,74.559,78.553,73.603,78.553z"
                          />
                        </g>
                        <g>
                          <path
                            d="M79.21,58.751l5.505-5.505c0.676-0.676,0.676-1.771,0-2.447l-4.894-4.893c-0.676-0.676-1.771-0.676-2.446,0l-5.505,5.505
			l-5.505-5.505c-0.676-0.676-1.77-0.676-2.446,0L59.026,50.8c-0.676,0.676-0.676,1.771,0,2.447l5.505,5.505l-5.505,5.505
			c-0.676,0.676-0.676,1.771,0,2.446l4.894,4.893c0.676,0.676,1.77,0.676,2.446,0l5.505-5.505l5.505,5.505
			c0.676,0.676,1.77,0.676,2.446,0l4.894-4.893c0.676-0.676,0.676-1.771,0-2.446L79.21,58.751z"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                  Delete Page
                </button>
              </div>
            )}
          </div>

          <div>Design</div>
          <div className="pdfdesign-flex-btn">
            <div className="customtag-preview-button">
              <button id="preview" onClick={() => setPdfPreview(!showPreview)}>
                <span>
                  <svg
                    fill="#000000"
                    viewBox="0 0 92 92"
                    className="cf-icon-svg"
                  >
                    <path
                      d="M91.3,43.8C90.6,42.8,74.4,19,46,19C17.6,19,1.4,42.8,0.7,43.8c-0.9,1.3-0.9,3.1,0,4.5
    C1.4,49.2,17.6,73,46,73c28.4,0,44.6-23.8,45.3-24.8C92.2,46.9,92.2,45.1,91.3,43.8z M46,65C26.7,65,13.5,51.4,9,46
    c4.5-5.5,17.6-19,37-19c19.3,0,32.5,13.6,37,19C78.4,51.5,65.3,65,46,65z M48.3,29.6c-4.4-0.6-8.7,0.5-12.3,3.2c0,0,0,0,0,0
    c-7.3,5.5-8.8,15.9-3.3,23.2c2.7,3.6,6.5,5.8,10.9,6.5c0.8,0.1,1.6,0.2,2.3,0.2c3.6,0,7-1.2,9.9-3.3c7.3-5.5,8.8-15.9,3.3-23.2
    C56.6,32.5,52.7,30.2,48.3,29.6z M52.3,54.5c-2.2,1.7-5,2.4-7.8,2c-2.8-0.4-5.3-1.9-7-4.1C34.1,47.7,35,41,39.7,37.5
    c2.2-1.7,5-2.4,7.8-2c2.8,0.4,5.3,1.9,7,4.1C57.9,44.3,57,51,52.3,54.5z M51.9,40c0.8,0.7,1.2,1.8,1.2,2.8c0,1-0.4,2.1-1.2,2.8
    c-0.7,0.7-1.8,1.2-2.8,1.2c-1.1,0-2.1-0.4-2.8-1.2c-0.8-0.8-1.2-1.8-1.2-2.8c0-1.1,0.4-2.1,1.2-2.8c0.7-0.8,1.8-1.2,2.8-1.2
    C50.2,38.9,51.2,39.3,51.9,40z"
                    />
                  </svg>
                </span>
                Preview
              </button>
            </div>
            <div className="customtag-preview-button">
              <button id="portrait">
                <span>
                  <svg viewBox="0 0 1024 1024" className="icon" version="1.1">
                    <path
                      d="M719.8 651.8m-10 0a10 10 0 1 0 20 0 10 10 0 1 0-20 0Z"
                      fill="#E73B37"
                    />
                    <path
                      d="M512.1 64H172v896h680V385.6L512.1 64z m278.8 324.3h-280v-265l280 265zM808 916H216V108h278.6l0.2 0.2v296.2h312.9l0.2 0.2V916z"
                      fill="#39393A"
                    />
                    <path d="M280.5 530h325.9v16H280.5z" fill="#39393A" />
                    <path d="M639.5 530h90.2v16h-90.2z" fill="#E73B37" />
                    <path d="M403.5 641.8h277v16h-277z" fill="#39393A" />
                    <path d="M280.6 641.8h91.2v16h-91.2z" fill="#E73B37" />
                    <path d="M279.9 753.7h326.5v16H279.9z" fill="#39393A" />
                    <path d="M655.8 753.7h73.9v16h-73.9z" fill="#E73B37" />
                  </svg>
                </span>
                Portrait
              </button>
            </div>
          </div>
        </div>
        {/* <div>
          <style>{COMMON_PDF_CSS}</style>
        </div> */}
        {showHtmlEditor === false ? (
          <div
            // contentEditable
            // suppressContentEditableWarning
            onDrop={handleDrop}
            onDragOver={e => e.preventDefault()}
            className="cm-tag-design-section"
            ref={contentRef}
          >
            {/* {components &&
            components.map((component, index) => React.cloneElement(component))} */}
            {components &&
              components.length > 0 &&
              components.map((component, index) => {
                return React.cloneElement(component, {
                  onValueChange: (
                    id: string,
                    newValue: string | string[] | string[][],
                  ) => {
                    const tempFields = [...components];

                    const updatedFields = tempFields.map(f =>
                      f.key === id
                        ? {
                            ...f,
                            props: {
                              ...f.props,
                              value:
                                typeof newValue === 'string'
                                  ? newValue //newValue.replace('&lt;br&gt;', '<br>')
                                  : Array.isArray(newValue)
                                  ? newValue.map((val, index) => {
                                      return typeof val === 'string'
                                        ? val //val.replace('&lt;br&gt;', '<br>')
                                        : val;
                                    })
                                  : newValue,
                            },
                          }
                        : f,
                    );
                    // console.log('After updating', tempFields);
                    contentRef.current &&
                      updateContent(contentRef.current?.innerHTML);
                    setComponents(updatedFields);
                  },
                });
              })}
          </div>
        ) : (
          <>
            <style>{cssContent}</style>

            <div
              ref={contentRef}
              contentEditable
              suppressContentEditableWarning
              dangerouslySetInnerHTML={{
                __html: htmlContent ? htmlContent : 'no content ',
              }}
              // onInput={handleContentChange}
              onBlur={handleContentChange}
              // (event: React.ChangeEvent<HTMLTextAreaElement>) => {
              //   // setContent &&
              //   // contentRef.current &&
              //   updateContent(event.target.innerHTML);
              // }}
            />
          </>
        )}
        <ContentMoveElement
          selectedElementId={selectedElementId}
          deleteComponent={deleteComponent}
          moveComponentUp={moveComponentUp}
          moveComponentDown={moveComponentDown}
        />
        <PdfPagination
          noOfPages={pages.size}
          activePage={activePage}
          setActivePage={setActivePage}
        ></PdfPagination>
      </div>
    </div>
  );
};
