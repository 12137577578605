import { FC } from 'react';
import './_elementsv2.scss';
import {
  BUTTON_TAG,
  CHECK_BOX_TAG,
  // EMAIL_TAG,
  INPUT_FIELD_TAG,
  LABEL_TAG,
  PASSWORD_FIELD_TAG,
  RADIO_BUTTONS_TAG,
  RANGE_TAG,
  SEARCH_BAR_TAG,
  SELECT_TAG,
  SWITCH_BUTTON_TAG,
  elementsLabelAndTagMap,
} from '../../types/elementTypes';
import { Title } from '../Title/Title';
import { ElementJson, getElementJson } from '../../utils/elementsUtil';
import { LAYOUT_TYPE } from '../../types/layoutTypes';

export interface ElementsV2Props {
  pageNumber: number;
  hide?: boolean;
  elementListMap: Map<number, ElementJson[]>;
  pagesLayoutMap: Map<number, LAYOUT_TYPE>;
  setElementListMap: (elementListMap: Map<number, ElementJson[]>) => void;
  setLayout: (pageLayouts: Map<number, LAYOUT_TYPE>) => void;
}

const addUpdateElementInListMap = (
  pageNumber: number,
  elementListMap: Map<number, ElementJson[]>,
  elementType: string,
  // setElementListMap: (elementListMap: Map<number, ElementJson[]>) => void,
) => {
  const tempMap = new Map(elementListMap);
  const elementList = tempMap.get(pageNumber);
  elementList
    ? tempMap.set(pageNumber, [...elementList, getElementJson(elementType)])
    : tempMap.set(pageNumber, [getElementJson(elementType)]);
  return tempMap;
};

const addUpdatePageLayoutIntMap = (
  pageNumber: number,
  pageLayoutMap: Map<number, LAYOUT_TYPE>,
  layout: LAYOUT_TYPE,
) => {
  const tempMap = new Map(pageLayoutMap);
  tempMap.set(pageNumber, layout);

  return tempMap;
};

export const ElementsV2: FC<ElementsV2Props> = ({
  pageNumber,
  hide = false,
  setElementListMap,
  elementListMap,
  setLayout,
  pagesLayoutMap,
}) => {
  return hide === false ? (
    <div className="resize-container">
      <div className="cm-element-container">
        <div className="title">
          <Title text="Design Campaign" />
        </div>
        <div className="row">
          <div className="cm-col">
            <div className="cm-tabs">
              <div className="cm-tab">
                <input type="checkbox" id="chck1" />
                <label className="tab-label" htmlFor="chck1">
                  Form elements
                </label>
                <div className="tab-content">
                  <div className="elements-wrapper">
                    <div
                      className="elements"
                      onClick={() => {
                        setElementListMap(
                          addUpdateElementInListMap(
                            pageNumber,
                            elementListMap,
                            INPUT_FIELD_TAG,
                          ),
                        );
                      }}
                    >
                      <img src="input-text.png" />
                      <div className="element-name">
                        {elementsLabelAndTagMap.get(INPUT_FIELD_TAG)}
                      </div>
                    </div>
                    <div
                      className="elements"
                      onClick={() =>
                        setElementListMap(
                          addUpdateElementInListMap(
                            pageNumber,
                            elementListMap,
                            CHECK_BOX_TAG,
                          ),
                        )
                      }
                    >
                      <img src="checkbox.png" />
                      <div className="element-name">
                        {elementsLabelAndTagMap.get(CHECK_BOX_TAG)}
                      </div>
                    </div>
                    <div
                      className="elements"
                      onClick={() =>
                        setElementListMap(
                          addUpdateElementInListMap(
                            pageNumber,
                            elementListMap,
                            RANGE_TAG,
                          ),
                        )
                      }
                    >
                      <img src="range.png" />
                      <div className="element-name">
                        {elementsLabelAndTagMap.get(RANGE_TAG)}
                      </div>
                    </div>
                    <div
                      className="elements"
                      onClick={() =>
                        setElementListMap(
                          addUpdateElementInListMap(
                            pageNumber,
                            elementListMap,
                            PASSWORD_FIELD_TAG,
                          ),
                        )
                      }
                    >
                      <img src="password.png" />
                      <div className="element-name">
                        {elementsLabelAndTagMap.get(PASSWORD_FIELD_TAG)}
                      </div>
                    </div>
                    {/* <div
                      className="elements"
                      onClick={() =>
                        addElementInListMap(
                          pageNumber,
                          elementListMap,
                          INPUT_FIELD_TAG,
                          setElementListMap,
                        )
                      }
                    >
                      <img src="password.png" />
                      <div className="element-name">
                        {elementsLabelAndTagMap.get(PASSWORD_FIELD_TAG)}
                      </div>
                    </div> */}
                    <div
                      className="elements"
                      onClick={() =>
                        setElementListMap(
                          addUpdateElementInListMap(
                            pageNumber,
                            elementListMap,
                            RADIO_BUTTONS_TAG,
                          ),
                        )
                      }
                    >
                      <img src="radio.png" />
                      <div className="element-name">
                        {elementsLabelAndTagMap.get(RADIO_BUTTONS_TAG)}
                      </div>
                    </div>
                    <div
                      className="elements"
                      onClick={() =>
                        setElementListMap(
                          addUpdateElementInListMap(
                            pageNumber,
                            elementListMap,
                            SEARCH_BAR_TAG,
                          ),
                        )
                      }
                    >
                      <img src="search.png" />
                      <div className="element-name">
                        {elementsLabelAndTagMap.get(SEARCH_BAR_TAG)}
                      </div>
                    </div>
                    <div
                      className="elements"
                      onClick={() =>
                        setElementListMap(
                          addUpdateElementInListMap(
                            pageNumber,
                            elementListMap,
                            SELECT_TAG,
                          ),
                        )
                      }
                    >
                      <img src="select.png" />
                      <div className="element-name">
                        {elementsLabelAndTagMap.get(SELECT_TAG)}
                      </div>
                    </div>
                    <div
                      className="elements"
                      onClick={() =>
                        setElementListMap(
                          addUpdateElementInListMap(
                            pageNumber,
                            elementListMap,
                            SWITCH_BUTTON_TAG,
                          ),
                        )
                      }
                    >
                      <img src="switch.png" />
                      <div className="element-name">
                        {elementsLabelAndTagMap.get(SWITCH_BUTTON_TAG)}
                      </div>
                    </div>
                    <div
                      className="elements"
                      onClick={() =>
                        setElementListMap(
                          addUpdateElementInListMap(
                            pageNumber,
                            elementListMap,
                            BUTTON_TAG,
                          ),
                        )
                      }
                    >
                      <img src="switch.png" />
                      <div className="element-name">
                        {elementsLabelAndTagMap.get(BUTTON_TAG)}
                      </div>
                    </div>
                    <div
                      className="elements"
                      onClick={() =>
                        setElementListMap(
                          addUpdateElementInListMap(
                            pageNumber,
                            elementListMap,
                            LABEL_TAG,
                          ),
                        )
                      }
                    >
                      <img src="text.png" />
                      <div className="element-name">
                        {elementsLabelAndTagMap.get(LABEL_TAG)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="cm-col">
            <div className="cm-tabs">
              <div className="cm-tab">
                <input type="checkbox" id="chck2" />
                <label className="tab-label" htmlFor="chck2">
                  Layouts
                </label>
                <div className="tab-content">
                  <div className="elements-wrapper">
                    <div
                      className="elements"
                      onClick={() =>
                        setLayout(
                          addUpdatePageLayoutIntMap(
                            pageNumber,
                            pagesLayoutMap,
                            LAYOUT_TYPE.SINGLE_COLUMN_LAYOUT,
                          ),
                        )
                      }
                    >
                      <img src="input-text.png" />
                      <div className="element-name">Single Column</div>
                    </div>
                    <div
                      className="elements"
                      onClick={() =>
                        setLayout(
                          addUpdatePageLayoutIntMap(
                            pageNumber,
                            pagesLayoutMap,
                            LAYOUT_TYPE.TWO_COLUMN_LAYOUT,
                          ),
                        )
                      }
                    >
                      <img src="checkbox.png" />
                      <div className="element-name">Two Column</div>
                    </div>
                    <div className="elements">
                      <img src="range.png" />
                      <div className="element-name">Range</div>
                    </div>
                    <div className="elements">
                      <img src="password.png" />
                      <div className="element-name">Password field</div>
                    </div>
                    <div className="elements">
                      <img src="radio.png" />
                      <div className="element-name">Radio buttons</div>
                    </div>
                    <div className="elements">
                      <img src="search.png" />
                      <div className="element-name">Search Bar</div>
                    </div>
                    <div className="elements">
                      <img src="select.png" />
                      <div className="element-name">Select</div>
                    </div>
                    <div className="elements">
                      <img src="switch.png" />
                      <div className="element-name">Switch button</div>
                    </div>
                    <div className="elements">
                      <img src="text.png" />
                      <div className="element-name">Text field</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="cm-col">
            <div className="cm-tabs">
              <div className="cm-tab">
                <input type="checkbox" id="chck3" />
                <label className="tab-label" htmlFor="chck3">
                  Templates
                </label>
                <div className="tab-content">
                  <div className="elements-wrapper">
                    <div className="elements">
                      <img src="input-text.png" />
                      <div className="element-name">Customer Details</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div style={{ display: hide ? 'block' : 'none' }}></div>
  );
};
