import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Breadcrum } from '../../atoms/Breadcrum/Breadcrum';
import InputBox from '../../atoms/InputBox/InputBox';
import { PreviewBox } from '../../atoms/PreviewBox/PreviewBox';
import PreviousButton from '../../atoms/PreviousButton/PreviousButton';
import SelectBox, { SelectOption } from '../../atoms/Select/Select';
// import Stepper from '../../atoms/Stepper/Stepper';
import Textbox from '../../atoms/Textbox/Textbox';
import { Title } from '../../atoms/Title/Title';
import { UploadButton } from '../../atoms/UploadButton/UploadButton';
import { dataSourceFormValidation } from './validation';
import { useFormik } from 'formik';
import { DataSourceDetails, DataSourceTable } from '../../types/datasource';
import './DataSource.scss';
import '../../styles/_CommonStyles.scss';
//import { Button } from '../../atoms/GenerallButton/Button';
import { Button } from 'cm-react-components/dist/js';
import { useGetDataSourceTables } from '../../hooks/useGetDataSourceTables/useGetDataSourceTables';
import { useSaveDataSource } from '../../hooks/useSaveDataSource/useSaveDataSource';
import { useGetDataSources } from '../../hooks/useGetDataSources/useGetDataSources';
//import NextButton from '../../atoms/NextButton/NextButton';
import { useGetDataSourceTablesByDsaId } from '../../hooks/useGetDataSourceTablesByDsId/useGetDataSourceTablesByDsId';
import { useGetCampaignDatasource } from '../../hooks/useGetCampaignDatasource/useGetCampaignDatasource';
import { useGetDataSourceNameExists } from '../../hooks/useGetDataSourceNameExists/useGetDataSourceNameExists';
import { DATABASE_TYPES } from '../../types/constants';
import { Next } from '../../atoms/Next/Next';

interface UseFormikWithButtonsProps {
  initialValues: DataSourceDetails;
  onSubmit: (values: DataSourceDetails, activeButton: string) => void;
}

const useFormikWithButtons = ({
  initialValues,
  onSubmit,
}: UseFormikWithButtonsProps) => {
  const [activeButton, setActiveButton] = useState('');

  const formik = useFormik({
    initialValues,
    validationSchema: dataSourceFormValidation,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values: DataSourceDetails) => {
      onSubmit(values, activeButton);
    },
  });

  return { ...formik, activeButton, setActiveButton };
};

const DataSource: FC = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('database connection');
  const [tableOptionList, setTableOptionList] = useState<SelectOption[]>([]);
  const [tableList, setTableList] = useState<DataSourceTable[]>([]);
  const [queryParams] = useSearchParams();
  const campaignId =
    queryParams.get('id') && queryParams.get('id') !== null
      ? queryParams.get('id')
      : '';
  const handleRadioChange = (event: any) => {
    setSelectedOption(event.target.value);
  };
  const {
    mutate: getCampaingDatasource,
    isLoading: loadingCampaignDatasource,
    data: campaignDatasource,
  } = useGetCampaignDatasource();
  let isEditing =
    campaignDatasource && campaignDatasource.databaseName ? true : false;

  const { mutate: getDataSourceTables } = useGetDataSourceTables();
  const { mutate: saveDataSourceMutate } = useSaveDataSource();
  const { mutate: checkNameExists } = useGetDataSourceNameExists();
  const { data: datasourceslist } = useGetDataSources();
  const datasources: SelectOption[] = datasourceslist
    ? datasourceslist.map(item => {
        return {
          label: item.name,
          value: item.datasourceId,
        };
      })
    : [];
  const { mutate: loadSelectedDDsTables } = useGetDataSourceTablesByDsaId();

  const handleDatasourceSelectionChange = (option: SelectOption | any) => {
    formik.setFieldValue('datasourceId', option.value);
    loadSelectedDDsTables(option.value, {
      onSuccess: data => {
        updateTableSelecList(data);
      },
      onError: () => {
        console.log('Error loading tables by ds ');
      },
    });
  };

  const updateTableSelecList = (data: any) => {
    if (data) {
      setTableList(data);
      const tables = data.map((table: any) => {
        return {
          value: table.schema + '.' + table.tableName,
          label: table.schema + '.' + table.tableName,
        };
      });
      setTableOptionList(tables);
    }
  };

  const loadTables = (values: any) => {
    getDataSourceTables(
      {
        name: values.name,
        databaseType: values.databaseType,
        hostName: values.hostName,
        port: values.port,
        databaseName: values.databaseName,
        userName: values.userName,
        password: values.password,
      },
      {
        onSuccess: data => {
          updateTableSelecList(data);
        },
        onError: () => {
          console.log('Error loading tables');
        },
      },
    );
  };

  const saveDataSource = (values: any, activeButton: string) => {
    if (activeButton === 'save') {
      formik.validateForm().then((result: any) => {
        if (result && Object.keys(result).length > 0) {
          console.log('Errors :: ', result);
          // addToast({
          //   label: `Please provide mandatory information`,
          //   type: 'error',
          // });
        } else {
          saveDataSourceMutate(values, {
            onSuccess: () => {
              navigate(`/selecttemplate?id=${campaignId}`);
            },
            onError: () => {
              console.log('Datasource Error');
            },
          });
        }
      });
    } else if (activeButton === 'button') {
      loadTables(values);
    }
  };

  const formik = useFormikWithButtons({
    initialValues: {
      name: '',
      databaseType: '',
      hostName: '',
      port: '',
      databaseName: '',
      userName: '',
      password: '',
      campaignId: campaignId ? campaignId : '',
      tables: [],
      selectedTables: [],
    },
    //validationSchema: dataSourceFormValidation,
    onSubmit: async (values: DataSourceDetails, activeButton: string) => {
      saveDataSource(values, activeButton);
    },
  });

  const handleDatabaseTypeSelectionChange = (option: SelectOption | any) => {
    formik.setFieldValue('databaseType', option.value);
  };

  const handleTableSelectionChange = (option: SelectOption[] | any) => {
    const selectedTables = tableList.filter(
      table => table.schema + '.' + table.tableName === option[0].value,
    );
    formik.setFieldValue('tables', selectedTables);

    formik.setFieldValue('selectedTables', option);
  };

  const checkDsNameExists = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (campaignDatasource && campaignDatasource.name === value) {
      formik.handleChange(e);
      return;
    }
    checkNameExists(
      { name: value },
      {
        onSuccess: data => {
          if (data && data === true) {
            formik.setFieldError('name', 'Datasource Name already exists');
          }
        },
        onError: () => {
          formik.handleChange(e);
        },
      },
    );
    formik.handleChange(e);
  };

  useEffect(() => {
    if (campaignId && campaignId !== null) {
      getCampaingDatasource(campaignId);
    }
  }, []);

  useEffect(() => {
    if (!loadingCampaignDatasource && campaignDatasource) {
      formik.setFieldValue('datasourceId', campaignDatasource.id);
      formik.setFieldValue('name', campaignDatasource.name);
      formik.setFieldValue('databaseType', campaignDatasource.databaseType);
      formik.setFieldValue('hostName', campaignDatasource.hostName);
      formik.setFieldValue('port', campaignDatasource.port);
      formik.setFieldValue('databaseName', campaignDatasource.databaseName);
      formik.setFieldValue('userName', campaignDatasource.userName);
      formik.setFieldValue('password', campaignDatasource.password);
      formik.setFieldValue('campaignId ', campaignId);
      formik.setFieldValue('tables', campaignDatasource.tables);
      const tables = campaignDatasource.tables
        ? campaignDatasource.tables.map((table: any) => {
            return {
              value: table.schema + '.' + table.tableName,
              label: table.schema + '.' + table.tableName,
            };
          })
        : [];
      setTableOptionList(tables);
      formik.setFieldValue('selectedTables', tables);
      isEditing = true;
    }
  }, [campaignDatasource, loadingCampaignDatasource]);

  // useEffect(() => {
  //   if (isNameExists && isNameExists === true) {
  //     formik.setFieldError('name', 'Name exists');
  //   }
  //   formik.handleBlur;
  // }, [isNameExists]);

  return (
    <div className="dataSource-container">
      <form onSubmit={formik.handleSubmit}>
        <Breadcrum pageName={'Create a Campaign'} />

        <div className="title">
          <Title text="Please provide datasource details" />
        </div>

        <div className="grid-layout">
          <div>
            {/* <Stepper step={3} /> */}

            {/* <div className="subHeading">Data Source</div>

            <p className="subHeading-text">
              {' '}
              Define your data source for personalisation
            </p> */}

            {/* <p className="label-audience-by">Data Source details:</p> */}

            <div className="input-grid-layout">
              <div>
                <input
                  type="radio"
                  id="uploadingData"
                  name="audienceBy"
                  value="uploading data"
                  checked={selectedOption === 'uploading data'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="uploadingData">Uploading data</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="databaseConnection"
                  name="audienceBy"
                  value="database connection"
                  checked={selectedOption === 'database connection'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="databaseConnection">
                  Adding data base connection details
                </label>
              </div>
            </div>

            {selectedOption === 'uploading data' && (
              <div className="upload-section">
                <div className="border-style">
                  <div className="upload-text">
                    Click upload or <br />
                    drag and drop file here
                  </div>

                  <div className="filesName">
                    .xls,.xlsx & .csv file formats are supported
                  </div>

                  <UploadButton />
                </div>
              </div>
            )}

            {selectedOption === 'database connection' && (
              <div style={{ marginTop: '20px' }}>
                <SelectBox
                  name={'datasourceId'}
                  label={'Choose Existing Database'}
                  id={'datasourceId'}
                  list={datasources}
                  onChange={handleDatasourceSelectionChange}
                />

                <InputBox
                  label={'Name'}
                  id={'name'}
                  name={'name'}
                  placeholder={'name'}
                  onChange={checkDsNameExists}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  errorMessage={formik.errors.name}
                />

                <InputBox
                  label={'Host Name / Address'}
                  id={'hostName'}
                  name={'hostName'}
                  placeholder={'www.hostname.com or 123.456.78.999'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.hostName}
                  errorMessage={formik.errors.hostName}
                />
                <SelectBox
                  name={'databaseType'}
                  id={'databaseType'}
                  label={'Database Type'}
                  list={DATABASE_TYPES}
                  value={
                    DATABASE_TYPES.filter(
                      obj =>
                        formik.values.databaseType === obj.value.toString(),
                    )[0]
                  }
                  placeholder={'Choose...'}
                  onChange={handleDatabaseTypeSelectionChange}
                />
                <InputBox
                  label={'Port'}
                  id={'port'}
                  name={'port'}
                  placeholder={'4404'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.port}
                  errorMessage={formik.errors.port}
                />
                <InputBox
                  label={'Maintenance Database'}
                  id={'databaseName'}
                  name={'databaseName'}
                  placeholder={'Enter maintenance database'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.databaseName}
                  errorMessage={formik.errors.databaseName}
                />
                <InputBox
                  label={'Username'}
                  id={'userName'}
                  name={'userName'}
                  placeholder={'Enter username'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userName}
                  errorMessage={formik.errors.userName}
                />

                <InputBox
                  label={'Password'}
                  id={'password'}
                  name={'password'}
                  password={true}
                  placeholder={'Enter password'}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  errorMessage={formik.errors.password}
                />

                {/* <ToggleSwitch label={'Save Password'} /> */}
                <div className="preview-button">
                  <Button
                    label={'Check connection'}
                    onClick={() => formik.setActiveButton('button')}
                  />
                </div>

                <SelectBox
                  name={'selectedTables'}
                  id={'selectedTables'}
                  label={'Table List'}
                  list={tableOptionList}
                  placeholder={'Choose...'}
                  isMulti={true}
                  value={
                    formik.values.selectedTables
                      ? formik.values.selectedTables[0]
                      : undefined
                  }
                  onChange={handleTableSelectionChange}
                />

                <Textbox
                  label={'SQL Query'}
                  id={''}
                  name={''}
                  row={4}
                  placeholder={'Enter the query here'}
                />

                <div className="preview-button">
                  <Button label={'Preview data'} />
                </div>
              </div>
            )}
          </div>

          <div className="html-preview-section">
            <PreviewBox code={''} />
          </div>

          <div className="navigationButtons">
            <Link to={`/audience?id=${campaignId}`}>
              <PreviousButton />
            </Link>

            {/* {isEditing && (
              <Link to={`/selecttemplate?id=${campaignId}`}>
                <Button name={'Skip'} />
              </Link>
            )} */}
            {isEditing && (
              <Link to={`/selecttemplate?id=${campaignId}`}>
                <Button label="Skip" size="medium" />
              </Link>
            )}
            {/* <NextButton
              type={'submit'}
              onClick={() => formik.setActiveButton('save')}
            /> */}
            <Next
              type={'submit'}
              label={isEditing ? 'save' : 'Next'}
              onClick={() => formik.setActiveButton('save')}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default DataSource;
