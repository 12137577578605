import {
  AppMenuBar as MenuBar,
  AppMenuItem as MenuItem,
} from 'cm-react-components/dist/js';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export interface MenuBarV2Props {
  isLoggedIn: boolean;
}
const dashboardLink = <Link to="/">Brands</Link>;
export const MenuBarV2: FC<MenuBarV2Props> = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.setItem('user.refreshToken', '');
    localStorage.setItem('user.accessToken', '');
    localStorage.setItem('user.email', '');

    localStorage.clear();
    navigate('/login');
  };

  return (
    <MenuBar handleSignOut={handleLogout}>
      <MenuItem label={'Brands'} active={true} link={dashboardLink}></MenuItem>
      <MenuItem label={'PDF'} active={true}>
        <MenuItem label={'Campaigns'} link={<Link to="/">Brands</Link>} />
        <MenuItem
          label="Tags Library"
          link={<Link to="/customtags">Custom Tags</Link>}
        />
        <MenuItem
          label="System Templates"
          link={<Link to="/systemtemplates">System Templates</Link>}
        />
        {/* <MenuItem label="System Templates" /> */}
      </MenuItem>
      <MenuItem
        label={'Forms'}
        active={true}
        link={<Link to="/campaign-list">Forms</Link>}
      />
      <MenuItem
        label={'Social'}
        active={true}
        link={<Link to="/campaign-list">Social</Link>}
      />
      <MenuItem
        label={'Web Pages'}
        active={true}
        link={<Link to="/campaign-list">Web Pages</Link>}
      />
      <MenuItem
        label={'Dashboards'}
        active={true}
        link={<Link to="/campaign-list">Dashboards</Link>}
      />
      <MenuItem
        label={'Contact Us'}
        active={true}
        link={<Link to="/campaign-list">Contact Us</Link>}
      />
      {/* <MenuItem label={'Settings'} isActive={true}>
        <MenuItem label="Preferences" />
      </MenuItem> */}
    </MenuBar>
  );
};
