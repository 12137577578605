import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateCampaignData } from '../context/createCampaignContext';
import { CampaignCssAndPages } from '../types/campaign';

const initialState: CreateCampaignData = {
  createdCampaignId: '',
  creatingCampaignType: '',
  campaignCssAndPages: new Map<string, CampaignCssAndPages>(),
  selectedSystemTemplateIds: new Map<string, string>(),
  backgroundDesign: 'rgb(200, 227, 238)',
  leftMenuFontColor: '#666666',
};

export const createCampaignSlice = createSlice({
  name: 'createCampaign',
  initialState,
  reducers: {
    updateCreatedCampaignId: (state, action: PayloadAction<string>) => {
      state.createdCampaignId = action.payload;
    },
    updateBrandId: (state, action: PayloadAction<string>) => {
      state.brandId = action.payload;
    },
    updateCampaignType: (state, action: PayloadAction<string>) => {
      state.creatingCampaignType = action.payload;
    },
    updateCampaignContent: (
      state,
      action: PayloadAction<Map<string, CampaignCssAndPages>>,
    ) => {
      state.campaignCssAndPages = action.payload;
    },
    updateCampaignTemplateIds: (
      state,
      action: PayloadAction<Map<string, string>>,
    ) => {
      state.selectedSystemTemplateIds = action.payload;
    },
    updateBackgroundDesign: (state, action: PayloadAction<string>) => {
      state.backgroundDesign = action.payload;
    },
    updateLeftMenuFontColor: (state, action: PayloadAction<string>) => {
      state.leftMenuFontColor = action.payload;
    },
  },
});

export const {
  updateBrandId,
  updateCreatedCampaignId,
  updateCampaignContent,
  updateCampaignType,
  updateCampaignTemplateIds,
  updateBackgroundDesign,
  updateLeftMenuFontColor,
} = createCampaignSlice.actions;
