import ValidationMessage from '../ValidationMessage/ValidationMessage';
import './InputBox.scss';

export interface InputBoxProps {
  label: string;
  id: string;
  name: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
  onBlur?: (e: React.ChangeEvent<any>) => void;
  value?: string;
  password?: boolean;
  errorMessage?: string;
}

const InputBox = (props: InputBoxProps) => {
  return (
    <div className="cm-inputbox-container">
      <div className="flex-display">
        <label htmlFor={props.id}>{props.label}</label>

        {props.errorMessage && <ValidationMessage label={props.errorMessage} />}
      </div>

      <input
        id={props.id}
        name={props.name}
        type={`${props.password === true ? 'password' : 'text'}`}
        placeholder={props.placeholder ? props.placeholder : 'Enter..'}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
      />
    </div>
  );
};

export default InputBox;
