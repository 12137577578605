import { useMutation, UseMutationResult } from 'react-query';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { QueryKeys } from '../../api/QueryKeys';

export const exportCampaignPdf = async (campaignId: string): Promise<Blob> =>
  api
    .get(URLs.EXPORT_CAMPAIGN_PDF(campaignId), {
      responseType: 'blob',
    })
    .then((res: any) => res.data);

export const useExportCampaignPdf = (): UseMutationResult<
  Blob,
  Error,
  string
> => {
  return useMutation<Blob, Error, string>(
    [QueryKeys.ExportCampaignPdf],
    (campaignId: string) =>
      api
        .get<Blob>(URLs.EXPORT_CAMPAIGN_PDF(campaignId), {
          responseType: 'blob',
        })
        .then(response => response.data),
  );
};
