export enum CAMPAIGN_TYPES {
  Social_Media = 'social_media',
  PDF = 'pdf',
  FORMS = 'forms',
}

export const campaignTypes = [
  {
    value: CAMPAIGN_TYPES.Social_Media,
    label: 'Social Media',
  },
  {
    value: CAMPAIGN_TYPES.PDF,
    label: 'PDF',
  },
  {
    value: CAMPAIGN_TYPES.FORMS,
    label: 'Forms',
  },
  // {
  //   value: 'email',
  //   label: 'EMAIL',
  // },
];

export enum TEMPLATE_TYPES {
  FINANCE = 'Finance',
  SCHOOL = 'School',
  CONTRACTS = 'Contracts',
  INVOICES = 'Invoices',
  BILLS = 'Bills',
}

export const templateTypes = [
  {
    value: TEMPLATE_TYPES.FINANCE,
    label: TEMPLATE_TYPES.FINANCE,
  },
  {
    value: TEMPLATE_TYPES.SCHOOL,
    label: TEMPLATE_TYPES.SCHOOL,
  },
  {
    value: TEMPLATE_TYPES.CONTRACTS,
    label: TEMPLATE_TYPES.CONTRACTS,
  },
  {
    value: TEMPLATE_TYPES.BILLS,
    label: TEMPLATE_TYPES.BILLS,
  },
  {
    value: TEMPLATE_TYPES.INVOICES,
    label: TEMPLATE_TYPES.INVOICES,
  },
];

export interface PdfTemplateInfo {
  templateId?: string;
  name: string;
  description: string;
  templateType: string;
  charset?: string;
  thumbnailUrl?: string;
}

export interface CampaignInfo {
  campaignId?: string;
  name: string;
  description: string;
  templateId: string;
  // charset?: string;
}

export interface CreateCampaignPage {
  name: string;
  json?: string;
  content: string;
  cssContent: string;
  orientation: string;
  index: number;
  active: boolean;
  templateId?: string;
}

export interface UICreateCampaignPage {
  name: string;
  json?: string;
  components?: JSX.Element[];
  content: string;
  cssContent: string;
  orientation: string;
  index: number;
  active: boolean;
  idList: string[];
  selectedElementId: string;
  systemTemplateId?: string;
}

export interface CampaignCssAndPages {
  templateId?: string;
  campaignId: string;
  campaignPages: CreateCampaignPage[];
  cssContent?: string;
  caption?: string;
}

export interface TemplatePage {
  name: string;
  json?: string;
  content: string;
  cssContent: string;
  orientation: string;
  index: number;
  active: boolean;
  templateId?: string;
}

export interface PdfTemplateCssAndPages {
  templateId: string;
  templatePages: TemplatePage[];
  cssContent?: string;
  caption?: string;
}

export interface CampaignDetails {
  campaignId: string;
  brandId: string;
  name: string;
  description: string;
  charset: string;
  isDeleted: number;
  currentCssVersion: number;
  createdAt: Date;
  createdAtStr: string;
  versions: number[];
  campaignType: string;
  currentVersion: number;
}

export interface CampaignsListResponse {
  records: CampaignDetails[];
  totalRecords: number;
  noOfPages: number;
}

export interface CampaignPreview {
  pages: CampaignPreviewPage[];
  cssContent: string;
  caption?: string;
}

export interface CampaignPreviewPage {
  id: string;
  campaignId: string;
  name: string;
  content: string;
  json: string;
  orientation: string;
  index: number;
  cssContent: string;
  templateId?: string;
}

export interface DeleteCampaignInfo {
  campaignId: string;
}

export interface CampaignSelectedTemplate {
  campaignId: string;
  templateId: string;
}

export interface Pagination {
  startIndex: number;
  endIndex: number;
  currentPage: number;
  pageSize: number;
}

export interface CampaignTrackingReq {
  pagination: Pagination;
  brandId: string;
}
export interface CampaignTrackingRes {
  campaignId: string;
  campaignName: string;
  campaignType: string;
  scheduleType: string;
  lastExecutionStartTime: string;
  lastExecutionEndTime: string;
  failCount: number;
  successCount: number;
}

export interface ExportPdf {
  campaignId: string;
}
