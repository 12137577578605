import './NewFooter.scss';
import { FC } from 'react';
export const NewFooter: FC = () => {
  return (
    <div>
      <footer>
        <div className="c1">
          <div className="footer-wrapper">
            <div className="footer-intro-column">
              <div className="footer-logo">
                <img
                  src="NCampaignSuite-white-logo.png"
                  className="white-logo"
                />
              </div>
              <p>Crafting IT Excellence for your Business.</p>
            </div>
            <div className="footer-column">
              <h2 className="footer-title">Links</h2>
              <ul className="footer-links">
                <li>
                  <a
                    href="https://www.nipunasolutions.co.uk/index.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Home
                  </a>
                </li>
                {/* <li>
                  <a
                    href="https://www.nipunasolutions.co.uk"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Nipuna Insights
                  </a>
                </li> */}
                {/* <li>
                  <a
                    href="https://www.nipunasolutions.co.uk"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Press Release
                  </a>
                </li> */}
                <li>
                  <a
                    href="https://www.nipunasolutions.co.uk/privacy-policy.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    href="terms-and-conditions.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </li>
                <li className="marg-b0">
                  <a
                    href="https://www.nipunasolutions.co.uk/contact-us.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-column">
              <h2 className="footer-title">Services</h2>
              <ul className="footer-links">
                <li>
                  <a
                    href="https://www.nipunasolutions.co.uk/services.html#cyber-security"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cybersecurity
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.nipunasolutions.co.uk/services.html#data-analytics"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Analytics
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.nipunasolutions.co.uk/services.html#ai"
                    target="_blank"
                    rel="noreferrer"
                  >
                    AI
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.nipunasolutions.co.uk/services.html#it-consulting"
                    target="_blank"
                    rel="noreferrer"
                  >
                    IT Consulting
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.nipunasolutions.co.uk/services.html#mobile-application"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Mobile App Development
                  </a>
                </li>
                <li className="marg-b0">
                  <a
                    href="https://www.nipunasolutions.co.uk/services.html#digital-transformation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Digital Transformation
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-column">
              <h2 className="footer-title">Social Links</h2>
              <ul className="footer-social-links">
                <li>
                  <a
                    href="https://www.facebook.com/profile.php?id=100073958389457/"
                    target="_blank"
                    className="facebook"
                    rel="noreferrer"
                  >
                    <svg
                      fill="#000000"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="-143 145 512 512"
                      xmlSpace="preserve"
                    >
                      {' '}
                      <g>
                        {' '}
                        <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M272.8,560.7 c-20.8,20.8-44.9,37.1-71.8,48.4c-27.8,11.8-57.4,17.7-88,17.7c-30.5,0-60.1-6-88-17.7c-26.9-11.4-51.1-27.7-71.8-48.4 c-20.8-20.8-37.1-44.9-48.4-71.8C-107,461.1-113,431.5-113,401s6-60.1,17.7-88c11.4-26.9,27.7-51.1,48.4-71.8 c20.9-20.8,45-37.1,71.9-48.5C52.9,181,82.5,175,113,175s60.1,6,88,17.7c26.9,11.4,51.1,27.7,71.8,48.4 c20.8,20.8,37.1,44.9,48.4,71.8c11.8,27.8,17.7,57.4,17.7,88c0,30.5-6,60.1-17.7,88C309.8,515.8,293.5,540,272.8,560.7z" />{' '}
                        <path d="M146.8,313.7c10.3,0,21.3,3.2,21.3,3.2l6.6-39.2c0,0-14-4.8-47.4-4.8c-20.5,0-32.4,7.8-41.1,19.3 c-8.2,10.9-8.5,28.4-8.5,39.7v25.7H51.2v38.3h26.5v133h49.6v-133h39.3l2.9-38.3h-42.2v-29.9C127.3,317.4,136.5,313.7,146.8,313.7z" />
                      </g>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/nipunasolutions/"
                    target="_blank"
                    className="linkedin"
                    rel="noreferrer"
                  >
                    <svg
                      fill="#000000"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="-143 145 512 512"
                      xmlSpace="preserve"
                    >
                      {' '}
                      <g>
                        {' '}
                        <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M272.8,560.7 c-20.8,20.8-44.9,37.1-71.8,48.4c-27.8,11.8-57.4,17.7-88,17.7c-30.5,0-60.1-6-88-17.7c-26.9-11.4-51.1-27.7-71.8-48.4 c-20.8-20.8-37.1-44.9-48.4-71.8C-107,461.1-113,431.5-113,401s6-60.1,17.7-88c11.4-26.9,27.7-51.1,48.4-71.8 c20.9-20.8,45-37.1,71.9-48.5C52.9,181,82.5,175,113,175s60.1,6,88,17.7c26.9,11.4,51.1,27.7,71.8,48.4 c20.8,20.8,37.1,44.9,48.4,71.8c11.8,27.8,17.7,57.4,17.7,88c0,30.5-6,60.1-17.7,88C309.8,515.8,293.5,540,272.8,560.7z" />{' '}
                        <rect x="-8.5" y="348.4" width="49.9" height="159.7" />{' '}
                        <path d="M15.4,273c-18.4,0-30.5,11.9-30.5,27.7c0,15.5,11.7,27.7,29.8,27.7h0.4c18.8,0,30.5-12.3,30.4-27.7 C45.1,284.9,33.8,273,15.4,273z" />{' '}
                        <path d="M177.7,346.9c-28.6,0-46.5,15.6-49.8,26.6v-25.1H71.8c0.7,13.3,0,159.7,0,159.7h56.1v-86.3c0-4.9-0.2-9.7,1.2-13.1 c3.8-9.6,12.1-19.6,27-19.6c19.5,0,28.3,14.8,28.3,36.4v82.6H241v-88.8C241,369.9,213.2,346.9,177.7,346.9z" />{' '}
                      </g>{' '}
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/nipunasolutions/"
                    target="_blank"
                    className="instagram"
                    rel="noreferrer"
                  >
                    <svg
                      fill="#000000"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="-143 145 512 512"
                      xmlSpace="preserve"
                    >
                      {' '}
                      <g>
                        {' '}
                        <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M272.8,560.7 c-20.8,20.8-44.9,37.1-71.8,48.4c-27.8,11.8-57.4,17.7-88,17.7c-30.5,0-60.1-6-88-17.7c-26.9-11.4-51.1-27.7-71.8-48.4 c-20.8-20.8-37.1-44.9-48.4-71.8C-107,461.1-113,431.5-113,401s6-60.1,17.7-88c11.4-26.9,27.7-51.1,48.4-71.8 c20.9-20.8,45-37.1,71.9-48.5C52.9,181,82.5,175,113,175s60.1,6,88,17.7c26.9,11.4,51.1,27.7,71.8,48.4 c20.8,20.8,37.1,44.9,48.4,71.8c11.8,27.8,17.7,57.4,17.7,88c0,30.5-6,60.1-17.7,88C309.8,515.8,293.5,540,272.8,560.7z" />{' '}
                        <path d="M191.6,273h-157c-27.3,0-49.5,22.2-49.5,49.5v52.3v104.8c0,27.3,22.2,49.5,49.5,49.5h157c27.3,0,49.5-22.2,49.5-49.5V374.7 v-52.3C241,295.2,218.8,273,191.6,273z M205.8,302.5h5.7v5.6v37.8l-43.3,0.1l-0.1-43.4L205.8,302.5z M76.5,374.7 c8.2-11.3,21.5-18.8,36.5-18.8s28.3,7.4,36.5,18.8c5.4,7.4,8.5,16.5,8.5,26.3c0,24.8-20.2,45.1-45.1,45.1C88,446.1,68,425.8,68,401 C68,391.2,71.2,382.1,76.5,374.7z M216.1,479.5c0,13.5-11,24.5-24.5,24.5h-157c-13.5,0-24.5-11-24.5-24.5V374.7h38.2 c-3.3,8.1-5.2,17-5.2,26.3c0,38.6,31.4,70,70,70c38.6,0,70-31.4,70-70c0-9.3-1.9-18.2-5.2-26.3h38.2V479.5z" />{' '}
                      </g>{' '}
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/nipunaukltd/"
                    target="_blank"
                    className="twitter"
                    rel="noreferrer"
                  >
                    <svg
                      fill="#000000"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      // eslint-disable-next-line react/no-unknown-property
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512 512"
                      //   style="enable-background:new 0 0 512 512;"
                      xmlSpace="preserve"
                    >
                      {' '}
                      <g>
                        {' '}
                        <path d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M415.8,415.7 c-20.8,20.8-44.9,37.1-71.8,48.4c-27.8,11.8-57.4,17.7-88,17.7c-30.5,0-60.1-6-88-17.7c-26.9-11.4-51.1-27.7-71.8-48.4 c-20.8-20.8-37.1-44.9-48.4-71.8C36,316.1,30,286.5,30,256s6-60.1,17.7-88c11.4-26.9,27.7-51.1,48.4-71.8 c20.9-20.8,45-37.1,71.9-48.5C195.9,36,225.5,30,256,30s60.1,6,88,17.7c26.9,11.4,51.1,27.7,71.8,48.4 c20.8,20.8,37.1,44.9,48.4,71.8c11.8,27.8,17.7,57.4,17.7,88c0,30.5-6,60.1-17.7,88C452.8,370.8,436.5,395,415.8,415.7z" />{' '}
                      </g>{' '}
                      <g>
                        {' '}
                        <path d="M331.8,128h39.7l-86.7,99.1l102,134.8h-79.8l-62.5-81.8l-71.6,81.8h-39.7l92.7-106L128,128h81.9l56.5,74.7L331.8,128z M317.9,338.1h22L197.9,150.5h-23.6L317.9,338.1z" />{' '}
                      </g>{' '}
                    </svg>
                  </a>
                </li>
              </ul>
              <h2 className="footer-title">Get in touch</h2>
              <div className="footer-contact-option">
                <svg
                  width="15px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {' '}
                  <g strokeWidth="0" />{' '}
                  <g strokeLinecap="round" strokeLinejoin="round" />{' '}
                  <g>
                    {' '}
                    <path
                      d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z"
                      fill="#fff"
                    />{' '}
                  </g>{' '}
                </svg>
                0044 7399652379
              </div>
              <div className="footer-contact-option">
                <svg
                  fill="#FFFFFF"
                  width="15px"
                  viewBox="0 -2.5 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  {' '}
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    {' '}
                    <g
                      transform="translate(-340.000000, -922.000000)"
                      fill="#FFFFFF"
                    >
                      {' '}
                      <g transform="translate(56.000000, 160.000000)">
                        {' '}
                        <path d="M294,774.474 L284,765.649 L284,777 L304,777 L304,765.649 L294,774.474 Z M294.001,771.812 L284,762.981 L284,762 L304,762 L304,762.981 L294.001,771.812 Z">
                          {' '}
                        </path>{' '}
                      </g>{' '}
                    </g>{' '}
                  </g>{' '}
                </svg>
                <a href="mailto: info@nipunasolutions.co.uk">
                  {' '}
                  info@nipunasolutions.co.uk
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright">
        &copy; 2024 Nipuna Solutions. All Rights Reserved.
      </div>
    </div>
  );
};
