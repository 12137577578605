import { useFormik } from 'formik';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import DatePicker from '../../../atoms/DatePicker/DatePicker';
import InputBox from '../../../atoms/InputBox/InputBox';
import PreviousButton from '../../../atoms/PreviousButton/PreviousButton';
import SelectBox, { SelectOption } from '../../../atoms/Select/Select';
import { useSaveS3Connection } from '../../../hooks/useSaveS3Connection/useSaveS3Connection';
import { AWS_REGIONS, SCHEDULE_TYPES } from '../../../types/constants';
import { audienceS3FormValidation } from '../validation';
// import NextButton from '../../../atoms/NextButton/NextButton';
import './PdfAudience.scss';
import { S3AndScheduleDetails } from '../../../types/s3deatils';
import { useGetCampaignS3Details } from '../../../hooks/useGetCampaignS3Details/useGetCampaignS3Details';
// import { Button } from '../../../atoms/GenerallButton/Button';
import { Button } from 'cm-react-components/dist/js';
import { useIsS3NameExists } from '../../../hooks/useIsS3NameExists/useIsS3NameExists';
import { Next } from '../../../atoms/Next/Next';

export const PdfAudience: FC = () => {
  const [queryParams] = useSearchParams();
  const campaignId =
    queryParams.get('id') && queryParams.get('id') !== null
      ? queryParams.get('id')
      : '';

  const { data: s3ScheduleDetails, isLoading: loading } =
    useGetCampaignS3Details(campaignId);
  let isEditing =
    s3ScheduleDetails && s3ScheduleDetails.s3Connection ? true : false;
  const { mutate } = useSaveS3Connection();
  const navigate = useNavigate();

  const saveS3Connection = (values: S3AndScheduleDetails) => {
    formik.validateForm().then((result: any) => {
      if (result && Object.keys(result).length > 0) {
        console.log('validation failed :: ', result);
      } else {
        mutate(
          {
            s3Connection: {
              ...values.s3Connection,
              campaignId: campaignId ? campaignId : '',
            },
            campaignId: campaignId ? campaignId : '',
            campaignSchedule: {
              campaignId: campaignId ? campaignId : '',
              scheduleType: values.campaignSchedule.scheduleType,
              startDate: values.campaignSchedule.startDate,
            },
          },
          {
            onSuccess: () => {
              navigate(`/datasource?id=${campaignId}`);
            },
            onError: () => {
              console.log('Error');
            },
          },
        );
      }
    });
  };

  const { mutate: isS3NameExists } = useIsS3NameExists();

  const formik = useFormik({
    initialValues:
      loading === false &&
      s3ScheduleDetails &&
      s3ScheduleDetails.campaignId &&
      s3ScheduleDetails.s3Connection
        ? s3ScheduleDetails
        : {
            campaignId: campaignId ? campaignId : '',
            s3Connection: {
              name: '',
              accessKey: '',
              secreteAccessKey: '',
              bucketName: '',
              region: '',
              s3Id: '',
            },
            campaignSchedule: {
              campaignId: campaignId ? campaignId : '',
              scheduleType: '',
              startDate: '',
            },
          },
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: true,
    validationSchema: audienceS3FormValidation,
    onSubmit: async (values: S3AndScheduleDetails) => {
      saveS3Connection(values);
    },
  });

  const getKeyByValue = (object: any, value: string) => {
    return object[value];
  };
  const [recurringFrequency, setRecurringFrequency] = useState<string>();

  const handleRegionSelectionChange = (option: SelectOption | any) => {
    formik.setFieldValue('s3Connection.region', option.value);
  };

  const [campaignFrequency, setCampaignFrequency] = useState<string>('');

  const handleCampaignFrequency = (option: SelectOption | any) => {
    formik.setFieldValue('campaignSchedule.scheduleType', option.value);
    setCampaignFrequency(option.value);
    setRecurringFrequency('');
  };

  const handleFrequencyRecurringChange = (option: SelectOption | any) => {
    setRecurringFrequency(option.value);
  };

  const getTodaysDate = () => {
    return new Date().toISOString().split('T')[0];
  };

  const handleS3NameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (
      s3ScheduleDetails &&
      s3ScheduleDetails.s3Connection &&
      s3ScheduleDetails.s3Connection.name === value
    ) {
      formik.handleChange(e);
      return;
    }
    isS3NameExists(
      { name: value },
      {
        onSuccess: data => {
          if (data && data === true) {
            formik.setFieldError('s3Connection.name', 'S3 Name already exists');
          }
        },
        // onError: () => {},
      },
    );
    formik.handleChange(e);
  };

  useEffect(() => {
    if (loading === false && s3ScheduleDetails) {
      isEditing = true;
    }
  }, [s3ScheduleDetails, loading]);

  return (
    <div className="grid-layout">
      <div className="pdfAudience-container">
        <form onSubmit={formik.handleSubmit}>
          <div
          // style={{
          //   width: '50%',
          // }}
          >
            <InputBox
              label={'Name'}
              id={'s3Connection.name'}
              name={'s3Connection.name'}
              placeholder={'Connection Name'}
              onChange={handleS3NameChange}
              onBlur={formik.handleBlur}
              value={formik.values.s3Connection.name}
              errorMessage={
                formik.errors.s3Connection
                  ? getKeyByValue(formik.errors.s3Connection, 'name')
                  : ''
              }
            />
            <InputBox
              label={'Access Key'}
              id={'s3Connection.accessKey'}
              name={'s3Connection.accessKey'}
              placeholder={'Access Key'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.s3Connection.accessKey}
              errorMessage={
                formik.errors.s3Connection
                  ? getKeyByValue(formik.errors.s3Connection, 'accessKey')
                  : ''
              }
            />
            <InputBox
              label={'Secrete Access Key'}
              id={'s3Connection.secreteAccessKey'}
              name={'s3Connection.secreteAccessKey'}
              placeholder={'Secrete Access Key'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.s3Connection.secreteAccessKey}
              errorMessage={formik.errors.s3Connection?.secreteAccessKey}
            />
            <InputBox
              label={'Bucket Name'}
              id={'s3Connection.bucketName'}
              name={'s3Connection.bucketName'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.s3Connection.bucketName}
              errorMessage={formik.errors.s3Connection?.bucketName}
              placeholder={'Bucket Name'}
            />

            <div style={{ cursor: 'pointer' }}>
              <SelectBox
                name={'s3Connection.region'}
                id={'s3Connection.region'}
                label={'Region'}
                list={AWS_REGIONS}
                onChange={handleRegionSelectionChange}
                //onBlur={formik.handleBlur}
                value={
                  AWS_REGIONS.filter(
                    obj =>
                      formik.values.s3Connection.region ===
                      obj.value.toString(),
                  )[0]
                }
                placeholder={'Select region'}
              />
            </div>

            {/* <div className="subHeading">Frequency</div> */}

            <SelectBox
              name={'campaignSchedule.scheduleType'}
              id={'campaignSchedule.scheduleType'}
              label={'Campaign Frequency'}
              list={SCHEDULE_TYPES}
              value={
                SCHEDULE_TYPES.filter(
                  obj =>
                    formik.values.campaignSchedule?.scheduleType ===
                    obj.value.toString(),
                )[0]
              }
              onChange={e => handleCampaignFrequency(e)}
            />

            {campaignFrequency === 'oneTime' && (
              <div>
                <DatePicker
                  id={'campaignSchedule.startDate'}
                  name={'campaignSchedule.startDate'}
                  label={'Date'}
                  min={getTodaysDate()}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.campaignSchedule?.startDate}
                />

                <div style={{ display: 'flex', columnGap: '20px' }}>
                  <SelectBox
                    name={'startTimeHrs'}
                    id={'startTimeHrs'}
                    label={'Hour'}
                    width={'100px'}
                    list={[
                      { label: '1', value: 1 },
                      { label: '2', value: 2 },
                      { label: '3', value: 3 },
                      { label: '4', value: 4 },
                      { label: '5', value: 5 },
                      { label: '6', value: 6 },
                      { label: '7', value: 7 },
                      { label: '8', value: 8 },
                      { label: '9', value: 9 },
                      { label: '10', value: 10 },
                      { label: '11', value: 11 },
                      { label: '12', value: 12 },
                      { label: '13', value: 13 },
                      { label: '14', value: 14 },
                      { label: '15', value: 15 },
                      { label: '16', value: 16 },
                      { label: '17', value: 17 },
                      { label: '18', value: 18 },
                      { label: '19', value: 19 },
                      { label: '20', value: 20 },
                      { label: '21', value: 21 },
                      { label: '22', value: 22 },
                      { label: '23', value: 23 },
                      { label: '24', value: 24 },
                    ]}
                  />

                  <SelectBox
                    name={''}
                    id={''}
                    label={'minutes'}
                    width={'100px'}
                    list={[
                      { label: '5', value: 5 },
                      { label: '10', value: 10 },
                      { label: '15', value: 15 },
                      { label: '20', value: 20 },
                      { label: '25', value: 25 },
                      { label: '30', value: 30 },
                      { label: '35', value: 35 },
                      { label: '40', value: 40 },
                      { label: '45', value: 45 },
                      { label: '50', value: 50 },
                      { label: '55', value: 55 },
                      { label: '60', value: 60 },
                    ]}
                  />
                </div>
              </div>
            )}

            {campaignFrequency === 'recurring' && (
              <div>
                <SelectBox
                  name={'frequency'}
                  id={'frequency'}
                  label={'Frequency'}
                  list={[
                    { label: 'Daily', value: 'daily' },
                    { label: 'Hourly', value: 'hour' },
                    { label: 'Minutes', value: 'minutes' },
                  ]}
                  onChange={e => handleFrequencyRecurringChange(e)}
                />

                {recurringFrequency === 'daily' && (
                  <div
                    style={{
                      display: 'flex',
                      columnGap: '20px',
                    }}
                  >
                    <SelectBox
                      name={''}
                      id={''}
                      label={'Hour'}
                      width={'100px'}
                      list={[
                        { label: '1', value: 1 },
                        { label: '2', value: 2 },
                        { label: '3', value: 3 },
                        { label: '4', value: 4 },
                        { label: '5', value: 5 },
                        { label: '6', value: 6 },
                        { label: '7', value: 7 },
                        { label: '8', value: 8 },
                        { label: '9', value: 9 },
                        { label: '10', value: 10 },
                        { label: '11', value: 11 },
                        { label: '12', value: 12 },
                        { label: '13', value: 13 },
                        { label: '14', value: 14 },
                        { label: '15', value: 15 },
                        { label: '16', value: 16 },
                        { label: '17', value: 17 },
                        { label: '18', value: 18 },
                        { label: '19', value: 19 },
                        { label: '20', value: 20 },
                        { label: '21', value: 21 },
                        { label: '22', value: 22 },
                        { label: '23', value: 23 },
                        { label: '24', value: 24 },
                      ]}
                    />

                    <SelectBox
                      name={''}
                      id={''}
                      label={'minutes'}
                      width={'100px'}
                      list={[
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '15', value: 15 },
                        { label: '20', value: 20 },
                        { label: '25', value: 25 },
                        { label: '30', value: 30 },
                        { label: '35', value: 35 },
                        { label: '40', value: 40 },
                        { label: '45', value: 45 },
                        { label: '50', value: 50 },
                        { label: '55', value: 55 },
                        { label: '60', value: 60 },
                      ]}
                    />
                  </div>
                )}

                {recurringFrequency === 'hour' && (
                  <div>
                    <SelectBox
                      name={''}
                      id={''}
                      label={'Hour'}
                      width={'100px'}
                      list={[
                        { label: '1', value: 1 },
                        { label: '2', value: 2 },
                        { label: '3', value: 2 },
                        { label: '4', value: 4 },
                        { label: '5', value: 5 },
                        { label: '6', value: 6 },
                        { label: '7', value: 7 },
                        { label: '8', value: 7 },
                      ]}
                    />
                  </div>
                )}

                {recurringFrequency === 'minutes' && (
                  <div>
                    <SelectBox
                      name={''}
                      id={''}
                      label={' Minute'}
                      width={'100px'}
                      list={[
                        { label: '2', value: 2 },
                        { label: '5', value: 5 },
                        { label: '10', value: 10 },
                        { label: '15', value: 15 },
                        { label: '20', value: 20 },
                        { label: '25', value: 25 },
                        { label: '30', value: 30 },
                        { label: '45', value: 45 },
                      ]}
                    />
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="navigationButtons">
            <Link to={`/createcampaign?id=${campaignId}`}>
              <PreviousButton />
            </Link>

            {/* {isEditing && (
            <Link to={`/datasource?id=${campaignId}`}>
              <Button name={'Skip'} />
            </Link>
          )} */}

            {isEditing && (
              <Link to={`/datasource?id=${campaignId}`}>
                <Button label="Skip" size="medium" />
              </Link>
            )}

            {/* <NextButton
            label={isEditing ? 'Update' : 'Next'}
            onClick={() => saveS3Connection(formik.values)}
          /> */}
            <Next
              type={'button'}
              label={isEditing ? 'Update' : 'Next'}
              onClick={() => saveS3Connection(formik.values)}
            />

            {/* <button
                className="loginPage__content__button"
                //onClick={() => saveS3Connection(formik.values)}
              >
                Next
              </button> */}
          </div>
        </form>
      </div>
      <div className="right-image-panel">
        <img src="https://cdn-icons-png.flaticon.com/512/11180/11180336.png" />
      </div>
    </div>
  );
};
