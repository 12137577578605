import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { CampaignsListResponse, Pagination } from '../../types/campaign';

export const getCampaignList = async (
  pagination: Pagination,
): Promise<CampaignsListResponse> =>
  api.post(URLs.GET_CAMPAIGN_LIST, pagination).then((res: any) => res.data);

export const useGetCampaignList = (): UseMutationResult<
  CampaignsListResponse,
  Error,
  Pagination
> => {
  return useMutation([QueryKeys.GetCampaignList], (pagination: Pagination) =>
    getCampaignList(pagination),
  );
};
