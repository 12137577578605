import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { CampaignPreview } from '../../types/campaign';

export const getCampaignContent = async (
  campaignId: any,
): Promise<CampaignPreview> =>
  api.get(URLs.GET_CAMPAIGN_CONTENT(campaignId)).then((res: any) => res.data);

export const useGetCampaignContent = (): UseMutationResult<
  CampaignPreview,
  Error,
  string
> => {
  return useMutation([QueryKeys.GetCampaignContent], (campaignId: string) =>
    getCampaignContent(campaignId),
  );
};
