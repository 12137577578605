/* eslint-disable react/prop-types */
import { useState } from 'react';
import './LeftNavigation.scss';

interface LeftNavigationProps {
  typeLabels: string[];
  industriesLabels: string[];
  // LanguagesLabels: string[];
}

export const LeftNavigation: React.FC<LeftNavigationProps> = ({
  typeLabels,
  industriesLabels,
  // LanguagesLabels,
}) => {
  // const [isFilterOpen, setIsFilterOpen] = useState(true);
  // const [isSortOpen, setIsSortOpen] = useState(true);
  const [isTypesOpen, setIsTypesOpen] = useState(true);
  const [isIndustriesOpen, setIsIndustriesOpen] = useState(true);
  // const [isLanguagesOpen, setIsLanguagesOpen] = useState(true);

  // const toggleFilterPanel = () => {
  //   setIsFilterOpen(!isFilterOpen);
  // };
  // const toggleSortPanel = () => {
  //   setIsSortOpen(!isSortOpen);
  // };
  const toggleTypesPanel = () => {
    setIsTypesOpen(!isTypesOpen);
  };
  const toggleIndustriesPanel = () => {
    setIsIndustriesOpen(!isIndustriesOpen);
  };
  // const toggleLanguagesPanel = () => {
  //   setIsLanguagesOpen(!isLanguagesOpen);
  // };

  return (
    <>
      <div className="pdf-template-wrapper">
        <div className="sidebar-filter">
          <div className="primary">
            <div className="row">
              <div className="col-sm-3 col-xs-12 left-template-filter">
                <div className="filter-menu">
                  <form>
                    <div className="panel panel-default">
                      {/* <div className="panel-heading">
                        <a
                          className="panel-title accordion-toggle"
                          role="button"
                          onClick={toggleFilterPanel}
                          style={{ marginLeft: '2px' }}
                        >
                          Filter
                          {isFilterOpen ? (
                            <span className="expand-icon">
                              <img src="down-arrow.svg" />
                            </span>
                          ) : (
                            <span className="expand-icon">
                              <img src="up-arrow.svg" />
                            </span>
                          )}
                        </a>
                      </div> */}

                      {/* {isFilterOpen && (
                        <div className="panel-body">
                          <div className="panel-title hidden-sm">
                            <strong>Filter</strong>
                          </div>
                        </div>
                      )} */}
                      <div className="panel-body">
                        <div className="panel-title hidden-sm">
                          <strong>Filter</strong>
                        </div>
                      </div>

                      <div className="panel-body">
                        <div
                          className="panel-group"
                          id="filter-menu"
                          role="tablist"
                          aria-multiselectable="true"
                        >
                          {/* <div className="panel panel-default">
                            <div className="panel-heading">
                              <a
                                className="panel-title accordion-toggle"
                                onClick={toggleSortPanel}
                              >
                                Sort By{''}
                                {isSortOpen ? (
                                  <span className="expand-icon">
                                    <img src="down-arrow.svg" />
                                  </span>
                                ) : (
                                  <span className="expand-icon">
                                    <img src="up-arrow.svg" />
                                  </span>
                                )}
                              </a>
                            </div>

                            {isSortOpen && (
                              <div className="panel-body">
                                <div className="checkbox">
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="career_state[]"
                                      value="recent_graduate"
                                    />
                                    Recent
                                  </label>
                                </div>
                                <div className="checkbox">
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="career_state[]"
                                      value="imposter_syndrome"
                                    />
                                    Popular
                                  </label>
                                </div>
                              </div>
                            )}
                          </div> */}

                          <div className="panel panel-default">
                            <div
                              className="panel-heading"
                              role="tab"
                              id="headingTwo"
                            >
                              <a
                                className="panel-title accordion-toggle collapsed"
                                role="button"
                                onClick={toggleTypesPanel}
                                // data-toggle="collapse"
                                // href="#collapseTwo"
                                // aria-expanded="false"
                                // aria-controls="collapseTwo"
                              >
                                Types
                                {isTypesOpen ? (
                                  <span className="expand-icon">
                                    <img src="up-arrow.svg" />
                                  </span>
                                ) : (
                                  <span className="expand-icon">
                                    <img src="down-arrow.svg" />
                                  </span>
                                )}
                              </a>
                            </div>
                            {isTypesOpen && (
                              <div className="panel-body">
                                {typeLabels.map(label => (
                                  <div className="checkbox" key={label}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        name="topic[]"
                                        value={label.toLowerCase()}
                                      />
                                      {label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            )}{' '}
                          </div>

                          <div className="panel panel-default">
                            <div
                              className="panel-heading"
                              role="tab"
                              id="headingThree"
                            >
                              <a
                                className="panel-title accordion-toggle collapsed"
                                role="button"
                                onClick={toggleIndustriesPanel}
                                // data-toggle="collapse"
                                // href="#collapseThree"
                                // aria-expanded="false"
                                // aria-controls="collapseThree"
                              >
                                Industries
                                {isIndustriesOpen ? (
                                  <span className="expand-icon">
                                    <img src="up-arrow.svg" />
                                  </span>
                                ) : (
                                  <span className="expand-icon">
                                    <img src="down-arrow.svg" />
                                  </span>
                                )}
                              </a>
                            </div>
                            {isIndustriesOpen && (
                              <div className="panel-body">
                                {industriesLabels.map(label => (
                                  <div className="checkbox" key={label}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        name="topic[]"
                                        value={label.toLowerCase()}
                                      />
                                      {label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>

                          {/* <div className="panel panel-default">
                            <div
                              className="panel-heading"
                              role="tab"
                              id="headingFour"
                            >
                              <a
                                className="panel-title accordion-toggle collapsed"
                                role="button"
                                onClick={toggleLanguagesPanel}
                                // data-toggle="collapse"
                                // href="#collapseFour"
                                // aria-expanded="false"
                                // aria-controls="collapseFour"
                              >
                                Language
                                {isLanguagesOpen ? (
                                  <span className="expand-icon">
                                    <img src="down-arrow.svg" />
                                  </span>
                                ) : (
                                  <span className="expand-icon">
                                    <img src="up-arrow.svg" />
                                  </span>
                                )}
                              </a>
                            </div>
                            {isLanguagesOpen && (
                              <div className="panel-body">
                                {LanguagesLabels.map(label => (
                                  <div className="checkbox" key={label}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        name="topic[]"
                                        value={label.toLowerCase()}
                                      />
                                      {label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
