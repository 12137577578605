import { useMutation, UseMutationResult } from 'react-query';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { QueryKeys } from '../../api/QueryKeys';
import { SocialCampaignPage } from '../../types/social';

export const getSocialImageBlob = async (
  designImagePreviewRequest: SocialCampaignPage,
): Promise<Blob> => {
  return api
    .post(URLs.GET_SOCIAL_IMAGE_PREVIEW, designImagePreviewRequest, {
      responseType: 'blob',
    })
    .then((res: any) => res.data);
};
export const useGetSocialImage = (): UseMutationResult<
  Blob,
  Error,
  SocialCampaignPage
> => {
  return useMutation<Blob, Error, SocialCampaignPage>(
    [QueryKeys.GetSocialImageBlob],
    (designImagePreviewRequest: SocialCampaignPage) => {
      const propsMap = designImagePreviewRequest.propsMap;

      let propsMapJson;
      try {
        propsMapJson = propsMap
          ? JSON.stringify(Object.fromEntries(propsMap))
          : '';
      } catch (error: any) {
        propsMapJson = null;
      }

      return api
        .post<Blob>(
          URLs.GET_SOCIAL_IMAGE_PREVIEW,
          {
            designId: designImagePreviewRequest.designId,
            propsMap: propsMapJson ? JSON.parse(propsMapJson) : propsMap,
          },
          {
            responseType: 'blob',
          },
        )
        .then(response => response.data);
    },
  );
};
