import { SelectOption } from '../atoms/Select/Select';
import { Dashboard } from '../types/dashboard';

export const brandToSelectOption = (
  brand: Dashboard | undefined,
): SelectOption => {
  return brand
    ? {
        value: brand.brandId,
        label: brand.brandName,
      }
    : { label: 'Select brand', value: '' };
};

export const getPropValue = (obj: any, key: string) => {
  if (
    obj &&
    Object.keys(obj) &&
    Object.keys(obj).length > 0 &&
    obj !== undefined &&
    obj[key]
  ) {
    return obj[key];
  }
  return '';
};

export const removeSpaces = (value: string) => value.replace(/\s/g, '');

export const compareStrings = (valueOne: string, valueTwo: string) =>
  removeSpaces(valueOne) === removeSpaces(valueTwo);

export const removeWord = (
  inputString: string,
  stringToRemove: string,
): string => {
  const regex = new RegExp(stringToRemove, 'g');

  return inputString.replace(regex, '');
};
