import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { API_BASE, URLs } from '../../api/URLs';
// import { CampaignCssAndPages } from '../../types/campaign';
import axios, { AxiosRequestConfig } from 'axios';
import { SystemTemplatePdf } from '../../types/systemTemplate';

const transformToFormData = (obj: SystemTemplatePdf | undefined): FormData => {
  const formData = new FormData();
  if (!obj) {
    return formData;
  }

  const commonCssBlob = new Blob([obj.cssContent ? obj.cssContent : ''], {
    type: 'text/plain',
  });
  formData.append('cssContent', commonCssBlob, 'cssfile.css');
  obj.systemTemplatePages.map((templatePage, index) => {
    const contentBlob = new Blob([templatePage.content], {
      type: 'text/plain',
    });
    const cssBlob = new Blob([templatePage.cssContent], {
      type: 'text/plain',
    });

    formData.append(
      `systemTemplatePages[${index}].orientation`,
      templatePage.orientation,
    );
    formData.append(
      `systemTemplatePages[${index}].content`,
      contentBlob,
      `content_${index}.html`,
    );

    formData.append(
      `systemTemplatePages[${index}].cssContent`,
      cssBlob,
      `content_${index}.css`,
    );
  });

  return formData;
};

export const getSystemTemplatePdfPreview = async (
  systemTemplatePdf: SystemTemplatePdf | undefined,
): Promise<ArrayBuffer> => {
  const accessToken = localStorage.getItem('user.accessToken');
  const config: AxiosRequestConfig = {
    method: 'post',
    url: API_BASE + URLs.GET_SYSTEM_TEMPLATE_PDF_PREVIEW,
    responseType: 'blob',
    headers: {
      'Content-Disposition': 'form-data',
      Authorization: `Bearer ${accessToken}`,
    },
    data: transformToFormData(systemTemplatePdf),
  };

  return await axios(config).then((res: any) => res.data);
};

export const useSystemTemplateDownloadPdf = (): UseMutationResult<
  ArrayBuffer,
  Error,
  SystemTemplatePdf
> => {
  return useMutation(
    [QueryKeys.GetSystemTemplatePreview],
    (systemTemplatePdf: SystemTemplatePdf) =>
      getSystemTemplatePdfPreview(systemTemplatePdf),
  );
};
