import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { CustomTag, CustomTagVersion } from '../../types/customtag';

export const getCustomTagContent = async (
  customTagVersion: CustomTagVersion,
): Promise<CustomTag> =>
  api
    .post(URLs.GET_CUSTOMTAG_CONTENT(customTagVersion.tagId), {
      version: customTagVersion.version,
    })
    .then((res: any) => res.data);

export const useGetCustomTagContent = (): UseMutationResult<
  CustomTag,
  Error,
  CustomTagVersion
> => {
  return useMutation(
    [QueryKeys.GetCustomTagContent],
    (customTagVersion: CustomTagVersion) =>
      getCustomTagContent(customTagVersion),
  );
};
