import { FC, useEffect, useState } from 'react';
import './PdfTwoColumnLayout.scss';

export interface PdfTwoColumnLayoutProps {
  id: string;
  name: string;
  alignment?: 'Left' | 'Right' | 'Center';
  className?: string;
  setSelectedElementId: (id: string) => void;
  value: string[];
  onValueChange: (id: string, newValue: string[]) => void;
}

export const PdfTwoColumnLayout: FC<PdfTwoColumnLayoutProps> = ({
  id,
  name,
  alignment = 'Left',
  className,
  setSelectedElementId,
  value,
  onValueChange,
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [rightColumnEditing, setRightColumnEditing] = useState<boolean>(false);
  const [stateValue, setStateValue] = useState<string[]>(value);

  useEffect(() => {
    editing === false &&
      rightColumnEditing === false &&
      onValueChange(id, stateValue);
  }, [editing, rightColumnEditing]);

  return (
    <div
      id={id}
      key={id}
      onClick={() => {
        setSelectedElementId(id);
      }}
      // onKeyDown={handleKeyDown}
    >
      <div className="cm-two-column">
        {editing ? (
          <textarea
            rows={6}
            onBlur={e => {
              const tempValue = stateValue;
              tempValue[0] = e.target.value;
              setStateValue(tempValue);
              setEditing(false);
            }}
            onChange={e => {
              const tempValue = stateValue;
              tempValue[0] = e.target.value;

              onValueChange(id, tempValue);
            }}
            value={stateValue[0]}
          ></textarea>
        ) : (
          <div
            onClick={e => {
              setSelectedElementId(id);
              // value[0] = e.currentTarget.innerHTML;
              setEditing(true);
            }}
            dangerouslySetInnerHTML={{ __html: stateValue[0] }}
          />
        )}

        {rightColumnEditing ? (
          <textarea
            rows={6}
            onBlur={e => {
              const tempValue = stateValue;
              tempValue[1] = e.target.value;
              setStateValue(tempValue);
              setRightColumnEditing(false);
            }}
            onChange={e => {
              const tempValue = stateValue;
              tempValue[1] = e.target.value;
              onValueChange(id, tempValue);
            }}
            value={stateValue[1]}
          ></textarea>
        ) : (
          <div
            // contentEditable
            // suppressContentEditableWarning
            // onBlur={e => {
            //   setSelectedElementId(id);
            //   value[1] = e.currentTarget.innerHTML;
            //   onValueChange(id, value);
            // }}
            onClick={e => {
              setSelectedElementId(id);
              // value[1] = e.currentTarget.innerHTML;
              // onValueChange(id, value);
              setRightColumnEditing(true);
            }}
            className={'fwg-text-right'}
            dangerouslySetInnerHTML={{ __html: stateValue[1] }}
          ></div>
        )}
      </div>
    </div>
  );
};

PdfTwoColumnLayout.displayName = 'PdfTwoColumnLayout';
