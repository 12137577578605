import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { ChangePassword, LoginResponse } from '../../types/login';

export const postChangePassword = async (
  changePassword: ChangePassword,
): Promise<LoginResponse> =>
  api.post(URLs.CHANGE_PASSWORD, changePassword).then((res: any) => res.data);

export const useChangePassword = (): UseMutationResult<
  LoginResponse,
  Error,
  ChangePassword
> => {
  return useMutation(
    [QueryKeys.ChangePassword],
    (changePassword: ChangePassword) => postChangePassword(changePassword),
  );
};
