import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import { TokenRequest, LoginResponse } from '../../types/login';
import api from '../../api/apifilter';

export const oidcLogin = async (
  tokenRequest: TokenRequest,
): Promise<LoginResponse> =>
  api.post(URLs.OIDC_LOGIN, tokenRequest).then((res: any) => res.data);

export const useOidcLogin = (): UseMutationResult<
  LoginResponse,
  Error,
  TokenRequest
> => {
  return useMutation([QueryKeys.OidcLogin], (tokenRequest: TokenRequest) =>
    oidcLogin(tokenRequest),
  );
};
