import { useMutation, UseMutationResult } from 'react-query';
import { QueryKeys } from '../../api/QueryKeys';
import { URLs } from '../../api/URLs';
import api from '../../api/apifilter';
import { CampaignPreview } from '../../types/campaign';

export const getPdfTemplateContent = async (
  templateId: any,
): Promise<CampaignPreview> =>
  api
    .get(URLs.GET_PDF_TEMPLATE_CONTENT(templateId))
    .then((res: any) => res.data);

export const useGetPdfTemplateContent = (): UseMutationResult<
  CampaignPreview,
  Error,
  string
> => {
  return useMutation([QueryKeys.GetPdfTemplateContent], (templateId: string) =>
    getPdfTemplateContent(templateId),
  );
};
