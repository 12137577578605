import {
  Form,
  Image,
  Label,
  Select,
  TextArea,
  TextInput,
  // TextInputV2,
} from 'cm-react-components/dist/js';
// import { ALIGN } from 'cm-react-components/dist/js/components/molecules/Grid/Grid';
import React, {
  FC,
  ReactNode,
  // useCallback,
  useEffect,
  // useRef,
  useState,
} from 'react';
import {
  BUTTON_TAG,
  // INPUT_FIELD_TAG,
  IMAGE_TAG,
  INPUT_FIELD_TAG,
  LABEL_TAG,
  MULTILINE_INPUT_TAG,
  PASSWORD_FIELD_TAG,
  SELECT_TAG,
} from '../../types/elementTypes';
import { removeSpaces } from '../../utils/utils';
import { ElementJson } from '../../utils/elementsUtil';
import { TwoColumnLayout } from '../../layouts/TwoColumnLayout/TwoColumnLayout';
import { LAYOUT_TYPE } from '../../types/layoutTypes';
import { ButtonLayout } from '../../components/ButtonLayout/ButtonLayout';

export interface FormPreviewProps {
  elementListMap: Map<number, ElementJson[]>;
  children?: ReactNode;
  // elementList?: ElementJson[];
  activePage: number;
  width?: number;
  setActivePage: (pageNumber: number) => void;
  pageLayoutMap: Map<number, LAYOUT_TYPE>;
  // noOfPages: number;
  setElementListMap: (elementListMap: Map<number, ElementJson[]>) => void;
}

export const FormPreview: FC<FormPreviewProps> = ({
  elementListMap,
  activePage,
  width,
  setActivePage,
  pageLayoutMap,
  // noOfPages,
  setElementListMap,
}) => {
  const onFocus = () => {
    console.log('Text Got focus !!');
  };

  const onBlur = () => {
    console.log('Text Lost focus !!');
  };

  // console.log('activePage ::', activePage);
  // console.log('noOfPages ::', elementListMap.keys.length);
  // console.log('elementListMap ::', elementListMap.keys);
  // console.log('elementList ::', elementListMap.get(activePage));
  const [mobilePreview, setMobilePreview] = useState(false);
  // const [sidebarWidth, setSidebarWidth] = useState(400);
  // const [pageNumber, setPageNumber] = useState<number>(0);
  // const sidebarRef = useRef<HTMLDivElement>(null);

  // const [droppedElements, setDroppedElements] = useState<ReactNode[]>([]);

  // const startResizing = useCallback((mouseDownEvent: React.MouseEvent) => {
  //   setIsResizing(true);
  // }, []);

  // const stopResizing = useCallback(() => {
  //   setIsResizing(false);
  // }, []);

  // const resize = useCallback(
  //   (mouseMoveEvent: MouseEvent) => {
  //     if (isResizing && sidebarRef.current) {
  //       const newWidth =
  //         sidebarRef.current.getBoundingClientRect().right -
  //         mouseMoveEvent.clientX;

  //       if (newWidth <= 450) {
  //         setSidebarWidth(newWidth);
  //       }
  //     }
  //   },
  //   [isResizing],
  // );

  // const components = [
  //   ,
  //   <div key={'title'}>TITLE One</div>,
  //   <TextInput
  //     key={'textInput1'}
  //     borderRadius="14px"
  //     size="xs"
  //     onFocus={() => console.log('Text Got focus !!')}
  //     onBlur={() => console.log('Text Lost focus !!')}
  //   />,
  //   <TextInput key={'textInput2'} borderRadius="14px" size="xs" />,
  //   <Select
  //     label="How did you"
  //     options={['Option 1', 'Option 2', 'Option 3']}
  //     key={'select2'}
  //     borderRadius="14px"
  //     onFocus={() => console.log('Select Two Got focus !!')}
  //     onBlur={() => console.log('Select Two Lost focus !!')}
  //     tabIndex={4}
  //   ></Select>,

  // ];

  // const girdComponent = (index: number): ReactNode => {
  //   return (
  //     <Grid columns={1} rows={8} alignContent={ALIGN.CENTRE}>
  //       {components}
  //     </Grid>
  //   );
  // };

  // const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  //   const data = e.dataTransfer.getData('text/plain');

  //   // const data = e.dataTransfer.getData('');
  //   console.log('Dropped tag', data);

  //   setDroppedElements([
  //     ...droppedElements,
  //     girdComponent(droppedElements.length),
  //   ]);
  // };

  // const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
  //   e.preventDefault();
  // };
  //   const paginationLinks = Array.from(
  //     { length: campaignPages && campaignPages.length > 5 ? 5 : campaignPages.length },
  //     (_, index) => (
  //       <div
  //         className={`pagenumber ${pageNumber === index ? 'active' : ''}`}
  //         key={index}
  //         onClick={() => {
  //           setPageNumber(index);
  //         }}
  //       >
  //         {index + 1}
  //       </div>
  //     ),
  //   );

  // useEffect(() => {
  //   window.addEventListener('mousemove', resize);
  //   window.addEventListener('mouseup', stopResizing);
  //   return () => {
  //     window.removeEventListener('mousemove', resize);
  //     window.removeEventListener('mouseup', stopResizing);
  //   };
  // }, [resize, stopResizing]);

  useEffect(() => {
    // Function to log the currently focused element
    const logFocusedElement = () => {
      // const focusedElement = document.activeElement;
      // console.log('Currently focused element:', focusedElement);
    };

    // Add an event listener to capture focus changes
    window.addEventListener('focus', logFocusedElement);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('focus', logFocusedElement);
    };
  }, []);

  // const traverseChildren = (child: ReactNode) => {
  //   if (React.isValidElement(child)) {
  //     const childProps = child.props;

  //     console.log('Child component properties:', childProps);
  //     console.log('Child component type:', child.type);
  //     console.log('Child component key:', child.key);

  //     // Check if the child has nested children
  //     if (childProps.children) {
  //       React.Children.forEach(childProps.children, grandChild => {
  //         traverseChildren(grandChild); // Recursively traverse nested children
  //       });
  //     }
  //   }
  // };

  // const components = [
  //   <Image
  //     url="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiQmcqzN9KSMx-hxPJfiB3yt59uQhN9R4IqjisfUEitJv9lbQVN14QYLsUfmgiH-AoH2VgTFMdRBaTWa9XXpU9aMV1fveYnRgRsf4peaqt_rCR_qyQ483NgjHHdhfYpOr8axyGWhk3DHw5lAUQkXl6NGMugPS7k6Apw7CUjqRMgwAv01i2_AXyRumuBfw/s16000/blank-profile-picture-hd-images-photo.JPG"
  //     height={40}
  //     width={40}
  //     key={'image'}
  //     tagName="Image"
  //   />,
  //   <div key={'title'}>TITLE One</div>,
  //   <TextInput
  //     key={'FirstName'}
  //     name="First Name"
  //     borderRadius="14px"
  //     size="xs"
  //     onFocus={() => console.log('Text Got focus !!')}
  //     onBlur={() => console.log('Text Lost focus !!')}
  //     tagName="TextInput"
  //   />,
  //   <TextInput
  //     key={'textInput2'}
  //     borderRadius="14px"
  //     size="xs"
  //     tagName="TextInput"
  //   />,
  //   <Select
  //     label="What did you"
  //     options={['Option 1', 'Option 2', 'Option 3']}
  //     key={'select1'}
  //     borderRadius="14px"
  //     onFocus={() => console.log('Select One Got focus !!')}
  //     onBlur={() => console.log('Select Lost focus !!')}
  //     tabIndex={3}
  //     // tagName="Select"
  //   ></Select>,
  //   <Select
  //     label="How did you"
  //     options={['Option 1', 'Option 2', 'Option 3']}
  //     key={'select2'}
  //     borderRadius="14px"
  //     onFocus={() => console.log('Select Two Got focus !!')}
  //     onBlur={() => console.log('Select Two Lost focus !!')}
  //     tabIndex={4}
  //     // tagName="Select"
  //   ></Select>,
  //   <TextArea
  //     key={'LastName'}
  //     name={'Last Name'}
  //     id={'Last Name'}
  //     borderRadius="14px"
  //     onFocus={() => console.log('TextArea Got focus !!')}
  //     onBlur={() => console.log('TextArea Lost focus !!')}
  //     tagName="TextArea"
  //   ></TextArea>,
  //   <Button
  //     label="SUBMIT"
  //     key={'button'}
  //     onFocus={() => console.log('Button Got focus !!')}
  //     onBlur={() => console.log('Button Lost focus !!')}
  //     // tagName="Button"
  //   ></Button>,
  // ];

  const generateJson = (child: ReactNode): any => {
    if (React.isValidElement(child)) {
      // console.log(child.type);
      const childProps = child.props;
      const { children, ...props } = child.props;
      const childJson: any = {
        type:
          child.props && child.props['tagName']
            ? child.props['tagName']
            : child.key,
        props: props,
      };

      if (childProps.children) {
        childJson.children = React.Children.map(
          childProps.children,
          grandChild => generateJson(grandChild),
        );
      }

      return childJson;
    }
    return null;
  };

  const form =
    pageLayoutMap.get(activePage) !== LAYOUT_TYPE.TWO_COLUMN_LAYOUT ? (
      <Form className={mobilePreview === true ? 'width-370' : 'width-100p'}>
        {elementListMap.get(activePage)?.map((element, index) => {
          switch (removeSpaces(element.type)) {
            case removeSpaces(INPUT_FIELD_TAG):
              return (
                <TextInput
                  key={'textInput1'}
                  label="Input One"
                  borderRadius="14px"
                  size="xs"
                  // onFocus={() => console.log('Text Got focus !!')}
                  // onBlur={() => console.log('Text Lost focus !!')}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  required={true}
                />
              );

            case removeSpaces(SELECT_TAG):
              return (
                <Select
                  label="What did you"
                  options={['Option 1', 'Option 2', 'Option 3']}
                  key={'select1'}
                  borderRadius="14px"
                  // onFocus={() => console.log('Select One Got focus !!')}
                  // onBlur={() => console.log('Select Lost focus !!')}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  tabIndex={3}
                ></Select>
              );
            // case removeSpaces(INPUT_FIELD_TAG):
            //   return <input key={index} type="email" placeholder="Enter email" />;
            case removeSpaces(PASSWORD_FIELD_TAG):
              return (
                <input
                  key={index}
                  type="password"
                  placeholder="Enter password"
                />
              );
            case removeSpaces(IMAGE_TAG):
              return (
                <Image
                  url="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiQmcqzN9KSMx-hxPJfiB3yt59uQhN9R4IqjisfUEitJv9lbQVN14QYLsUfmgiH-AoH2VgTFMdRBaTWa9XXpU9aMV1fveYnRgRsf4peaqt_rCR_qyQ483NgjHHdhfYpOr8axyGWhk3DHw5lAUQkXl6NGMugPS7k6Apw7CUjqRMgwAv01i2_AXyRumuBfw/s16000/blank-profile-picture-hd-images-photo.JPG"
                  height={40}
                  width={40}
                />
              );
            case removeSpaces(MULTILINE_INPUT_TAG):
              return (
                <TextArea
                  key={'textarea'}
                  borderRadius="14px"
                  // onFocus={() => console.log('TextArea Got focus !!')}
                  // onBlur={() => console.log('TextArea Lost focus !!')}
                  onFocus={onFocus}
                  onBlur={onBlur}
                ></TextArea>
              );
            case LABEL_TAG:
              return <Label>Label component</Label>;
            case BUTTON_TAG:
              return <ButtonLayout numberOfButtons={2} />;
            default:
              return <></>;
          }
        })}
        {/* <Button
          label="Print Heirarchy"
          key={'button'}
          onClick={() => printChildrenHierarchy()}
          // onFocus={() => console.log('Button Got focus !!')}
          // onBlur={() => console.log('Button Lost focus !!')}
          onFocus={onFocus}
          onBlur={onBlur}
        ></Button> */}
      </Form>
    ) : (
      <TwoColumnLayout
        className={mobilePreview === true ? 'width-370' : 'width-100p'}
        elementList={elementListMap.get(activePage)}
      ></TwoColumnLayout>
    );

  // const printChildrenHierarchy = () => {
  //   const json: any = {
  //     type: 'Parent',
  //     children: React.Children.map(form, child => generateJson(child)),
  //   };
  //   console.log(JSON.stringify(json));
  // };
  return (
    <div
      className="cm-preview-container-form"
      // ref={sidebarRef}
      // style={{
      //   minWidth: sidebarWidth + 'px',
      // }}
    >
      <div className="cm-preview-container">
        <div className="cm-preview-header">
          <div className="cm-preview-buttons">
            <button
              id="desktop-view"
              className={mobilePreview === false ? 'active' : ''}
              onClick={() => setMobilePreview(!mobilePreview)}
            >
              <span>
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 60 60"
                  xmlSpace="preserve"
                >
                  <g>
                    <path d="M55.5,9.9h-51c-1.2,0-2.2,1-2.2,2.2c0,0,0,0,0,0v28.8c0,1.2,1,2.2,2.2,2.2c0,0,0,0,0,0h51c1.2,0,2.2-1,2.2-2.2c0,0,0,0,0,0 V12.1C57.7,10.8,56.7,9.9,55.5,9.9C55.5,9.9,55.5,9.9,55.5,9.9z M54.9,39.3c0,0.6-0.5,1-1,1c0,0,0,0,0,0H6.1c-0.6,0-1-0.5-1-1 c0,0,0,0,0,0V13.7c0-0.6,0.5-1,1-1c0,0,0,0,0,0h47.8c0.6,0,1,0.5,1,1c0,0,0,0,0,0V39.3z"></path>
                    <path d="M38.2,44.9c-0.2,0-0.3,0.1-0.3,0.3l0,0c-0.1,0.9-0.8,1.5-1.6,1.6H23.7c-0.9,0-1.6-0.7-1.6-1.6l0,0c0-0.2-0.1-0.3-0.3-0.3H0 c0,3.1,2.3,4.6,5.1,4.6h49.7c2.8,0,5.1-1.6,5.1-4.6H38.2z"></path>
                  </g>
                </svg>
              </span>
              Desktop
            </button>
            <button
              id="mobile-view"
              className={mobilePreview === true ? 'active' : ''}
              onClick={() => setMobilePreview(!mobilePreview)}
            >
              <span>
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 60 60"
                  xmlSpace="preserve"
                >
                  <g>
                    <path d="M42.9,0H17.4C15,0,13,2,13,4.4v51.2c0,2.4,2,4.4,4.4,4.4h25.5c2.4,0,4.4-2,4.4-4.4V4.4C47.4,2,45.4,0,42.9,0z M45.2,55.6 c0,1.2-1,2.2-2.2,2.2H17.4c-1.2,0-2.2-1-2.2-2.2V4.4c0-1.2,1-2.2,2.2-2.2h25.5c1.2,0,2.2,1,2.2,2.2V55.6z"></path>
                    <path d="M34.5,51.9h-8.7c-1.4,0-2.6,1.1-2.6,2.6s1.1,2.6,2.6,2.6h8.7c1.4,0,2.6-1.1,2.6-2.6S35.9,51.9,34.5,51.9z M34.5,54.8h-8.7 c-0.2,0-0.3-0.2-0.3-0.3c0-0.2,0.2-0.3,0.3-0.3h8.7c0.2,0,0.3,0.2,0.3,0.3C34.9,54.6,34.7,54.8,34.5,54.8z"></path>
                  </g>
                </svg>
              </span>
              Mobile
            </button>
          </div>
          <div>Preview</div>
          <div className="add-remove-buttons-container">
            <button
              id="add-page"
              className={mobilePreview === false ? 'active' : ''}
              onClick={() => {
                const tempMap = new Map(elementListMap);
                const noOfPages = tempMap.size;
                if (noOfPages === 0) {
                  const elementList = tempMap.get(noOfPages);
                  if (!elementList || elementList.length === 0) {
                    tempMap.set(0, []);
                    tempMap.set(1, []);
                  }
                } else {
                  tempMap.set(noOfPages + 1, []);
                }
                // console.log('adding new page :', noOfPages + 1);
                setElementListMap(tempMap);
              }}
            >
              <span>
                <svg
                  fill="#000000"
                  viewBox="-3.5 0 19 19"
                  xmlns="http://www.w3.org/2000/svg"
                  className="cf-icon-svg"
                >
                  <path d="M11.16 16.153a.477.477 0 0 1-.476.475H1.316a.477.477 0 0 1-.475-.475V3.046a.477.477 0 0 1 .475-.475h6.95l2.893 2.893zm-1.11-9.924H8.059a.575.575 0 0 1-.574-.574V3.679H1.95v11.84h8.102zm-1.519 4.198a.475.475 0 0 1-.475.475H6.475v1.582a.475.475 0 1 1-.95 0v-1.582H3.944a.475.475 0 0 1 0-.95h1.581v-1.58a.475.475 0 0 1 .95 0v1.58h1.581a.475.475 0 0 1 .475.475z" />
                </svg>
              </span>
              Add Page
            </button>
            {elementListMap.size > 1 && (
              <button
                id="delete-page"
                className={mobilePreview === true ? 'active' : ''}
                onClick={() => setMobilePreview(!mobilePreview)}
              >
                <span>
                  <svg
                    fill="#000000"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 100 100"
                    enableBackground="new 0 0 100 100"
                    xmlSpace="preserve"
                  >
                    <g>
                      <g>
                        <path
                          d="M73.603,78.553h-6.49h0c-0.956,0-1.73,0.774-1.73,1.73h-0.007v3.01H24.735V36.16h17.723c0.956,0,1.73-0.774,1.73-1.73
			V16.707h21.188l0,20.627h0.008c0.003,0.954,0.776,1.726,1.73,1.726h6.49c0.954,0,1.727-0.772,1.729-1.726h0V37.33l0-0.001
			c0,0,0-0.001,0-0.001l0-24.828h-0.005V8.48c0-0.956-0.774-1.73-1.73-1.73h-2.45v0H42.457v0h-1.73L14.778,32.7v2.447v1.013v52.912
			v2.447c0,0.956,0.774,1.73,1.73,1.73h1.582h53.925h1.582c0.956,0,1.73-0.774,1.73-1.73v-2.448h0.005l0-8.789l0-0.001
			C75.333,79.328,74.559,78.553,73.603,78.553z"
                        />
                      </g>
                      <g>
                        <path
                          d="M79.21,58.751l5.505-5.505c0.676-0.676,0.676-1.771,0-2.447l-4.894-4.893c-0.676-0.676-1.771-0.676-2.446,0l-5.505,5.505
			l-5.505-5.505c-0.676-0.676-1.77-0.676-2.446,0L59.026,50.8c-0.676,0.676-0.676,1.771,0,2.447l5.505,5.505l-5.505,5.505
			c-0.676,0.676-0.676,1.771,0,2.446l4.894,4.893c0.676,0.676,1.77,0.676,2.446,0l5.505-5.505l5.505,5.505
			c0.676,0.676,1.77,0.676,2.446,0l4.894-4.893c0.676-0.676,0.676-1.771,0-2.446L79.21,58.751z"
                        />
                      </g>
                    </g>
                  </svg>
                </span>
                Delete Page
              </button>
            )}
          </div>
        </div>

        {form}
        <div className="cm-pagination-wrapper">
          <div className="cm-pagination">
            <button className="btn-nav left-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="left-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </button>
            <div className="page-numbers">
              {elementListMap.size > 1 ? (
                Array.from(
                  { length: elementListMap.size },
                  (v, i) => `${i + 1}`,
                ).map((page, index) => (
                  <button
                    className={
                      activePage === index
                        ? 'btn-page btn-selected'
                        : 'btn-page'
                    }
                    onClick={() => setActivePage(index)}
                    key={`page_no_${index}`}
                  >
                    {page}
                  </button>
                ))
              ) : (
                <button className="btn-page" onClick={() => setActivePage(0)}>
                  1
                </button>
              )}
              {/* <button className="btn-page" onClick={() => setActivePage(2)}>
                2
              </button>
              <button
                className="btn-page btn-selected"
                onClick={() => setActivePage(3)}
              >
                3
              </button>
              <button className="btn-page" onClick={() => setActivePage(4)}>
                4
              </button> */}
            </div>
            <button className="btn-nav right-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="right-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
