import { FC, useEffect } from 'react';
import { Breadcrum } from '../../atoms/Breadcrum/Breadcrum';
import { Title } from '../../atoms/Title/Title';
import './Audience.scss';
// import Stepper from '../../atoms/Stepper/Stepper';
import { PdfAudience } from './PdfAudience/PdfAudience';
import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';
import { WebAudience } from './WebAudience/WebAudience';
import { EmailAudience } from './EmailAudience/EmailAudience';
import { useNavigate } from 'react-router-dom';

export const Audience: FC = () => {
  const creatingCampaignType = useSelector(
    (state: RootState) => state.createCampaignSlice.creatingCampaignType,
  );
  const navigate = useNavigate();
  useEffect(() => {
    creatingCampaignType === 'forms' && navigate('/formdesign');
  }, [navigate, creatingCampaignType]);

  return (
    <div className="audienceSocialmedia-container">
      <Breadcrum pageName={'Create a Campaign'} />

      {creatingCampaignType === 'web' && <WebAudience />}

      {creatingCampaignType === 'pdf' && (
        <>
          <Title text="Please provide your S3 connection details" />
          <PdfAudience />
        </>
      )}
      {creatingCampaignType === 'email' && <EmailAudience />}

      {/* <div className="navigationButtons">
        <Button name="Save as draft" />

        <Link to="/create-a-campaign">
          <PreviousButton />
        </Link>

        <Link to="/audience">
          <NextButton />
        </Link>
      </div> */}
    </div>
  );
};
